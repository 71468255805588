.faqs-page-list {
    .list-style {
        li {
            padding: 10px 0;
            border-bottom: 1px solid #e4e4e4;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}