.fa {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
}

.fa,
.fa-brands,
.fa-duotone,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fab,
.fad,
.fal,
.far,
.fas,
.fat {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-2xs {
    font-size: 0.625em;
    line-height: 0.1em;
    vertical-align: 0.225em;
}

.fa-xs {
    font-size: 0.75em;
    line-height: 0.08333em;
    vertical-align: 0.125em;
}

.fa-sm {
    font-size: 0.875em;
    line-height: 0.07143em;
    vertical-align: 0.05357em;
}

.fa-lg {
    font-size: 1.25em;
    line-height: 0.05em;
    vertical-align: -0.075em;
}

.fa-xl {
    font-size: 1.5em;
    line-height: 0.04167em;
    vertical-align: -0.125em;
}

.fa-2xl {
    font-size: 2em;
    line-height: 0.03125em;
    vertical-align: -0.1875em;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-ul {
    list-style-type: none;
    margin-left: var(--fa-li-margin, 2.5em);
    padding-left: 0;
}

.fa-ul>li {
    position: relative;
}

.fa-li {
    left: calc(var(--fa-li-width, 2em) * -1);
    position: absolute;
    text-align: center;
    width: var(--fa-li-width, 2em);
    line-height: inherit;
}

.fa-border {
    border-radius: var(--fa-border-radius, 0.1em);
    border: var(--fa-border-width, 0.08em) var(--fa-border-style, solid) var(--fa-border-color, #eee);
    padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
    float: left;
    margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
    float: right;
    margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
    -webkit-animation-name: fa-beat;
    animation-name: fa-beat;
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
    animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
    -webkit-animation-name: fa-bounce;
    animation-name: fa-bounce;
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
    animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
    -webkit-animation-name: fa-fade;
    animation-name: fa-fade;
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
    animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade,
.fa-fade {
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
}

.fa-beat-fade {
    -webkit-animation-name: fa-beat-fade;
    animation-name: fa-beat-fade;
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
    animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
    -webkit-animation-name: fa-flip;
    animation-name: fa-flip;
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
    animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
    -webkit-animation-name: fa-shake;
    animation-name: fa-shake;
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
    animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-shake,
.fa-spin {
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
}

.fa-spin {
    -webkit-animation-name: fa-spin;
    animation-name: fa-spin;
    -webkit-animation-duration: var(--fa-animation-duration, 2s);
    animation-duration: var(--fa-animation-duration, 2s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, linear);
    animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
    --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
    -webkit-animation-name: fa-spin;
    animation-name: fa-spin;
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
    animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {

    .fa-beat,
    .fa-beat-fade,
    .fa-bounce,
    .fa-fade,
    .fa-flip,
    .fa-pulse,
    .fa-shake,
    .fa-spin,
    .fa-spin-pulse {
        -webkit-animation-delay: -1ms;
        animation-delay: -1ms;
        -webkit-animation-duration: 1ms;
        animation-duration: 1ms;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        transition-delay: 0s;
        transition-duration: 0s;
    }
}

@-webkit-keyframes fa-beat {

    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25));
    }
}

@keyframes fa-beat {

    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25));
    }
}

@-webkit-keyframes fa-bounce {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }

    10% {
        -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    }

    30% {
        -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
        transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    }

    50% {
        -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    }

    57% {
        -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
        transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
    }

    64% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }

    to {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }
}

@keyframes fa-bounce {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }

    10% {
        -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    }

    30% {
        -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
        transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    }

    50% {
        -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    }

    57% {
        -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
        transform: scale(1) translateY(var(--fa-bounce-rebound, -0.125em));
    }

    64% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }

    to {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }
}

@-webkit-keyframes fa-fade {
    50% {
        opacity: var(--fa-fade-opacity, 0.4);
    }
}

@keyframes fa-fade {
    50% {
        opacity: var(--fa-fade-opacity, 0.4);
    }
}

@-webkit-keyframes fa-beat-fade {

    0%,
    to {
        opacity: var(--fa-beat-fade-opacity, 0.4);
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
        transform: scale(var(--fa-beat-fade-scale, 1.125));
    }
}

@keyframes fa-beat-fade {

    0%,
    to {
        opacity: var(--fa-beat-fade-opacity, 0.4);
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
        transform: scale(var(--fa-beat-fade-scale, 1.125));
    }
}

@-webkit-keyframes fa-flip {
    50% {
        -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
        transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    }
}

@keyframes fa-flip {
    50% {
        -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
        transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    }
}

@-webkit-keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }

    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }

    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }

    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }

    40%,
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }

    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }

    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }

    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }

    40%,
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

.fa-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.fa-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.fa-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.fa-flip-vertical {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
    -webkit-transform: scale(-1);
    transform: scale(-1);
}

.fa-rotate-by {
    -webkit-transform: rotate(var(--fa-rotate-angle, none));
    transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
    line-height: inherit;
}

.fa-stack-2x {
    font-size: 2em;
}

.fa-inverse {
    color: var(--fa-inverse, #fff);
}

.fa-0:before {
    content: "0";
}

.fa-1:before {
    content: "1";
}

.fa-2:before {
    content: "2";
}

.fa-3:before {
    content: "3";
}

.fa-4:before {
    content: "4";
}

.fa-5:before {
    content: "5";
}

.fa-6:before {
    content: "6";
}

.fa-7:before {
    content: "7";
}

.fa-8:before {
    content: "8";
}

.fa-9:before {
    content: "9";
}

.fa-a:before {
    content: "A";
}

.fa-address-book:before,
.fa-contact-book:before {
    content: "\f2b9";
}

.fa-address-card:before,
.fa-contact-card:before,
.fa-vcard:before {
    content: "\f2bb";
}

.fa-align-center:before {
    content: "\f037";
}

.fa-align-justify:before {
    content: "\f039";
}

.fa-align-left:before {
    content: "\f036";
}

.fa-align-right:before {
    content: "\f038";
}

.fa-anchor:before {
    content: "\f13d";
}

.fa-anchor-circle-check:before {
    content: "\e4aa";
}

.fa-anchor-circle-exclamation:before {
    content: "\e4ab";
}

.fa-anchor-circle-xmark:before {
    content: "\e4ac";
}

.fa-anchor-lock:before {
    content: "\e4ad";
}

.fa-angle-down:before {
    content: "\f107";
}

.fa-angle-left:before {
    content: "\f104";
}

.fa-angle-right:before {
    content: "\f105";
}

.fa-angle-up:before {
    content: "\f106";
}

.fa-angle-double-down:before,
.fa-angles-down:before {
    content: "\f103";
}

.fa-angle-double-left:before,
.fa-angles-left:before {
    content: "\f100";
}

.fa-angle-double-right:before,
.fa-angles-right:before {
    content: "\f101";
}

.fa-angle-double-up:before,
.fa-angles-up:before {
    content: "\f102";
}

.fa-ankh:before {
    content: "\f644";
}

.fa-apple-alt:before,
.fa-apple-whole:before {
    content: "\f5d1";
}

.fa-archway:before {
    content: "\f557";
}

.fa-arrow-down:before {
    content: "\f063";
}

.fa-arrow-down-1-9:before,
.fa-sort-numeric-asc:before,
.fa-sort-numeric-down:before {
    content: "\f162";
}

.fa-arrow-down-9-1:before,
.fa-sort-numeric-desc:before,
.fa-sort-numeric-down-alt:before {
    content: "\f886";
}

.fa-arrow-down-a-z:before,
.fa-sort-alpha-asc:before,
.fa-sort-alpha-down:before {
    content: "\f15d";
}

.fa-arrow-down-long:before,
.fa-long-arrow-down:before {
    content: "\f175";
}

.fa-arrow-down-short-wide:before,
.fa-sort-amount-desc:before,
.fa-sort-amount-down-alt:before {
    content: "\f884";
}

.fa-arrow-down-up-across-line:before {
    content: "\e4af";
}

.fa-arrow-down-up-lock:before {
    content: "\e4b0";
}

.fa-arrow-down-wide-short:before,
.fa-sort-amount-asc:before,
.fa-sort-amount-down:before {
    content: "\f160";
}

.fa-arrow-down-z-a:before,
.fa-sort-alpha-desc:before,
.fa-sort-alpha-down-alt:before {
    content: "\f881";
}

.fa-arrow-left:before {
    content: "\f060";
}

.fa-arrow-left-long:before,
.fa-long-arrow-left:before {
    content: "\f177";
}

.fa-arrow-pointer:before,
.fa-mouse-pointer:before {
    content: "\f245";
}

.fa-arrow-right:before {
    content: "\f061";
}

.fa-arrow-right-arrow-left:before,
.fa-exchange:before {
    content: "\f0ec";
}

.fa-arrow-right-from-bracket:before,
.fa-sign-out:before {
    content: "\f08b";
}

.fa-arrow-right-long:before,
.fa-long-arrow-right:before {
    content: "\f178";
}

.fa-arrow-right-to-bracket:before,
.fa-sign-in:before {
    content: "\f090";
}

.fa-arrow-right-to-city:before {
    content: "\e4b3";
}

.fa-arrow-left-rotate:before,
.fa-arrow-rotate-back:before,
.fa-arrow-rotate-backward:before,
.fa-arrow-rotate-left:before,
.fa-undo:before {
    content: "\f0e2";
}

.fa-arrow-right-rotate:before,
.fa-arrow-rotate-forward:before,
.fa-arrow-rotate-right:before,
.fa-redo:before {
    content: "\f01e";
}

.fa-arrow-trend-down:before {
    content: "\e097";
}

.fa-arrow-trend-up:before {
    content: "\e098";
}

.fa-arrow-turn-down:before,
.fa-level-down:before {
    content: "\f149";
}

.fa-arrow-turn-up:before,
.fa-level-up:before {
    content: "\f148";
}

.fa-arrow-up:before {
    content: "\f062";
}

.fa-arrow-up-1-9:before,
.fa-sort-numeric-up:before {
    content: "\f163";
}

.fa-arrow-up-9-1:before,
.fa-sort-numeric-up-alt:before {
    content: "\f887";
}

.fa-arrow-up-a-z:before,
.fa-sort-alpha-up:before {
    content: "\f15e";
}

.fa-arrow-up-from-bracket:before {
    content: "\e09a";
}

.fa-arrow-up-from-ground-water:before {
    content: "\e4b5";
}

.fa-arrow-up-from-water-pump:before {
    content: "\e4b6";
}

.fa-arrow-up-long:before,
.fa-long-arrow-up:before {
    content: "\f176";
}

.fa-arrow-up-right-dots:before {
    content: "\e4b7";
}

.fa-arrow-up-right-from-square:before,
.fa-external-link:before {
    content: "\f08e";
}

.fa-arrow-up-short-wide:before,
.fa-sort-amount-up-alt:before {
    content: "\f885";
}

.fa-arrow-up-wide-short:before,
.fa-sort-amount-up:before {
    content: "\f161";
}

.fa-arrow-up-z-a:before,
.fa-sort-alpha-up-alt:before {
    content: "\f882";
}

.fa-arrows-down-to-line:before {
    content: "\e4b8";
}

.fa-arrows-down-to-people:before {
    content: "\e4b9";
}

.fa-arrows-h:before,
.fa-arrows-left-right:before {
    content: "\f07e";
}

.fa-arrows-left-right-to-line:before {
    content: "\e4ba";
}

.fa-arrows-rotate:before,
.fa-refresh:before,
.fa-sync:before {
    content: "\f021";
}

.fa-arrows-spin:before {
    content: "\e4bb";
}

.fa-arrows-split-up-and-left:before {
    content: "\e4bc";
}

.fa-arrows-to-circle:before {
    content: "\e4bd";
}

.fa-arrows-to-dot:before {
    content: "\e4be";
}

.fa-arrows-to-eye:before {
    content: "\e4bf";
}

.fa-arrows-turn-right:before {
    content: "\e4c0";
}

.fa-arrows-turn-to-dots:before {
    content: "\e4c1";
}

.fa-arrows-up-down:before,
.fa-arrows-v:before {
    content: "\f07d";
}

.fa-arrows-up-down-left-right:before,
.fa-arrows:before {
    content: "\f047";
}

.fa-arrows-up-to-line:before {
    content: "\e4c2";
}

.fa-asterisk:before {
    content: "*";
}

.fa-at:before {
    content: "@";
}

.fa-atom:before {
    content: "\f5d2";
}

.fa-audio-description:before {
    content: "\f29e";
}

.fa-austral-sign:before {
    content: "\e0a9";
}

.fa-award:before {
    content: "\f559";
}

.fa-b:before {
    content: "B";
}

.fa-baby:before {
    content: "\f77c";
}

.fa-baby-carriage:before,
.fa-carriage-baby:before {
    content: "\f77d";
}

.fa-backward:before {
    content: "\f04a";
}

.fa-backward-fast:before,
.fa-fast-backward:before {
    content: "\f049";
}

.fa-backward-step:before,
.fa-step-backward:before {
    content: "\f048";
}

.fa-bacon:before {
    content: "\f7e5";
}

.fa-bacteria:before {
    content: "\e059";
}

.fa-bacterium:before {
    content: "\e05a";
}

.fa-bag-shopping:before,
.fa-shopping-bag:before {
    content: "\f290";
}

.fa-bahai:before {
    content: "\f666";
}

.fa-baht-sign:before {
    content: "\e0ac";
}

.fa-ban:before,
.fa-cancel:before {
    content: "\f05e";
}

.fa-ban-smoking:before,
.fa-smoking-ban:before {
    content: "\f54d";
}

.fa-band-aid:before,
.fa-bandage:before {
    content: "\f462";
}

.fa-barcode:before {
    content: "\f02a";
}

.fa-bars:before,
.fa-navicon:before {
    content: "\f0c9";
}

.fa-bars-progress:before,
.fa-tasks-alt:before {
    content: "\f828";
}

.fa-bars-staggered:before,
.fa-reorder:before,
.fa-stream:before {
    content: "\f550";
}

.fa-baseball-ball:before,
.fa-baseball:before {
    content: "\f433";
}

.fa-baseball-bat-ball:before {
    content: "\f432";
}

.fa-basket-shopping:before,
.fa-shopping-basket:before {
    content: "\f291";
}

.fa-basketball-ball:before,
.fa-basketball:before {
    content: "\f434";
}

.fa-bath:before,
.fa-bathtub:before {
    content: "\f2cd";
}

.fa-battery-0:before,
.fa-battery-empty:before {
    content: "\f244";
}

.fa-battery-5:before,
.fa-battery-full:before,
.fa-battery:before {
    content: "\f240";
}

.fa-battery-3:before,
.fa-battery-half:before {
    content: "\f242";
}

.fa-battery-2:before,
.fa-battery-quarter:before {
    content: "\f243";
}

.fa-battery-4:before,
.fa-battery-three-quarters:before {
    content: "\f241";
}

.fa-bed:before {
    content: "\f236";
}

.fa-bed-pulse:before,
.fa-procedures:before {
    content: "\f487";
}

.fa-beer-mug-empty:before,
.fa-beer:before {
    content: "\f0fc";
}

.fa-bell:before {
    content: "\f0f3";
}

.fa-bell-concierge:before,
.fa-concierge-bell:before {
    content: "\f562";
}

.fa-bell-slash:before {
    content: "\f1f6";
}

.fa-bezier-curve:before {
    content: "\f55b";
}

.fa-bicycle:before {
    content: "\f206";
}

.fa-binoculars:before {
    content: "\f1e5";
}

.fa-biohazard:before {
    content: "\f780";
}

.fa-bitcoin-sign:before {
    content: "\e0b4";
}

.fa-blender:before {
    content: "\f517";
}

.fa-blender-phone:before {
    content: "\f6b6";
}

.fa-blog:before {
    content: "\f781";
}

.fa-bold:before {
    content: "\f032";
}

.fa-bolt:before,
.fa-zap:before {
    content: "\f0e7";
}

.fa-bolt-lightning:before {
    content: "\e0b7";
}

.fa-bomb:before {
    content: "\f1e2";
}

.fa-bone:before {
    content: "\f5d7";
}

.fa-bong:before {
    content: "\f55c";
}

.fa-book:before {
    content: "\f02d";
}

.fa-atlas:before,
.fa-book-atlas:before {
    content: "\f558";
}

.fa-bible:before,
.fa-book-bible:before {
    content: "\f647";
}

.fa-book-bookmark:before {
    content: "\e0bb";
}

.fa-book-journal-whills:before,
.fa-journal-whills:before {
    content: "\f66a";
}

.fa-book-medical:before {
    content: "\f7e6";
}

.fa-book-open:before {
    content: "\f518";
}

.fa-book-open-reader:before,
.fa-book-reader:before {
    content: "\f5da";
}

.fa-book-quran:before,
.fa-quran:before {
    content: "\f687";
}

.fa-book-dead:before,
.fa-book-skull:before {
    content: "\f6b7";
}

.fa-bookmark:before {
    content: "\f02e";
}

.fa-border-all:before {
    content: "\f84c";
}

.fa-border-none:before {
    content: "\f850";
}

.fa-border-style:before,
.fa-border-top-left:before {
    content: "\f853";
}

.fa-bore-hole:before {
    content: "\e4c3";
}

.fa-bottle-droplet:before {
    content: "\e4c4";
}

.fa-bottle-water:before {
    content: "\e4c5";
}

.fa-bowl-food:before {
    content: "\e4c6";
}

.fa-bowl-rice:before {
    content: "\e2eb";
}

.fa-bowling-ball:before {
    content: "\f436";
}

.fa-box:before {
    content: "\f466";
}

.fa-archive:before,
.fa-box-archive:before {
    content: "\f187";
}

.fa-box-open:before {
    content: "\f49e";
}

.fa-box-tissue:before {
    content: "\e05b";
}

.fa-boxes-packing:before {
    content: "\e4c7";
}

.fa-boxes-alt:before,
.fa-boxes-stacked:before,
.fa-boxes:before {
    content: "\f468";
}

.fa-braille:before {
    content: "\f2a1";
}

.fa-brain:before {
    content: "\f5dc";
}

.fa-brazilian-real-sign:before {
    content: "\e46c";
}

.fa-bread-slice:before {
    content: "\f7ec";
}

.fa-bridge:before {
    content: "\e4c8";
}

.fa-bridge-circle-check:before {
    content: "\e4c9";
}

.fa-bridge-circle-exclamation:before {
    content: "\e4ca";
}

.fa-bridge-circle-xmark:before {
    content: "\e4cb";
}

.fa-bridge-lock:before {
    content: "\e4cc";
}

.fa-bridge-water:before {
    content: "\e4ce";
}

.fa-briefcase:before {
    content: "\f0b1";
}

.fa-briefcase-medical:before {
    content: "\f469";
}

.fa-broom:before {
    content: "\f51a";
}

.fa-broom-ball:before,
.fa-quidditch-broom-ball:before,
.fa-quidditch:before {
    content: "\f458";
}

.fa-brush:before {
    content: "\f55d";
}

.fa-bucket:before {
    content: "\e4cf";
}

.fa-bug:before {
    content: "\f188";
}

.fa-bug-slash:before {
    content: "\e490";
}

.fa-bugs:before {
    content: "\e4d0";
}

.fa-building:before {
    content: "\f1ad";
}

.fa-building-circle-arrow-right:before {
    content: "\e4d1";
}

.fa-building-circle-check:before {
    content: "\e4d2";
}

.fa-building-circle-exclamation:before {
    content: "\e4d3";
}

.fa-building-circle-xmark:before {
    content: "\e4d4";
}

.fa-bank:before,
.fa-building-columns:before,
.fa-institution:before,
.fa-museum:before,
.fa-university:before {
    content: "\f19c";
}

.fa-building-flag:before {
    content: "\e4d5";
}

.fa-building-lock:before {
    content: "\e4d6";
}

.fa-building-ngo:before {
    content: "\e4d7";
}

.fa-building-shield:before {
    content: "\e4d8";
}

.fa-building-un:before {
    content: "\e4d9";
}

.fa-building-user:before {
    content: "\e4da";
}

.fa-building-wheat:before {
    content: "\e4db";
}

.fa-bullhorn:before {
    content: "\f0a1";
}

.fa-bullseye:before {
    content: "\f140";
}

.fa-burger:before,
.fa-hamburger:before {
    content: "\f805";
}

.fa-burst:before {
    content: "\e4dc";
}

.fa-bus:before {
    content: "\f207";
}

.fa-bus-alt:before,
.fa-bus-simple:before {
    content: "\f55e";
}

.fa-briefcase-clock:before,
.fa-business-time:before {
    content: "\f64a";
}

.fa-c:before {
    content: "C";
}

.fa-birthday-cake:before,
.fa-cake-candles:before,
.fa-cake:before {
    content: "\f1fd";
}

.fa-calculator:before {
    content: "\f1ec";
}

.fa-calendar:before {
    content: "\f133";
}

.fa-calendar-check:before {
    content: "\f274";
}

.fa-calendar-day:before {
    content: "\f783";
}

.fa-calendar-alt:before,
.fa-calendar-days:before {
    content: "\f073";
}

.fa-calendar-minus:before {
    content: "\f272";
}

.fa-calendar-plus:before {
    content: "\f271";
}

.fa-calendar-week:before {
    content: "\f784";
}

.fa-calendar-times:before,
.fa-calendar-xmark:before {
    content: "\f273";
}

.fa-camera-alt:before,
.fa-camera:before {
    content: "\f030";
}

.fa-camera-retro:before {
    content: "\f083";
}

.fa-camera-rotate:before {
    content: "\e0d8";
}

.fa-campground:before {
    content: "\f6bb";
}

.fa-candy-cane:before {
    content: "\f786";
}

.fa-cannabis:before {
    content: "\f55f";
}

.fa-capsules:before {
    content: "\f46b";
}

.fa-automobile:before,
.fa-car:before {
    content: "\f1b9";
}

.fa-battery-car:before,
.fa-car-battery:before {
    content: "\f5df";
}

.fa-car-burst:before,
.fa-car-crash:before {
    content: "\f5e1";
}

.fa-car-on:before {
    content: "\e4dd";
}

.fa-car-alt:before,
.fa-car-rear:before {
    content: "\f5de";
}

.fa-car-side:before {
    content: "\f5e4";
}

.fa-car-tunnel:before {
    content: "\e4de";
}

.fa-caravan:before {
    content: "\f8ff";
}

.fa-caret-down:before {
    content: "\f0d7";
}

.fa-caret-left:before {
    content: "\f0d9";
}

.fa-caret-right:before {
    content: "\f0da";
}

.fa-caret-up:before {
    content: "\f0d8";
}

.fa-carrot:before {
    content: "\f787";
}

.fa-cart-arrow-down:before {
    content: "\f218";
}

.fa-cart-flatbed:before,
.fa-dolly-flatbed:before {
    content: "\f474";
}

.fa-cart-flatbed-suitcase:before,
.fa-luggage-cart:before {
    content: "\f59d";
}

.fa-cart-plus:before {
    content: "\f217";
}

.fa-cart-shopping:before,
.fa-shopping-cart:before {
    content: "\f07a";
}

.fa-cash-register:before {
    content: "\f788";
}

.fa-cat:before {
    content: "\f6be";
}

.fa-cedi-sign:before {
    content: "\e0df";
}

.fa-cent-sign:before {
    content: "\e3f5";
}

.fa-certificate:before {
    content: "\f0a3";
}

.fa-chair:before {
    content: "\f6c0";
}

.fa-blackboard:before,
.fa-chalkboard:before {
    content: "\f51b";
}

.fa-chalkboard-teacher:before,
.fa-chalkboard-user:before {
    content: "\f51c";
}

.fa-champagne-glasses:before,
.fa-glass-cheers:before {
    content: "\f79f";
}

.fa-charging-station:before {
    content: "\f5e7";
}

.fa-area-chart:before,
.fa-chart-area:before {
    content: "\f1fe";
}

.fa-bar-chart:before,
.fa-chart-bar:before {
    content: "\f080";
}

.fa-chart-column:before {
    content: "\e0e3";
}

.fa-chart-gantt:before {
    content: "\e0e4";
}

.fa-chart-line:before,
.fa-line-chart:before {
    content: "\f201";
}

.fa-chart-pie:before,
.fa-pie-chart:before {
    content: "\f200";
}

.fa-chart-simple:before {
    content: "\e473";
}

.fa-check:before {
    content: "\f00c";
}

.fa-check-double:before {
    content: "\f560";
}

.fa-check-to-slot:before,
.fa-vote-yea:before {
    content: "\f772";
}

.fa-cheese:before {
    content: "\f7ef";
}

.fa-chess:before {
    content: "\f439";
}

.fa-chess-bishop:before {
    content: "\f43a";
}

.fa-chess-board:before {
    content: "\f43c";
}

.fa-chess-king:before {
    content: "\f43f";
}

.fa-chess-knight:before {
    content: "\f441";
}

.fa-chess-pawn:before {
    content: "\f443";
}

.fa-chess-queen:before {
    content: "\f445";
}

.fa-chess-rook:before {
    content: "\f447";
}

.fa-chevron-down:before {
    content: "\f078";
}

.fa-chevron-left:before {
    content: "\f053";
}

.fa-chevron-right:before {
    content: "\f054";
}

.fa-chevron-up:before {
    content: "\f077";
}

.fa-child:before {
    content: "\f1ae";
}

.fa-child-dress:before {
    content: "\e59c";
}

.fa-child-reaching:before {
    content: "\e59d";
}

.fa-child-rifle:before {
    content: "\e4e0";
}

.fa-children:before {
    content: "\e4e1";
}

.fa-church:before {
    content: "\f51d";
}

.fa-circle:before {
    content: "\f111";
}

.fa-arrow-circle-down:before,
.fa-circle-arrow-down:before {
    content: "\f0ab";
}

.fa-arrow-circle-left:before,
.fa-circle-arrow-left:before {
    content: "\f0a8";
}

.fa-arrow-circle-right:before,
.fa-circle-arrow-right:before {
    content: "\f0a9";
}

.fa-arrow-circle-up:before,
.fa-circle-arrow-up:before {
    content: "\f0aa";
}

.fa-check-circle:before,
.fa-circle-check:before {
    content: "\f058";
}

.fa-chevron-circle-down:before,
.fa-circle-chevron-down:before {
    content: "\f13a";
}

.fa-chevron-circle-left:before,
.fa-circle-chevron-left:before {
    content: "\f137";
}

.fa-chevron-circle-right:before,
.fa-circle-chevron-right:before {
    content: "\f138";
}

.fa-chevron-circle-up:before,
.fa-circle-chevron-up:before {
    content: "\f139";
}

.fa-circle-dollar-to-slot:before,
.fa-donate:before {
    content: "\f4b9";
}

.fa-circle-dot:before,
.fa-dot-circle:before {
    content: "\f192";
}

.fa-arrow-alt-circle-down:before,
.fa-circle-down:before {
    content: "\f358";
}

.fa-circle-exclamation:before,
.fa-exclamation-circle:before {
    content: "\f06a";
}

.fa-circle-h:before,
.fa-hospital-symbol:before {
    content: "\f47e";
}

.fa-adjust:before,
.fa-circle-half-stroke:before {
    content: "\f042";
}

.fa-circle-info:before,
.fa-info-circle:before {
    content: "\f05a";
}

.fa-arrow-alt-circle-left:before,
.fa-circle-left:before {
    content: "\f359";
}

.fa-circle-minus:before,
.fa-minus-circle:before {
    content: "\f056";
}

.fa-circle-nodes:before {
    content: "\e4e2";
}

.fa-circle-notch:before {
    content: "\f1ce";
}

.fa-circle-pause:before,
.fa-pause-circle:before {
    content: "\f28b";
}

.fa-circle-play:before,
.fa-play-circle:before {
    content: "\f144";
}

.fa-circle-plus:before,
.fa-plus-circle:before {
    content: "\f055";
}

.fa-circle-question:before,
.fa-question-circle:before {
    content: "\f059";
}

.fa-circle-radiation:before,
.fa-radiation-alt:before {
    content: "\f7ba";
}

.fa-arrow-alt-circle-right:before,
.fa-circle-right:before {
    content: "\f35a";
}

.fa-circle-stop:before,
.fa-stop-circle:before {
    content: "\f28d";
}

.fa-arrow-alt-circle-up:before,
.fa-circle-up:before {
    content: "\f35b";
}

.fa-circle-user:before,
.fa-user-circle:before {
    content: "\f2bd";
}

.fa-circle-xmark:before,
.fa-times-circle:before,
.fa-xmark-circle:before {
    content: "\f057";
}

.fa-city:before {
    content: "\f64f";
}

.fa-clapperboard:before {
    content: "\e131";
}

.fa-clipboard:before {
    content: "\f328";
}

.fa-clipboard-check:before {
    content: "\f46c";
}

.fa-clipboard-list:before {
    content: "\f46d";
}

.fa-clipboard-question:before {
    content: "\e4e3";
}

.fa-clipboard-user:before {
    content: "\f7f3";
}

.fa-clock-four:before,
.fa-clock:before {
    content: "\f017";
}

.fa-clock-rotate-left:before,
.fa-history:before {
    content: "\f1da";
}

.fa-clone:before {
    content: "\f24d";
}

.fa-closed-captioning:before {
    content: "\f20a";
}

.fa-cloud:before {
    content: "\f0c2";
}

.fa-cloud-arrow-down:before,
.fa-cloud-download-alt:before,
.fa-cloud-download:before {
    content: "\f0ed";
}

.fa-cloud-arrow-up:before,
.fa-cloud-upload-alt:before,
.fa-cloud-upload:before {
    content: "\f0ee";
}

.fa-cloud-bolt:before,
.fa-thunderstorm:before {
    content: "\f76c";
}

.fa-cloud-meatball:before {
    content: "\f73b";
}

.fa-cloud-moon:before {
    content: "\f6c3";
}

.fa-cloud-moon-rain:before {
    content: "\f73c";
}

.fa-cloud-rain:before {
    content: "\f73d";
}

.fa-cloud-showers-heavy:before {
    content: "\f740";
}

.fa-cloud-showers-water:before {
    content: "\e4e4";
}

.fa-cloud-sun:before {
    content: "\f6c4";
}

.fa-cloud-sun-rain:before {
    content: "\f743";
}

.fa-clover:before {
    content: "\e139";
}

.fa-code:before {
    content: "\f121";
}

.fa-code-branch:before {
    content: "\f126";
}

.fa-code-commit:before {
    content: "\f386";
}

.fa-code-compare:before {
    content: "\e13a";
}

.fa-code-fork:before {
    content: "\e13b";
}

.fa-code-merge:before {
    content: "\f387";
}

.fa-code-pull-request:before {
    content: "\e13c";
}

.fa-coins:before {
    content: "\f51e";
}

.fa-colon-sign:before {
    content: "\e140";
}

.fa-comment:before {
    content: "\f075";
}

.fa-comment-dollar:before {
    content: "\f651";
}

.fa-comment-dots:before,
.fa-commenting:before {
    content: "\f4ad";
}

.fa-comment-medical:before {
    content: "\f7f5";
}

.fa-comment-slash:before {
    content: "\f4b3";
}

.fa-comment-sms:before,
.fa-sms:before {
    content: "\f7cd";
}

.fa-comments:before {
    content: "\f086";
}

.fa-comments-dollar:before {
    content: "\f653";
}

.fa-compact-disc:before {
    content: "\f51f";
}

.fa-compass:before {
    content: "\f14e";
}

.fa-compass-drafting:before,
.fa-drafting-compass:before {
    content: "\f568";
}

.fa-compress:before {
    content: "\f066";
}

.fa-computer:before {
    content: "\e4e5";
}

.fa-computer-mouse:before,
.fa-mouse:before {
    content: "\f8cc";
}

.fa-cookie:before {
    content: "\f563";
}

.fa-cookie-bite:before {
    content: "\f564";
}

.fa-copy:before {
    content: "\f0c5";
}

.fa-copyright:before {
    content: "\f1f9";
}

.fa-couch:before {
    content: "\f4b8";
}

.fa-cow:before {
    content: "\f6c8";
}

.fa-credit-card-alt:before,
.fa-credit-card:before {
    content: "\f09d";
}

.fa-crop:before {
    content: "\f125";
}

.fa-crop-alt:before,
.fa-crop-simple:before {
    content: "\f565";
}

.fa-cross:before {
    content: "\f654";
}

.fa-crosshairs:before {
    content: "\f05b";
}

.fa-crow:before {
    content: "\f520";
}

.fa-crown:before {
    content: "\f521";
}

.fa-crutch:before {
    content: "\f7f7";
}

.fa-cruzeiro-sign:before {
    content: "\e152";
}

.fa-cube:before {
    content: "\f1b2";
}

.fa-cubes:before {
    content: "\f1b3";
}

.fa-cubes-stacked:before {
    content: "\e4e6";
}

.fa-d:before {
    content: "D";
}

.fa-database:before {
    content: "\f1c0";
}

.fa-backspace:before,
.fa-delete-left:before {
    content: "\f55a";
}

.fa-democrat:before {
    content: "\f747";
}

.fa-desktop-alt:before,
.fa-desktop:before {
    content: "\f390";
}

.fa-dharmachakra:before {
    content: "\f655";
}

.fa-diagram-next:before {
    content: "\e476";
}

.fa-diagram-predecessor:before {
    content: "\e477";
}

.fa-diagram-project:before,
.fa-project-diagram:before {
    content: "\f542";
}

.fa-diagram-successor:before {
    content: "\e47a";
}

.fa-diamond:before {
    content: "\f219";
}

.fa-diamond-turn-right:before,
.fa-directions:before {
    content: "\f5eb";
}

.fa-dice:before {
    content: "\f522";
}

.fa-dice-d20:before {
    content: "\f6cf";
}

.fa-dice-d6:before {
    content: "\f6d1";
}

.fa-dice-five:before {
    content: "\f523";
}

.fa-dice-four:before {
    content: "\f524";
}

.fa-dice-one:before {
    content: "\f525";
}

.fa-dice-six:before {
    content: "\f526";
}

.fa-dice-three:before {
    content: "\f527";
}

.fa-dice-two:before {
    content: "\f528";
}

.fa-disease:before {
    content: "\f7fa";
}

.fa-display:before {
    content: "\e163";
}

.fa-divide:before {
    content: "\f529";
}

.fa-dna:before {
    content: "\f471";
}

.fa-dog:before {
    content: "\f6d3";
}

.fa-dollar-sign:before,
.fa-dollar:before,
.fa-usd:before {
    content: "$";
}

.fa-dolly-box:before,
.fa-dolly:before {
    content: "\f472";
}

.fa-dong-sign:before {
    content: "\e169";
}

.fa-door-closed:before {
    content: "\f52a";
}

.fa-door-open:before {
    content: "\f52b";
}

.fa-dove:before {
    content: "\f4ba";
}

.fa-compress-alt:before,
.fa-down-left-and-up-right-to-center:before {
    content: "\f422";
}

.fa-down-long:before,
.fa-long-arrow-alt-down:before {
    content: "\f309";
}

.fa-download:before {
    content: "\f019";
}

.fa-dragon:before {
    content: "\f6d5";
}

.fa-draw-polygon:before {
    content: "\f5ee";
}

.fa-droplet:before,
.fa-tint:before {
    content: "\f043";
}

.fa-droplet-slash:before,
.fa-tint-slash:before {
    content: "\f5c7";
}

.fa-drum:before {
    content: "\f569";
}

.fa-drum-steelpan:before {
    content: "\f56a";
}

.fa-drumstick-bite:before {
    content: "\f6d7";
}

.fa-dumbbell:before {
    content: "\f44b";
}

.fa-dumpster:before {
    content: "\f793";
}

.fa-dumpster-fire:before {
    content: "\f794";
}

.fa-dungeon:before {
    content: "\f6d9";
}

.fa-e:before {
    content: "E";
}

.fa-deaf:before,
.fa-deafness:before,
.fa-ear-deaf:before,
.fa-hard-of-hearing:before {
    content: "\f2a4";
}

.fa-assistive-listening-systems:before,
.fa-ear-listen:before {
    content: "\f2a2";
}

.fa-earth-africa:before,
.fa-globe-africa:before {
    content: "\f57c";
}

.fa-earth-america:before,
.fa-earth-americas:before,
.fa-earth:before,
.fa-globe-americas:before {
    content: "\f57d";
}

.fa-earth-asia:before,
.fa-globe-asia:before {
    content: "\f57e";
}

.fa-earth-europe:before,
.fa-globe-europe:before {
    content: "\f7a2";
}

.fa-earth-oceania:before,
.fa-globe-oceania:before {
    content: "\e47b";
}

.fa-egg:before {
    content: "\f7fb";
}

.fa-eject:before {
    content: "\f052";
}

.fa-elevator:before {
    content: "\e16d";
}

.fa-ellipsis-h:before,
.fa-ellipsis:before {
    content: "\f141";
}

.fa-ellipsis-v:before,
.fa-ellipsis-vertical:before {
    content: "\f142";
}

.fa-envelope:before {
    content: "\f0e0";
}

.fa-envelope-circle-check:before {
    content: "\e4e8";
}

.fa-envelope-open:before {
    content: "\f2b6";
}

.fa-envelope-open-text:before {
    content: "\f658";
}

.fa-envelopes-bulk:before,
.fa-mail-bulk:before {
    content: "\f674";
}

.fa-equals:before {
    content: "=";
}

.fa-eraser:before {
    content: "\f12d";
}

.fa-ethernet:before {
    content: "\f796";
}

.fa-eur:before,
.fa-euro-sign:before,
.fa-euro:before {
    content: "\f153";
}

.fa-exclamation:before {
    content: "!";
}

.fa-expand:before {
    content: "\f065";
}

.fa-explosion:before {
    content: "\e4e9";
}

.fa-eye:before {
    content: "\f06e";
}

.fa-eye-dropper-empty:before,
.fa-eye-dropper:before,
.fa-eyedropper:before {
    content: "\f1fb";
}

.fa-eye-low-vision:before,
.fa-low-vision:before {
    content: "\f2a8";
}

.fa-eye-slash:before {
    content: "\f070";
}

.fa-f:before {
    content: "F";
}

.fa-angry:before,
.fa-face-angry:before {
    content: "\f556";
}

.fa-dizzy:before,
.fa-face-dizzy:before {
    content: "\f567";
}

.fa-face-flushed:before,
.fa-flushed:before {
    content: "\f579";
}

.fa-face-frown:before,
.fa-frown:before {
    content: "\f119";
}

.fa-face-frown-open:before,
.fa-frown-open:before {
    content: "\f57a";
}

.fa-face-grimace:before,
.fa-grimace:before {
    content: "\f57f";
}

.fa-face-grin:before,
.fa-grin:before {
    content: "\f580";
}

.fa-face-grin-beam:before,
.fa-grin-beam:before {
    content: "\f582";
}

.fa-face-grin-beam-sweat:before,
.fa-grin-beam-sweat:before {
    content: "\f583";
}

.fa-face-grin-hearts:before,
.fa-grin-hearts:before {
    content: "\f584";
}

.fa-face-grin-squint:before,
.fa-grin-squint:before {
    content: "\f585";
}

.fa-face-grin-squint-tears:before,
.fa-grin-squint-tears:before {
    content: "\f586";
}

.fa-face-grin-stars:before,
.fa-grin-stars:before {
    content: "\f587";
}

.fa-face-grin-tears:before,
.fa-grin-tears:before {
    content: "\f588";
}

.fa-face-grin-tongue:before,
.fa-grin-tongue:before {
    content: "\f589";
}

.fa-face-grin-tongue-squint:before,
.fa-grin-tongue-squint:before {
    content: "\f58a";
}

.fa-face-grin-tongue-wink:before,
.fa-grin-tongue-wink:before {
    content: "\f58b";
}

.fa-face-grin-wide:before,
.fa-grin-alt:before {
    content: "\f581";
}

.fa-face-grin-wink:before,
.fa-grin-wink:before {
    content: "\f58c";
}

.fa-face-kiss:before,
.fa-kiss:before {
    content: "\f596";
}

.fa-face-kiss-beam:before,
.fa-kiss-beam:before {
    content: "\f597";
}

.fa-face-kiss-wink-heart:before,
.fa-kiss-wink-heart:before {
    content: "\f598";
}

.fa-face-laugh:before,
.fa-laugh:before {
    content: "\f599";
}

.fa-face-laugh-beam:before,
.fa-laugh-beam:before {
    content: "\f59a";
}

.fa-face-laugh-squint:before,
.fa-laugh-squint:before {
    content: "\f59b";
}

.fa-face-laugh-wink:before,
.fa-laugh-wink:before {
    content: "\f59c";
}

.fa-face-meh:before,
.fa-meh:before {
    content: "\f11a";
}

.fa-face-meh-blank:before,
.fa-meh-blank:before {
    content: "\f5a4";
}

.fa-face-rolling-eyes:before,
.fa-meh-rolling-eyes:before {
    content: "\f5a5";
}

.fa-face-sad-cry:before,
.fa-sad-cry:before {
    content: "\f5b3";
}

.fa-face-sad-tear:before,
.fa-sad-tear:before {
    content: "\f5b4";
}

.fa-face-smile:before,
.fa-smile:before {
    content: "\f118";
}

.fa-face-smile-beam:before,
.fa-smile-beam:before {
    content: "\f5b8";
}

.fa-face-smile-wink:before,
.fa-smile-wink:before {
    content: "\f4da";
}

.fa-face-surprise:before,
.fa-surprise:before {
    content: "\f5c2";
}

.fa-face-tired:before,
.fa-tired:before {
    content: "\f5c8";
}

.fa-fan:before {
    content: "\f863";
}

.fa-faucet:before {
    content: "\e005";
}

.fa-faucet-drip:before {
    content: "\e006";
}

.fa-fax:before {
    content: "\f1ac";
}

.fa-feather:before {
    content: "\f52d";
}

.fa-feather-alt:before,
.fa-feather-pointed:before {
    content: "\f56b";
}

.fa-ferry:before {
    content: "\e4ea";
}

.fa-file:before {
    content: "\f15b";
}

.fa-file-arrow-down:before,
.fa-file-download:before {
    content: "\f56d";
}

.fa-file-arrow-up:before,
.fa-file-upload:before {
    content: "\f574";
}

.fa-file-audio:before {
    content: "\f1c7";
}

.fa-file-circle-check:before {
    content: "\e493";
}

.fa-file-circle-exclamation:before {
    content: "\e4eb";
}

.fa-file-circle-minus:before {
    content: "\e4ed";
}

.fa-file-circle-plus:before {
    content: "\e4ee";
}

.fa-file-circle-question:before {
    content: "\e4ef";
}

.fa-file-circle-xmark:before {
    content: "\e494";
}

.fa-file-code:before {
    content: "\f1c9";
}

.fa-file-contract:before {
    content: "\f56c";
}

.fa-file-csv:before {
    content: "\f6dd";
}

.fa-file-excel:before {
    content: "\f1c3";
}

.fa-arrow-right-from-file:before,
.fa-file-export:before {
    content: "\f56e";
}

.fa-file-image:before {
    content: "\f1c5";
}

.fa-arrow-right-to-file:before,
.fa-file-import:before {
    content: "\f56f";
}

.fa-file-invoice:before {
    content: "\f570";
}

.fa-file-invoice-dollar:before {
    content: "\f571";
}

.fa-file-alt:before,
.fa-file-lines:before,
.fa-file-text:before {
    content: "\f15c";
}

.fa-file-medical:before {
    content: "\f477";
}

.fa-file-pdf:before {
    content: "\f1c1";
}

.fa-file-edit:before,
.fa-file-pen:before {
    content: "\f31c";
}

.fa-file-powerpoint:before {
    content: "\f1c4";
}

.fa-file-prescription:before {
    content: "\f572";
}

.fa-file-shield:before {
    content: "\e4f0";
}

.fa-file-signature:before {
    content: "\f573";
}

.fa-file-video:before {
    content: "\f1c8";
}

.fa-file-medical-alt:before,
.fa-file-waveform:before {
    content: "\f478";
}

.fa-file-word:before {
    content: "\f1c2";
}

.fa-file-archive:before,
.fa-file-zipper:before {
    content: "\f1c6";
}

.fa-fill:before {
    content: "\f575";
}

.fa-fill-drip:before {
    content: "\f576";
}

.fa-film:before {
    content: "\f008";
}

.fa-filter:before {
    content: "\f0b0";
}

.fa-filter-circle-dollar:before,
.fa-funnel-dollar:before {
    content: "\f662";
}

.fa-filter-circle-xmark:before {
    content: "\e17b";
}

.fa-fingerprint:before {
    content: "\f577";
}

.fa-fire:before {
    content: "\f06d";
}

.fa-fire-burner:before {
    content: "\e4f1";
}

.fa-fire-extinguisher:before {
    content: "\f134";
}

.fa-fire-alt:before,
.fa-fire-flame-curved:before {
    content: "\f7e4";
}

.fa-burn:before,
.fa-fire-flame-simple:before {
    content: "\f46a";
}

.fa-fish:before {
    content: "\f578";
}

.fa-fish-fins:before {
    content: "\e4f2";
}

.fa-flag:before {
    content: "\f024";
}

.fa-flag-checkered:before {
    content: "\f11e";
}

.fa-flag-usa:before {
    content: "\f74d";
}

.fa-flask:before {
    content: "\f0c3";
}

.fa-flask-vial:before {
    content: "\e4f3";
}

.fa-floppy-disk:before,
.fa-save:before {
    content: "\f0c7";
}

.fa-florin-sign:before {
    content: "\e184";
}

.fa-folder-blank:before,
.fa-folder:before {
    content: "\f07b";
}

.fa-folder-closed:before {
    content: "\e185";
}

.fa-folder-minus:before {
    content: "\f65d";
}

.fa-folder-open:before {
    content: "\f07c";
}

.fa-folder-plus:before {
    content: "\f65e";
}

.fa-folder-tree:before {
    content: "\f802";
}

.fa-font:before {
    content: "\f031";
}

.fa-football-ball:before,
.fa-football:before {
    content: "\f44e";
}

.fa-forward:before {
    content: "\f04e";
}

.fa-fast-forward:before,
.fa-forward-fast:before {
    content: "\f050";
}

.fa-forward-step:before,
.fa-step-forward:before {
    content: "\f051";
}

.fa-franc-sign:before {
    content: "\e18f";
}

.fa-frog:before {
    content: "\f52e";
}

.fa-futbol-ball:before,
.fa-futbol:before,
.fa-soccer-ball:before {
    content: "\f1e3";
}

.fa-g:before {
    content: "G";
}

.fa-gamepad:before {
    content: "\f11b";
}

.fa-gas-pump:before {
    content: "\f52f";
}

.fa-dashboard:before,
.fa-gauge-med:before,
.fa-gauge:before,
.fa-tachometer-alt-average:before {
    content: "\f624";
}

.fa-gauge-high:before,
.fa-tachometer-alt-fast:before,
.fa-tachometer-alt:before {
    content: "\f625";
}

.fa-gauge-simple-med:before,
.fa-gauge-simple:before,
.fa-tachometer-average:before {
    content: "\f629";
}

.fa-gauge-simple-high:before,
.fa-tachometer-fast:before,
.fa-tachometer:before {
    content: "\f62a";
}

.fa-gavel:before,
.fa-legal:before {
    content: "\f0e3";
}

.fa-cog:before,
.fa-gear:before {
    content: "\f013";
}

.fa-cogs:before,
.fa-gears:before {
    content: "\f085";
}

.fa-gem:before {
    content: "\f3a5";
}

.fa-genderless:before {
    content: "\f22d";
}

.fa-ghost:before {
    content: "\f6e2";
}

.fa-gift:before {
    content: "\f06b";
}

.fa-gifts:before {
    content: "\f79c";
}

.fa-glass-water:before {
    content: "\e4f4";
}

.fa-glass-water-droplet:before {
    content: "\e4f5";
}

.fa-glasses:before {
    content: "\f530";
}

.fa-globe:before {
    content: "\f0ac";
}

.fa-golf-ball-tee:before,
.fa-golf-ball:before {
    content: "\f450";
}

.fa-gopuram:before {
    content: "\f664";
}

.fa-graduation-cap:before,
.fa-mortar-board:before {
    content: "\f19d";
}

.fa-greater-than:before {
    content: ">";
}

.fa-greater-than-equal:before {
    content: "\f532";
}

.fa-grip-horizontal:before,
.fa-grip:before {
    content: "\f58d";
}

.fa-grip-lines:before {
    content: "\f7a4";
}

.fa-grip-lines-vertical:before {
    content: "\f7a5";
}

.fa-grip-vertical:before {
    content: "\f58e";
}

.fa-group-arrows-rotate:before {
    content: "\e4f6";
}

.fa-guarani-sign:before {
    content: "\e19a";
}

.fa-guitar:before {
    content: "\f7a6";
}

.fa-gun:before {
    content: "\e19b";
}

.fa-h:before {
    content: "H";
}

.fa-hammer:before {
    content: "\f6e3";
}

.fa-hamsa:before {
    content: "\f665";
}

.fa-hand-paper:before,
.fa-hand:before {
    content: "\f256";
}

.fa-hand-back-fist:before,
.fa-hand-rock:before {
    content: "\f255";
}

.fa-allergies:before,
.fa-hand-dots:before {
    content: "\f461";
}

.fa-fist-raised:before,
.fa-hand-fist:before {
    content: "\f6de";
}

.fa-hand-holding:before {
    content: "\f4bd";
}

.fa-hand-holding-dollar:before,
.fa-hand-holding-usd:before {
    content: "\f4c0";
}

.fa-hand-holding-droplet:before,
.fa-hand-holding-water:before {
    content: "\f4c1";
}

.fa-hand-holding-hand:before {
    content: "\e4f7";
}

.fa-hand-holding-heart:before {
    content: "\f4be";
}

.fa-hand-holding-medical:before {
    content: "\e05c";
}

.fa-hand-lizard:before {
    content: "\f258";
}

.fa-hand-middle-finger:before {
    content: "\f806";
}

.fa-hand-peace:before {
    content: "\f25b";
}

.fa-hand-point-down:before {
    content: "\f0a7";
}

.fa-hand-point-left:before {
    content: "\f0a5";
}

.fa-hand-point-right:before {
    content: "\f0a4";
}

.fa-hand-point-up:before {
    content: "\f0a6";
}

.fa-hand-pointer:before {
    content: "\f25a";
}

.fa-hand-scissors:before {
    content: "\f257";
}

.fa-hand-sparkles:before {
    content: "\e05d";
}

.fa-hand-spock:before {
    content: "\f259";
}

.fa-handcuffs:before {
    content: "\e4f8";
}

.fa-hands:before,
.fa-sign-language:before,
.fa-signing:before {
    content: "\f2a7";
}

.fa-american-sign-language-interpreting:before,
.fa-asl-interpreting:before,
.fa-hands-american-sign-language-interpreting:before,
.fa-hands-asl-interpreting:before {
    content: "\f2a3";
}

.fa-hands-bound:before {
    content: "\e4f9";
}

.fa-hands-bubbles:before,
.fa-hands-wash:before {
    content: "\e05e";
}

.fa-hands-clapping:before {
    content: "\e1a8";
}

.fa-hands-holding:before {
    content: "\f4c2";
}

.fa-hands-holding-child:before {
    content: "\e4fa";
}

.fa-hands-holding-circle:before {
    content: "\e4fb";
}

.fa-hands-praying:before,
.fa-praying-hands:before {
    content: "\f684";
}

.fa-handshake:before {
    content: "\f2b5";
}

.fa-hands-helping:before,
.fa-handshake-angle:before {
    content: "\f4c4";
}

.fa-handshake-alt:before,
.fa-handshake-simple:before {
    content: "\f4c6";
}

.fa-handshake-alt-slash:before,
.fa-handshake-simple-slash:before {
    content: "\e05f";
}

.fa-handshake-slash:before {
    content: "\e060";
}

.fa-hanukiah:before {
    content: "\f6e6";
}

.fa-hard-drive:before,
.fa-hdd:before {
    content: "\f0a0";
}

.fa-hashtag:before {
    content: "#";
}

.fa-hat-cowboy:before {
    content: "\f8c0";
}

.fa-hat-cowboy-side:before {
    content: "\f8c1";
}

.fa-hat-wizard:before {
    content: "\f6e8";
}

.fa-head-side-cough:before {
    content: "\e061";
}

.fa-head-side-cough-slash:before {
    content: "\e062";
}

.fa-head-side-mask:before {
    content: "\e063";
}

.fa-head-side-virus:before {
    content: "\e064";
}

.fa-header:before,
.fa-heading:before {
    content: "\f1dc";
}

.fa-headphones:before {
    content: "\f025";
}

.fa-headphones-alt:before,
.fa-headphones-simple:before {
    content: "\f58f";
}

.fa-headset:before {
    content: "\f590";
}

.fa-heart:before {
    content: "\f004";
}

.fa-heart-circle-bolt:before {
    content: "\e4fc";
}

.fa-heart-circle-check:before {
    content: "\e4fd";
}

.fa-heart-circle-exclamation:before {
    content: "\e4fe";
}

.fa-heart-circle-minus:before {
    content: "\e4ff";
}

.fa-heart-circle-plus:before {
    content: "\e500";
}

.fa-heart-circle-xmark:before {
    content: "\e501";
}

.fa-heart-broken:before,
.fa-heart-crack:before {
    content: "\f7a9";
}

.fa-heart-pulse:before,
.fa-heartbeat:before {
    content: "\f21e";
}

.fa-helicopter:before {
    content: "\f533";
}

.fa-helicopter-symbol:before {
    content: "\e502";
}

.fa-hard-hat:before,
.fa-hat-hard:before,
.fa-helmet-safety:before {
    content: "\f807";
}

.fa-helmet-un:before {
    content: "\e503";
}

.fa-highlighter:before {
    content: "\f591";
}

.fa-hill-avalanche:before {
    content: "\e507";
}

.fa-hill-rockslide:before {
    content: "\e508";
}

.fa-hippo:before {
    content: "\f6ed";
}

.fa-hockey-puck:before {
    content: "\f453";
}

.fa-holly-berry:before {
    content: "\f7aa";
}

.fa-horse:before {
    content: "\f6f0";
}

.fa-horse-head:before {
    content: "\f7ab";
}

.fa-hospital-alt:before,
.fa-hospital-wide:before,
.fa-hospital:before {
    content: "\f0f8";
}

.fa-hospital-user:before {
    content: "\f80d";
}

.fa-hot-tub-person:before,
.fa-hot-tub:before {
    content: "\f593";
}

.fa-hotdog:before {
    content: "\f80f";
}

.fa-hotel:before {
    content: "\f594";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before,
.fa-hourglass:before {
    content: "\f254";
}

.fa-hourglass-empty:before {
    content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
    content: "\f253";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
    content: "\f251";
}

.fa-home-alt:before,
.fa-home-lg-alt:before,
.fa-home:before,
.fa-house:before {
    content: "\f015";
}

.fa-home-lg:before,
.fa-house-chimney:before {
    content: "\e3af";
}

.fa-house-chimney-crack:before,
.fa-house-damage:before {
    content: "\f6f1";
}

.fa-clinic-medical:before,
.fa-house-chimney-medical:before {
    content: "\f7f2";
}

.fa-house-chimney-user:before {
    content: "\e065";
}

.fa-house-chimney-window:before {
    content: "\e00d";
}

.fa-house-circle-check:before {
    content: "\e509";
}

.fa-house-circle-exclamation:before {
    content: "\e50a";
}

.fa-house-circle-xmark:before {
    content: "\e50b";
}

.fa-house-crack:before {
    content: "\e3b1";
}

.fa-house-fire:before {
    content: "\e50c";
}

.fa-house-flag:before {
    content: "\e50d";
}

.fa-house-flood-water:before {
    content: "\e50e";
}

.fa-house-flood-water-circle-arrow-right:before {
    content: "\e50f";
}

.fa-house-laptop:before,
.fa-laptop-house:before {
    content: "\e066";
}

.fa-house-lock:before {
    content: "\e510";
}

.fa-house-medical:before {
    content: "\e3b2";
}

.fa-house-medical-circle-check:before {
    content: "\e511";
}

.fa-house-medical-circle-exclamation:before {
    content: "\e512";
}

.fa-house-medical-circle-xmark:before {
    content: "\e513";
}

.fa-house-medical-flag:before {
    content: "\e514";
}

.fa-house-signal:before {
    content: "\e012";
}

.fa-house-tsunami:before {
    content: "\e515";
}

.fa-home-user:before,
.fa-house-user:before {
    content: "\e1b0";
}

.fa-hryvnia-sign:before,
.fa-hryvnia:before {
    content: "\f6f2";
}

.fa-hurricane:before {
    content: "\f751";
}

.fa-i:before {
    content: "I";
}

.fa-i-cursor:before {
    content: "\f246";
}

.fa-ice-cream:before {
    content: "\f810";
}

.fa-icicles:before {
    content: "\f7ad";
}

.fa-heart-music-camera-bolt:before,
.fa-icons:before {
    content: "\f86d";
}

.fa-id-badge:before {
    content: "\f2c1";
}

.fa-drivers-license:before,
.fa-id-card:before {
    content: "\f2c2";
}

.fa-id-card-alt:before,
.fa-id-card-clip:before {
    content: "\f47f";
}

.fa-igloo:before {
    content: "\f7ae";
}

.fa-image:before {
    content: "\f03e";
}

.fa-image-portrait:before,
.fa-portrait:before {
    content: "\f3e0";
}

.fa-images:before {
    content: "\f302";
}

.fa-inbox:before {
    content: "\f01c";
}

.fa-indent:before {
    content: "\f03c";
}

.fa-indian-rupee-sign:before,
.fa-indian-rupee:before,
.fa-inr:before {
    content: "\e1bc";
}

.fa-industry:before {
    content: "\f275";
}

.fa-infinity:before {
    content: "\f534";
}

.fa-info:before {
    content: "\f129";
}

.fa-italic:before {
    content: "\f033";
}

.fa-j:before {
    content: "J";
}

.fa-jar:before {
    content: "\e516";
}

.fa-jar-wheat:before {
    content: "\e517";
}

.fa-jedi:before {
    content: "\f669";
}

.fa-fighter-jet:before,
.fa-jet-fighter:before {
    content: "\f0fb";
}

.fa-jet-fighter-up:before {
    content: "\e518";
}

.fa-joint:before {
    content: "\f595";
}

.fa-jug-detergent:before {
    content: "\e519";
}

.fa-k:before {
    content: "K";
}

.fa-kaaba:before {
    content: "\f66b";
}

.fa-key:before {
    content: "\f084";
}

.fa-keyboard:before {
    content: "\f11c";
}

.fa-khanda:before {
    content: "\f66d";
}

.fa-kip-sign:before {
    content: "\e1c4";
}

.fa-first-aid:before,
.fa-kit-medical:before {
    content: "\f479";
}

.fa-kitchen-set:before {
    content: "\e51a";
}

.fa-kiwi-bird:before {
    content: "\f535";
}

.fa-l:before {
    content: "L";
}

.fa-land-mine-on:before {
    content: "\e51b";
}

.fa-landmark:before {
    content: "\f66f";
}

.fa-landmark-alt:before,
.fa-landmark-dome:before {
    content: "\f752";
}

.fa-landmark-flag:before {
    content: "\e51c";
}

.fa-language:before {
    content: "\f1ab";
}

.fa-laptop:before {
    content: "\f109";
}

.fa-laptop-code:before {
    content: "\f5fc";
}

.fa-laptop-file:before {
    content: "\e51d";
}

.fa-laptop-medical:before {
    content: "\f812";
}

.fa-lari-sign:before {
    content: "\e1c8";
}

.fa-layer-group:before {
    content: "\f5fd";
}

.fa-leaf:before {
    content: "\f06c";
}

.fa-left-long:before,
.fa-long-arrow-alt-left:before {
    content: "\f30a";
}

.fa-arrows-alt-h:before,
.fa-left-right:before {
    content: "\f337";
}

.fa-lemon:before {
    content: "\f094";
}

.fa-less-than:before {
    content: "<";
}

.fa-less-than-equal:before {
    content: "\f537";
}

.fa-life-ring:before {
    content: "\f1cd";
}

.fa-lightbulb:before {
    content: "\f0eb";
}

.fa-lines-leaning:before {
    content: "\e51e";
}

.fa-chain:before,
.fa-link:before {
    content: "\f0c1";
}

.fa-chain-broken:before,
.fa-chain-slash:before,
.fa-link-slash:before,
.fa-unlink:before {
    content: "\f127";
}

.fa-lira-sign:before {
    content: "\f195";
}

.fa-list-squares:before,
.fa-list:before {
    content: "\f03a";
}

.fa-list-check:before,
.fa-tasks:before {
    content: "\f0ae";
}

.fa-list-1-2:before,
.fa-list-numeric:before,
.fa-list-ol:before {
    content: "\f0cb";
}

.fa-list-dots:before,
.fa-list-ul:before {
    content: "\f0ca";
}

.fa-litecoin-sign:before {
    content: "\e1d3";
}

.fa-location-arrow:before {
    content: "\f124";
}

.fa-location-crosshairs:before,
.fa-location:before {
    content: "\f601";
}

.fa-location-dot:before,
.fa-map-marker-alt:before {
    content: "\f3c5";
}

.fa-location-pin:before,
.fa-map-marker:before {
    content: "\f041";
}

.fa-location-pin-lock:before {
    content: "\e51f";
}

.fa-lock:before {
    content: "\f023";
}

.fa-lock-open:before {
    content: "\f3c1";
}

.fa-locust:before {
    content: "\e520";
}

.fa-lungs:before {
    content: "\f604";
}

.fa-lungs-virus:before {
    content: "\e067";
}

.fa-m:before {
    content: "M";
}

.fa-magnet:before {
    content: "\f076";
}

.fa-magnifying-glass:before,
.fa-search:before {
    content: "\f002";
}

.fa-magnifying-glass-arrow-right:before {
    content: "\e521";
}

.fa-magnifying-glass-chart:before {
    content: "\e522";
}

.fa-magnifying-glass-dollar:before,
.fa-search-dollar:before {
    content: "\f688";
}

.fa-magnifying-glass-location:before,
.fa-search-location:before {
    content: "\f689";
}

.fa-magnifying-glass-minus:before,
.fa-search-minus:before {
    content: "\f010";
}

.fa-magnifying-glass-plus:before,
.fa-search-plus:before {
    content: "\f00e";
}

.fa-manat-sign:before {
    content: "\e1d5";
}

.fa-map:before {
    content: "\f279";
}

.fa-map-location:before,
.fa-map-marked:before {
    content: "\f59f";
}

.fa-map-location-dot:before,
.fa-map-marked-alt:before {
    content: "\f5a0";
}

.fa-map-pin:before {
    content: "\f276";
}

.fa-marker:before {
    content: "\f5a1";
}

.fa-mars:before {
    content: "\f222";
}

.fa-mars-and-venus:before {
    content: "\f224";
}

.fa-mars-and-venus-burst:before {
    content: "\e523";
}

.fa-mars-double:before {
    content: "\f227";
}

.fa-mars-stroke:before {
    content: "\f229";
}

.fa-mars-stroke-h:before,
.fa-mars-stroke-right:before {
    content: "\f22b";
}

.fa-mars-stroke-up:before,
.fa-mars-stroke-v:before {
    content: "\f22a";
}

.fa-glass-martini-alt:before,
.fa-martini-glass:before {
    content: "\f57b";
}

.fa-cocktail:before,
.fa-martini-glass-citrus:before {
    content: "\f561";
}

.fa-glass-martini:before,
.fa-martini-glass-empty:before {
    content: "\f000";
}

.fa-mask:before {
    content: "\f6fa";
}

.fa-mask-face:before {
    content: "\e1d7";
}

.fa-mask-ventilator:before {
    content: "\e524";
}

.fa-masks-theater:before,
.fa-theater-masks:before {
    content: "\f630";
}

.fa-mattress-pillow:before {
    content: "\e525";
}

.fa-expand-arrows-alt:before,
.fa-maximize:before {
    content: "\f31e";
}

.fa-medal:before {
    content: "\f5a2";
}

.fa-memory:before {
    content: "\f538";
}

.fa-menorah:before {
    content: "\f676";
}

.fa-mercury:before {
    content: "\f223";
}

.fa-comment-alt:before,
.fa-message:before {
    content: "\f27a";
}

.fa-meteor:before {
    content: "\f753";
}

.fa-microchip:before {
    content: "\f2db";
}

.fa-microphone:before {
    content: "\f130";
}

.fa-microphone-alt:before,
.fa-microphone-lines:before {
    content: "\f3c9";
}

.fa-microphone-alt-slash:before,
.fa-microphone-lines-slash:before {
    content: "\f539";
}

.fa-microphone-slash:before {
    content: "\f131";
}

.fa-microscope:before {
    content: "\f610";
}

.fa-mill-sign:before {
    content: "\e1ed";
}

.fa-compress-arrows-alt:before,
.fa-minimize:before {
    content: "\f78c";
}

.fa-minus:before,
.fa-subtract:before {
    content: "\f068";
}

.fa-mitten:before {
    content: "\f7b5";
}

.fa-mobile-android:before,
.fa-mobile-phone:before,
.fa-mobile:before {
    content: "\f3ce";
}

.fa-mobile-button:before {
    content: "\f10b";
}

.fa-mobile-retro:before {
    content: "\e527";
}

.fa-mobile-android-alt:before,
.fa-mobile-screen:before {
    content: "\f3cf";
}

.fa-mobile-alt:before,
.fa-mobile-screen-button:before {
    content: "\f3cd";
}

.fa-money-bill:before {
    content: "\f0d6";
}

.fa-money-bill-1:before,
.fa-money-bill-alt:before {
    content: "\f3d1";
}

.fa-money-bill-1-wave:before,
.fa-money-bill-wave-alt:before {
    content: "\f53b";
}

.fa-money-bill-transfer:before {
    content: "\e528";
}

.fa-money-bill-trend-up:before {
    content: "\e529";
}

.fa-money-bill-wave:before {
    content: "\f53a";
}

.fa-money-bill-wheat:before {
    content: "\e52a";
}

.fa-money-bills:before {
    content: "\e1f3";
}

.fa-money-check:before {
    content: "\f53c";
}

.fa-money-check-alt:before,
.fa-money-check-dollar:before {
    content: "\f53d";
}

.fa-monument:before {
    content: "\f5a6";
}

.fa-moon:before {
    content: "\f186";
}

.fa-mortar-pestle:before {
    content: "\f5a7";
}

.fa-mosque:before {
    content: "\f678";
}

.fa-mosquito:before {
    content: "\e52b";
}

.fa-mosquito-net:before {
    content: "\e52c";
}

.fa-motorcycle:before {
    content: "\f21c";
}

.fa-mound:before {
    content: "\e52d";
}

.fa-mountain:before {
    content: "\f6fc";
}

.fa-mountain-city:before {
    content: "\e52e";
}

.fa-mountain-sun:before {
    content: "\e52f";
}

.fa-mug-hot:before {
    content: "\f7b6";
}

.fa-coffee:before,
.fa-mug-saucer:before {
    content: "\f0f4";
}

.fa-music:before {
    content: "\f001";
}

.fa-n:before {
    content: "N";
}

.fa-naira-sign:before {
    content: "\e1f6";
}

.fa-network-wired:before {
    content: "\f6ff";
}

.fa-neuter:before {
    content: "\f22c";
}

.fa-newspaper:before {
    content: "\f1ea";
}

.fa-not-equal:before {
    content: "\f53e";
}

.fa-note-sticky:before,
.fa-sticky-note:before {
    content: "\f249";
}

.fa-notes-medical:before {
    content: "\f481";
}

.fa-o:before {
    content: "O";
}

.fa-object-group:before {
    content: "\f247";
}

.fa-object-ungroup:before {
    content: "\f248";
}

.fa-oil-can:before {
    content: "\f613";
}

.fa-oil-well:before {
    content: "\e532";
}

.fa-om:before {
    content: "\f679";
}

.fa-otter:before {
    content: "\f700";
}

.fa-dedent:before,
.fa-outdent:before {
    content: "\f03b";
}

.fa-p:before {
    content: "P";
}

.fa-pager:before {
    content: "\f815";
}

.fa-paint-roller:before {
    content: "\f5aa";
}

.fa-paint-brush:before,
.fa-paintbrush:before {
    content: "\f1fc";
}

.fa-palette:before {
    content: "\f53f";
}

.fa-pallet:before {
    content: "\f482";
}

.fa-panorama:before {
    content: "\e209";
}

.fa-paper-plane:before {
    content: "\f1d8";
}

.fa-paperclip:before {
    content: "\f0c6";
}

.fa-parachute-box:before {
    content: "\f4cd";
}

.fa-paragraph:before {
    content: "\f1dd";
}

.fa-passport:before {
    content: "\f5ab";
}

.fa-file-clipboard:before,
.fa-paste:before {
    content: "\f0ea";
}

.fa-pause:before {
    content: "\f04c";
}

.fa-paw:before {
    content: "\f1b0";
}

.fa-peace:before {
    content: "\f67c";
}

.fa-pen:before {
    content: "\f304";
}

.fa-pen-alt:before,
.fa-pen-clip:before {
    content: "\f305";
}

.fa-pen-fancy:before {
    content: "\f5ac";
}

.fa-pen-nib:before {
    content: "\f5ad";
}

.fa-pen-ruler:before,
.fa-pencil-ruler:before {
    content: "\f5ae";
}

.fa-edit:before,
.fa-pen-to-square:before {
    content: "\f044";
}

.fa-pencil-alt:before,
.fa-pencil:before {
    content: "\f303";
}

.fa-people-arrows-left-right:before,
.fa-people-arrows:before {
    content: "\e068";
}

.fa-people-carry-box:before,
.fa-people-carry:before {
    content: "\f4ce";
}

.fa-people-group:before {
    content: "\e533";
}

.fa-people-line:before {
    content: "\e534";
}

.fa-people-pulling:before {
    content: "\e535";
}

.fa-people-robbery:before {
    content: "\e536";
}

.fa-people-roof:before {
    content: "\e537";
}

.fa-pepper-hot:before {
    content: "\f816";
}

.fa-percent:before,
.fa-percentage:before {
    content: "%";
}

.fa-male:before,
.fa-person:before {
    content: "\f183";
}

.fa-person-arrow-down-to-line:before {
    content: "\e538";
}

.fa-person-arrow-up-from-line:before {
    content: "\e539";
}

.fa-biking:before,
.fa-person-biking:before {
    content: "\f84a";
}

.fa-person-booth:before {
    content: "\f756";
}

.fa-person-breastfeeding:before {
    content: "\e53a";
}

.fa-person-burst:before {
    content: "\e53b";
}

.fa-person-cane:before {
    content: "\e53c";
}

.fa-person-chalkboard:before {
    content: "\e53d";
}

.fa-person-circle-check:before {
    content: "\e53e";
}

.fa-person-circle-exclamation:before {
    content: "\e53f";
}

.fa-person-circle-minus:before {
    content: "\e540";
}

.fa-person-circle-plus:before {
    content: "\e541";
}

.fa-person-circle-question:before {
    content: "\e542";
}

.fa-person-circle-xmark:before {
    content: "\e543";
}

.fa-digging:before,
.fa-person-digging:before {
    content: "\f85e";
}

.fa-diagnoses:before,
.fa-person-dots-from-line:before {
    content: "\f470";
}

.fa-female:before,
.fa-person-dress:before {
    content: "\f182";
}

.fa-person-dress-burst:before {
    content: "\e544";
}

.fa-person-drowning:before {
    content: "\e545";
}

.fa-person-falling:before {
    content: "\e546";
}

.fa-person-falling-burst:before {
    content: "\e547";
}

.fa-person-half-dress:before {
    content: "\e548";
}

.fa-person-harassing:before {
    content: "\e549";
}

.fa-hiking:before,
.fa-person-hiking:before {
    content: "\f6ec";
}

.fa-person-military-pointing:before {
    content: "\e54a";
}

.fa-person-military-rifle:before {
    content: "\e54b";
}

.fa-person-military-to-person:before {
    content: "\e54c";
}

.fa-person-praying:before,
.fa-pray:before {
    content: "\f683";
}

.fa-person-pregnant:before {
    content: "\e31e";
}

.fa-person-rays:before {
    content: "\e54d";
}

.fa-person-rifle:before {
    content: "\e54e";
}

.fa-person-running:before,
.fa-running:before {
    content: "\f70c";
}

.fa-person-shelter:before {
    content: "\e54f";
}

.fa-person-skating:before,
.fa-skating:before {
    content: "\f7c5";
}

.fa-person-skiing:before,
.fa-skiing:before {
    content: "\f7c9";
}

.fa-person-skiing-nordic:before,
.fa-skiing-nordic:before {
    content: "\f7ca";
}

.fa-person-snowboarding:before,
.fa-snowboarding:before {
    content: "\f7ce";
}

.fa-person-swimming:before,
.fa-swimmer:before {
    content: "\f5c4";
}

.fa-person-through-window:before {
    content: "\e433";
}

.fa-person-walking:before,
.fa-walking:before {
    content: "\f554";
}

.fa-person-walking-arrow-loop-left:before {
    content: "\e551";
}

.fa-person-walking-arrow-right:before {
    content: "\e552";
}

.fa-person-walking-dashed-line-arrow-right:before {
    content: "\e553";
}

.fa-person-walking-luggage:before {
    content: "\e554";
}

.fa-blind:before,
.fa-person-walking-with-cane:before {
    content: "\f29d";
}

.fa-peseta-sign:before {
    content: "\e221";
}

.fa-peso-sign:before {
    content: "\e222";
}

.fa-phone:before {
    content: "\f095";
}

.fa-phone-alt:before,
.fa-phone-flip:before {
    content: "\f879";
}

.fa-phone-slash:before {
    content: "\f3dd";
}

.fa-phone-volume:before,
.fa-volume-control-phone:before {
    content: "\f2a0";
}

.fa-photo-film:before,
.fa-photo-video:before {
    content: "\f87c";
}

.fa-piggy-bank:before {
    content: "\f4d3";
}

.fa-pills:before {
    content: "\f484";
}

.fa-pizza-slice:before {
    content: "\f818";
}

.fa-place-of-worship:before {
    content: "\f67f";
}

.fa-plane:before {
    content: "\f072";
}

.fa-plane-arrival:before {
    content: "\f5af";
}

.fa-plane-circle-check:before {
    content: "\e555";
}

.fa-plane-circle-exclamation:before {
    content: "\e556";
}

.fa-plane-circle-xmark:before {
    content: "\e557";
}

.fa-plane-departure:before {
    content: "\f5b0";
}

.fa-plane-lock:before {
    content: "\e558";
}

.fa-plane-slash:before {
    content: "\e069";
}

.fa-plane-up:before {
    content: "\e22d";
}

.fa-plant-wilt:before {
    content: "\e43b";
}

.fa-plate-wheat:before {
    content: "\e55a";
}

.fa-play:before {
    content: "\f04b";
}

.fa-plug:before {
    content: "\f1e6";
}

.fa-plug-circle-bolt:before {
    content: "\e55b";
}

.fa-plug-circle-check:before {
    content: "\e55c";
}

.fa-plug-circle-exclamation:before {
    content: "\e55d";
}

.fa-plug-circle-minus:before {
    content: "\e55e";
}

.fa-plug-circle-plus:before {
    content: "\e55f";
}

.fa-plug-circle-xmark:before {
    content: "\e560";
}

.fa-add:before,
.fa-plus:before {
    content: "+";
}

.fa-plus-minus:before {
    content: "\e43c";
}

.fa-podcast:before {
    content: "\f2ce";
}

.fa-poo:before {
    content: "\f2fe";
}

.fa-poo-bolt:before,
.fa-poo-storm:before {
    content: "\f75a";
}

.fa-poop:before {
    content: "\f619";
}

.fa-power-off:before {
    content: "\f011";
}

.fa-prescription:before {
    content: "\f5b1";
}

.fa-prescription-bottle:before {
    content: "\f485";
}

.fa-prescription-bottle-alt:before,
.fa-prescription-bottle-medical:before {
    content: "\f486";
}

.fa-print:before {
    content: "\f02f";
}

.fa-pump-medical:before {
    content: "\e06a";
}

.fa-pump-soap:before {
    content: "\e06b";
}

.fa-puzzle-piece:before {
    content: "\f12e";
}

.fa-q:before {
    content: "Q";
}

.fa-qrcode:before {
    content: "\f029";
}

.fa-question:before {
    content: "?";
}

.fa-quote-left-alt:before,
.fa-quote-left:before {
    content: "\f10d";
}

.fa-quote-right-alt:before,
.fa-quote-right:before {
    content: "\f10e";
}

.fa-r:before {
    content: "R";
}

.fa-radiation:before {
    content: "\f7b9";
}

.fa-radio:before {
    content: "\f8d7";
}

.fa-rainbow:before {
    content: "\f75b";
}

.fa-ranking-star:before {
    content: "\e561";
}

.fa-receipt:before {
    content: "\f543";
}

.fa-record-vinyl:before {
    content: "\f8d9";
}

.fa-ad:before,
.fa-rectangle-ad:before {
    content: "\f641";
}

.fa-list-alt:before,
.fa-rectangle-list:before {
    content: "\f022";
}

.fa-rectangle-times:before,
.fa-rectangle-xmark:before,
.fa-times-rectangle:before,
.fa-window-close:before {
    content: "\f410";
}

.fa-recycle:before {
    content: "\f1b8";
}

.fa-registered:before {
    content: "\f25d";
}

.fa-repeat:before {
    content: "\f363";
}

.fa-mail-reply:before,
.fa-reply:before {
    content: "\f3e5";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
    content: "\f122";
}

.fa-republican:before {
    content: "\f75e";
}

.fa-restroom:before {
    content: "\f7bd";
}

.fa-retweet:before {
    content: "\f079";
}

.fa-ribbon:before {
    content: "\f4d6";
}

.fa-right-from-bracket:before,
.fa-sign-out-alt:before {
    content: "\f2f5";
}

.fa-exchange-alt:before,
.fa-right-left:before {
    content: "\f362";
}

.fa-long-arrow-alt-right:before,
.fa-right-long:before {
    content: "\f30b";
}

.fa-right-to-bracket:before,
.fa-sign-in-alt:before {
    content: "\f2f6";
}

.fa-ring:before {
    content: "\f70b";
}

.fa-road:before {
    content: "\f018";
}

.fa-road-barrier:before {
    content: "\e562";
}

.fa-road-bridge:before {
    content: "\e563";
}

.fa-road-circle-check:before {
    content: "\e564";
}

.fa-road-circle-exclamation:before {
    content: "\e565";
}

.fa-road-circle-xmark:before {
    content: "\e566";
}

.fa-road-lock:before {
    content: "\e567";
}

.fa-road-spikes:before {
    content: "\e568";
}

.fa-robot:before {
    content: "\f544";
}

.fa-rocket:before {
    content: "\f135";
}

.fa-rotate:before,
.fa-sync-alt:before {
    content: "\f2f1";
}

.fa-rotate-back:before,
.fa-rotate-backward:before,
.fa-rotate-left:before,
.fa-undo-alt:before {
    content: "\f2ea";
}

.fa-redo-alt:before,
.fa-rotate-forward:before,
.fa-rotate-right:before {
    content: "\f2f9";
}

.fa-route:before {
    content: "\f4d7";
}

.fa-feed:before,
.fa-rss:before {
    content: "\f09e";
}

.fa-rouble:before,
.fa-rub:before,
.fa-ruble-sign:before,
.fa-ruble:before {
    content: "\f158";
}

.fa-rug:before {
    content: "\e569";
}

.fa-ruler:before {
    content: "\f545";
}

.fa-ruler-combined:before {
    content: "\f546";
}

.fa-ruler-horizontal:before {
    content: "\f547";
}

.fa-ruler-vertical:before {
    content: "\f548";
}

.fa-rupee-sign:before,
.fa-rupee:before {
    content: "\f156";
}

.fa-rupiah-sign:before {
    content: "\e23d";
}

.fa-s:before {
    content: "S";
}

.fa-sack-dollar:before {
    content: "\f81d";
}

.fa-sack-xmark:before {
    content: "\e56a";
}

.fa-sailboat:before {
    content: "\e445";
}

.fa-satellite:before {
    content: "\f7bf";
}

.fa-satellite-dish:before {
    content: "\f7c0";
}

.fa-balance-scale:before,
.fa-scale-balanced:before {
    content: "\f24e";
}

.fa-balance-scale-left:before,
.fa-scale-unbalanced:before {
    content: "\f515";
}

.fa-balance-scale-right:before,
.fa-scale-unbalanced-flip:before {
    content: "\f516";
}

.fa-school:before {
    content: "\f549";
}

.fa-school-circle-check:before {
    content: "\e56b";
}

.fa-school-circle-exclamation:before {
    content: "\e56c";
}

.fa-school-circle-xmark:before {
    content: "\e56d";
}

.fa-school-flag:before {
    content: "\e56e";
}

.fa-school-lock:before {
    content: "\e56f";
}

.fa-cut:before,
.fa-scissors:before {
    content: "\f0c4";
}

.fa-screwdriver:before {
    content: "\f54a";
}

.fa-screwdriver-wrench:before,
.fa-tools:before {
    content: "\f7d9";
}

.fa-scroll:before {
    content: "\f70e";
}

.fa-scroll-torah:before,
.fa-torah:before {
    content: "\f6a0";
}

.fa-sd-card:before {
    content: "\f7c2";
}

.fa-section:before {
    content: "\e447";
}

.fa-seedling:before,
.fa-sprout:before {
    content: "\f4d8";
}

.fa-server:before {
    content: "\f233";
}

.fa-shapes:before,
.fa-triangle-circle-square:before {
    content: "\f61f";
}

.fa-arrow-turn-right:before,
.fa-mail-forward:before,
.fa-share:before {
    content: "\f064";
}

.fa-share-from-square:before,
.fa-share-square:before {
    content: "\f14d";
}

.fa-share-alt:before,
.fa-share-nodes:before {
    content: "\f1e0";
}

.fa-sheet-plastic:before {
    content: "\e571";
}

.fa-ils:before,
.fa-shekel-sign:before,
.fa-shekel:before,
.fa-sheqel-sign:before,
.fa-sheqel:before {
    content: "\f20b";
}

.fa-shield-blank:before,
.fa-shield:before {
    content: "\f132";
}

.fa-shield-cat:before {
    content: "\e572";
}

.fa-shield-dog:before {
    content: "\e573";
}

.fa-shield-alt:before,
.fa-shield-halved:before {
    content: "\f3ed";
}

.fa-shield-heart:before {
    content: "\e574";
}

.fa-shield-virus:before {
    content: "\e06c";
}

.fa-ship:before {
    content: "\f21a";
}

.fa-shirt:before,
.fa-t-shirt:before,
.fa-tshirt:before {
    content: "\f553";
}

.fa-shoe-prints:before {
    content: "\f54b";
}

.fa-shop:before,
.fa-store-alt:before {
    content: "\f54f";
}

.fa-shop-lock:before {
    content: "\e4a5";
}

.fa-shop-slash:before,
.fa-store-alt-slash:before {
    content: "\e070";
}

.fa-shower:before {
    content: "\f2cc";
}

.fa-shrimp:before {
    content: "\e448";
}

.fa-random:before,
.fa-shuffle:before {
    content: "\f074";
}

.fa-shuttle-space:before,
.fa-space-shuttle:before {
    content: "\f197";
}

.fa-sign-hanging:before,
.fa-sign:before {
    content: "\f4d9";
}

.fa-signal-5:before,
.fa-signal-perfect:before,
.fa-signal:before {
    content: "\f012";
}

.fa-signature:before {
    content: "\f5b7";
}

.fa-map-signs:before,
.fa-signs-post:before {
    content: "\f277";
}

.fa-sim-card:before {
    content: "\f7c4";
}

.fa-sink:before {
    content: "\e06d";
}

.fa-sitemap:before {
    content: "\f0e8";
}

.fa-skull:before {
    content: "\f54c";
}

.fa-skull-crossbones:before {
    content: "\f714";
}

.fa-slash:before {
    content: "\f715";
}

.fa-sleigh:before {
    content: "\f7cc";
}

.fa-sliders-h:before,
.fa-sliders:before {
    content: "\f1de";
}

.fa-smog:before {
    content: "\f75f";
}

.fa-smoking:before {
    content: "\f48d";
}

.fa-snowflake:before {
    content: "\f2dc";
}

.fa-snowman:before {
    content: "\f7d0";
}

.fa-snowplow:before {
    content: "\f7d2";
}

.fa-soap:before {
    content: "\e06e";
}

.fa-socks:before {
    content: "\f696";
}

.fa-solar-panel:before {
    content: "\f5ba";
}

.fa-sort:before,
.fa-unsorted:before {
    content: "\f0dc";
}

.fa-sort-desc:before,
.fa-sort-down:before {
    content: "\f0dd";
}

.fa-sort-asc:before,
.fa-sort-up:before {
    content: "\f0de";
}

.fa-spa:before {
    content: "\f5bb";
}

.fa-pastafarianism:before,
.fa-spaghetti-monster-flying:before {
    content: "\f67b";
}

.fa-spell-check:before {
    content: "\f891";
}

.fa-spider:before {
    content: "\f717";
}

.fa-spinner:before {
    content: "\f110";
}

.fa-splotch:before {
    content: "\f5bc";
}

.fa-spoon:before,
.fa-utensil-spoon:before {
    content: "\f2e5";
}

.fa-spray-can:before {
    content: "\f5bd";
}

.fa-air-freshener:before,
.fa-spray-can-sparkles:before {
    content: "\f5d0";
}

.fa-square:before {
    content: "\f0c8";
}

.fa-external-link-square:before,
.fa-square-arrow-up-right:before {
    content: "\f14c";
}

.fa-caret-square-down:before,
.fa-square-caret-down:before {
    content: "\f150";
}

.fa-caret-square-left:before,
.fa-square-caret-left:before {
    content: "\f191";
}

.fa-caret-square-right:before,
.fa-square-caret-right:before {
    content: "\f152";
}

.fa-caret-square-up:before,
.fa-square-caret-up:before {
    content: "\f151";
}

.fa-check-square:before,
.fa-square-check:before {
    content: "\f14a";
}

.fa-envelope-square:before,
.fa-square-envelope:before {
    content: "\f199";
}

.fa-square-full:before {
    content: "\f45c";
}

.fa-h-square:before,
.fa-square-h:before {
    content: "\f0fd";
}

.fa-minus-square:before,
.fa-square-minus:before {
    content: "\f146";
}

.fa-square-nfi:before {
    content: "\e576";
}

.fa-parking:before,
.fa-square-parking:before {
    content: "\f540";
}

.fa-pen-square:before,
.fa-pencil-square:before,
.fa-square-pen:before {
    content: "\f14b";
}

.fa-square-person-confined:before {
    content: "\e577";
}

.fa-phone-square:before,
.fa-square-phone:before {
    content: "\f098";
}

.fa-phone-square-alt:before,
.fa-square-phone-flip:before {
    content: "\f87b";
}

.fa-plus-square:before,
.fa-square-plus:before {
    content: "\f0fe";
}

.fa-poll-h:before,
.fa-square-poll-horizontal:before {
    content: "\f682";
}

.fa-poll:before,
.fa-square-poll-vertical:before {
    content: "\f681";
}

.fa-square-root-alt:before,
.fa-square-root-variable:before {
    content: "\f698";
}

.fa-rss-square:before,
.fa-square-rss:before {
    content: "\f143";
}

.fa-share-alt-square:before,
.fa-square-share-nodes:before {
    content: "\f1e1";
}

.fa-external-link-square-alt:before,
.fa-square-up-right:before {
    content: "\f360";
}

.fa-square-virus:before {
    content: "\e578";
}

.fa-square-xmark:before,
.fa-times-square:before,
.fa-xmark-square:before {
    content: "\f2d3";
}

.fa-rod-asclepius:before,
.fa-rod-snake:before,
.fa-staff-aesculapius:before,
.fa-staff-snake:before {
    content: "\e579";
}

.fa-stairs:before {
    content: "\e289";
}

.fa-stamp:before {
    content: "\f5bf";
}

.fa-star:before {
    content: "\f005";
}

.fa-star-and-crescent:before {
    content: "\f699";
}

.fa-star-half:before {
    content: "\f089";
}

.fa-star-half-alt:before,
.fa-star-half-stroke:before {
    content: "\f5c0";
}

.fa-star-of-david:before {
    content: "\f69a";
}

.fa-star-of-life:before {
    content: "\f621";
}

.fa-gbp:before,
.fa-pound-sign:before,
.fa-sterling-sign:before {
    content: "\f154";
}

.fa-stethoscope:before {
    content: "\f0f1";
}

.fa-stop:before {
    content: "\f04d";
}

.fa-stopwatch:before {
    content: "\f2f2";
}

.fa-stopwatch-20:before {
    content: "\e06f";
}

.fa-store:before {
    content: "\f54e";
}

.fa-store-slash:before {
    content: "\e071";
}

.fa-street-view:before {
    content: "\f21d";
}

.fa-strikethrough:before {
    content: "\f0cc";
}

.fa-stroopwafel:before {
    content: "\f551";
}

.fa-subscript:before {
    content: "\f12c";
}

.fa-suitcase:before {
    content: "\f0f2";
}

.fa-medkit:before,
.fa-suitcase-medical:before {
    content: "\f0fa";
}

.fa-suitcase-rolling:before {
    content: "\f5c1";
}

.fa-sun:before {
    content: "\f185";
}

.fa-sun-plant-wilt:before {
    content: "\e57a";
}

.fa-superscript:before {
    content: "\f12b";
}

.fa-swatchbook:before {
    content: "\f5c3";
}

.fa-synagogue:before {
    content: "\f69b";
}

.fa-syringe:before {
    content: "\f48e";
}

.fa-t:before {
    content: "T";
}

.fa-table:before {
    content: "\f0ce";
}

.fa-table-cells:before,
.fa-th:before {
    content: "\f00a";
}

.fa-table-cells-large:before,
.fa-th-large:before {
    content: "\f009";
}

.fa-columns:before,
.fa-table-columns:before {
    content: "\f0db";
}

.fa-table-list:before,
.fa-th-list:before {
    content: "\f00b";
}

.fa-ping-pong-paddle-ball:before,
.fa-table-tennis-paddle-ball:before,
.fa-table-tennis:before {
    content: "\f45d";
}

.fa-tablet-android:before,
.fa-tablet:before {
    content: "\f3fb";
}

.fa-tablet-button:before {
    content: "\f10a";
}

.fa-tablet-alt:before,
.fa-tablet-screen-button:before {
    content: "\f3fa";
}

.fa-tablets:before {
    content: "\f490";
}

.fa-digital-tachograph:before,
.fa-tachograph-digital:before {
    content: "\f566";
}

.fa-tag:before {
    content: "\f02b";
}

.fa-tags:before {
    content: "\f02c";
}

.fa-tape:before {
    content: "\f4db";
}

.fa-tarp:before {
    content: "\e57b";
}

.fa-tarp-droplet:before {
    content: "\e57c";
}

.fa-cab:before,
.fa-taxi:before {
    content: "\f1ba";
}

.fa-teeth:before {
    content: "\f62e";
}

.fa-teeth-open:before {
    content: "\f62f";
}

.fa-temperature-arrow-down:before,
.fa-temperature-down:before {
    content: "\e03f";
}

.fa-temperature-arrow-up:before,
.fa-temperature-up:before {
    content: "\e040";
}

.fa-temperature-0:before,
.fa-temperature-empty:before,
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
    content: "\f2cb";
}

.fa-temperature-4:before,
.fa-temperature-full:before,
.fa-thermometer-4:before,
.fa-thermometer-full:before {
    content: "\f2c7";
}

.fa-temperature-2:before,
.fa-temperature-half:before,
.fa-thermometer-2:before,
.fa-thermometer-half:before {
    content: "\f2c9";
}

.fa-temperature-high:before {
    content: "\f769";
}

.fa-temperature-low:before {
    content: "\f76b";
}

.fa-temperature-1:before,
.fa-temperature-quarter:before,
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
    content: "\f2ca";
}

.fa-temperature-3:before,
.fa-temperature-three-quarters:before,
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
    content: "\f2c8";
}

.fa-tenge-sign:before,
.fa-tenge:before {
    content: "\f7d7";
}

.fa-tent:before {
    content: "\e57d";
}

.fa-tent-arrow-down-to-line:before {
    content: "\e57e";
}

.fa-tent-arrow-left-right:before {
    content: "\e57f";
}

.fa-tent-arrow-turn-left:before {
    content: "\e580";
}

.fa-tent-arrows-down:before {
    content: "\e581";
}

.fa-tents:before {
    content: "\e582";
}

.fa-terminal:before {
    content: "\f120";
}

.fa-text-height:before {
    content: "\f034";
}

.fa-remove-format:before,
.fa-text-slash:before {
    content: "\f87d";
}

.fa-text-width:before {
    content: "\f035";
}

.fa-thermometer:before {
    content: "\f491";
}

.fa-thumbs-down:before {
    content: "\f165";
}

.fa-thumbs-up:before {
    content: "\f164";
}

.fa-thumb-tack:before,
.fa-thumbtack:before {
    content: "\f08d";
}

.fa-ticket:before {
    content: "\f145";
}

.fa-ticket-alt:before,
.fa-ticket-simple:before {
    content: "\f3ff";
}

.fa-timeline:before {
    content: "\e29c";
}

.fa-toggle-off:before {
    content: "\f204";
}

.fa-toggle-on:before {
    content: "\f205";
}

.fa-toilet:before {
    content: "\f7d8";
}

.fa-toilet-paper:before {
    content: "\f71e";
}

.fa-toilet-paper-slash:before {
    content: "\e072";
}

.fa-toilet-portable:before {
    content: "\e583";
}

.fa-toilets-portable:before {
    content: "\e584";
}

.fa-toolbox:before {
    content: "\f552";
}

.fa-tooth:before {
    content: "\f5c9";
}

.fa-torii-gate:before {
    content: "\f6a1";
}

.fa-tornado:before {
    content: "\f76f";
}

.fa-broadcast-tower:before,
.fa-tower-broadcast:before {
    content: "\f519";
}

.fa-tower-cell:before {
    content: "\e585";
}

.fa-tower-observation:before {
    content: "\e586";
}

.fa-tractor:before {
    content: "\f722";
}

.fa-trademark:before {
    content: "\f25c";
}

.fa-traffic-light:before {
    content: "\f637";
}

.fa-trailer:before {
    content: "\e041";
}

.fa-train:before {
    content: "\f238";
}

.fa-subway:before,
.fa-train-subway:before {
    content: "\f239";
}

.fa-train-tram:before,
.fa-tram:before {
    content: "\f7da";
}

.fa-transgender-alt:before,
.fa-transgender:before {
    content: "\f225";
}

.fa-trash:before {
    content: "\f1f8";
}

.fa-trash-arrow-up:before,
.fa-trash-restore:before {
    content: "\f829";
}

.fa-trash-alt:before,
.fa-trash-can:before {
    content: "\f2ed";
}

.fa-trash-can-arrow-up:before,
.fa-trash-restore-alt:before {
    content: "\f82a";
}

.fa-tree:before {
    content: "\f1bb";
}

.fa-tree-city:before {
    content: "\e587";
}

.fa-exclamation-triangle:before,
.fa-triangle-exclamation:before,
.fa-warning:before {
    content: "\f071";
}

.fa-trophy:before {
    content: "\f091";
}

.fa-trowel:before {
    content: "\e589";
}

.fa-trowel-bricks:before {
    content: "\e58a";
}

.fa-truck:before {
    content: "\f0d1";
}

.fa-truck-arrow-right:before {
    content: "\e58b";
}

.fa-truck-droplet:before {
    content: "\e58c";
}

.fa-shipping-fast:before,
.fa-truck-fast:before {
    content: "\f48b";
}

.fa-truck-field:before {
    content: "\e58d";
}

.fa-truck-field-un:before {
    content: "\e58e";
}

.fa-truck-front:before {
    content: "\e2b7";
}

.fa-ambulance:before,
.fa-truck-medical:before {
    content: "\f0f9";
}

.fa-truck-monster:before {
    content: "\f63b";
}

.fa-truck-moving:before {
    content: "\f4df";
}

.fa-truck-pickup:before {
    content: "\f63c";
}

.fa-truck-plane:before {
    content: "\e58f";
}

.fa-truck-loading:before,
.fa-truck-ramp-box:before {
    content: "\f4de";
}

.fa-teletype:before,
.fa-tty:before {
    content: "\f1e4";
}

.fa-try:before,
.fa-turkish-lira-sign:before,
.fa-turkish-lira:before {
    content: "\e2bb";
}

.fa-level-down-alt:before,
.fa-turn-down:before {
    content: "\f3be";
}

.fa-level-up-alt:before,
.fa-turn-up:before {
    content: "\f3bf";
}

.fa-television:before,
.fa-tv-alt:before,
.fa-tv:before {
    content: "\f26c";
}

.fa-u:before {
    content: "U";
}

.fa-umbrella:before {
    content: "\f0e9";
}

.fa-umbrella-beach:before {
    content: "\f5ca";
}

.fa-underline:before {
    content: "\f0cd";
}

.fa-universal-access:before {
    content: "\f29a";
}

.fa-unlock:before {
    content: "\f09c";
}

.fa-unlock-alt:before,
.fa-unlock-keyhole:before {
    content: "\f13e";
}

.fa-arrows-alt-v:before,
.fa-up-down:before {
    content: "\f338";
}

.fa-arrows-alt:before,
.fa-up-down-left-right:before {
    content: "\f0b2";
}

.fa-long-arrow-alt-up:before,
.fa-up-long:before {
    content: "\f30c";
}

.fa-expand-alt:before,
.fa-up-right-and-down-left-from-center:before {
    content: "\f424";
}

.fa-external-link-alt:before,
.fa-up-right-from-square:before {
    content: "\f35d";
}

.fa-upload:before {
    content: "\f093";
}

.fa-user:before {
    content: "\f007";
}

.fa-user-astronaut:before {
    content: "\f4fb";
}

.fa-user-check:before {
    content: "\f4fc";
}

.fa-user-clock:before {
    content: "\f4fd";
}

.fa-user-doctor:before,
.fa-user-md:before {
    content: "\f0f0";
}

.fa-user-cog:before,
.fa-user-gear:before {
    content: "\f4fe";
}

.fa-user-graduate:before {
    content: "\f501";
}

.fa-user-friends:before,
.fa-user-group:before {
    content: "\f500";
}

.fa-user-injured:before {
    content: "\f728";
}

.fa-user-alt:before,
.fa-user-large:before {
    content: "\f406";
}

.fa-user-alt-slash:before,
.fa-user-large-slash:before {
    content: "\f4fa";
}

.fa-user-lock:before {
    content: "\f502";
}

.fa-user-minus:before {
    content: "\f503";
}

.fa-user-ninja:before {
    content: "\f504";
}

.fa-user-nurse:before {
    content: "\f82f";
}

.fa-user-edit:before,
.fa-user-pen:before {
    content: "\f4ff";
}

.fa-user-plus:before {
    content: "\f234";
}

.fa-user-secret:before {
    content: "\f21b";
}

.fa-user-shield:before {
    content: "\f505";
}

.fa-user-slash:before {
    content: "\f506";
}

.fa-user-tag:before {
    content: "\f507";
}

.fa-user-tie:before {
    content: "\f508";
}

.fa-user-times:before,
.fa-user-xmark:before {
    content: "\f235";
}

.fa-users:before {
    content: "\f0c0";
}

.fa-users-between-lines:before {
    content: "\e591";
}

.fa-users-cog:before,
.fa-users-gear:before {
    content: "\f509";
}

.fa-users-line:before {
    content: "\e592";
}

.fa-users-rays:before {
    content: "\e593";
}

.fa-users-rectangle:before {
    content: "\e594";
}

.fa-users-slash:before {
    content: "\e073";
}

.fa-users-viewfinder:before {
    content: "\e595";
}

.fa-cutlery:before,
.fa-utensils:before {
    content: "\f2e7";
}

.fa-v:before {
    content: "V";
}

.fa-shuttle-van:before,
.fa-van-shuttle:before {
    content: "\f5b6";
}

.fa-vault:before {
    content: "\e2c5";
}

.fa-vector-square:before {
    content: "\f5cb";
}

.fa-venus:before {
    content: "\f221";
}

.fa-venus-double:before {
    content: "\f226";
}

.fa-venus-mars:before {
    content: "\f228";
}

.fa-vest:before {
    content: "\e085";
}

.fa-vest-patches:before {
    content: "\e086";
}

.fa-vial:before {
    content: "\f492";
}

.fa-vial-circle-check:before {
    content: "\e596";
}

.fa-vial-virus:before {
    content: "\e597";
}

.fa-vials:before {
    content: "\f493";
}

.fa-video-camera:before,
.fa-video:before {
    content: "\f03d";
}

.fa-video-slash:before {
    content: "\f4e2";
}

.fa-vihara:before {
    content: "\f6a7";
}

.fa-virus:before {
    content: "\e074";
}

.fa-virus-covid:before {
    content: "\e4a8";
}

.fa-virus-covid-slash:before {
    content: "\e4a9";
}

.fa-virus-slash:before {
    content: "\e075";
}

.fa-viruses:before {
    content: "\e076";
}

.fa-voicemail:before {
    content: "\f897";
}

.fa-volcano:before {
    content: "\f770";
}

.fa-volleyball-ball:before,
.fa-volleyball:before {
    content: "\f45f";
}

.fa-volume-high:before,
.fa-volume-up:before {
    content: "\f028";
}

.fa-volume-down:before,
.fa-volume-low:before {
    content: "\f027";
}

.fa-volume-off:before {
    content: "\f026";
}

.fa-volume-mute:before,
.fa-volume-times:before,
.fa-volume-xmark:before {
    content: "\f6a9";
}

.fa-vr-cardboard:before {
    content: "\f729";
}

.fa-w:before {
    content: "W";
}

.fa-walkie-talkie:before {
    content: "\f8ef";
}

.fa-wallet:before {
    content: "\f555";
}

.fa-magic:before,
.fa-wand-magic:before {
    content: "\f0d0";
}

.fa-magic-wand-sparkles:before,
.fa-wand-magic-sparkles:before {
    content: "\e2ca";
}

.fa-wand-sparkles:before {
    content: "\f72b";
}

.fa-warehouse:before {
    content: "\f494";
}

.fa-water:before {
    content: "\f773";
}

.fa-ladder-water:before,
.fa-swimming-pool:before,
.fa-water-ladder:before {
    content: "\f5c5";
}

.fa-wave-square:before {
    content: "\f83e";
}

.fa-weight-hanging:before {
    content: "\f5cd";
}

.fa-weight-scale:before,
.fa-weight:before {
    content: "\f496";
}

.fa-wheat-alt:before,
.fa-wheat-awn:before {
    content: "\e2cd";
}

.fa-wheat-awn-circle-exclamation:before {
    content: "\e598";
}

.fa-wheelchair:before {
    content: "\f193";
}

.fa-wheelchair-alt:before,
.fa-wheelchair-move:before {
    content: "\e2ce";
}

.fa-glass-whiskey:before,
.fa-whiskey-glass:before {
    content: "\f7a0";
}

.fa-wifi-3:before,
.fa-wifi-strong:before,
.fa-wifi:before {
    content: "\f1eb";
}

.fa-wind:before {
    content: "\f72e";
}

.fa-window-maximize:before {
    content: "\f2d0";
}

.fa-window-minimize:before {
    content: "\f2d1";
}

.fa-window-restore:before {
    content: "\f2d2";
}

.fa-wine-bottle:before {
    content: "\f72f";
}

.fa-wine-glass:before {
    content: "\f4e3";
}

.fa-wine-glass-alt:before,
.fa-wine-glass-empty:before {
    content: "\f5ce";
}

.fa-krw:before,
.fa-won-sign:before,
.fa-won:before {
    content: "\f159";
}

.fa-worm:before {
    content: "\e599";
}

.fa-wrench:before {
    content: "\f0ad";
}

.fa-x:before {
    content: "X";
}

.fa-x-ray:before {
    content: "\f497";
}

.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
    content: "\f00d";
}

.fa-xmarks-lines:before {
    content: "\e59a";
}

.fa-y:before {
    content: "Y";
}

.fa-cny:before,
.fa-jpy:before,
.fa-rmb:before,
.fa-yen-sign:before,
.fa-yen:before {
    content: "\f157";
}

.fa-yin-yang:before {
    content: "\f6ad";
}

.fa-z:before {
    content: "Z";
}

.fa-sr-only,
.fa-sr-only-focusable:not(:focus),
.sr-only,
.sr-only-focusable:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

:host,
:root {
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
    font-family: "Font Awesome 6 Brands";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(../fonts/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands-400.ttf) format("truetype");
}

.fa-brands,
.fab {
    font-family: "Font Awesome 6 Brands";
    font-weight: 400;
}

.fa-42-group:before,
.fa-innosoft:before {
    content: "\e080";
}

.fa-500px:before {
    content: "\f26e";
}

.fa-accessible-icon:before {
    content: "\f368";
}

.fa-accusoft:before {
    content: "\f369";
}

.fa-adn:before {
    content: "\f170";
}

.fa-adversal:before {
    content: "\f36a";
}

.fa-affiliatetheme:before {
    content: "\f36b";
}

.fa-airbnb:before {
    content: "\f834";
}

.fa-algolia:before {
    content: "\f36c";
}

.fa-alipay:before {
    content: "\f642";
}

.fa-amazon:before {
    content: "\f270";
}

.fa-amazon-pay:before {
    content: "\f42c";
}

.fa-amilia:before {
    content: "\f36d";
}

.fa-android:before {
    content: "\f17b";
}

.fa-angellist:before {
    content: "\f209";
}

.fa-angrycreative:before {
    content: "\f36e";
}

.fa-angular:before {
    content: "\f420";
}

.fa-app-store:before {
    content: "\f36f";
}

.fa-app-store-ios:before {
    content: "\f370";
}

.fa-apper:before {
    content: "\f371";
}

.fa-apple:before {
    content: "\f179";
}

.fa-apple-pay:before {
    content: "\f415";
}

.fa-artstation:before {
    content: "\f77a";
}

.fa-asymmetrik:before {
    content: "\f372";
}

.fa-atlassian:before {
    content: "\f77b";
}

.fa-audible:before {
    content: "\f373";
}

.fa-autoprefixer:before {
    content: "\f41c";
}

.fa-avianex:before {
    content: "\f374";
}

.fa-aviato:before {
    content: "\f421";
}

.fa-aws:before {
    content: "\f375";
}

.fa-bandcamp:before {
    content: "\f2d5";
}

.fa-battle-net:before {
    content: "\f835";
}

.fa-behance:before {
    content: "\f1b4";
}

.fa-behance-square:before {
    content: "\f1b5";
}

.fa-bilibili:before {
    content: "\e3d9";
}

.fa-bimobject:before {
    content: "\f378";
}

.fa-bitbucket:before {
    content: "\f171";
}

.fa-bitcoin:before {
    content: "\f379";
}

.fa-bity:before {
    content: "\f37a";
}

.fa-black-tie:before {
    content: "\f27e";
}

.fa-blackberry:before {
    content: "\f37b";
}

.fa-blogger:before {
    content: "\f37c";
}

.fa-blogger-b:before {
    content: "\f37d";
}

.fa-bluetooth:before {
    content: "\f293";
}

.fa-bluetooth-b:before {
    content: "\f294";
}

.fa-bootstrap:before {
    content: "\f836";
}

.fa-bots:before {
    content: "\e340";
}

.fa-btc:before {
    content: "\f15a";
}

.fa-buffer:before {
    content: "\f837";
}

.fa-buromobelexperte:before {
    content: "\f37f";
}

.fa-buy-n-large:before {
    content: "\f8a6";
}

.fa-buysellads:before {
    content: "\f20d";
}

.fa-canadian-maple-leaf:before {
    content: "\f785";
}

.fa-cc-amazon-pay:before {
    content: "\f42d";
}

.fa-cc-amex:before {
    content: "\f1f3";
}

.fa-cc-apple-pay:before {
    content: "\f416";
}

.fa-cc-diners-club:before {
    content: "\f24c";
}

.fa-cc-discover:before {
    content: "\f1f2";
}

.fa-cc-jcb:before {
    content: "\f24b";
}

.fa-cc-mastercard:before {
    content: "\f1f1";
}

.fa-cc-paypal:before {
    content: "\f1f4";
}

.fa-cc-stripe:before {
    content: "\f1f5";
}

.fa-cc-visa:before {
    content: "\f1f0";
}

.fa-centercode:before {
    content: "\f380";
}

.fa-centos:before {
    content: "\f789";
}

.fa-chrome:before {
    content: "\f268";
}

.fa-chromecast:before {
    content: "\f838";
}

.fa-cloudflare:before {
    content: "\e07d";
}

.fa-cloudscale:before {
    content: "\f383";
}

.fa-cloudsmith:before {
    content: "\f384";
}

.fa-cloudversify:before {
    content: "\f385";
}

.fa-cmplid:before {
    content: "\e360";
}

.fa-codepen:before {
    content: "\f1cb";
}

.fa-codiepie:before {
    content: "\f284";
}

.fa-confluence:before {
    content: "\f78d";
}

.fa-connectdevelop:before {
    content: "\f20e";
}

.fa-contao:before {
    content: "\f26d";
}

.fa-cotton-bureau:before {
    content: "\f89e";
}

.fa-cpanel:before {
    content: "\f388";
}

.fa-creative-commons:before {
    content: "\f25e";
}

.fa-creative-commons-by:before {
    content: "\f4e7";
}

.fa-creative-commons-nc:before {
    content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
    content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
    content: "\f4ea";
}

.fa-creative-commons-nd:before {
    content: "\f4eb";
}

.fa-creative-commons-pd:before {
    content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
    content: "\f4ed";
}

.fa-creative-commons-remix:before {
    content: "\f4ee";
}

.fa-creative-commons-sa:before {
    content: "\f4ef";
}

.fa-creative-commons-sampling:before {
    content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
    content: "\f4f1";
}

.fa-creative-commons-share:before {
    content: "\f4f2";
}

.fa-creative-commons-zero:before {
    content: "\f4f3";
}

.fa-critical-role:before {
    content: "\f6c9";
}

.fa-css3:before {
    content: "\f13c";
}

.fa-css3-alt:before {
    content: "\f38b";
}

.fa-cuttlefish:before {
    content: "\f38c";
}

.fa-d-and-d:before {
    content: "\f38d";
}

.fa-d-and-d-beyond:before {
    content: "\f6ca";
}

.fa-dailymotion:before {
    content: "\e052";
}

.fa-dashcube:before {
    content: "\f210";
}

.fa-deezer:before {
    content: "\e077";
}

.fa-delicious:before {
    content: "\f1a5";
}

.fa-deploydog:before {
    content: "\f38e";
}

.fa-deskpro:before {
    content: "\f38f";
}

.fa-dev:before {
    content: "\f6cc";
}

.fa-deviantart:before {
    content: "\f1bd";
}

.fa-dhl:before {
    content: "\f790";
}

.fa-diaspora:before {
    content: "\f791";
}

.fa-digg:before {
    content: "\f1a6";
}

.fa-digital-ocean:before {
    content: "\f391";
}

.fa-discord:before {
    content: "\f392";
}

.fa-discourse:before {
    content: "\f393";
}

.fa-dochub:before {
    content: "\f394";
}

.fa-docker:before {
    content: "\f395";
}

.fa-draft2digital:before {
    content: "\f396";
}

.fa-dribbble:before {
    content: "\f17d";
}

.fa-dribbble-square:before {
    content: "\f397";
}

.fa-dropbox:before {
    content: "\f16b";
}

.fa-drupal:before {
    content: "\f1a9";
}

.fa-dyalog:before {
    content: "\f399";
}

.fa-earlybirds:before {
    content: "\f39a";
}

.fa-ebay:before {
    content: "\f4f4";
}

.fa-edge:before {
    content: "\f282";
}

.fa-edge-legacy:before {
    content: "\e078";
}

.fa-elementor:before {
    content: "\f430";
}

.fa-ello:before {
    content: "\f5f1";
}

.fa-ember:before {
    content: "\f423";
}

.fa-empire:before {
    content: "\f1d1";
}

.fa-envira:before {
    content: "\f299";
}

.fa-erlang:before {
    content: "\f39d";
}

.fa-ethereum:before {
    content: "\f42e";
}

.fa-etsy:before {
    content: "\f2d7";
}

.fa-evernote:before {
    content: "\f839";
}

.fa-expeditedssl:before {
    content: "\f23e";
}

.fa-facebook:before {
    content: "\f09a";
}

.fa-facebook-f:before {
    content: "\f39e";
}

.fa-facebook-messenger:before {
    content: "\f39f";
}

.fa-facebook-square:before {
    content: "\f082";
}

.fa-fantasy-flight-games:before {
    content: "\f6dc";
}

.fa-fedex:before {
    content: "\f797";
}

.fa-fedora:before {
    content: "\f798";
}

.fa-figma:before {
    content: "\f799";
}

.fa-firefox:before {
    content: "\f269";
}

.fa-firefox-browser:before {
    content: "\e007";
}

.fa-first-order:before {
    content: "\f2b0";
}

.fa-first-order-alt:before {
    content: "\f50a";
}

.fa-firstdraft:before {
    content: "\f3a1";
}

.fa-flickr:before {
    content: "\f16e";
}

.fa-flipboard:before {
    content: "\f44d";
}

.fa-fly:before {
    content: "\f417";
}

.fa-font-awesome-flag:before,
.fa-font-awesome-logo-full:before,
.fa-font-awesome:before {
    content: "\f2b4";
}

.fa-fonticons:before {
    content: "\f280";
}

.fa-fonticons-fi:before {
    content: "\f3a2";
}

.fa-fort-awesome:before {
    content: "\f286";
}

.fa-fort-awesome-alt:before {
    content: "\f3a3";
}

.fa-forumbee:before {
    content: "\f211";
}

.fa-foursquare:before {
    content: "\f180";
}

.fa-free-code-camp:before {
    content: "\f2c5";
}

.fa-freebsd:before {
    content: "\f3a4";
}

.fa-fulcrum:before {
    content: "\f50b";
}

.fa-galactic-republic:before {
    content: "\f50c";
}

.fa-galactic-senate:before {
    content: "\f50d";
}

.fa-get-pocket:before {
    content: "\f265";
}

.fa-gg:before {
    content: "\f260";
}

.fa-gg-circle:before {
    content: "\f261";
}

.fa-git:before {
    content: "\f1d3";
}

.fa-git-alt:before {
    content: "\f841";
}

.fa-git-square:before {
    content: "\f1d2";
}

.fa-github:before {
    content: "\f09b";
}

.fa-github-alt:before {
    content: "\f113";
}

.fa-github-square:before {
    content: "\f092";
}

.fa-gitkraken:before {
    content: "\f3a6";
}

.fa-gitlab:before {
    content: "\f296";
}

.fa-gitter:before {
    content: "\f426";
}

.fa-glide:before {
    content: "\f2a5";
}

.fa-glide-g:before {
    content: "\f2a6";
}

.fa-gofore:before {
    content: "\f3a7";
}

.fa-golang:before {
    content: "\e40f";
}

.fa-goodreads:before {
    content: "\f3a8";
}

.fa-goodreads-g:before {
    content: "\f3a9";
}

.fa-google:before {
    content: "\f1a0";
}

.fa-google-drive:before {
    content: "\f3aa";
}

.fa-google-pay:before {
    content: "\e079";
}

.fa-google-play:before {
    content: "\f3ab";
}

.fa-google-plus:before {
    content: "\f2b3";
}

.fa-google-plus-g:before {
    content: "\f0d5";
}

.fa-google-plus-square:before {
    content: "\f0d4";
}

.fa-google-wallet:before {
    content: "\f1ee";
}

.fa-gratipay:before {
    content: "\f184";
}

.fa-grav:before {
    content: "\f2d6";
}

.fa-gripfire:before {
    content: "\f3ac";
}

.fa-grunt:before {
    content: "\f3ad";
}

.fa-guilded:before {
    content: "\e07e";
}

.fa-gulp:before {
    content: "\f3ae";
}

.fa-hacker-news:before {
    content: "\f1d4";
}

.fa-hacker-news-square:before {
    content: "\f3af";
}

.fa-hackerrank:before {
    content: "\f5f7";
}

.fa-hashnode:before {
    content: "\e499";
}

.fa-hips:before {
    content: "\f452";
}

.fa-hire-a-helper:before {
    content: "\f3b0";
}

.fa-hive:before {
    content: "\e07f";
}

.fa-hooli:before {
    content: "\f427";
}

.fa-hornbill:before {
    content: "\f592";
}

.fa-hotjar:before {
    content: "\f3b1";
}

.fa-houzz:before {
    content: "\f27c";
}

.fa-html5:before {
    content: "\f13b";
}

.fa-hubspot:before {
    content: "\f3b2";
}

.fa-ideal:before {
    content: "\e013";
}

.fa-imdb:before {
    content: "\f2d8";
}

.fa-instagram:before {
    content: "\f16d";
}

.fa-instagram-square:before {
    content: "\e055";
}

.fa-instalod:before {
    content: "\e081";
}

.fa-intercom:before {
    content: "\f7af";
}

.fa-internet-explorer:before {
    content: "\f26b";
}

.fa-invision:before {
    content: "\f7b0";
}

.fa-ioxhost:before {
    content: "\f208";
}

.fa-itch-io:before {
    content: "\f83a";
}

.fa-itunes:before {
    content: "\f3b4";
}

.fa-itunes-note:before {
    content: "\f3b5";
}

.fa-java:before {
    content: "\f4e4";
}

.fa-jedi-order:before {
    content: "\f50e";
}

.fa-jenkins:before {
    content: "\f3b6";
}

.fa-jira:before {
    content: "\f7b1";
}

.fa-joget:before {
    content: "\f3b7";
}

.fa-joomla:before {
    content: "\f1aa";
}

.fa-js:before {
    content: "\f3b8";
}

.fa-js-square:before {
    content: "\f3b9";
}

.fa-jsfiddle:before {
    content: "\f1cc";
}

.fa-kaggle:before {
    content: "\f5fa";
}

.fa-keybase:before {
    content: "\f4f5";
}

.fa-keycdn:before {
    content: "\f3ba";
}

.fa-kickstarter:before {
    content: "\f3bb";
}

.fa-kickstarter-k:before {
    content: "\f3bc";
}

.fa-korvue:before {
    content: "\f42f";
}

.fa-laravel:before {
    content: "\f3bd";
}

.fa-lastfm:before {
    content: "\f202";
}

.fa-lastfm-square:before {
    content: "\f203";
}

.fa-leanpub:before {
    content: "\f212";
}

.fa-less:before {
    content: "\f41d";
}

.fa-line:before {
    content: "\f3c0";
}

.fa-linkedin:before {
    content: "\f08c";
}

.fa-linkedin-in:before {
    content: "\f0e1";
}

.fa-linode:before {
    content: "\f2b8";
}

.fa-linux:before {
    content: "\f17c";
}

.fa-lyft:before {
    content: "\f3c3";
}

.fa-magento:before {
    content: "\f3c4";
}

.fa-mailchimp:before {
    content: "\f59e";
}

.fa-mandalorian:before {
    content: "\f50f";
}

.fa-markdown:before {
    content: "\f60f";
}

.fa-mastodon:before {
    content: "\f4f6";
}

.fa-maxcdn:before {
    content: "\f136";
}

.fa-mdb:before {
    content: "\f8ca";
}

.fa-medapps:before {
    content: "\f3c6";
}

.fa-medium-m:before,
.fa-medium:before {
    content: "\f23a";
}

.fa-medrt:before {
    content: "\f3c8";
}

.fa-meetup:before {
    content: "\f2e0";
}

.fa-megaport:before {
    content: "\f5a3";
}

.fa-mendeley:before {
    content: "\f7b3";
}

.fa-microblog:before {
    content: "\e01a";
}

.fa-microsoft:before {
    content: "\f3ca";
}

.fa-mix:before {
    content: "\f3cb";
}

.fa-mixcloud:before {
    content: "\f289";
}

.fa-mixer:before {
    content: "\e056";
}

.fa-mizuni:before {
    content: "\f3cc";
}

.fa-modx:before {
    content: "\f285";
}

.fa-monero:before {
    content: "\f3d0";
}

.fa-napster:before {
    content: "\f3d2";
}

.fa-neos:before {
    content: "\f612";
}

.fa-nfc-directional:before {
    content: "\e530";
}

.fa-nfc-symbol:before {
    content: "\e531";
}

.fa-nimblr:before {
    content: "\f5a8";
}

.fa-node:before {
    content: "\f419";
}

.fa-node-js:before {
    content: "\f3d3";
}

.fa-npm:before {
    content: "\f3d4";
}

.fa-ns8:before {
    content: "\f3d5";
}

.fa-nutritionix:before {
    content: "\f3d6";
}

.fa-octopus-deploy:before {
    content: "\e082";
}

.fa-odnoklassniki:before {
    content: "\f263";
}

.fa-odnoklassniki-square:before {
    content: "\f264";
}

.fa-old-republic:before {
    content: "\f510";
}

.fa-opencart:before {
    content: "\f23d";
}

.fa-openid:before {
    content: "\f19b";
}

.fa-opera:before {
    content: "\f26a";
}

.fa-optin-monster:before {
    content: "\f23c";
}

.fa-orcid:before {
    content: "\f8d2";
}

.fa-osi:before {
    content: "\f41a";
}

.fa-padlet:before {
    content: "\e4a0";
}

.fa-page4:before {
    content: "\f3d7";
}

.fa-pagelines:before {
    content: "\f18c";
}

.fa-palfed:before {
    content: "\f3d8";
}

.fa-patreon:before {
    content: "\f3d9";
}

.fa-paypal:before {
    content: "\f1ed";
}

.fa-perbyte:before {
    content: "\e083";
}

.fa-periscope:before {
    content: "\f3da";
}

.fa-phabricator:before {
    content: "\f3db";
}

.fa-phoenix-framework:before {
    content: "\f3dc";
}

.fa-phoenix-squadron:before {
    content: "\f511";
}

.fa-php:before {
    content: "\f457";
}

.fa-pied-piper:before {
    content: "\f2ae";
}

.fa-pied-piper-alt:before {
    content: "\f1a8";
}

.fa-pied-piper-hat:before {
    content: "\f4e5";
}

.fa-pied-piper-pp:before {
    content: "\f1a7";
}

.fa-pied-piper-square:before {
    content: "\e01e";
}

.fa-pinterest:before {
    content: "\f0d2";
}

.fa-pinterest-p:before {
    content: "\f231";
}

.fa-pinterest-square:before {
    content: "\f0d3";
}

.fa-pix:before {
    content: "\e43a";
}

.fa-playstation:before {
    content: "\f3df";
}

.fa-product-hunt:before {
    content: "\f288";
}

.fa-pushed:before {
    content: "\f3e1";
}

.fa-python:before {
    content: "\f3e2";
}

.fa-qq:before {
    content: "\f1d6";
}

.fa-quinscape:before {
    content: "\f459";
}

.fa-quora:before {
    content: "\f2c4";
}

.fa-r-project:before {
    content: "\f4f7";
}

.fa-raspberry-pi:before {
    content: "\f7bb";
}

.fa-ravelry:before {
    content: "\f2d9";
}

.fa-react:before {
    content: "\f41b";
}

.fa-reacteurope:before {
    content: "\f75d";
}

.fa-readme:before {
    content: "\f4d5";
}

.fa-rebel:before {
    content: "\f1d0";
}

.fa-red-river:before {
    content: "\f3e3";
}

.fa-reddit:before {
    content: "\f1a1";
}

.fa-reddit-alien:before {
    content: "\f281";
}

.fa-reddit-square:before {
    content: "\f1a2";
}

.fa-redhat:before {
    content: "\f7bc";
}

.fa-renren:before {
    content: "\f18b";
}

.fa-replyd:before {
    content: "\f3e6";
}

.fa-researchgate:before {
    content: "\f4f8";
}

.fa-resolving:before {
    content: "\f3e7";
}

.fa-rev:before {
    content: "\f5b2";
}

.fa-rocketchat:before {
    content: "\f3e8";
}

.fa-rockrms:before {
    content: "\f3e9";
}

.fa-rust:before {
    content: "\e07a";
}

.fa-safari:before {
    content: "\f267";
}

.fa-salesforce:before {
    content: "\f83b";
}

.fa-sass:before {
    content: "\f41e";
}

.fa-schlix:before {
    content: "\f3ea";
}

.fa-screenpal:before {
    content: "\e570";
}

.fa-scribd:before {
    content: "\f28a";
}

.fa-searchengin:before {
    content: "\f3eb";
}

.fa-sellcast:before {
    content: "\f2da";
}

.fa-sellsy:before {
    content: "\f213";
}

.fa-servicestack:before {
    content: "\f3ec";
}

.fa-shirtsinbulk:before {
    content: "\f214";
}

.fa-shopify:before {
    content: "\e057";
}

.fa-shopware:before {
    content: "\f5b5";
}

.fa-simplybuilt:before {
    content: "\f215";
}

.fa-sistrix:before {
    content: "\f3ee";
}

.fa-sith:before {
    content: "\f512";
}

.fa-sitrox:before {
    content: "\e44a";
}

.fa-sketch:before {
    content: "\f7c6";
}

.fa-skyatlas:before {
    content: "\f216";
}

.fa-skype:before {
    content: "\f17e";
}

.fa-slack-hash:before,
.fa-slack:before {
    content: "\f198";
}

.fa-slideshare:before {
    content: "\f1e7";
}

.fa-snapchat-ghost:before,
.fa-snapchat:before {
    content: "\f2ab";
}

.fa-snapchat-square:before {
    content: "\f2ad";
}

.fa-soundcloud:before {
    content: "\f1be";
}

.fa-sourcetree:before {
    content: "\f7d3";
}

.fa-speakap:before {
    content: "\f3f3";
}

.fa-speaker-deck:before {
    content: "\f83c";
}

.fa-spotify:before {
    content: "\f1bc";
}

.fa-square-font-awesome:before {
    content: "\f425";
}

.fa-font-awesome-alt:before,
.fa-square-font-awesome-stroke:before {
    content: "\f35c";
}

.fa-squarespace:before {
    content: "\f5be";
}

.fa-stack-exchange:before {
    content: "\f18d";
}

.fa-stack-overflow:before {
    content: "\f16c";
}

.fa-stackpath:before {
    content: "\f842";
}

.fa-staylinked:before {
    content: "\f3f5";
}

.fa-steam:before {
    content: "\f1b6";
}

.fa-steam-square:before {
    content: "\f1b7";
}

.fa-steam-symbol:before {
    content: "\f3f6";
}

.fa-sticker-mule:before {
    content: "\f3f7";
}

.fa-strava:before {
    content: "\f428";
}

.fa-stripe:before {
    content: "\f429";
}

.fa-stripe-s:before {
    content: "\f42a";
}

.fa-studiovinari:before {
    content: "\f3f8";
}

.fa-stumbleupon:before {
    content: "\f1a4";
}

.fa-stumbleupon-circle:before {
    content: "\f1a3";
}

.fa-superpowers:before {
    content: "\f2dd";
}

.fa-supple:before {
    content: "\f3f9";
}

.fa-suse:before {
    content: "\f7d6";
}

.fa-swift:before {
    content: "\f8e1";
}

.fa-symfony:before {
    content: "\f83d";
}

.fa-teamspeak:before {
    content: "\f4f9";
}

.fa-telegram-plane:before,
.fa-telegram:before {
    content: "\f2c6";
}

.fa-tencent-weibo:before {
    content: "\f1d5";
}

.fa-the-red-yeti:before {
    content: "\f69d";
}

.fa-themeco:before {
    content: "\f5c6";
}

.fa-themeisle:before {
    content: "\f2b2";
}

.fa-think-peaks:before {
    content: "\f731";
}

.fa-tiktok:before {
    content: "\e07b";
}

.fa-trade-federation:before {
    content: "\f513";
}

.fa-trello:before {
    content: "\f181";
}

.fa-tumblr:before {
    content: "\f173";
}

.fa-tumblr-square:before {
    content: "\f174";
}

.fa-twitch:before {
    content: "\f1e8";
}

.fa-twitter:before {
    content: "\f099";
}

.fa-twitter-square:before {
    content: "\f081";
}

.fa-typo3:before {
    content: "\f42b";
}

.fa-uber:before {
    content: "\f402";
}

.fa-ubuntu:before {
    content: "\f7df";
}

.fa-uikit:before {
    content: "\f403";
}

.fa-umbraco:before {
    content: "\f8e8";
}

.fa-uncharted:before {
    content: "\e084";
}

.fa-uniregistry:before {
    content: "\f404";
}

.fa-unity:before {
    content: "\e049";
}

.fa-unsplash:before {
    content: "\e07c";
}

.fa-untappd:before {
    content: "\f405";
}

.fa-ups:before {
    content: "\f7e0";
}

.fa-usb:before {
    content: "\f287";
}

.fa-usps:before {
    content: "\f7e1";
}

.fa-ussunnah:before {
    content: "\f407";
}

.fa-vaadin:before {
    content: "\f408";
}

.fa-viacoin:before {
    content: "\f237";
}

.fa-viadeo:before {
    content: "\f2a9";
}

.fa-viadeo-square:before {
    content: "\f2aa";
}

.fa-viber:before {
    content: "\f409";
}

.fa-vimeo:before {
    content: "\f40a";
}

.fa-vimeo-square:before {
    content: "\f194";
}

.fa-vimeo-v:before {
    content: "\f27d";
}

.fa-vine:before {
    content: "\f1ca";
}

.fa-vk:before {
    content: "\f189";
}

.fa-vnv:before {
    content: "\f40b";
}

.fa-vuejs:before {
    content: "\f41f";
}

.fa-watchman-monitoring:before {
    content: "\e087";
}

.fa-waze:before {
    content: "\f83f";
}

.fa-weebly:before {
    content: "\f5cc";
}

.fa-weibo:before {
    content: "\f18a";
}

.fa-weixin:before {
    content: "\f1d7";
}

.fa-whatsapp:before {
    content: "\f232";
}

.fa-whatsapp-square:before {
    content: "\f40c";
}

.fa-whmcs:before {
    content: "\f40d";
}

.fa-wikipedia-w:before {
    content: "\f266";
}

.fa-windows:before {
    content: "\f17a";
}

.fa-wirsindhandwerk:before,
.fa-wsh:before {
    content: "\e2d0";
}

.fa-wix:before {
    content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
    content: "\f730";
}

.fa-wodu:before {
    content: "\e088";
}

.fa-wolf-pack-battalion:before {
    content: "\f514";
}

.fa-wordpress:before {
    content: "\f19a";
}

.fa-wordpress-simple:before {
    content: "\f411";
}

.fa-wpbeginner:before {
    content: "\f297";
}

.fa-wpexplorer:before {
    content: "\f2de";
}

.fa-wpforms:before {
    content: "\f298";
}

.fa-wpressr:before {
    content: "\f3e4";
}

.fa-xbox:before {
    content: "\f412";
}

.fa-xing:before {
    content: "\f168";
}

.fa-xing-square:before {
    content: "\f169";
}

.fa-y-combinator:before {
    content: "\f23b";
}

.fa-yahoo:before {
    content: "\f19e";
}

.fa-yammer:before {
    content: "\f840";
}

.fa-yandex:before {
    content: "\f413";
}

.fa-yandex-international:before {
    content: "\f414";
}

.fa-yarn:before {
    content: "\f7e3";
}

.fa-yelp:before {
    content: "\f1e9";
}

.fa-yoast:before {
    content: "\f2b1";
}

.fa-youtube:before {
    content: "\f167";
}

.fa-youtube-square:before {
    content: "\f431";
}

.fa-zhihu:before {
    content: "\f63f";
}

:host,
:root {
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Free";
}

@font-face {
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(../fonts/fa-regular-400.woff2) format("woff2"), url(../fonts/fa-regular-400.ttf) format("truetype");
}

.fa-regular,
.far {
    font-family: "Font Awesome 6 Free";
    font-weight: 400;
}

:host,
:root {
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Free";
}

@font-face {
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(../fonts/fa-solid-900.woff2) format("woff2"), url(../fonts/fa-solid-900.ttf) format("truetype");
}

.fa-solid,
.fas {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
}

@font-face {
    font-family: "Font Awesome 5 Brands";
    font-display: block;
    font-weight: 400;
    src: url(../fonts/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands-400.ttf) format("truetype");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-display: block;
    font-weight: 900;
    src: url(../fonts/fa-solid-900.woff2) format("woff2"), url(../fonts/fa-solid-900.ttf) format("truetype");
}

@font-face {
    font-family: "Font Awesome 5 Free";
    font-display: block;
    font-weight: 400;
    src: url(../fonts/fa-regular-400.woff2) format("woff2"), url(../fonts/fa-regular-400.ttf) format("truetype");
}

@font-face {
    font-family: "FontAwesome";
    font-display: block;
    src: url(../fonts/fa-solid-900.woff2) format("woff2"), url(../fonts/fa-solid-900.ttf) format("truetype");
}

@font-face {
    font-family: "FontAwesome";
    font-display: block;
    src: url(../fonts/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands-400.ttf) format("truetype");
}

@font-face {
    font-family: "FontAwesome";
    font-display: block;
    src: url(../fonts/fa-regular-400.woff2) format("woff2"), url(../fonts/fa-regular-400.ttf) format("truetype");
    unicode-range: u+f003, u+f006, u+f014, u+f016-f017, u+f01a-f01b, u+f01d, u+f022, u+f03e, u+f044, u+f046, u+f05c-f05d, u+f06e, u+f070, u+f087-f088, u+f08a, u+f094, u+f096-f097, u+f09d, u+f0a0, u+f0a2, u+f0a4-f0a7, u+f0c5, u+f0c7, u+f0e5-f0e6, u+f0eb, u+f0f6-f0f8, u+f10c, u+f114-f115, u+f118-f11a, u+f11c-f11d, u+f133, u+f147, u+f14e, u+f150-f152, u+f185-f186, u+f18e, u+f190-f192, u+f196, u+f1c1-f1c9, u+f1d9, u+f1db, u+f1e3, u+f1ea, u+f1f7, u+f1f9, u+f20a, u+f247-f248, u+f24a, u+f24d, u+f255-f25b, u+f25d, u+f271-f274, u+f278, u+f27b, u+f28c, u+f28e, u+f29c, u+f2b5, u+f2b7, u+f2ba, u+f2bc, u+f2be, u+f2c0-f2c1, u+f2c3, u+f2d0, u+f2d2, u+f2d4, u+f2dc;
}

@font-face {
    font-family: "FontAwesome";
    font-display: block;
    src: url(../fonts/fa-v4compatibility.woff2) format("woff2"), url(../fonts/fa-v4compatibility.ttf) format("truetype");
    unicode-range: u+f041, u+f047, u+f065-f066, u+f07d-f07e, u+f080, u+f08b, u+f08e, u+f090, u+f09a, u+f0ac, u+f0ae, u+f0b2, u+f0d0, u+f0d6, u+f0e4, u+f0ec, u+f10a-f10b, u+f123, u+f13e, u+f148-f149, u+f14c, u+f156, u+f15e, u+f160-f161, u+f163, u+f175-f178, u+f195, u+f1f8, u+f219, u+f250, u+f252, u+f27a;
}

@font-face {
    font-family: "Feather";
    src: url("../fonts/Feather.ttf?sdxovp") format("truetype"), url("../fonts/Feather.woff?sdxovp") format("woff"), url("../fonts/Feather.svg?sdxovp#Feather") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^=feather-],
[class*=" feather-"] {
    font-family: "feather" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.feather-activity:before {
    content: "\e900";
}

.feather-airplay:before {
    content: "\e901";
}

.feather-alert-circle:before {
    content: "\e902";
}

.feather-alert-octagon:before {
    content: "\e903";
}

.feather-alert-triangle:before {
    content: "\e904";
}

.feather-align-center:before {
    content: "\e905";
}

.feather-align-justify:before {
    content: "\e906";
}

.feather-align-left:before {
    content: "\e907";
}

.feather-align-right:before {
    content: "\e908";
}

.feather-anchor:before {
    content: "\e909";
}

.feather-aperture:before {
    content: "\e90a";
}

.feather-archive:before {
    content: "\e90b";
}

.feather-arrow-down:before {
    content: "\e90c";
}

.feather-arrow-down-circle:before {
    content: "\e90d";
}

.feather-arrow-down-left:before {
    content: "\e90e";
}

.feather-arrow-down-right:before {
    content: "\e90f";
}

.feather-arrow-left:before {
    content: "\e910";
}

.feather-arrow-left-circle:before {
    content: "\e911";
}

.feather-arrow-right:before {
    content: "\e912";
}

.feather-arrow-right-circle:before {
    content: "\e913";
}

.feather-arrow-up:before {
    content: "\e914";
}

.feather-arrow-up-circle:before {
    content: "\e915";
}

.feather-arrow-up-left:before {
    content: "\e916";
}

.feather-arrow-up-right:before {
    content: "\e917";
}

.feather-at-sign:before {
    content: "\e918";
}

.feather-award:before {
    content: "\e919";
}

.feather-bar-chart:before {
    content: "\e91a";
}

.feather-bar-chart-2:before {
    content: "\e91b";
}

.feather-battery:before {
    content: "\e91c";
}

.feather-battery-charging:before {
    content: "\e91d";
}

.feather-bell:before {
    content: "\e91e";
}

.feather-bell-off:before {
    content: "\e91f";
}

.feather-bluetooth:before {
    content: "\e920";
}

.feather-bold:before {
    content: "\e921";
}

.feather-book:before {
    content: "\e922";
}

.feather-book-open:before {
    content: "\e923";
}

.feather-bookmark:before {
    content: "\e924";
}

.feather-box:before {
    content: "\e925";
}

.feather-briefcase:before {
    content: "\e926";
}

.feather-calendar:before {
    content: "\e927";
}

.feather-camera:before {
    content: "\e928";
}

.feather-camera-off:before {
    content: "\e929";
}

.feather-cast:before {
    content: "\e92a";
}

.feather-check:before {
    content: "\e92b";
}

.feather-check-circle:before {
    content: "\e92c";
}

.feather-check-square:before {
    content: "\e92d";
}

.feather-chevron-down:before {
    content: "\e92e";
}

.feather-chevron-left:before {
    content: "\e92f";
}

.feather-chevron-right:before {
    content: "\e930";
}

.feather-chevron-up:before {
    content: "\e931";
}

.feather-chevrons-down:before {
    content: "\e932";
}

.feather-chevrons-left:before {
    content: "\e933";
}

.feather-chevrons-right:before {
    content: "\e934";
}

.feather-chevrons-up:before {
    content: "\e935";
}

.feather-chrome:before {
    content: "\e936";
}

.feather-circle:before {
    content: "\e937";
}

.feather-clipboard:before {
    content: "\e938";
}

.feather-clock:before {
    content: "\e939";
}

.feather-cloud:before {
    content: "\e93a";
}

.feather-cloud-drizzle:before {
    content: "\e93b";
}

.feather-cloud-lightning:before {
    content: "\e93c";
}

.feather-cloud-off:before {
    content: "\e93d";
}

.feather-cloud-rain:before {
    content: "\e93e";
}

.feather-cloud-snow:before {
    content: "\e93f";
}

.feather-code:before {
    content: "\e940";
}

.feather-codepen:before {
    content: "\e941";
}

.feather-command:before {
    content: "\e942";
}

.feather-compass:before {
    content: "\e943";
}

.feather-copy:before {
    content: "\e944";
}

.feather-corner-down-left:before {
    content: "\e945";
}

.feather-corner-down-right:before {
    content: "\e946";
}

.feather-corner-left-down:before {
    content: "\e947";
}

.feather-corner-left-up:before {
    content: "\e948";
}

.feather-corner-right-down:before {
    content: "\e949";
}

.feather-corner-right-up:before {
    content: "\e94a";
}

.feather-corner-up-left:before {
    content: "\e94b";
}

.feather-corner-up-right:before {
    content: "\e94c";
}

.feather-cpu:before {
    content: "\e94d";
}

.feather-credit-card:before {
    content: "\e94e";
}

.feather-crop:before {
    content: "\e94f";
}

.feather-crosshair:before {
    content: "\e950";
}

.feather-database:before {
    content: "\e951";
}

.feather-delete:before {
    content: "\e952";
}

.feather-disc:before {
    content: "\e953";
}

.feather-dollar-sign:before {
    content: "\e954";
}

.feather-download:before {
    content: "\e955";
}

.feather-download-cloud:before {
    content: "\e956";
}

.feather-droplet:before {
    content: "\e957";
}

.feather-edit:before {
    content: "\e958";
}

.feather-edit-2:before {
    content: "\e959";
}

.feather-edit-3:before {
    content: "\e95a";
}

.feather-external-link:before {
    content: "\e95b";
}

.feather-eye:before {
    content: "\e95c";
}

.feather-eye-off:before {
    content: "\e95d";
}

.feather-facebook:before {
    content: "\e95e";
}

.feather-fast-forward:before {
    content: "\e95f";
}

.feather-feather:before {
    content: "\e960";
}

.feather-file:before {
    content: "\e961";
}

.feather-file-minus:before {
    content: "\e962";
}

.feather-file-plus:before {
    content: "\e963";
}

.feather-file-text:before {
    content: "\e964";
}

.feather-film:before {
    content: "\e965";
}

.feather-filter:before {
    content: "\e966";
}

.feather-flag:before {
    content: "\e967";
}

.feather-folder:before {
    content: "\e968";
}

.feather-folder-minus:before {
    content: "\e969";
}

.feather-folder-plus:before {
    content: "\e96a";
}

.feather-gift:before {
    content: "\e96b";
}

.feather-git-branch:before {
    content: "\e96c";
}

.feather-git-commit:before {
    content: "\e96d";
}

.feather-git-merge:before {
    content: "\e96e";
}

.feather-git-pull-request:before {
    content: "\e96f";
}

.feather-github:before {
    content: "\e970";
}

.feather-gitlab:before {
    content: "\e971";
}

.feather-globe:before {
    content: "\e972";
}

.feather-grid:before {
    content: "\e973";
}

.feather-hard-drive:before {
    content: "\e974";
}

.feather-hash:before {
    content: "\e975";
}

.feather-headphones:before {
    content: "\e976";
}

.feather-heart:before {
    content: "\e977";
}

.feather-help-circle:before {
    content: "\e978";
}

.feather-home:before {
    content: "\e979";
}

.feather-image:before {
    content: "\e97a";
}

.feather-inbox:before {
    content: "\e97b";
}

.feather-info:before {
    content: "\e97c";
}

.feather-instagram:before {
    content: "\e97d";
}

.feather-italic:before {
    content: "\e97e";
}

.feather-layers:before {
    content: "\e97f";
}

.feather-layout:before {
    content: "\e980";
}

.feather-life-buoy:before {
    content: "\e981";
}

.feather-link:before {
    content: "\e982";
}

.feather-link-2:before {
    content: "\e983";
}

.feather-linkedin:before {
    content: "\e984";
}

.feather-list:before {
    content: "\e985";
}

.feather-loader:before {
    content: "\e986";
}

.feather-lock:before {
    content: "\e987";
}

.feather-log-in:before {
    content: "\e988";
}

.feather-log-out:before {
    content: "\e989";
}

.feather-mail:before {
    content: "\e98a";
}

.feather-map:before {
    content: "\e98b";
}

.feather-map-pin:before {
    content: "\e98c";
}

.feather-maximize:before {
    content: "\e98d";
}

.feather-maximize-2:before {
    content: "\e98e";
}

.feather-menu:before {
    content: "\e98f";
}

.feather-message-circle:before {
    content: "\e990";
}

.feather-message-square:before {
    content: "\e991";
}

.feather-mic:before {
    content: "\e992";
}

.feather-mic-off:before {
    content: "\e993";
}

.feather-minimize:before {
    content: "\e994";
}

.feather-minimize-2:before {
    content: "\e995";
}

.feather-minus:before {
    content: "\e996";
}

.feather-minus-circle:before {
    content: "\e997";
}

.feather-minus-square:before {
    content: "\e998";
}

.feather-monitor:before {
    content: "\e999";
}

.feather-moon:before {
    content: "\e99a";
}

.feather-more-horizontal:before {
    content: "\e99b";
}

.feather-more-vertical:before {
    content: "\e99c";
}

.feather-move:before {
    content: "\e99d";
}

.feather-music:before {
    content: "\e99e";
}

.feather-navigation:before {
    content: "\e99f";
}

.feather-navigation-2:before {
    content: "\e9a0";
}

.feather-octagon:before {
    content: "\e9a1";
}

.feather-package:before {
    content: "\e9a2";
}

.feather-paperclip:before {
    content: "\e9a3";
}

.feather-pause:before {
    content: "\e9a4";
}

.feather-pause-circle:before {
    content: "\e9a5";
}

.feather-percent:before {
    content: "\e9a6";
}

.feather-phone:before {
    content: "\e9a7";
}

.feather-phone-call:before {
    content: "\e9a8";
}

.feather-phone-forwarded:before {
    content: "\e9a9";
}

.feather-phone-incoming:before {
    content: "\e9aa";
}

.feather-phone-missed:before {
    content: "\e9ab";
}

.feather-phone-off:before {
    content: "\e9ac";
}

.feather-phone-outgoing:before {
    content: "\e9ad";
}

.feather-pie-chart:before {
    content: "\e9ae";
}

.feather-play:before {
    content: "\e9af";
}

.feather-play-circle:before {
    content: "\e9b0";
}

.feather-plus:before {
    content: "\e9b1";
}

.feather-plus-circle:before {
    content: "\e9b2";
}

.feather-plus-square:before {
    content: "\e9b3";
}

.feather-pocket:before {
    content: "\e9b4";
}

.feather-power:before {
    content: "\e9b5";
}

.feather-printer:before {
    content: "\e9b6";
}

.feather-radio:before {
    content: "\e9b7";
}

.feather-refresh-ccw:before {
    content: "\e9b8";
}

.feather-refresh-cw:before {
    content: "\e9b9";
}

.feather-repeat:before {
    content: "\e9ba";
}

.feather-rewind:before {
    content: "\e9bb";
}

.feather-rotate-ccw:before {
    content: "\e9bc";
}

.feather-rotate-cw:before {
    content: "\e9bd";
}

.feather-rss:before {
    content: "\e9be";
}

.feather-save:before {
    content: "\e9bf";
}

.feather-scissors:before {
    content: "\e9c0";
}

.feather-search:before {
    content: "\e9c1";
}

.feather-send:before {
    content: "\e9c2";
}

.feather-server:before {
    content: "\e9c3";
}

.feather-settings:before {
    content: "\e9c4";
}

.feather-share:before {
    content: "\e9c5";
}

.feather-share-2:before {
    content: "\e9c6";
}

.feather-shield:before {
    content: "\e9c7";
}

.feather-shield-off:before {
    content: "\e9c8";
}

.feather-shopping-bag:before {
    content: "\e9c9";
}

.feather-shopping-cart:before {
    content: "\e9ca";
}

.feather-shuffle:before {
    content: "\e9cb";
}

.feather-sidebar:before {
    content: "\e9cc";
}

.feather-skip-back:before {
    content: "\e9cd";
}

.feather-skip-forward:before {
    content: "\e9ce";
}

.feather-slack:before {
    content: "\e9cf";
}

.feather-slash:before {
    content: "\e9d0";
}

.feather-sliders:before {
    content: "\e9d1";
}

.feather-smartphone:before {
    content: "\e9d2";
}

.feather-speaker:before {
    content: "\e9d3";
}

.feather-square:before {
    content: "\e9d4";
}

.feather-star:before {
    content: "\e9d5";
}

.feather-stop-circle:before {
    content: "\e9d6";
}

.feather-sun:before {
    content: "\e9d7";
}

.feather-sunrise:before {
    content: "\e9d8";
}

.feather-sunset:before {
    content: "\e9d9";
}

.feather-tablet:before {
    content: "\e9da";
}

.feather-tag:before {
    content: "\e9db";
}

.feather-target:before {
    content: "\e9dc";
}

.feather-terminal:before {
    content: "\e9dd";
}

.feather-thermometer:before {
    content: "\e9de";
}

.feather-thumbs-down:before {
    content: "\e9df";
}

.feather-thumbs-up:before {
    content: "\e9e0";
}

.feather-toggle-left:before {
    content: "\e9e1";
}

.feather-toggle-right:before {
    content: "\e9e2";
}

.feather-trash:before {
    content: "\e9e3";
}

.feather-trash-2:before {
    content: "\e9e4";
}

.feather-trending-down:before {
    content: "\e9e5";
}

.feather-trending-up:before {
    content: "\e9e6";
}

.feather-triangle:before {
    content: "\e9e7";
}

.feather-truck:before {
    content: "\e9e8";
}

.feather-tv:before {
    content: "\e9e9";
}

.feather-twitter:before {
    content: "\e9ea";
}

.feather-type:before {
    content: "\e9eb";
}

.feather-umbrella:before {
    content: "\e9ec";
}

.feather-underline:before {
    content: "\e9ed";
}

.feather-unlock:before {
    content: "\e9ee";
}

.feather-upload:before {
    content: "\e9ef";
}

.feather-upload-cloud:before {
    content: "\e9f0";
}

.feather-user:before {
    content: "\e9f1";
}

.feather-user-check:before {
    content: "\e9f2";
}

.feather-user-minus:before {
    content: "\e9f3";
}

.feather-user-plus:before {
    content: "\e9f4";
}

.feather-user-x:before {
    content: "\e9f5";
}

.feather-users:before {
    content: "\e9f6";
}

.feather-video:before {
    content: "\e9f7";
}

.feather-video-off:before {
    content: "\e9f8";
}

.feather-voicemail:before {
    content: "\e9f9";
}

.feather-volume:before {
    content: "\e9fa";
}

.feather-volume-1:before {
    content: "\e9fb";
}

.feather-volume-2:before {
    content: "\e9fc";
}

.feather-volume-x:before {
    content: "\e9fd";
}

.feather-watch:before {
    content: "\e9fe";
}

.feather-wifi:before {
    content: "\e9ff";
}

.feather-wifi-off:before {
    content: "\ea00";
}

.feather-wind:before {
    content: "\ea01";
}

.feather-x:before {
    content: "\ea02";
}

.feather-x-circle:before {
    content: "\ea03";
}

.feather-x-square:before {
    content: "\ea04";
}

.feather-youtube:before {
    content: "\ea05";
}

.feather-zap:before {
    content: "\ea06";
}

.feather-zap-off:before {
    content: "\ea07";
}

.feather-zoom-in:before {
    content: "\ea08";
}

.feather-zoom-out:before {
    content: "\ea09";
}

/*
Icons mind
Plugin URI: https://iconsmind.com/view_icons/
*/
@font-face {
    font-family: icomoon;
    src: url(../fonts/icomoon.eot?-rdmvgc);
    src: url(../fonts/icomoon.eot?#iefix-rdmvgc) format("embedded-opentype"), url(../fonts/icomoon.woff?-rdmvgc) format("woff"), url(../fonts/icomoon.ttf?-rdmvgc) format("truetype"), url(../fonts/icomoon.svg?-rdmvgc#icomoon) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

[class*=" line-icon-"],
[class^=line-icon-] {
    font-family: icomoon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.line-icon-A-Z:before {
    content: "\e600";
}

.line-icon-Aa:before {
    content: "\e601";
}

.line-icon-Add-Bag:before {
    content: "\e602";
}

.line-icon-Add-Basket:before {
    content: "\e603";
}

.line-icon-Add-Cart:before {
    content: "\e604";
}

.line-icon-Add-File:before {
    content: "\e605";
}

.line-icon-Add-SpaceAfterParagraph:before {
    content: "\e606";
}

.line-icon-Add-SpaceBeforeParagraph:before {
    content: "\e607";
}

.line-icon-Add-User:before {
    content: "\e608";
}

.line-icon-Add-UserStar:before {
    content: "\e609";
}

.line-icon-Add-Window:before {
    content: "\e60a";
}

.line-icon-Add:before {
    content: "\e60b";
}

.line-icon-Address-Book:before {
    content: "\e60c";
}

.line-icon-Address-Book2:before {
    content: "\e60d";
}

.line-icon-Administrator:before {
    content: "\e60e";
}

.line-icon-Aerobics-2:before {
    content: "\e60f";
}

.line-icon-Aerobics-3:before {
    content: "\e610";
}

.line-icon-Aerobics:before {
    content: "\e611";
}

.line-icon-Affiliate:before {
    content: "\e612";
}

.line-icon-Aim:before {
    content: "\e613";
}

.line-icon-Air-Balloon:before {
    content: "\e614";
}

.line-icon-Airbrush:before {
    content: "\e615";
}

.line-icon-Airship:before {
    content: "\e616";
}

.line-icon-Alarm-Clock:before {
    content: "\e617";
}

.line-icon-Alarm-Clock2:before {
    content: "\e618";
}

.line-icon-Alarm:before {
    content: "\e619";
}

.line-icon-Alien-2:before {
    content: "\e61a";
}

.line-icon-Alien:before {
    content: "\e61b";
}

.line-icon-Aligator:before {
    content: "\e61c";
}

.line-icon-Align-Center:before {
    content: "\e61d";
}

.line-icon-Align-JustifyAll:before {
    content: "\e61e";
}

.line-icon-Align-JustifyCenter:before {
    content: "\e61f";
}

.line-icon-Align-JustifyLeft:before {
    content: "\e620";
}

.line-icon-Align-JustifyRight:before {
    content: "\e621";
}

.line-icon-Align-Left:before {
    content: "\e622";
}

.line-icon-Align-Right:before {
    content: "\e623";
}

.line-icon-Alpha:before {
    content: "\e624";
}

.line-icon-Ambulance:before {
    content: "\e625";
}

.line-icon-AMX:before {
    content: "\e626";
}

.line-icon-Anchor-2:before {
    content: "\e627";
}

.line-icon-Anchor:before {
    content: "\e628";
}

.line-icon-Android-Store:before {
    content: "\e629";
}

.line-icon-Android:before {
    content: "\e62a";
}

.line-icon-Angel-Smiley:before {
    content: "\e62b";
}

.line-icon-Angel:before {
    content: "\e62c";
}

.line-icon-Angry:before {
    content: "\e62d";
}

.line-icon-Apple-Bite:before {
    content: "\e62e";
}

.line-icon-Apple-Store:before {
    content: "\e62f";
}

.line-icon-Apple:before {
    content: "\e630";
}

.line-icon-Approved-Window:before {
    content: "\e631";
}

.line-icon-Aquarius-2:before {
    content: "\e632";
}

.line-icon-Aquarius:before {
    content: "\e633";
}

.line-icon-Archery-2:before {
    content: "\e634";
}

.line-icon-Archery:before {
    content: "\e635";
}

.line-icon-Argentina:before {
    content: "\e636";
}

.line-icon-Aries-2:before {
    content: "\e637";
}

.line-icon-Aries:before {
    content: "\e638";
}

.line-icon-Army-Key:before {
    content: "\e639";
}

.line-icon-Arrow-Around:before {
    content: "\e63a";
}

.line-icon-Arrow-Back3:before {
    content: "\e63b";
}

.line-icon-Arrow-Back:before {
    content: "\e63c";
}

.line-icon-Arrow-Back2:before {
    content: "\e63d";
}

.line-icon-Arrow-Barrier:before {
    content: "\e63e";
}

.line-icon-Arrow-Circle:before {
    content: "\e63f";
}

.line-icon-Arrow-Cross:before {
    content: "\e640";
}

.line-icon-Arrow-Down:before {
    content: "\e641";
}

.line-icon-Arrow-Down2:before {
    content: "\e642";
}

.line-icon-Arrow-Down3:before {
    content: "\e643";
}

.line-icon-Arrow-DowninCircle:before {
    content: "\e644";
}

.line-icon-Arrow-Fork:before {
    content: "\e645";
}

.line-icon-Arrow-Forward:before {
    content: "\e646";
}

.line-icon-Arrow-Forward2:before {
    content: "\e647";
}

.line-icon-Arrow-From:before {
    content: "\e648";
}

.line-icon-Arrow-Inside:before {
    content: "\e649";
}

.line-icon-Arrow-Inside45:before {
    content: "\e64a";
}

.line-icon-Arrow-InsideGap:before {
    content: "\e64b";
}

.line-icon-Arrow-InsideGap45:before {
    content: "\e64c";
}

.line-icon-Arrow-Into:before {
    content: "\e64d";
}

.line-icon-Arrow-Join:before {
    content: "\e64e";
}

.line-icon-Arrow-Junction:before {
    content: "\e64f";
}

.line-icon-Arrow-Left:before {
    content: "\e650";
}

.line-icon-Arrow-Left2:before {
    content: "\e651";
}

.line-icon-Arrow-LeftinCircle:before {
    content: "\e652";
}

.line-icon-Arrow-Loop:before {
    content: "\e653";
}

.line-icon-Arrow-Merge:before {
    content: "\e654";
}

.line-icon-Arrow-Mix:before {
    content: "\e655";
}

.line-icon-Arrow-Next:before {
    content: "\e656";
}

.line-icon-Arrow-OutLeft:before {
    content: "\e657";
}

.line-icon-Arrow-OutRight:before {
    content: "\e658";
}

.line-icon-Arrow-Outside:before {
    content: "\e659";
}

.line-icon-Arrow-Outside45:before {
    content: "\e65a";
}

.line-icon-Arrow-OutsideGap:before {
    content: "\e65b";
}

.line-icon-Arrow-OutsideGap45:before {
    content: "\e65c";
}

.line-icon-Arrow-Over:before {
    content: "\e65d";
}

.line-icon-Arrow-Refresh:before {
    content: "\e65e";
}

.line-icon-Arrow-Refresh2:before {
    content: "\e65f";
}

.line-icon-Arrow-Right:before {
    content: "\e660";
}

.line-icon-Arrow-Right2:before {
    content: "\e661";
}

.line-icon-Arrow-RightinCircle:before {
    content: "\e662";
}

.line-icon-Arrow-Shuffle:before {
    content: "\e663";
}

.line-icon-Arrow-Squiggly:before {
    content: "\e664";
}

.line-icon-Arrow-Through:before {
    content: "\e665";
}

.line-icon-Arrow-To:before {
    content: "\e666";
}

.line-icon-Arrow-TurnLeft:before {
    content: "\e667";
}

.line-icon-Arrow-TurnRight:before {
    content: "\e668";
}

.line-icon-Arrow-Up:before {
    content: "\e669";
}

.line-icon-Arrow-Up2:before {
    content: "\e66a";
}

.line-icon-Arrow-Up3:before {
    content: "\e66b";
}

.line-icon-Arrow-UpinCircle:before {
    content: "\e66c";
}

.line-icon-Arrow-XLeft:before {
    content: "\e66d";
}

.line-icon-Arrow-XRight:before {
    content: "\e66e";
}

.line-icon-Ask:before {
    content: "\e66f";
}

.line-icon-Assistant:before {
    content: "\e670";
}

.line-icon-Astronaut:before {
    content: "\e671";
}

.line-icon-At-Sign:before {
    content: "\e672";
}

.line-icon-ATM:before {
    content: "\e673";
}

.line-icon-Atom:before {
    content: "\e674";
}

.line-icon-Audio:before {
    content: "\e675";
}

.line-icon-Auto-Flash:before {
    content: "\e676";
}

.line-icon-Autumn:before {
    content: "\e677";
}

.line-icon-Baby-Clothes:before {
    content: "\e678";
}

.line-icon-Baby-Clothes2:before {
    content: "\e679";
}

.line-icon-Baby-Cry:before {
    content: "\e67a";
}

.line-icon-Baby:before {
    content: "\e67b";
}

.line-icon-Back2:before {
    content: "\e67c";
}

.line-icon-Back-Media:before {
    content: "\e67d";
}

.line-icon-Back-Music:before {
    content: "\e67e";
}

.line-icon-Back:before {
    content: "\e67f";
}

.line-icon-Background:before {
    content: "\e680";
}

.line-icon-Bacteria:before {
    content: "\e681";
}

.line-icon-Bag-Coins:before {
    content: "\e682";
}

.line-icon-Bag-Items:before {
    content: "\e683";
}

.line-icon-Bag-Quantity:before {
    content: "\e684";
}

.line-icon-Bag:before {
    content: "\e685";
}

.line-icon-Bakelite:before {
    content: "\e686";
}

.line-icon-Ballet-Shoes:before {
    content: "\e687";
}

.line-icon-Balloon:before {
    content: "\e688";
}

.line-icon-Banana:before {
    content: "\e689";
}

.line-icon-Band-Aid:before {
    content: "\e68a";
}

.line-icon-Bank:before {
    content: "\e68b";
}

.line-icon-Bar-Chart:before {
    content: "\e68c";
}

.line-icon-Bar-Chart2:before {
    content: "\e68d";
}

.line-icon-Bar-Chart3:before {
    content: "\e68e";
}

.line-icon-Bar-Chart4:before {
    content: "\e68f";
}

.line-icon-Bar-Chart5:before {
    content: "\e690";
}

.line-icon-Bar-Code:before {
    content: "\e691";
}

.line-icon-Barricade-2:before {
    content: "\e692";
}

.line-icon-Barricade:before {
    content: "\e693";
}

.line-icon-Baseball:before {
    content: "\e694";
}

.line-icon-Basket-Ball:before {
    content: "\e695";
}

.line-icon-Basket-Coins:before {
    content: "\e696";
}

.line-icon-Basket-Items:before {
    content: "\e697";
}

.line-icon-Basket-Quantity:before {
    content: "\e698";
}

.line-icon-Bat-2:before {
    content: "\e699";
}

.line-icon-Bat:before {
    content: "\e69a";
}

.line-icon-Bathrobe:before {
    content: "\e69b";
}

.line-icon-Batman-Mask:before {
    content: "\e69c";
}

.line-icon-Battery-0:before {
    content: "\e69d";
}

.line-icon-Battery-25:before {
    content: "\e69e";
}

.line-icon-Battery-50:before {
    content: "\e69f";
}

.line-icon-Battery-75:before {
    content: "\e6a0";
}

.line-icon-Battery-100:before {
    content: "\e6a1";
}

.line-icon-Battery-Charge:before {
    content: "\e6a2";
}

.line-icon-Bear:before {
    content: "\e6a3";
}

.line-icon-Beard-2:before {
    content: "\e6a4";
}

.line-icon-Beard-3:before {
    content: "\e6a5";
}

.line-icon-Beard:before {
    content: "\e6a6";
}

.line-icon-Bebo:before {
    content: "\e6a7";
}

.line-icon-Bee:before {
    content: "\e6a8";
}

.line-icon-Beer-Glass:before {
    content: "\e6a9";
}

.line-icon-Beer:before {
    content: "\e6aa";
}

.line-icon-Bell-2:before {
    content: "\e6ab";
}

.line-icon-Bell:before {
    content: "\e6ac";
}

.line-icon-Belt-2:before {
    content: "\e6ad";
}

.line-icon-Belt-3:before {
    content: "\e6ae";
}

.line-icon-Belt:before {
    content: "\e6af";
}

.line-icon-Berlin-Tower:before {
    content: "\e6b0";
}

.line-icon-Beta:before {
    content: "\e6b1";
}

.line-icon-Betvibes:before {
    content: "\e6b2";
}

.line-icon-Bicycle-2:before {
    content: "\e6b3";
}

.line-icon-Bicycle-3:before {
    content: "\e6b4";
}

.line-icon-Bicycle:before {
    content: "\e6b5";
}

.line-icon-Big-Bang:before {
    content: "\e6b6";
}

.line-icon-Big-Data:before {
    content: "\e6b7";
}

.line-icon-Bike-Helmet:before {
    content: "\e6b8";
}

.line-icon-Bikini:before {
    content: "\e6b9";
}

.line-icon-Bilk-Bottle2:before {
    content: "\e6ba";
}

.line-icon-Billing:before {
    content: "\e6bb";
}

.line-icon-Bing:before {
    content: "\e6bc";
}

.line-icon-Binocular:before {
    content: "\e6bd";
}

.line-icon-Bio-Hazard:before {
    content: "\e6be";
}

.line-icon-Biotech:before {
    content: "\e6bf";
}

.line-icon-Bird-DeliveringLetter:before {
    content: "\e6c0";
}

.line-icon-Bird:before {
    content: "\e6c1";
}

.line-icon-Birthday-Cake:before {
    content: "\e6c2";
}

.line-icon-Bisexual:before {
    content: "\e6c3";
}

.line-icon-Bishop:before {
    content: "\e6c4";
}

.line-icon-Bitcoin:before {
    content: "\e6c5";
}

.line-icon-Black-Cat:before {
    content: "\e6c6";
}

.line-icon-Blackboard:before {
    content: "\e6c7";
}

.line-icon-Blinklist:before {
    content: "\e6c8";
}

.line-icon-Block-Cloud:before {
    content: "\e6c9";
}

.line-icon-Block-Window:before {
    content: "\e6ca";
}

.line-icon-Blogger:before {
    content: "\e6cb";
}

.line-icon-Blood:before {
    content: "\e6cc";
}

.line-icon-Blouse:before {
    content: "\e6cd";
}

.line-icon-Blueprint:before {
    content: "\e6ce";
}

.line-icon-Board:before {
    content: "\e6cf";
}

.line-icon-Bodybuilding:before {
    content: "\e6d0";
}

.line-icon-Bold-Text:before {
    content: "\e6d1";
}

.line-icon-Bone:before {
    content: "\e6d2";
}

.line-icon-Bones:before {
    content: "\e6d3";
}

.line-icon-Book:before {
    content: "\e6d4";
}

.line-icon-Bookmark:before {
    content: "\e6d5";
}

.line-icon-Books-2:before {
    content: "\e6d6";
}

.line-icon-Books:before {
    content: "\e6d7";
}

.line-icon-Boom:before {
    content: "\e6d8";
}

.line-icon-Boot-2:before {
    content: "\e6d9";
}

.line-icon-Boot:before {
    content: "\e6da";
}

.line-icon-Bottom-ToTop:before {
    content: "\e6db";
}

.line-icon-Bow-2:before {
    content: "\e6dc";
}

.line-icon-Bow-3:before {
    content: "\e6dd";
}

.line-icon-Bow-4:before {
    content: "\e6de";
}

.line-icon-Bow-5:before {
    content: "\e6df";
}

.line-icon-Bow-6:before {
    content: "\e6e0";
}

.line-icon-Bow:before {
    content: "\e6e1";
}

.line-icon-Bowling-2:before {
    content: "\e6e2";
}

.line-icon-Bowling:before {
    content: "\e6e3";
}

.line-icon-Box2:before {
    content: "\e6e4";
}

.line-icon-Box-Close:before {
    content: "\e6e5";
}

.line-icon-Box-Full:before {
    content: "\e6e6";
}

.line-icon-Box-Open:before {
    content: "\e6e7";
}

.line-icon-Box-withFolders:before {
    content: "\e6e8";
}

.line-icon-Box:before {
    content: "\e6e9";
}

.line-icon-Boy:before {
    content: "\e6ea";
}

.line-icon-Bra:before {
    content: "\e6eb";
}

.line-icon-Brain-2:before {
    content: "\e6ec";
}

.line-icon-Brain-3:before {
    content: "\e6ed";
}

.line-icon-Brain:before {
    content: "\e6ee";
}

.line-icon-Brazil:before {
    content: "\e6ef";
}

.line-icon-Bread-2:before {
    content: "\e6f0";
}

.line-icon-Bread:before {
    content: "\e6f1";
}

.line-icon-Bridge:before {
    content: "\e6f2";
}

.line-icon-Brightkite:before {
    content: "\e6f3";
}

.line-icon-Broke-Link2:before {
    content: "\e6f4";
}

.line-icon-Broken-Link:before {
    content: "\e6f5";
}

.line-icon-Broom:before {
    content: "\e6f6";
}

.line-icon-Brush:before {
    content: "\e6f7";
}

.line-icon-Bucket:before {
    content: "\e6f8";
}

.line-icon-Bug:before {
    content: "\e6f9";
}

.line-icon-Building:before {
    content: "\e6fa";
}

.line-icon-Bulleted-List:before {
    content: "\e6fb";
}

.line-icon-Bus-2:before {
    content: "\e6fc";
}

.line-icon-Bus:before {
    content: "\e6fd";
}

.line-icon-Business-Man:before {
    content: "\e6fe";
}

.line-icon-Business-ManWoman:before {
    content: "\e6ff";
}

.line-icon-Business-Mens:before {
    content: "\e700";
}

.line-icon-Business-Woman:before {
    content: "\e701";
}

.line-icon-Butterfly:before {
    content: "\e702";
}

.line-icon-Button:before {
    content: "\e703";
}

.line-icon-Cable-Car:before {
    content: "\e704";
}

.line-icon-Cake:before {
    content: "\e705";
}

.line-icon-Calculator-2:before {
    content: "\e706";
}

.line-icon-Calculator-3:before {
    content: "\e707";
}

.line-icon-Calculator:before {
    content: "\e708";
}

.line-icon-Calendar-2:before {
    content: "\e709";
}

.line-icon-Calendar-3:before {
    content: "\e70a";
}

.line-icon-Calendar-4:before {
    content: "\e70b";
}

.line-icon-Calendar-Clock:before {
    content: "\e70c";
}

.line-icon-Calendar:before {
    content: "\e70d";
}

.line-icon-Camel:before {
    content: "\e70e";
}

.line-icon-Camera-2:before {
    content: "\e70f";
}

.line-icon-Camera-3:before {
    content: "\e710";
}

.line-icon-Camera-4:before {
    content: "\e711";
}

.line-icon-Camera-5:before {
    content: "\e712";
}

.line-icon-Camera-Back:before {
    content: "\e713";
}

.line-icon-Camera:before {
    content: "\e714";
}

.line-icon-Can-2:before {
    content: "\e715";
}

.line-icon-Can:before {
    content: "\e716";
}

.line-icon-Canada:before {
    content: "\e717";
}

.line-icon-Cancer-2:before {
    content: "\e718";
}

.line-icon-Cancer-3:before {
    content: "\e719";
}

.line-icon-Cancer:before {
    content: "\e71a";
}

.line-icon-Candle:before {
    content: "\e71b";
}

.line-icon-Candy-Cane:before {
    content: "\e71c";
}

.line-icon-Candy:before {
    content: "\e71d";
}

.line-icon-Cannon:before {
    content: "\e71e";
}

.line-icon-Cap-2:before {
    content: "\e71f";
}

.line-icon-Cap-3:before {
    content: "\e720";
}

.line-icon-Cap-Smiley:before {
    content: "\e721";
}

.line-icon-Cap:before {
    content: "\e722";
}

.line-icon-Capricorn-2:before {
    content: "\e723";
}

.line-icon-Capricorn:before {
    content: "\e724";
}

.line-icon-Car-2:before {
    content: "\e725";
}

.line-icon-Car-3:before {
    content: "\e726";
}

.line-icon-Car-Coins:before {
    content: "\e727";
}

.line-icon-Car-Items:before {
    content: "\e728";
}

.line-icon-Car-Wheel:before {
    content: "\e729";
}

.line-icon-Car:before {
    content: "\e72a";
}

.line-icon-Cardigan:before {
    content: "\e72b";
}

.line-icon-Cardiovascular:before {
    content: "\e72c";
}

.line-icon-Cart-Quantity:before {
    content: "\e72d";
}

.line-icon-Casette-Tape:before {
    content: "\e72e";
}

.line-icon-Cash-Register:before {
    content: "\e72f";
}

.line-icon-Cash-register2:before {
    content: "\e730";
}

.line-icon-Castle:before {
    content: "\e731";
}

.line-icon-Cat:before {
    content: "\e732";
}

.line-icon-Cathedral:before {
    content: "\e733";
}

.line-icon-Cauldron:before {
    content: "\e734";
}

.line-icon-CD-2:before {
    content: "\e735";
}

.line-icon-CD-Cover:before {
    content: "\e736";
}

.line-icon-CD:before {
    content: "\e737";
}

.line-icon-Cello:before {
    content: "\e738";
}

.line-icon-Celsius:before {
    content: "\e739";
}

.line-icon-Chacked-Flag:before {
    content: "\e73a";
}

.line-icon-Chair:before {
    content: "\e73b";
}

.line-icon-Charger:before {
    content: "\e73c";
}

.line-icon-Check-2:before {
    content: "\e73d";
}

.line-icon-Check:before {
    content: "\e73e";
}

.line-icon-Checked-User:before {
    content: "\e73f";
}

.line-icon-Checkmate:before {
    content: "\e740";
}

.line-icon-Checkout-Bag:before {
    content: "\e741";
}

.line-icon-Checkout-Basket:before {
    content: "\e742";
}

.line-icon-Checkout:before {
    content: "\e743";
}

.line-icon-Cheese:before {
    content: "\e744";
}

.line-icon-Cheetah:before {
    content: "\e745";
}

.line-icon-Chef-Hat:before {
    content: "\e746";
}

.line-icon-Chef-Hat2:before {
    content: "\e747";
}

.line-icon-Chef:before {
    content: "\e748";
}

.line-icon-Chemical-2:before {
    content: "\e749";
}

.line-icon-Chemical-3:before {
    content: "\e74a";
}

.line-icon-Chemical-4:before {
    content: "\e74b";
}

.line-icon-Chemical-5:before {
    content: "\e74c";
}

.line-icon-Chemical:before {
    content: "\e74d";
}

.line-icon-Chess-Board:before {
    content: "\e74e";
}

.line-icon-Chess:before {
    content: "\e74f";
}

.line-icon-Chicken:before {
    content: "\e750";
}

.line-icon-Chile:before {
    content: "\e751";
}

.line-icon-Chimney:before {
    content: "\e752";
}

.line-icon-China:before {
    content: "\e753";
}

.line-icon-Chinese-Temple:before {
    content: "\e754";
}

.line-icon-Chip:before {
    content: "\e755";
}

.line-icon-Chopsticks-2:before {
    content: "\e756";
}

.line-icon-Chopsticks:before {
    content: "\e757";
}

.line-icon-Christmas-Ball:before {
    content: "\e758";
}

.line-icon-Christmas-Bell:before {
    content: "\e759";
}

.line-icon-Christmas-Candle:before {
    content: "\e75a";
}

.line-icon-Christmas-Hat:before {
    content: "\e75b";
}

.line-icon-Christmas-Sleigh:before {
    content: "\e75c";
}

.line-icon-Christmas-Snowman:before {
    content: "\e75d";
}

.line-icon-Christmas-Sock:before {
    content: "\e75e";
}

.line-icon-Christmas-Tree:before {
    content: "\e75f";
}

.line-icon-Christmas:before {
    content: "\e760";
}

.line-icon-Chrome:before {
    content: "\e761";
}

.line-icon-Chrysler-Building:before {
    content: "\e762";
}

.line-icon-Cinema:before {
    content: "\e763";
}

.line-icon-Circular-Point:before {
    content: "\e764";
}

.line-icon-City-Hall:before {
    content: "\e765";
}

.line-icon-Clamp:before {
    content: "\e766";
}

.line-icon-Clapperboard-Close:before {
    content: "\e767";
}

.line-icon-Clapperboard-Open:before {
    content: "\e768";
}

.line-icon-Claps:before {
    content: "\e769";
}

.line-icon-Clef:before {
    content: "\e76a";
}

.line-icon-Clinic:before {
    content: "\e76b";
}

.line-icon-Clock-2:before {
    content: "\e76c";
}

.line-icon-Clock-3:before {
    content: "\e76d";
}

.line-icon-Clock-4:before {
    content: "\e76e";
}

.line-icon-Clock-Back:before {
    content: "\e76f";
}

.line-icon-Clock-Forward:before {
    content: "\e770";
}

.line-icon-Clock:before {
    content: "\e771";
}

.line-icon-Close-Window:before {
    content: "\e772";
}

.line-icon-Close:before {
    content: "\e773";
}

.line-icon-Clothing-Store:before {
    content: "\e774";
}

.line-icon-Cloud--:before {
    content: "\e775";
}

.line-icon-Cloud-:before {
    content: "\e776";
}

.line-icon-Cloud-Camera:before {
    content: "\e777";
}

.line-icon-Cloud-Computer:before {
    content: "\e778";
}

.line-icon-Cloud-Email:before {
    content: "\e779";
}

.line-icon-Cloud-Hail:before {
    content: "\e77a";
}

.line-icon-Cloud-Laptop:before {
    content: "\e77b";
}

.line-icon-Cloud-Lock:before {
    content: "\e77c";
}

.line-icon-Cloud-Moon:before {
    content: "\e77d";
}

.line-icon-Cloud-Music:before {
    content: "\e77e";
}

.line-icon-Cloud-Picture:before {
    content: "\e77f";
}

.line-icon-Cloud-Rain:before {
    content: "\e780";
}

.line-icon-Cloud-Remove:before {
    content: "\e781";
}

.line-icon-Cloud-Secure:before {
    content: "\e782";
}

.line-icon-Cloud-Settings:before {
    content: "\e783";
}

.line-icon-Cloud-Smartphone:before {
    content: "\e784";
}

.line-icon-Cloud-Snow:before {
    content: "\e785";
}

.line-icon-Cloud-Sun:before {
    content: "\e786";
}

.line-icon-Cloud-Tablet:before {
    content: "\e787";
}

.line-icon-Cloud-Video:before {
    content: "\e788";
}

.line-icon-Cloud-Weather:before {
    content: "\e789";
}

.line-icon-Cloud:before {
    content: "\e78a";
}

.line-icon-Clouds-Weather:before {
    content: "\e78b";
}

.line-icon-Clouds:before {
    content: "\e78c";
}

.line-icon-Clown:before {
    content: "\e78d";
}

.line-icon-CMYK:before {
    content: "\e78e";
}

.line-icon-Coat:before {
    content: "\e78f";
}

.line-icon-Cocktail:before {
    content: "\e790";
}

.line-icon-Coconut:before {
    content: "\e791";
}

.line-icon-Code-Window:before {
    content: "\e792";
}

.line-icon-Coding:before {
    content: "\e793";
}

.line-icon-Coffee-2:before {
    content: "\e794";
}

.line-icon-Coffee-Bean:before {
    content: "\e795";
}

.line-icon-Coffee-Machine:before {
    content: "\e796";
}

.line-icon-Coffee-toGo:before {
    content: "\e797";
}

.line-icon-Coffee:before {
    content: "\e798";
}

.line-icon-Coffin:before {
    content: "\e799";
}

.line-icon-Coin:before {
    content: "\e79a";
}

.line-icon-Coins-2:before {
    content: "\e79b";
}

.line-icon-Coins-3:before {
    content: "\e79c";
}

.line-icon-Coins:before {
    content: "\e79d";
}

.line-icon-Colombia:before {
    content: "\e79e";
}

.line-icon-Colosseum:before {
    content: "\e79f";
}

.line-icon-Column-2:before {
    content: "\e7a0";
}

.line-icon-Column-3:before {
    content: "\e7a1";
}

.line-icon-Column:before {
    content: "\e7a2";
}

.line-icon-Comb-2:before {
    content: "\e7a3";
}

.line-icon-Comb:before {
    content: "\e7a4";
}

.line-icon-Communication-Tower:before {
    content: "\e7a5";
}

.line-icon-Communication-Tower2:before {
    content: "\e7a6";
}

.line-icon-Compass-2:before {
    content: "\e7a7";
}

.line-icon-Compass-3:before {
    content: "\e7a8";
}

.line-icon-Compass-4:before {
    content: "\e7a9";
}

.line-icon-Compass-Rose:before {
    content: "\e7aa";
}

.line-icon-Compass:before {
    content: "\e7ab";
}

.line-icon-Computer-2:before {
    content: "\e7ac";
}

.line-icon-Computer-3:before {
    content: "\e7ad";
}

.line-icon-Computer-Secure:before {
    content: "\e7ae";
}

.line-icon-Computer:before {
    content: "\e7af";
}

.line-icon-Conference:before {
    content: "\e7b0";
}

.line-icon-Confused:before {
    content: "\e7b1";
}

.line-icon-Conservation:before {
    content: "\e7b2";
}

.line-icon-Consulting:before {
    content: "\e7b3";
}

.line-icon-Contrast:before {
    content: "\e7b4";
}

.line-icon-Control-2:before {
    content: "\e7b5";
}

.line-icon-Control:before {
    content: "\e7b6";
}

.line-icon-Cookie-Man:before {
    content: "\e7b7";
}

.line-icon-Cookies:before {
    content: "\e7b8";
}

.line-icon-Cool-Guy:before {
    content: "\e7b9";
}

.line-icon-Cool:before {
    content: "\e7ba";
}

.line-icon-Copyright:before {
    content: "\e7bb";
}

.line-icon-Costume:before {
    content: "\e7bc";
}

.line-icon-Couple-Sign:before {
    content: "\e7bd";
}

.line-icon-Cow:before {
    content: "\e7be";
}

.line-icon-CPU:before {
    content: "\e7bf";
}

.line-icon-Crane:before {
    content: "\e7c0";
}

.line-icon-Cranium:before {
    content: "\e7c1";
}

.line-icon-Credit-Card:before {
    content: "\e7c2";
}

.line-icon-Credit-Card2:before {
    content: "\e7c3";
}

.line-icon-Credit-Card3:before {
    content: "\e7c4";
}

.line-icon-Cricket:before {
    content: "\e7c5";
}

.line-icon-Criminal:before {
    content: "\e7c6";
}

.line-icon-Croissant:before {
    content: "\e7c7";
}

.line-icon-Crop-2:before {
    content: "\e7c8";
}

.line-icon-Crop-3:before {
    content: "\e7c9";
}

.line-icon-Crown-2:before {
    content: "\e7ca";
}

.line-icon-Crown:before {
    content: "\e7cb";
}

.line-icon-Crying:before {
    content: "\e7cc";
}

.line-icon-Cube-Molecule:before {
    content: "\e7cd";
}

.line-icon-Cube-Molecule2:before {
    content: "\e7ce";
}

.line-icon-Cupcake:before {
    content: "\e7cf";
}

.line-icon-Cursor-Click:before {
    content: "\e7d0";
}

.line-icon-Cursor-Click2:before {
    content: "\e7d1";
}

.line-icon-Cursor-Move:before {
    content: "\e7d2";
}

.line-icon-Cursor-Move2:before {
    content: "\e7d3";
}

.line-icon-Cursor-Select:before {
    content: "\e7d4";
}

.line-icon-Cursor:before {
    content: "\e7d5";
}

.line-icon-D-Eyeglasses:before {
    content: "\e7d6";
}

.line-icon-D-Eyeglasses2:before {
    content: "\e7d7";
}

.line-icon-Dam:before {
    content: "\e7d8";
}

.line-icon-Danemark:before {
    content: "\e7d9";
}

.line-icon-Danger-2:before {
    content: "\e7da";
}

.line-icon-Danger:before {
    content: "\e7db";
}

.line-icon-Dashboard:before {
    content: "\e7dc";
}

.line-icon-Data-Backup:before {
    content: "\e7dd";
}

.line-icon-Data-Block:before {
    content: "\e7de";
}

.line-icon-Data-Center:before {
    content: "\e7df";
}

.line-icon-Data-Clock:before {
    content: "\e7e0";
}

.line-icon-Data-Cloud:before {
    content: "\e7e1";
}

.line-icon-Data-Compress:before {
    content: "\e7e2";
}

.line-icon-Data-Copy:before {
    content: "\e7e3";
}

.line-icon-Data-Download:before {
    content: "\e7e4";
}

.line-icon-Data-Financial:before {
    content: "\e7e5";
}

.line-icon-Data-Key:before {
    content: "\e7e6";
}

.line-icon-Data-Lock:before {
    content: "\e7e7";
}

.line-icon-Data-Network:before {
    content: "\e7e8";
}

.line-icon-Data-Password:before {
    content: "\e7e9";
}

.line-icon-Data-Power:before {
    content: "\e7ea";
}

.line-icon-Data-Refresh:before {
    content: "\e7eb";
}

.line-icon-Data-Save:before {
    content: "\e7ec";
}

.line-icon-Data-Search:before {
    content: "\e7ed";
}

.line-icon-Data-Security:before {
    content: "\e7ee";
}

.line-icon-Data-Settings:before {
    content: "\e7ef";
}

.line-icon-Data-Sharing:before {
    content: "\e7f0";
}

.line-icon-Data-Shield:before {
    content: "\e7f1";
}

.line-icon-Data-Signal:before {
    content: "\e7f2";
}

.line-icon-Data-Storage:before {
    content: "\e7f3";
}

.line-icon-Data-Stream:before {
    content: "\e7f4";
}

.line-icon-Data-Transfer:before {
    content: "\e7f5";
}

.line-icon-Data-Unlock:before {
    content: "\e7f6";
}

.line-icon-Data-Upload:before {
    content: "\e7f7";
}

.line-icon-Data-Yes:before {
    content: "\e7f8";
}

.line-icon-Data:before {
    content: "\e7f9";
}

.line-icon-David-Star:before {
    content: "\e7fa";
}

.line-icon-Daylight:before {
    content: "\e7fb";
}

.line-icon-Death:before {
    content: "\e7fc";
}

.line-icon-Debian:before {
    content: "\e7fd";
}

.line-icon-Dec:before {
    content: "\e7fe";
}

.line-icon-Decrase-Inedit:before {
    content: "\e7ff";
}

.line-icon-Deer-2:before {
    content: "\e800";
}

.line-icon-Deer:before {
    content: "\e801";
}

.line-icon-Delete-File:before {
    content: "\e802";
}

.line-icon-Delete-Window:before {
    content: "\e803";
}

.line-icon-Delicious:before {
    content: "\e804";
}

.line-icon-Depression:before {
    content: "\e805";
}

.line-icon-Deviantart:before {
    content: "\e806";
}

.line-icon-Device-SyncwithCloud:before {
    content: "\e807";
}

.line-icon-Diamond:before {
    content: "\e808";
}

.line-icon-Dice-2:before {
    content: "\e809";
}

.line-icon-Dice:before {
    content: "\e80a";
}

.line-icon-Digg:before {
    content: "\e80b";
}

.line-icon-Digital-Drawing:before {
    content: "\e80c";
}

.line-icon-Diigo:before {
    content: "\e80d";
}

.line-icon-Dinosaur:before {
    content: "\e80e";
}

.line-icon-Diploma-2:before {
    content: "\e80f";
}

.line-icon-Diploma:before {
    content: "\e810";
}

.line-icon-Direction-East:before {
    content: "\e811";
}

.line-icon-Direction-North:before {
    content: "\e812";
}

.line-icon-Direction-South:before {
    content: "\e813";
}

.line-icon-Direction-West:before {
    content: "\e814";
}

.line-icon-Director:before {
    content: "\e815";
}

.line-icon-Disk:before {
    content: "\e816";
}

.line-icon-Dj:before {
    content: "\e817";
}

.line-icon-DNA-2:before {
    content: "\e818";
}

.line-icon-DNA-Helix:before {
    content: "\e819";
}

.line-icon-DNA:before {
    content: "\e81a";
}

.line-icon-Doctor:before {
    content: "\e81b";
}

.line-icon-Dog:before {
    content: "\e81c";
}

.line-icon-Dollar-Sign:before {
    content: "\e81d";
}

.line-icon-Dollar-Sign2:before {
    content: "\e81e";
}

.line-icon-Dollar:before {
    content: "\e81f";
}

.line-icon-Dolphin:before {
    content: "\e820";
}

.line-icon-Domino:before {
    content: "\e821";
}

.line-icon-Door-Hanger:before {
    content: "\e822";
}

.line-icon-Door:before {
    content: "\e823";
}

.line-icon-Doplr:before {
    content: "\e824";
}

.line-icon-Double-Circle:before {
    content: "\e825";
}

.line-icon-Double-Tap:before {
    content: "\e826";
}

.line-icon-Doughnut:before {
    content: "\e827";
}

.line-icon-Dove:before {
    content: "\e828";
}

.line-icon-Down-2:before {
    content: "\e829";
}

.line-icon-Down-3:before {
    content: "\e82a";
}

.line-icon-Down-4:before {
    content: "\e82b";
}

.line-icon-Down:before {
    content: "\e82c";
}

.line-icon-Download-2:before {
    content: "\e82d";
}

.line-icon-Download-fromCloud:before {
    content: "\e82e";
}

.line-icon-Download-Window:before {
    content: "\e82f";
}

.line-icon-Download:before {
    content: "\e830";
}

.line-icon-Downward:before {
    content: "\e831";
}

.line-icon-Drag-Down:before {
    content: "\e832";
}

.line-icon-Drag-Left:before {
    content: "\e833";
}

.line-icon-Drag-Right:before {
    content: "\e834";
}

.line-icon-Drag-Up:before {
    content: "\e835";
}

.line-icon-Drag:before {
    content: "\e836";
}

.line-icon-Dress:before {
    content: "\e837";
}

.line-icon-Drill-2:before {
    content: "\e838";
}

.line-icon-Drill:before {
    content: "\e839";
}

.line-icon-Drop:before {
    content: "\e83a";
}

.line-icon-Dropbox:before {
    content: "\e83b";
}

.line-icon-Drum:before {
    content: "\e83c";
}

.line-icon-Dry:before {
    content: "\e83d";
}

.line-icon-Duck:before {
    content: "\e83e";
}

.line-icon-Dumbbell:before {
    content: "\e83f";
}

.line-icon-Duplicate-Layer:before {
    content: "\e840";
}

.line-icon-Duplicate-Window:before {
    content: "\e841";
}

.line-icon-DVD:before {
    content: "\e842";
}

.line-icon-Eagle:before {
    content: "\e843";
}

.line-icon-Ear:before {
    content: "\e844";
}

.line-icon-Earphones-2:before {
    content: "\e845";
}

.line-icon-Earphones:before {
    content: "\e846";
}

.line-icon-Eci-Icon:before {
    content: "\e847";
}

.line-icon-Edit-Map:before {
    content: "\e848";
}

.line-icon-Edit:before {
    content: "\e849";
}

.line-icon-Eggs:before {
    content: "\e84a";
}

.line-icon-Egypt:before {
    content: "\e84b";
}

.line-icon-Eifel-Tower:before {
    content: "\e84c";
}

.line-icon-eject-2:before {
    content: "\e84d";
}

.line-icon-Eject:before {
    content: "\e84e";
}

.line-icon-El-Castillo:before {
    content: "\e84f";
}

.line-icon-Elbow:before {
    content: "\e850";
}

.line-icon-Electric-Guitar:before {
    content: "\e851";
}

.line-icon-Electricity:before {
    content: "\e852";
}

.line-icon-Elephant:before {
    content: "\e853";
}

.line-icon-Email:before {
    content: "\e854";
}

.line-icon-Embassy:before {
    content: "\e855";
}

.line-icon-Empire-StateBuilding:before {
    content: "\e856";
}

.line-icon-Empty-Box:before {
    content: "\e857";
}

.line-icon-End2:before {
    content: "\e858";
}

.line-icon-End-2:before {
    content: "\e859";
}

.line-icon-End:before {
    content: "\e85a";
}

.line-icon-Endways:before {
    content: "\e85b";
}

.line-icon-Engineering:before {
    content: "\e85c";
}

.line-icon-Envelope-2:before {
    content: "\e85d";
}

.line-icon-Envelope:before {
    content: "\e85e";
}

.line-icon-Environmental-2:before {
    content: "\e85f";
}

.line-icon-Environmental-3:before {
    content: "\e860";
}

.line-icon-Environmental:before {
    content: "\e861";
}

.line-icon-Equalizer:before {
    content: "\e862";
}

.line-icon-Eraser-2:before {
    content: "\e863";
}

.line-icon-Eraser-3:before {
    content: "\e864";
}

.line-icon-Eraser:before {
    content: "\e865";
}

.line-icon-Error-404Window:before {
    content: "\e866";
}

.line-icon-Euro-Sign:before {
    content: "\e867";
}

.line-icon-Euro-Sign2:before {
    content: "\e868";
}

.line-icon-Euro:before {
    content: "\e869";
}

.line-icon-Evernote:before {
    content: "\e86a";
}

.line-icon-Evil:before {
    content: "\e86b";
}

.line-icon-Explode:before {
    content: "\e86c";
}

.line-icon-Eye-2:before {
    content: "\e86d";
}

.line-icon-Eye-Blind:before {
    content: "\e86e";
}

.line-icon-Eye-Invisible:before {
    content: "\e86f";
}

.line-icon-Eye-Scan:before {
    content: "\e870";
}

.line-icon-Eye-Visible:before {
    content: "\e871";
}

.line-icon-Eye:before {
    content: "\e872";
}

.line-icon-Eyebrow-2:before {
    content: "\e873";
}

.line-icon-Eyebrow-3:before {
    content: "\e874";
}

.line-icon-Eyebrow:before {
    content: "\e875";
}

.line-icon-Eyeglasses-Smiley:before {
    content: "\e876";
}

.line-icon-Eyeglasses-Smiley2:before {
    content: "\e877";
}

.line-icon-Face-Style:before {
    content: "\e878";
}

.line-icon-Face-Style2:before {
    content: "\e879";
}

.line-icon-Face-Style3:before {
    content: "\e87a";
}

.line-icon-Face-Style4:before {
    content: "\e87b";
}

.line-icon-Face-Style5:before {
    content: "\e87c";
}

.line-icon-Face-Style6:before {
    content: "\e87d";
}

.line-icon-Facebook-2:before {
    content: "\e87e";
}

.line-icon-Facebook:before {
    content: "\e87f";
}

.line-icon-Factory-2:before {
    content: "\e880";
}

.line-icon-Factory:before {
    content: "\e881";
}

.line-icon-Fahrenheit:before {
    content: "\e882";
}

.line-icon-Family-Sign:before {
    content: "\e883";
}

.line-icon-Fan:before {
    content: "\e884";
}

.line-icon-Farmer:before {
    content: "\e885";
}

.line-icon-Fashion:before {
    content: "\e886";
}

.line-icon-Favorite-Window:before {
    content: "\e887";
}

.line-icon-Fax:before {
    content: "\e888";
}

.line-icon-Feather:before {
    content: "\e889";
}

.line-icon-Feedburner:before {
    content: "\e88a";
}

.line-icon-Female-2:before {
    content: "\e88b";
}

.line-icon-Female-Sign:before {
    content: "\e88c";
}

.line-icon-Female:before {
    content: "\e88d";
}

.line-icon-File-Block:before {
    content: "\e88e";
}

.line-icon-File-Bookmark:before {
    content: "\e88f";
}

.line-icon-File-Chart:before {
    content: "\e890";
}

.line-icon-File-Clipboard:before {
    content: "\e891";
}

.line-icon-File-ClipboardFileText:before {
    content: "\e892";
}

.line-icon-File-ClipboardTextImage:before {
    content: "\e893";
}

.line-icon-File-Cloud:before {
    content: "\e894";
}

.line-icon-File-Copy:before {
    content: "\e895";
}

.line-icon-File-Copy2:before {
    content: "\e896";
}

.line-icon-File-CSV:before {
    content: "\e897";
}

.line-icon-File-Download:before {
    content: "\e898";
}

.line-icon-File-Edit:before {
    content: "\e899";
}

.line-icon-File-Excel:before {
    content: "\e89a";
}

.line-icon-File-Favorite:before {
    content: "\e89b";
}

.line-icon-File-Fire:before {
    content: "\e89c";
}

.line-icon-File-Graph:before {
    content: "\e89d";
}

.line-icon-File-Hide:before {
    content: "\e89e";
}

.line-icon-File-Horizontal:before {
    content: "\e89f";
}

.line-icon-File-HorizontalText:before {
    content: "\e8a0";
}

.line-icon-File-HTML:before {
    content: "\e8a1";
}

.line-icon-File-JPG:before {
    content: "\e8a2";
}

.line-icon-File-Link:before {
    content: "\e8a3";
}

.line-icon-File-Loading:before {
    content: "\e8a4";
}

.line-icon-File-Lock:before {
    content: "\e8a5";
}

.line-icon-File-Love:before {
    content: "\e8a6";
}

.line-icon-File-Music:before {
    content: "\e8a7";
}

.line-icon-File-Network:before {
    content: "\e8a8";
}

.line-icon-File-Pictures:before {
    content: "\e8a9";
}

.line-icon-File-Pie:before {
    content: "\e8aa";
}

.line-icon-File-Presentation:before {
    content: "\e8ab";
}

.line-icon-File-Refresh:before {
    content: "\e8ac";
}

.line-icon-File-Search:before {
    content: "\e8ad";
}

.line-icon-File-Settings:before {
    content: "\e8ae";
}

.line-icon-File-Share:before {
    content: "\e8af";
}

.line-icon-File-TextImage:before {
    content: "\e8b0";
}

.line-icon-File-Trash:before {
    content: "\e8b1";
}

.line-icon-File-TXT:before {
    content: "\e8b2";
}

.line-icon-File-Upload:before {
    content: "\e8b3";
}

.line-icon-File-Video:before {
    content: "\e8b4";
}

.line-icon-File-Word:before {
    content: "\e8b5";
}

.line-icon-File-Zip:before {
    content: "\e8b6";
}

.line-icon-File:before {
    content: "\e8b7";
}

.line-icon-Files:before {
    content: "\e8b8";
}

.line-icon-Film-Board:before {
    content: "\e8b9";
}

.line-icon-Film-Cartridge:before {
    content: "\e8ba";
}

.line-icon-Film-Strip:before {
    content: "\e8bb";
}

.line-icon-Film-Video:before {
    content: "\e8bc";
}

.line-icon-Film:before {
    content: "\e8bd";
}

.line-icon-Filter-2:before {
    content: "\e8be";
}

.line-icon-Filter:before {
    content: "\e8bf";
}

.line-icon-Financial:before {
    content: "\e8c0";
}

.line-icon-Find-User:before {
    content: "\e8c1";
}

.line-icon-Finger-DragFourSides:before {
    content: "\e8c2";
}

.line-icon-Finger-DragTwoSides:before {
    content: "\e8c3";
}

.line-icon-Finger-Print:before {
    content: "\e8c4";
}

.line-icon-Finger:before {
    content: "\e8c5";
}

.line-icon-Fingerprint-2:before {
    content: "\e8c6";
}

.line-icon-Fingerprint:before {
    content: "\e8c7";
}

.line-icon-Fire-Flame:before {
    content: "\e8c8";
}

.line-icon-Fire-Flame2:before {
    content: "\e8c9";
}

.line-icon-Fire-Hydrant:before {
    content: "\e8ca";
}

.line-icon-Fire-Staion:before {
    content: "\e8cb";
}

.line-icon-Firefox:before {
    content: "\e8cc";
}

.line-icon-Firewall:before {
    content: "\e8cd";
}

.line-icon-First-Aid:before {
    content: "\e8ce";
}

.line-icon-First:before {
    content: "\e8cf";
}

.line-icon-Fish-Food:before {
    content: "\e8d0";
}

.line-icon-Fish:before {
    content: "\e8d1";
}

.line-icon-Fit-To:before {
    content: "\e8d2";
}

.line-icon-Fit-To2:before {
    content: "\e8d3";
}

.line-icon-Five-Fingers:before {
    content: "\e8d4";
}

.line-icon-Five-FingersDrag:before {
    content: "\e8d5";
}

.line-icon-Five-FingersDrag2:before {
    content: "\e8d6";
}

.line-icon-Five-FingersTouch:before {
    content: "\e8d7";
}

.line-icon-Flag-2:before {
    content: "\e8d8";
}

.line-icon-Flag-3:before {
    content: "\e8d9";
}

.line-icon-Flag-4:before {
    content: "\e8da";
}

.line-icon-Flag-5:before {
    content: "\e8db";
}

.line-icon-Flag-6:before {
    content: "\e8dc";
}

.line-icon-Flag:before {
    content: "\e8dd";
}

.line-icon-Flamingo:before {
    content: "\e8de";
}

.line-icon-Flash-2:before {
    content: "\e8df";
}

.line-icon-Flash-Video:before {
    content: "\e8e0";
}

.line-icon-Flash:before {
    content: "\e8e1";
}

.line-icon-Flashlight:before {
    content: "\e8e2";
}

.line-icon-Flask-2:before {
    content: "\e8e3";
}

.line-icon-Flask:before {
    content: "\e8e4";
}

.line-icon-Flick:before {
    content: "\e8e5";
}

.line-icon-Flickr:before {
    content: "\e8e6";
}

.line-icon-Flowerpot:before {
    content: "\e8e7";
}

.line-icon-Fluorescent:before {
    content: "\e8e8";
}

.line-icon-Fog-Day:before {
    content: "\e8e9";
}

.line-icon-Fog-Night:before {
    content: "\e8ea";
}

.line-icon-Folder-Add:before {
    content: "\e8eb";
}

.line-icon-Folder-Archive:before {
    content: "\e8ec";
}

.line-icon-Folder-Binder:before {
    content: "\e8ed";
}

.line-icon-Folder-Binder2:before {
    content: "\e8ee";
}

.line-icon-Folder-Block:before {
    content: "\e8ef";
}

.line-icon-Folder-Bookmark:before {
    content: "\e8f0";
}

.line-icon-Folder-Close:before {
    content: "\e8f1";
}

.line-icon-Folder-Cloud:before {
    content: "\e8f2";
}

.line-icon-Folder-Delete:before {
    content: "\e8f3";
}

.line-icon-Folder-Download:before {
    content: "\e8f4";
}

.line-icon-Folder-Edit:before {
    content: "\e8f5";
}

.line-icon-Folder-Favorite:before {
    content: "\e8f6";
}

.line-icon-Folder-Fire:before {
    content: "\e8f7";
}

.line-icon-Folder-Hide:before {
    content: "\e8f8";
}

.line-icon-Folder-Link:before {
    content: "\e8f9";
}

.line-icon-Folder-Loading:before {
    content: "\e8fa";
}

.line-icon-Folder-Lock:before {
    content: "\e8fb";
}

.line-icon-Folder-Love:before {
    content: "\e8fc";
}

.line-icon-Folder-Music:before {
    content: "\e8fd";
}

.line-icon-Folder-Network:before {
    content: "\e8fe";
}

.line-icon-Folder-Open:before {
    content: "\e8ff";
}

.line-icon-Folder-Open2:before {
    content: "\e900";
}

.line-icon-Folder-Organizing:before {
    content: "\e901";
}

.line-icon-Folder-Pictures:before {
    content: "\e902";
}

.line-icon-Folder-Refresh:before {
    content: "\e903";
}

.line-icon-Folder-Remove-:before {
    content: "\e904";
}

.line-icon-Folder-Search:before {
    content: "\e905";
}

.line-icon-Folder-Settings:before {
    content: "\e906";
}

.line-icon-Folder-Share:before {
    content: "\e907";
}

.line-icon-Folder-Trash:before {
    content: "\e908";
}

.line-icon-Folder-Upload:before {
    content: "\e909";
}

.line-icon-Folder-Video:before {
    content: "\e90a";
}

.line-icon-Folder-WithDocument:before {
    content: "\e90b";
}

.line-icon-Folder-Zip:before {
    content: "\e90c";
}

.line-icon-Folder:before {
    content: "\e90d";
}

.line-icon-Folders:before {
    content: "\e90e";
}

.line-icon-Font-Color:before {
    content: "\e90f";
}

.line-icon-Font-Name:before {
    content: "\e910";
}

.line-icon-Font-Size:before {
    content: "\e911";
}

.line-icon-Font-Style:before {
    content: "\e912";
}

.line-icon-Font-StyleSubscript:before {
    content: "\e913";
}

.line-icon-Font-StyleSuperscript:before {
    content: "\e914";
}

.line-icon-Font-Window:before {
    content: "\e915";
}

.line-icon-Foot-2:before {
    content: "\e916";
}

.line-icon-Foot:before {
    content: "\e917";
}

.line-icon-Football-2:before {
    content: "\e918";
}

.line-icon-Football:before {
    content: "\e919";
}

.line-icon-Footprint-2:before {
    content: "\e91a";
}

.line-icon-Footprint-3:before {
    content: "\e91b";
}

.line-icon-Footprint:before {
    content: "\e91c";
}

.line-icon-Forest:before {
    content: "\e91d";
}

.line-icon-Fork:before {
    content: "\e91e";
}

.line-icon-Formspring:before {
    content: "\e91f";
}

.line-icon-Formula:before {
    content: "\e920";
}

.line-icon-Forsquare:before {
    content: "\e921";
}

.line-icon-Forward:before {
    content: "\e922";
}

.line-icon-Fountain-Pen:before {
    content: "\e923";
}

.line-icon-Four-Fingers:before {
    content: "\e924";
}

.line-icon-Four-FingersDrag:before {
    content: "\e925";
}

.line-icon-Four-FingersDrag2:before {
    content: "\e926";
}

.line-icon-Four-FingersTouch:before {
    content: "\e927";
}

.line-icon-Fox:before {
    content: "\e928";
}

.line-icon-Frankenstein:before {
    content: "\e929";
}

.line-icon-French-Fries:before {
    content: "\e92a";
}

.line-icon-Friendfeed:before {
    content: "\e92b";
}

.line-icon-Friendster:before {
    content: "\e92c";
}

.line-icon-Frog:before {
    content: "\e92d";
}

.line-icon-Fruits:before {
    content: "\e92e";
}

.line-icon-Fuel:before {
    content: "\e92f";
}

.line-icon-Full-Bag:before {
    content: "\e930";
}

.line-icon-Full-Basket:before {
    content: "\e931";
}

.line-icon-Full-Cart:before {
    content: "\e932";
}

.line-icon-Full-Moon:before {
    content: "\e933";
}

.line-icon-Full-Screen:before {
    content: "\e934";
}

.line-icon-Full-Screen2:before {
    content: "\e935";
}

.line-icon-Full-View:before {
    content: "\e936";
}

.line-icon-Full-View2:before {
    content: "\e937";
}

.line-icon-Full-ViewWindow:before {
    content: "\e938";
}

.line-icon-Function:before {
    content: "\e939";
}

.line-icon-Funky:before {
    content: "\e93a";
}

.line-icon-Funny-Bicycle:before {
    content: "\e93b";
}

.line-icon-Furl:before {
    content: "\e93c";
}

.line-icon-Gamepad-2:before {
    content: "\e93d";
}

.line-icon-Gamepad:before {
    content: "\e93e";
}

.line-icon-Gas-Pump:before {
    content: "\e93f";
}

.line-icon-Gaugage-2:before {
    content: "\e940";
}

.line-icon-Gaugage:before {
    content: "\e941";
}

.line-icon-Gay:before {
    content: "\e942";
}

.line-icon-Gear-2:before {
    content: "\e943";
}

.line-icon-Gear:before {
    content: "\e944";
}

.line-icon-Gears-2:before {
    content: "\e945";
}

.line-icon-Gears:before {
    content: "\e946";
}

.line-icon-Geek-2:before {
    content: "\e947";
}

.line-icon-Geek:before {
    content: "\e948";
}

.line-icon-Gemini-2:before {
    content: "\e949";
}

.line-icon-Gemini:before {
    content: "\e94a";
}

.line-icon-Genius:before {
    content: "\e94b";
}

.line-icon-Gentleman:before {
    content: "\e94c";
}

.line-icon-Geo--:before {
    content: "\e94d";
}

.line-icon-Geo-:before {
    content: "\e94e";
}

.line-icon-Geo-Close:before {
    content: "\e94f";
}

.line-icon-Geo-Love:before {
    content: "\e950";
}

.line-icon-Geo-Number:before {
    content: "\e951";
}

.line-icon-Geo-Star:before {
    content: "\e952";
}

.line-icon-Geo:before {
    content: "\e953";
}

.line-icon-Geo2--:before {
    content: "\e954";
}

.line-icon-Geo2-:before {
    content: "\e955";
}

.line-icon-Geo2-Close:before {
    content: "\e956";
}

.line-icon-Geo2-Love:before {
    content: "\e957";
}

.line-icon-Geo2-Number:before {
    content: "\e958";
}

.line-icon-Geo2-Star:before {
    content: "\e959";
}

.line-icon-Geo2:before {
    content: "\e95a";
}

.line-icon-Geo3--:before {
    content: "\e95b";
}

.line-icon-Geo3-:before {
    content: "\e95c";
}

.line-icon-Geo3-Close:before {
    content: "\e95d";
}

.line-icon-Geo3-Love:before {
    content: "\e95e";
}

.line-icon-Geo3-Number:before {
    content: "\e95f";
}

.line-icon-Geo3-Star:before {
    content: "\e960";
}

.line-icon-Geo3:before {
    content: "\e961";
}

.line-icon-Gey:before {
    content: "\e962";
}

.line-icon-Gift-Box:before {
    content: "\e963";
}

.line-icon-Giraffe:before {
    content: "\e964";
}

.line-icon-Girl:before {
    content: "\e965";
}

.line-icon-Glass-Water:before {
    content: "\e966";
}

.line-icon-Glasses-2:before {
    content: "\e967";
}

.line-icon-Glasses-3:before {
    content: "\e968";
}

.line-icon-Glasses:before {
    content: "\e969";
}

.line-icon-Global-Position:before {
    content: "\e96a";
}

.line-icon-Globe-2:before {
    content: "\e96b";
}

.line-icon-Globe:before {
    content: "\e96c";
}

.line-icon-Gloves:before {
    content: "\e96d";
}

.line-icon-Go-Bottom:before {
    content: "\e96e";
}

.line-icon-Go-Top:before {
    content: "\e96f";
}

.line-icon-Goggles:before {
    content: "\e970";
}

.line-icon-Golf-2:before {
    content: "\e971";
}

.line-icon-Golf:before {
    content: "\e972";
}

.line-icon-Google-Buzz:before {
    content: "\e973";
}

.line-icon-Google-Drive:before {
    content: "\e974";
}

.line-icon-Google-Play:before {
    content: "\e975";
}

.line-icon-Google-Plus:before {
    content: "\e976";
}

.line-icon-Google:before {
    content: "\e977";
}

.line-icon-Gopro:before {
    content: "\e978";
}

.line-icon-Gorilla:before {
    content: "\e979";
}

.line-icon-Gowalla:before {
    content: "\e97a";
}

.line-icon-Grave:before {
    content: "\e97b";
}

.line-icon-Graveyard:before {
    content: "\e97c";
}

.line-icon-Greece:before {
    content: "\e97d";
}

.line-icon-Green-Energy:before {
    content: "\e97e";
}

.line-icon-Green-House:before {
    content: "\e97f";
}

.line-icon-Guitar:before {
    content: "\e980";
}

.line-icon-Gun-2:before {
    content: "\e981";
}

.line-icon-Gun-3:before {
    content: "\e982";
}

.line-icon-Gun:before {
    content: "\e983";
}

.line-icon-Gymnastics:before {
    content: "\e984";
}

.line-icon-Hair-2:before {
    content: "\e985";
}

.line-icon-Hair-3:before {
    content: "\e986";
}

.line-icon-Hair-4:before {
    content: "\e987";
}

.line-icon-Hair:before {
    content: "\e988";
}

.line-icon-Half-Moon:before {
    content: "\e989";
}

.line-icon-Halloween-HalfMoon:before {
    content: "\e98a";
}

.line-icon-Halloween-Moon:before {
    content: "\e98b";
}

.line-icon-Hamburger:before {
    content: "\e98c";
}

.line-icon-Hammer:before {
    content: "\e98d";
}

.line-icon-Hand-Touch:before {
    content: "\e98e";
}

.line-icon-Hand-Touch2:before {
    content: "\e98f";
}

.line-icon-Hand-TouchSmartphone:before {
    content: "\e990";
}

.line-icon-Hand:before {
    content: "\e991";
}

.line-icon-Hands:before {
    content: "\e992";
}

.line-icon-Handshake:before {
    content: "\e993";
}

.line-icon-Hanger:before {
    content: "\e994";
}

.line-icon-Happy:before {
    content: "\e995";
}

.line-icon-Hat-2:before {
    content: "\e996";
}

.line-icon-Hat:before {
    content: "\e997";
}

.line-icon-Haunted-House:before {
    content: "\e998";
}

.line-icon-HD-Video:before {
    content: "\e999";
}

.line-icon-HD:before {
    content: "\e99a";
}

.line-icon-HDD:before {
    content: "\e99b";
}

.line-icon-Headphone:before {
    content: "\e99c";
}

.line-icon-Headphones:before {
    content: "\e99d";
}

.line-icon-Headset:before {
    content: "\e99e";
}

.line-icon-Heart-2:before {
    content: "\e99f";
}

.line-icon-Heart:before {
    content: "\e9a0";
}

.line-icon-Heels-2:before {
    content: "\e9a1";
}

.line-icon-Heels:before {
    content: "\e9a2";
}

.line-icon-Height-Window:before {
    content: "\e9a3";
}

.line-icon-Helicopter-2:before {
    content: "\e9a4";
}

.line-icon-Helicopter:before {
    content: "\e9a5";
}

.line-icon-Helix-2:before {
    content: "\e9a6";
}

.line-icon-Hello:before {
    content: "\e9a7";
}

.line-icon-Helmet-2:before {
    content: "\e9a8";
}

.line-icon-Helmet-3:before {
    content: "\e9a9";
}

.line-icon-Helmet:before {
    content: "\e9aa";
}

.line-icon-Hipo:before {
    content: "\e9ab";
}

.line-icon-Hipster-Glasses:before {
    content: "\e9ac";
}

.line-icon-Hipster-Glasses2:before {
    content: "\e9ad";
}

.line-icon-Hipster-Glasses3:before {
    content: "\e9ae";
}

.line-icon-Hipster-Headphones:before {
    content: "\e9af";
}

.line-icon-Hipster-Men:before {
    content: "\e9b0";
}

.line-icon-Hipster-Men2:before {
    content: "\e9b1";
}

.line-icon-Hipster-Men3:before {
    content: "\e9b2";
}

.line-icon-Hipster-Sunglasses:before {
    content: "\e9b3";
}

.line-icon-Hipster-Sunglasses2:before {
    content: "\e9b4";
}

.line-icon-Hipster-Sunglasses3:before {
    content: "\e9b5";
}

.line-icon-Hokey:before {
    content: "\e9b6";
}

.line-icon-Holly:before {
    content: "\e9b7";
}

.line-icon-Home-2:before {
    content: "\e9b8";
}

.line-icon-Home-3:before {
    content: "\e9b9";
}

.line-icon-Home-4:before {
    content: "\e9ba";
}

.line-icon-Home-5:before {
    content: "\e9bb";
}

.line-icon-Home-Window:before {
    content: "\e9bc";
}

.line-icon-Home:before {
    content: "\e9bd";
}

.line-icon-Homosexual:before {
    content: "\e9be";
}

.line-icon-Honey:before {
    content: "\e9bf";
}

.line-icon-Hong-Kong:before {
    content: "\e9c0";
}

.line-icon-Hoodie:before {
    content: "\e9c1";
}

.line-icon-Horror:before {
    content: "\e9c2";
}

.line-icon-Horse:before {
    content: "\e9c3";
}

.line-icon-Hospital-2:before {
    content: "\e9c4";
}

.line-icon-Hospital:before {
    content: "\e9c5";
}

.line-icon-Host:before {
    content: "\e9c6";
}

.line-icon-Hot-Dog:before {
    content: "\e9c7";
}

.line-icon-Hotel:before {
    content: "\e9c8";
}

.line-icon-Hour:before {
    content: "\e9c9";
}

.line-icon-Hub:before {
    content: "\e9ca";
}

.line-icon-Humor:before {
    content: "\e9cb";
}

.line-icon-Hurt:before {
    content: "\e9cc";
}

.line-icon-Ice-Cream:before {
    content: "\e9cd";
}

.line-icon-ICQ:before {
    content: "\e9ce";
}

.line-icon-ID-2:before {
    content: "\e9cf";
}

.line-icon-ID-3:before {
    content: "\e9d0";
}

.line-icon-ID-Card:before {
    content: "\e9d1";
}

.line-icon-Idea-2:before {
    content: "\e9d2";
}

.line-icon-Idea-3:before {
    content: "\e9d3";
}

.line-icon-Idea-4:before {
    content: "\e9d4";
}

.line-icon-Idea-5:before {
    content: "\e9d5";
}

.line-icon-Idea:before {
    content: "\e9d6";
}

.line-icon-Identification-Badge:before {
    content: "\e9d7";
}

.line-icon-ImDB:before {
    content: "\e9d8";
}

.line-icon-Inbox-Empty:before {
    content: "\e9d9";
}

.line-icon-Inbox-Forward:before {
    content: "\e9da";
}

.line-icon-Inbox-Full:before {
    content: "\e9db";
}

.line-icon-Inbox-Into:before {
    content: "\e9dc";
}

.line-icon-Inbox-Out:before {
    content: "\e9dd";
}

.line-icon-Inbox-Reply:before {
    content: "\e9de";
}

.line-icon-Inbox:before {
    content: "\e9df";
}

.line-icon-Increase-Inedit:before {
    content: "\e9e0";
}

.line-icon-Indent-FirstLine:before {
    content: "\e9e1";
}

.line-icon-Indent-LeftMargin:before {
    content: "\e9e2";
}

.line-icon-Indent-RightMargin:before {
    content: "\e9e3";
}

.line-icon-India:before {
    content: "\e9e4";
}

.line-icon-Info-Window:before {
    content: "\e9e5";
}

.line-icon-Information:before {
    content: "\e9e6";
}

.line-icon-Inifity:before {
    content: "\e9e7";
}

.line-icon-Instagram:before {
    content: "\e9e8";
}

.line-icon-Internet-2:before {
    content: "\e9e9";
}

.line-icon-Internet-Explorer:before {
    content: "\e9ea";
}

.line-icon-Internet-Smiley:before {
    content: "\e9eb";
}

.line-icon-Internet:before {
    content: "\e9ec";
}

.line-icon-iOS-Apple:before {
    content: "\e9ed";
}

.line-icon-Israel:before {
    content: "\e9ee";
}

.line-icon-Italic-Text:before {
    content: "\e9ef";
}

.line-icon-Jacket-2:before {
    content: "\e9f0";
}

.line-icon-Jacket:before {
    content: "\e9f1";
}

.line-icon-Jamaica:before {
    content: "\e9f2";
}

.line-icon-Japan:before {
    content: "\e9f3";
}

.line-icon-Japanese-Gate:before {
    content: "\e9f4";
}

.line-icon-Jeans:before {
    content: "\e9f5";
}

.line-icon-Jeep-2:before {
    content: "\e9f6";
}

.line-icon-Jeep:before {
    content: "\e9f7";
}

.line-icon-Jet:before {
    content: "\e9f8";
}

.line-icon-Joystick:before {
    content: "\e9f9";
}

.line-icon-Juice:before {
    content: "\e9fa";
}

.line-icon-Jump-Rope:before {
    content: "\e9fb";
}

.line-icon-Kangoroo:before {
    content: "\e9fc";
}

.line-icon-Kenya:before {
    content: "\e9fd";
}

.line-icon-Key-2:before {
    content: "\e9fe";
}

.line-icon-Key-3:before {
    content: "\e9ff";
}

.line-icon-Key-Lock:before {
    content: "\ea00";
}

.line-icon-Key:before {
    content: "\ea01";
}

.line-icon-Keyboard:before {
    content: "\ea02";
}

.line-icon-Keyboard3:before {
    content: "\ea03";
}

.line-icon-Keypad:before {
    content: "\ea04";
}

.line-icon-King-2:before {
    content: "\ea05";
}

.line-icon-King:before {
    content: "\ea06";
}

.line-icon-Kiss:before {
    content: "\ea07";
}

.line-icon-Knee:before {
    content: "\ea08";
}

.line-icon-Knife-2:before {
    content: "\ea09";
}

.line-icon-Knife:before {
    content: "\ea0a";
}

.line-icon-Knight:before {
    content: "\ea0b";
}

.line-icon-Koala:before {
    content: "\ea0c";
}

.line-icon-Korea:before {
    content: "\ea0d";
}

.line-icon-Lamp:before {
    content: "\ea0e";
}

.line-icon-Landscape-2:before {
    content: "\ea0f";
}

.line-icon-Landscape:before {
    content: "\ea10";
}

.line-icon-Lantern:before {
    content: "\ea11";
}

.line-icon-Laptop-2:before {
    content: "\ea12";
}

.line-icon-Laptop-3:before {
    content: "\ea13";
}

.line-icon-Laptop-Phone:before {
    content: "\ea14";
}

.line-icon-Laptop-Secure:before {
    content: "\ea15";
}

.line-icon-Laptop-Tablet:before {
    content: "\ea16";
}

.line-icon-Laptop:before {
    content: "\ea17";
}

.line-icon-Laser:before {
    content: "\ea18";
}

.line-icon-Last-FM:before {
    content: "\ea19";
}

.line-icon-Last:before {
    content: "\ea1a";
}

.line-icon-Laughing:before {
    content: "\ea1b";
}

.line-icon-Layer-1635:before {
    content: "\ea1c";
}

.line-icon-Layer-1646:before {
    content: "\ea1d";
}

.line-icon-Layer-Backward:before {
    content: "\ea1e";
}

.line-icon-Layer-Forward:before {
    content: "\ea1f";
}

.line-icon-Leafs-2:before {
    content: "\ea20";
}

.line-icon-Leafs:before {
    content: "\ea21";
}

.line-icon-Leaning-Tower:before {
    content: "\ea22";
}

.line-icon-Left--Right:before {
    content: "\ea23";
}

.line-icon-Left--Right3:before {
    content: "\ea24";
}

.line-icon-Left-2:before {
    content: "\ea25";
}

.line-icon-Left-3:before {
    content: "\ea26";
}

.line-icon-Left-4:before {
    content: "\ea27";
}

.line-icon-Left-ToRight:before {
    content: "\ea28";
}

.line-icon-Left:before {
    content: "\ea29";
}

.line-icon-Leg-2:before {
    content: "\ea2a";
}

.line-icon-Leg:before {
    content: "\ea2b";
}

.line-icon-Lego:before {
    content: "\ea2c";
}

.line-icon-Lemon:before {
    content: "\ea2d";
}

.line-icon-Len-2:before {
    content: "\ea2e";
}

.line-icon-Len-3:before {
    content: "\ea2f";
}

.line-icon-Len:before {
    content: "\ea30";
}

.line-icon-Leo-2:before {
    content: "\ea31";
}

.line-icon-Leo:before {
    content: "\ea32";
}

.line-icon-Leopard:before {
    content: "\ea33";
}

.line-icon-Lesbian:before {
    content: "\ea34";
}

.line-icon-Lesbians:before {
    content: "\ea35";
}

.line-icon-Letter-Close:before {
    content: "\ea36";
}

.line-icon-Letter-Open:before {
    content: "\ea37";
}

.line-icon-Letter-Sent:before {
    content: "\ea38";
}

.line-icon-Libra-2:before {
    content: "\ea39";
}

.line-icon-Libra:before {
    content: "\ea3a";
}

.line-icon-Library-2:before {
    content: "\ea3b";
}

.line-icon-Library:before {
    content: "\ea3c";
}

.line-icon-Life-Jacket:before {
    content: "\ea3d";
}

.line-icon-Life-Safer:before {
    content: "\ea3e";
}

.line-icon-Light-Bulb:before {
    content: "\ea3f";
}

.line-icon-Light-Bulb2:before {
    content: "\ea40";
}

.line-icon-Light-BulbLeaf:before {
    content: "\ea41";
}

.line-icon-Lighthouse:before {
    content: "\ea42";
}

.line-icon-Like-2:before {
    content: "\ea43";
}

.line-icon-Like:before {
    content: "\ea44";
}

.line-icon-Line-Chart:before {
    content: "\ea45";
}

.line-icon-Line-Chart2:before {
    content: "\ea46";
}

.line-icon-Line-Chart3:before {
    content: "\ea47";
}

.line-icon-Line-Chart4:before {
    content: "\ea48";
}

.line-icon-Line-Spacing:before {
    content: "\ea49";
}

.line-icon-Line-SpacingText:before {
    content: "\ea4a";
}

.line-icon-Link-2:before {
    content: "\ea4b";
}

.line-icon-Link:before {
    content: "\ea4c";
}

.line-icon-Linkedin-2:before {
    content: "\ea4d";
}

.line-icon-Linkedin:before {
    content: "\ea4e";
}

.line-icon-Linux:before {
    content: "\ea4f";
}

.line-icon-Lion:before {
    content: "\ea50";
}

.line-icon-Livejournal:before {
    content: "\ea51";
}

.line-icon-Loading-2:before {
    content: "\ea52";
}

.line-icon-Loading-3:before {
    content: "\ea53";
}

.line-icon-Loading-Window:before {
    content: "\ea54";
}

.line-icon-Loading:before {
    content: "\ea55";
}

.line-icon-Location-2:before {
    content: "\ea56";
}

.line-icon-Location:before {
    content: "\ea57";
}

.line-icon-Lock-2:before {
    content: "\ea58";
}

.line-icon-Lock-3:before {
    content: "\ea59";
}

.line-icon-Lock-User:before {
    content: "\ea5a";
}

.line-icon-Lock-Window:before {
    content: "\ea5b";
}

.line-icon-Lock:before {
    content: "\ea5c";
}

.line-icon-Lollipop-2:before {
    content: "\ea5d";
}

.line-icon-Lollipop-3:before {
    content: "\ea5e";
}

.line-icon-Lollipop:before {
    content: "\ea5f";
}

.line-icon-Loop:before {
    content: "\ea60";
}

.line-icon-Loud:before {
    content: "\ea61";
}

.line-icon-Loudspeaker:before {
    content: "\ea62";
}

.line-icon-Love-2:before {
    content: "\ea63";
}

.line-icon-Love-User:before {
    content: "\ea64";
}

.line-icon-Love-Window:before {
    content: "\ea65";
}

.line-icon-Love:before {
    content: "\ea66";
}

.line-icon-Lowercase-Text:before {
    content: "\ea67";
}

.line-icon-Luggafe-Front:before {
    content: "\ea68";
}

.line-icon-Luggage-2:before {
    content: "\ea69";
}

.line-icon-Macro:before {
    content: "\ea6a";
}

.line-icon-Magic-Wand:before {
    content: "\ea6b";
}

.line-icon-Magnet:before {
    content: "\ea6c";
}

.line-icon-Magnifi-Glass-:before {
    content: "\ea6d";
}

.line-icon-Magnifi-Glass:before {
    content: "\ea6e";
}

.line-icon-Magnifi-Glass2:before {
    content: "\ea6f";
}

.line-icon-Mail-2:before {
    content: "\ea70";
}

.line-icon-Mail-3:before {
    content: "\ea71";
}

.line-icon-Mail-Add:before {
    content: "\ea72";
}

.line-icon-Mail-Attachement:before {
    content: "\ea73";
}

.line-icon-Mail-Block:before {
    content: "\ea74";
}

.line-icon-Mail-Delete:before {
    content: "\ea75";
}

.line-icon-Mail-Favorite:before {
    content: "\ea76";
}

.line-icon-Mail-Forward:before {
    content: "\ea77";
}

.line-icon-Mail-Gallery:before {
    content: "\ea78";
}

.line-icon-Mail-Inbox:before {
    content: "\ea79";
}

.line-icon-Mail-Link:before {
    content: "\ea7a";
}

.line-icon-Mail-Lock:before {
    content: "\ea7b";
}

.line-icon-Mail-Love:before {
    content: "\ea7c";
}

.line-icon-Mail-Money:before {
    content: "\ea7d";
}

.line-icon-Mail-Open:before {
    content: "\ea7e";
}

.line-icon-Mail-Outbox:before {
    content: "\ea7f";
}

.line-icon-Mail-Password:before {
    content: "\ea80";
}

.line-icon-Mail-Photo:before {
    content: "\ea81";
}

.line-icon-Mail-Read:before {
    content: "\ea82";
}

.line-icon-Mail-Removex:before {
    content: "\ea83";
}

.line-icon-Mail-Reply:before {
    content: "\ea84";
}

.line-icon-Mail-ReplyAll:before {
    content: "\ea85";
}

.line-icon-Mail-Search:before {
    content: "\ea86";
}

.line-icon-Mail-Send:before {
    content: "\ea87";
}

.line-icon-Mail-Settings:before {
    content: "\ea88";
}

.line-icon-Mail-Unread:before {
    content: "\ea89";
}

.line-icon-Mail-Video:before {
    content: "\ea8a";
}

.line-icon-Mail-withAtSign:before {
    content: "\ea8b";
}

.line-icon-Mail-WithCursors:before {
    content: "\ea8c";
}

.line-icon-Mail:before {
    content: "\ea8d";
}

.line-icon-Mailbox-Empty:before {
    content: "\ea8e";
}

.line-icon-Mailbox-Full:before {
    content: "\ea8f";
}

.line-icon-Male-2:before {
    content: "\ea90";
}

.line-icon-Male-Sign:before {
    content: "\ea91";
}

.line-icon-Male:before {
    content: "\ea92";
}

.line-icon-MaleFemale:before {
    content: "\ea93";
}

.line-icon-Man-Sign:before {
    content: "\ea94";
}

.line-icon-Management:before {
    content: "\ea95";
}

.line-icon-Mans-Underwear:before {
    content: "\ea96";
}

.line-icon-Mans-Underwear2:before {
    content: "\ea97";
}

.line-icon-Map-Marker:before {
    content: "\ea98";
}

.line-icon-Map-Marker2:before {
    content: "\ea99";
}

.line-icon-Map-Marker3:before {
    content: "\ea9a";
}

.line-icon-Map:before {
    content: "\ea9b";
}

.line-icon-Map2:before {
    content: "\ea9c";
}

.line-icon-Marker-2:before {
    content: "\ea9d";
}

.line-icon-Marker-3:before {
    content: "\ea9e";
}

.line-icon-Marker:before {
    content: "\ea9f";
}

.line-icon-Martini-Glass:before {
    content: "\eaa0";
}

.line-icon-Mask:before {
    content: "\eaa1";
}

.line-icon-Master-Card:before {
    content: "\eaa2";
}

.line-icon-Maximize-Window:before {
    content: "\eaa3";
}

.line-icon-Maximize:before {
    content: "\eaa4";
}

.line-icon-Medal-2:before {
    content: "\eaa5";
}

.line-icon-Medal-3:before {
    content: "\eaa6";
}

.line-icon-Medal:before {
    content: "\eaa7";
}

.line-icon-Medical-Sign:before {
    content: "\eaa8";
}

.line-icon-Medicine-2:before {
    content: "\eaa9";
}

.line-icon-Medicine-3:before {
    content: "\eaaa";
}

.line-icon-Medicine:before {
    content: "\eaab";
}

.line-icon-Megaphone:before {
    content: "\eaac";
}

.line-icon-Memory-Card:before {
    content: "\eaad";
}

.line-icon-Memory-Card2:before {
    content: "\eaae";
}

.line-icon-Memory-Card3:before {
    content: "\eaaf";
}

.line-icon-Men:before {
    content: "\eab0";
}

.line-icon-Menorah:before {
    content: "\eab1";
}

.line-icon-Mens:before {
    content: "\eab2";
}

.line-icon-Metacafe:before {
    content: "\eab3";
}

.line-icon-Mexico:before {
    content: "\eab4";
}

.line-icon-Mic:before {
    content: "\eab5";
}

.line-icon-Microphone-2:before {
    content: "\eab6";
}

.line-icon-Microphone-3:before {
    content: "\eab7";
}

.line-icon-Microphone-4:before {
    content: "\eab8";
}

.line-icon-Microphone-5:before {
    content: "\eab9";
}

.line-icon-Microphone-6:before {
    content: "\eaba";
}

.line-icon-Microphone-7:before {
    content: "\eabb";
}

.line-icon-Microphone:before {
    content: "\eabc";
}

.line-icon-Microscope:before {
    content: "\eabd";
}

.line-icon-Milk-Bottle:before {
    content: "\eabe";
}

.line-icon-Mine:before {
    content: "\eabf";
}

.line-icon-Minimize-Maximize-Close-Window:before {
    content: "\eac0";
}

.line-icon-Minimize-Window:before {
    content: "\eac1";
}

.line-icon-Minimize:before {
    content: "\eac2";
}

.line-icon-Mirror:before {
    content: "\eac3";
}

.line-icon-Mixer:before {
    content: "\eac4";
}

.line-icon-Mixx:before {
    content: "\eac5";
}

.line-icon-Money-2:before {
    content: "\eac6";
}

.line-icon-Money-Bag:before {
    content: "\eac7";
}

.line-icon-Money-Smiley:before {
    content: "\eac8";
}

.line-icon-Money:before {
    content: "\eac9";
}

.line-icon-Monitor-2:before {
    content: "\eaca";
}

.line-icon-Monitor-3:before {
    content: "\eacb";
}

.line-icon-Monitor-4:before {
    content: "\eacc";
}

.line-icon-Monitor-5:before {
    content: "\eacd";
}

.line-icon-Monitor-Analytics:before {
    content: "\eace";
}

.line-icon-Monitor-Laptop:before {
    content: "\eacf";
}

.line-icon-Monitor-phone:before {
    content: "\ead0";
}

.line-icon-Monitor-Tablet:before {
    content: "\ead1";
}

.line-icon-Monitor-Vertical:before {
    content: "\ead2";
}

.line-icon-Monitor:before {
    content: "\ead3";
}

.line-icon-Monitoring:before {
    content: "\ead4";
}

.line-icon-Monkey:before {
    content: "\ead5";
}

.line-icon-Monster:before {
    content: "\ead6";
}

.line-icon-Morocco:before {
    content: "\ead7";
}

.line-icon-Motorcycle:before {
    content: "\ead8";
}

.line-icon-Mouse-2:before {
    content: "\ead9";
}

.line-icon-Mouse-3:before {
    content: "\eada";
}

.line-icon-Mouse-4:before {
    content: "\eadb";
}

.line-icon-Mouse-Pointer:before {
    content: "\eadc";
}

.line-icon-Mouse:before {
    content: "\eadd";
}

.line-icon-Moustache-Smiley:before {
    content: "\eade";
}

.line-icon-Movie-Ticket:before {
    content: "\eadf";
}

.line-icon-Movie:before {
    content: "\eae0";
}

.line-icon-Mp3-File:before {
    content: "\eae1";
}

.line-icon-Museum:before {
    content: "\eae2";
}

.line-icon-Mushroom:before {
    content: "\eae3";
}

.line-icon-Music-Note:before {
    content: "\eae4";
}

.line-icon-Music-Note2:before {
    content: "\eae5";
}

.line-icon-Music-Note3:before {
    content: "\eae6";
}

.line-icon-Music-Note4:before {
    content: "\eae7";
}

.line-icon-Music-Player:before {
    content: "\eae8";
}

.line-icon-Mustache-2:before {
    content: "\eae9";
}

.line-icon-Mustache-3:before {
    content: "\eaea";
}

.line-icon-Mustache-4:before {
    content: "\eaeb";
}

.line-icon-Mustache-5:before {
    content: "\eaec";
}

.line-icon-Mustache-6:before {
    content: "\eaed";
}

.line-icon-Mustache-7:before {
    content: "\eaee";
}

.line-icon-Mustache-8:before {
    content: "\eaef";
}

.line-icon-Mustache:before {
    content: "\eaf0";
}

.line-icon-Mute:before {
    content: "\eaf1";
}

.line-icon-Myspace:before {
    content: "\eaf2";
}

.line-icon-Navigat-Start:before {
    content: "\eaf3";
}

.line-icon-Navigate-End:before {
    content: "\eaf4";
}

.line-icon-Navigation-LeftWindow:before {
    content: "\eaf5";
}

.line-icon-Navigation-RightWindow:before {
    content: "\eaf6";
}

.line-icon-Nepal:before {
    content: "\eaf7";
}

.line-icon-Netscape:before {
    content: "\eaf8";
}

.line-icon-Network-Window:before {
    content: "\eaf9";
}

.line-icon-Network:before {
    content: "\eafa";
}

.line-icon-Neutron:before {
    content: "\eafb";
}

.line-icon-New-Mail:before {
    content: "\eafc";
}

.line-icon-New-Tab:before {
    content: "\eafd";
}

.line-icon-Newspaper-2:before {
    content: "\eafe";
}

.line-icon-Newspaper:before {
    content: "\eaff";
}

.line-icon-Newsvine:before {
    content: "\eb00";
}

.line-icon-Next2:before {
    content: "\eb01";
}

.line-icon-Next-3:before {
    content: "\eb02";
}

.line-icon-Next-Music:before {
    content: "\eb03";
}

.line-icon-Next:before {
    content: "\eb04";
}

.line-icon-No-Battery:before {
    content: "\eb05";
}

.line-icon-No-Drop:before {
    content: "\eb06";
}

.line-icon-No-Flash:before {
    content: "\eb07";
}

.line-icon-No-Smoking:before {
    content: "\eb08";
}

.line-icon-Noose:before {
    content: "\eb09";
}

.line-icon-Normal-Text:before {
    content: "\eb0a";
}

.line-icon-Note:before {
    content: "\eb0b";
}

.line-icon-Notepad-2:before {
    content: "\eb0c";
}

.line-icon-Notepad:before {
    content: "\eb0d";
}

.line-icon-Nuclear:before {
    content: "\eb0e";
}

.line-icon-Numbering-List:before {
    content: "\eb0f";
}

.line-icon-Nurse:before {
    content: "\eb10";
}

.line-icon-Office-Lamp:before {
    content: "\eb11";
}

.line-icon-Office:before {
    content: "\eb12";
}

.line-icon-Oil:before {
    content: "\eb13";
}

.line-icon-Old-Camera:before {
    content: "\eb14";
}

.line-icon-Old-Cassette:before {
    content: "\eb15";
}

.line-icon-Old-Clock:before {
    content: "\eb16";
}

.line-icon-Old-Radio:before {
    content: "\eb17";
}

.line-icon-Old-Sticky:before {
    content: "\eb18";
}

.line-icon-Old-Sticky2:before {
    content: "\eb19";
}

.line-icon-Old-Telephone:before {
    content: "\eb1a";
}

.line-icon-Old-TV:before {
    content: "\eb1b";
}

.line-icon-On-Air:before {
    content: "\eb1c";
}

.line-icon-On-Off-2:before {
    content: "\eb1d";
}

.line-icon-On-Off-3:before {
    content: "\eb1e";
}

.line-icon-On-off:before {
    content: "\eb1f";
}

.line-icon-One-Finger:before {
    content: "\eb20";
}

.line-icon-One-FingerTouch:before {
    content: "\eb21";
}

.line-icon-One-Window:before {
    content: "\eb22";
}

.line-icon-Open-Banana:before {
    content: "\eb23";
}

.line-icon-Open-Book:before {
    content: "\eb24";
}

.line-icon-Opera-House:before {
    content: "\eb25";
}

.line-icon-Opera:before {
    content: "\eb26";
}

.line-icon-Optimization:before {
    content: "\eb27";
}

.line-icon-Orientation-2:before {
    content: "\eb28";
}

.line-icon-Orientation-3:before {
    content: "\eb29";
}

.line-icon-Orientation:before {
    content: "\eb2a";
}

.line-icon-Orkut:before {
    content: "\eb2b";
}

.line-icon-Ornament:before {
    content: "\eb2c";
}

.line-icon-Over-Time:before {
    content: "\eb2d";
}

.line-icon-Over-Time2:before {
    content: "\eb2e";
}

.line-icon-Owl:before {
    content: "\eb2f";
}

.line-icon-Pac-Man:before {
    content: "\eb30";
}

.line-icon-Paint-Brush:before {
    content: "\eb31";
}

.line-icon-Paint-Bucket:before {
    content: "\eb32";
}

.line-icon-Paintbrush:before {
    content: "\eb33";
}

.line-icon-Palette:before {
    content: "\eb34";
}

.line-icon-Palm-Tree:before {
    content: "\eb35";
}

.line-icon-Panda:before {
    content: "\eb36";
}

.line-icon-Panorama:before {
    content: "\eb37";
}

.line-icon-Pantheon:before {
    content: "\eb38";
}

.line-icon-Pantone:before {
    content: "\eb39";
}

.line-icon-Pants:before {
    content: "\eb3a";
}

.line-icon-Paper-Plane:before {
    content: "\eb3b";
}

.line-icon-Paper:before {
    content: "\eb3c";
}

.line-icon-Parasailing:before {
    content: "\eb3d";
}

.line-icon-Parrot:before {
    content: "\eb3e";
}

.line-icon-Password-2shopping:before {
    content: "\eb3f";
}

.line-icon-Password-Field:before {
    content: "\eb40";
}

.line-icon-Password-shopping:before {
    content: "\eb41";
}

.line-icon-Password:before {
    content: "\eb42";
}

.line-icon-pause-2:before {
    content: "\eb43";
}

.line-icon-Pause:before {
    content: "\eb44";
}

.line-icon-Paw:before {
    content: "\eb45";
}

.line-icon-Pawn:before {
    content: "\eb46";
}

.line-icon-Paypal:before {
    content: "\eb47";
}

.line-icon-Pen-2:before {
    content: "\eb48";
}

.line-icon-Pen-3:before {
    content: "\eb49";
}

.line-icon-Pen-4:before {
    content: "\eb4a";
}

.line-icon-Pen-5:before {
    content: "\eb4b";
}

.line-icon-Pen-6:before {
    content: "\eb4c";
}

.line-icon-Pen:before {
    content: "\eb4d";
}

.line-icon-Pencil-Ruler:before {
    content: "\eb4e";
}

.line-icon-Pencil:before {
    content: "\eb4f";
}

.line-icon-Penguin:before {
    content: "\eb50";
}

.line-icon-Pentagon:before {
    content: "\eb51";
}

.line-icon-People-onCloud:before {
    content: "\eb52";
}

.line-icon-Pepper-withFire:before {
    content: "\eb53";
}

.line-icon-Pepper:before {
    content: "\eb54";
}

.line-icon-Petrol:before {
    content: "\eb55";
}

.line-icon-Petronas-Tower:before {
    content: "\eb56";
}

.line-icon-Philipines:before {
    content: "\eb57";
}

.line-icon-Phone-2:before {
    content: "\eb58";
}

.line-icon-Phone-3:before {
    content: "\eb59";
}

.line-icon-Phone-3G:before {
    content: "\eb5a";
}

.line-icon-Phone-4G:before {
    content: "\eb5b";
}

.line-icon-Phone-Simcard:before {
    content: "\eb5c";
}

.line-icon-Phone-SMS:before {
    content: "\eb5d";
}

.line-icon-Phone-Wifi:before {
    content: "\eb5e";
}

.line-icon-Phone:before {
    content: "\eb5f";
}

.line-icon-Photo-2:before {
    content: "\eb60";
}

.line-icon-Photo-3:before {
    content: "\eb61";
}

.line-icon-Photo-Album:before {
    content: "\eb62";
}

.line-icon-Photo-Album2:before {
    content: "\eb63";
}

.line-icon-Photo-Album3:before {
    content: "\eb64";
}

.line-icon-Photo:before {
    content: "\eb65";
}

.line-icon-Photos:before {
    content: "\eb66";
}

.line-icon-Physics:before {
    content: "\eb67";
}

.line-icon-Pi:before {
    content: "\eb68";
}

.line-icon-Piano:before {
    content: "\eb69";
}

.line-icon-Picasa:before {
    content: "\eb6a";
}

.line-icon-Pie-Chart:before {
    content: "\eb6b";
}

.line-icon-Pie-Chart2:before {
    content: "\eb6c";
}

.line-icon-Pie-Chart3:before {
    content: "\eb6d";
}

.line-icon-Pilates-2:before {
    content: "\eb6e";
}

.line-icon-Pilates-3:before {
    content: "\eb6f";
}

.line-icon-Pilates:before {
    content: "\eb70";
}

.line-icon-Pilot:before {
    content: "\eb71";
}

.line-icon-Pinch:before {
    content: "\eb72";
}

.line-icon-Ping-Pong:before {
    content: "\eb73";
}

.line-icon-Pinterest:before {
    content: "\eb74";
}

.line-icon-Pipe:before {
    content: "\eb75";
}

.line-icon-Pipette:before {
    content: "\eb76";
}

.line-icon-Piramids:before {
    content: "\eb77";
}

.line-icon-Pisces-2:before {
    content: "\eb78";
}

.line-icon-Pisces:before {
    content: "\eb79";
}

.line-icon-Pizza-Slice:before {
    content: "\eb7a";
}

.line-icon-Pizza:before {
    content: "\eb7b";
}

.line-icon-Plane-2:before {
    content: "\eb7c";
}

.line-icon-Plane:before {
    content: "\eb7d";
}

.line-icon-Plant:before {
    content: "\eb7e";
}

.line-icon-Plasmid:before {
    content: "\eb7f";
}

.line-icon-Plaster:before {
    content: "\eb80";
}

.line-icon-Plastic-CupPhone:before {
    content: "\eb81";
}

.line-icon-Plastic-CupPhone2:before {
    content: "\eb82";
}

.line-icon-Plate:before {
    content: "\eb83";
}

.line-icon-Plates:before {
    content: "\eb84";
}

.line-icon-Plaxo:before {
    content: "\eb85";
}

.line-icon-Play-Music:before {
    content: "\eb86";
}

.line-icon-Plug-In:before {
    content: "\eb87";
}

.line-icon-Plug-In2:before {
    content: "\eb88";
}

.line-icon-Plurk:before {
    content: "\eb89";
}

.line-icon-Pointer:before {
    content: "\eb8a";
}

.line-icon-Poland:before {
    content: "\eb8b";
}

.line-icon-Police-Man:before {
    content: "\eb8c";
}

.line-icon-Police-Station:before {
    content: "\eb8d";
}

.line-icon-Police-Woman:before {
    content: "\eb8e";
}

.line-icon-Police:before {
    content: "\eb8f";
}

.line-icon-Polo-Shirt:before {
    content: "\eb90";
}

.line-icon-Portrait:before {
    content: "\eb91";
}

.line-icon-Portugal:before {
    content: "\eb92";
}

.line-icon-Post-Mail:before {
    content: "\eb93";
}

.line-icon-Post-Mail2:before {
    content: "\eb94";
}

.line-icon-Post-Office:before {
    content: "\eb95";
}

.line-icon-Post-Sign:before {
    content: "\eb96";
}

.line-icon-Post-Sign2ways:before {
    content: "\eb97";
}

.line-icon-Posterous:before {
    content: "\eb98";
}

.line-icon-Pound-Sign:before {
    content: "\eb99";
}

.line-icon-Pound-Sign2:before {
    content: "\eb9a";
}

.line-icon-Pound:before {
    content: "\eb9b";
}

.line-icon-Power-2:before {
    content: "\eb9c";
}

.line-icon-Power-3:before {
    content: "\eb9d";
}

.line-icon-Power-Cable:before {
    content: "\eb9e";
}

.line-icon-Power-Station:before {
    content: "\eb9f";
}

.line-icon-Power:before {
    content: "\eba0";
}

.line-icon-Prater:before {
    content: "\eba1";
}

.line-icon-Present:before {
    content: "\eba2";
}

.line-icon-Presents:before {
    content: "\eba3";
}

.line-icon-Press:before {
    content: "\eba4";
}

.line-icon-Preview:before {
    content: "\eba5";
}

.line-icon-Previous:before {
    content: "\eba6";
}

.line-icon-Pricing:before {
    content: "\eba7";
}

.line-icon-Printer:before {
    content: "\eba8";
}

.line-icon-Professor:before {
    content: "\eba9";
}

.line-icon-Profile:before {
    content: "\ebaa";
}

.line-icon-Project:before {
    content: "\ebab";
}

.line-icon-Projector-2:before {
    content: "\ebac";
}

.line-icon-Projector:before {
    content: "\ebad";
}

.line-icon-Pulse:before {
    content: "\ebae";
}

.line-icon-Pumpkin:before {
    content: "\ebaf";
}

.line-icon-Punk:before {
    content: "\ebb0";
}

.line-icon-Punker:before {
    content: "\ebb1";
}

.line-icon-Puzzle:before {
    content: "\ebb2";
}

.line-icon-QIK:before {
    content: "\ebb3";
}

.line-icon-QR-Code:before {
    content: "\ebb4";
}

.line-icon-Queen-2:before {
    content: "\ebb5";
}

.line-icon-Queen:before {
    content: "\ebb6";
}

.line-icon-Quill-2:before {
    content: "\ebb7";
}

.line-icon-Quill-3:before {
    content: "\ebb8";
}

.line-icon-Quill:before {
    content: "\ebb9";
}

.line-icon-Quotes-2:before {
    content: "\ebba";
}

.line-icon-Quotes:before {
    content: "\ebbb";
}

.line-icon-Radio:before {
    content: "\ebbc";
}

.line-icon-Radioactive:before {
    content: "\ebbd";
}

.line-icon-Rafting:before {
    content: "\ebbe";
}

.line-icon-Rain-Drop:before {
    content: "\ebbf";
}

.line-icon-Rainbow-2:before {
    content: "\ebc0";
}

.line-icon-Rainbow:before {
    content: "\ebc1";
}

.line-icon-Ram:before {
    content: "\ebc2";
}

.line-icon-Razzor-Blade:before {
    content: "\ebc3";
}

.line-icon-Receipt-2:before {
    content: "\ebc4";
}

.line-icon-Receipt-3:before {
    content: "\ebc5";
}

.line-icon-Receipt-4:before {
    content: "\ebc6";
}

.line-icon-Receipt:before {
    content: "\ebc7";
}

.line-icon-Record2:before {
    content: "\ebc8";
}

.line-icon-Record-3:before {
    content: "\ebc9";
}

.line-icon-Record-Music:before {
    content: "\ebca";
}

.line-icon-Record:before {
    content: "\ebcb";
}

.line-icon-Recycling-2:before {
    content: "\ebcc";
}

.line-icon-Recycling:before {
    content: "\ebcd";
}

.line-icon-Reddit:before {
    content: "\ebce";
}

.line-icon-Redhat:before {
    content: "\ebcf";
}

.line-icon-Redirect:before {
    content: "\ebd0";
}

.line-icon-Redo:before {
    content: "\ebd1";
}

.line-icon-Reel:before {
    content: "\ebd2";
}

.line-icon-Refinery:before {
    content: "\ebd3";
}

.line-icon-Refresh-Window:before {
    content: "\ebd4";
}

.line-icon-Refresh:before {
    content: "\ebd5";
}

.line-icon-Reload-2:before {
    content: "\ebd6";
}

.line-icon-Reload-3:before {
    content: "\ebd7";
}

.line-icon-Reload:before {
    content: "\ebd8";
}

.line-icon-Remote-Controll:before {
    content: "\ebd9";
}

.line-icon-Remote-Controll2:before {
    content: "\ebda";
}

.line-icon-Remove-Bag:before {
    content: "\ebdb";
}

.line-icon-Remove-Basket:before {
    content: "\ebdc";
}

.line-icon-Remove-Cart:before {
    content: "\ebdd";
}

.line-icon-Remove-File:before {
    content: "\ebde";
}

.line-icon-Remove-User:before {
    content: "\ebdf";
}

.line-icon-Remove-Window:before {
    content: "\ebe0";
}

.line-icon-Remove:before {
    content: "\ebe1";
}

.line-icon-Rename:before {
    content: "\ebe2";
}

.line-icon-Repair:before {
    content: "\ebe3";
}

.line-icon-Repeat-2:before {
    content: "\ebe4";
}

.line-icon-Repeat-3:before {
    content: "\ebe5";
}

.line-icon-Repeat-4:before {
    content: "\ebe6";
}

.line-icon-Repeat-5:before {
    content: "\ebe7";
}

.line-icon-Repeat-6:before {
    content: "\ebe8";
}

.line-icon-Repeat-7:before {
    content: "\ebe9";
}

.line-icon-Repeat:before {
    content: "\ebea";
}

.line-icon-Reset:before {
    content: "\ebeb";
}

.line-icon-Resize:before {
    content: "\ebec";
}

.line-icon-Restore-Window:before {
    content: "\ebed";
}

.line-icon-Retouching:before {
    content: "\ebee";
}

.line-icon-Retro-Camera:before {
    content: "\ebef";
}

.line-icon-Retro:before {
    content: "\ebf0";
}

.line-icon-Retweet:before {
    content: "\ebf1";
}

.line-icon-Reverbnation:before {
    content: "\ebf2";
}

.line-icon-Rewind:before {
    content: "\ebf3";
}

.line-icon-RGB:before {
    content: "\ebf4";
}

.line-icon-Ribbon-2:before {
    content: "\ebf5";
}

.line-icon-Ribbon-3:before {
    content: "\ebf6";
}

.line-icon-Ribbon:before {
    content: "\ebf7";
}

.line-icon-Right-2:before {
    content: "\ebf8";
}

.line-icon-Right-3:before {
    content: "\ebf9";
}

.line-icon-Right-4:before {
    content: "\ebfa";
}

.line-icon-Right-ToLeft:before {
    content: "\ebfb";
}

.line-icon-Right:before {
    content: "\ebfc";
}

.line-icon-Road-2:before {
    content: "\ebfd";
}

.line-icon-Road-3:before {
    content: "\ebfe";
}

.line-icon-Road:before {
    content: "\ebff";
}

.line-icon-Robot-2:before {
    content: "\ec00";
}

.line-icon-Robot:before {
    content: "\ec01";
}

.line-icon-Rock-andRoll:before {
    content: "\ec02";
}

.line-icon-Rocket:before {
    content: "\ec03";
}

.line-icon-Roller:before {
    content: "\ec04";
}

.line-icon-Roof:before {
    content: "\ec05";
}

.line-icon-Rook:before {
    content: "\ec06";
}

.line-icon-Rotate-Gesture:before {
    content: "\ec07";
}

.line-icon-Rotate-Gesture2:before {
    content: "\ec08";
}

.line-icon-Rotate-Gesture3:before {
    content: "\ec09";
}

.line-icon-Rotation-390:before {
    content: "\ec0a";
}

.line-icon-Rotation:before {
    content: "\ec0b";
}

.line-icon-Router-2:before {
    content: "\ec0c";
}

.line-icon-Router:before {
    content: "\ec0d";
}

.line-icon-RSS:before {
    content: "\ec0e";
}

.line-icon-Ruler-2:before {
    content: "\ec0f";
}

.line-icon-Ruler:before {
    content: "\ec10";
}

.line-icon-Running-Shoes:before {
    content: "\ec11";
}

.line-icon-Running:before {
    content: "\ec12";
}

.line-icon-Safari:before {
    content: "\ec13";
}

.line-icon-Safe-Box:before {
    content: "\ec14";
}

.line-icon-Safe-Box2:before {
    content: "\ec15";
}

.line-icon-Safety-PinClose:before {
    content: "\ec16";
}

.line-icon-Safety-PinOpen:before {
    content: "\ec17";
}

.line-icon-Sagittarus-2:before {
    content: "\ec18";
}

.line-icon-Sagittarus:before {
    content: "\ec19";
}

.line-icon-Sailing-Ship:before {
    content: "\ec1a";
}

.line-icon-Sand-watch:before {
    content: "\ec1b";
}

.line-icon-Sand-watch2:before {
    content: "\ec1c";
}

.line-icon-Santa-Claus:before {
    content: "\ec1d";
}

.line-icon-Santa-Claus2:before {
    content: "\ec1e";
}

.line-icon-Santa-onSled:before {
    content: "\ec1f";
}

.line-icon-Satelite-2:before {
    content: "\ec20";
}

.line-icon-Satelite:before {
    content: "\ec21";
}

.line-icon-Save-Window:before {
    content: "\ec22";
}

.line-icon-Save:before {
    content: "\ec23";
}

.line-icon-Saw:before {
    content: "\ec24";
}

.line-icon-Saxophone:before {
    content: "\ec25";
}

.line-icon-Scale:before {
    content: "\ec26";
}

.line-icon-Scarf:before {
    content: "\ec27";
}

.line-icon-Scissor:before {
    content: "\ec28";
}

.line-icon-Scooter-Front:before {
    content: "\ec29";
}

.line-icon-Scooter:before {
    content: "\ec2a";
}

.line-icon-Scorpio-2:before {
    content: "\ec2b";
}

.line-icon-Scorpio:before {
    content: "\ec2c";
}

.line-icon-Scotland:before {
    content: "\ec2d";
}

.line-icon-Screwdriver:before {
    content: "\ec2e";
}

.line-icon-Scroll-Fast:before {
    content: "\ec2f";
}

.line-icon-Scroll:before {
    content: "\ec30";
}

.line-icon-Scroller-2:before {
    content: "\ec31";
}

.line-icon-Scroller:before {
    content: "\ec32";
}

.line-icon-Sea-Dog:before {
    content: "\ec33";
}

.line-icon-Search-onCloud:before {
    content: "\ec34";
}

.line-icon-Search-People:before {
    content: "\ec35";
}

.line-icon-secound:before {
    content: "\ec36";
}

.line-icon-secound2:before {
    content: "\ec37";
}

.line-icon-Security-Block:before {
    content: "\ec38";
}

.line-icon-Security-Bug:before {
    content: "\ec39";
}

.line-icon-Security-Camera:before {
    content: "\ec3a";
}

.line-icon-Security-Check:before {
    content: "\ec3b";
}

.line-icon-Security-Settings:before {
    content: "\ec3c";
}

.line-icon-Security-Smiley:before {
    content: "\ec3d";
}

.line-icon-Securiy-Remove:before {
    content: "\ec3e";
}

.line-icon-Seed:before {
    content: "\ec3f";
}

.line-icon-Selfie:before {
    content: "\ec40";
}

.line-icon-Serbia:before {
    content: "\ec41";
}

.line-icon-Server-2:before {
    content: "\ec42";
}

.line-icon-Server:before {
    content: "\ec43";
}

.line-icon-Servers:before {
    content: "\ec44";
}

.line-icon-Settings-Window:before {
    content: "\ec45";
}

.line-icon-Sewing-Machine:before {
    content: "\ec46";
}

.line-icon-Sexual:before {
    content: "\ec47";
}

.line-icon-Share-onCloud:before {
    content: "\ec48";
}

.line-icon-Share-Window:before {
    content: "\ec49";
}

.line-icon-Share:before {
    content: "\ec4a";
}

.line-icon-Sharethis:before {
    content: "\ec4b";
}

.line-icon-Shark:before {
    content: "\ec4c";
}

.line-icon-Sheep:before {
    content: "\ec4d";
}

.line-icon-Sheriff-Badge:before {
    content: "\ec4e";
}

.line-icon-Shield:before {
    content: "\ec4f";
}

.line-icon-Ship-2:before {
    content: "\ec50";
}

.line-icon-Ship:before {
    content: "\ec51";
}

.line-icon-Shirt:before {
    content: "\ec52";
}

.line-icon-Shoes-2:before {
    content: "\ec53";
}

.line-icon-Shoes-3:before {
    content: "\ec54";
}

.line-icon-Shoes:before {
    content: "\ec55";
}

.line-icon-Shop-2:before {
    content: "\ec56";
}

.line-icon-Shop-3:before {
    content: "\ec57";
}

.line-icon-Shop-4:before {
    content: "\ec58";
}

.line-icon-Shop:before {
    content: "\ec59";
}

.line-icon-Shopping-Bag:before {
    content: "\ec5a";
}

.line-icon-Shopping-Basket:before {
    content: "\ec5b";
}

.line-icon-Shopping-Cart:before {
    content: "\ec5c";
}

.line-icon-Short-Pants:before {
    content: "\ec5d";
}

.line-icon-Shoutwire:before {
    content: "\ec5e";
}

.line-icon-Shovel:before {
    content: "\ec5f";
}

.line-icon-Shuffle-2:before {
    content: "\ec60";
}

.line-icon-Shuffle-3:before {
    content: "\ec61";
}

.line-icon-Shuffle-4:before {
    content: "\ec62";
}

.line-icon-Shuffle:before {
    content: "\ec63";
}

.line-icon-Shutter:before {
    content: "\ec64";
}

.line-icon-Sidebar-Window:before {
    content: "\ec65";
}

.line-icon-Signal:before {
    content: "\ec66";
}

.line-icon-Singapore:before {
    content: "\ec67";
}

.line-icon-Skate-Shoes:before {
    content: "\ec68";
}

.line-icon-Skateboard-2:before {
    content: "\ec69";
}

.line-icon-Skateboard:before {
    content: "\ec6a";
}

.line-icon-Skeleton:before {
    content: "\ec6b";
}

.line-icon-Ski:before {
    content: "\ec6c";
}

.line-icon-Skirt:before {
    content: "\ec6d";
}

.line-icon-Skrill:before {
    content: "\ec6e";
}

.line-icon-Skull:before {
    content: "\ec6f";
}

.line-icon-Skydiving:before {
    content: "\ec70";
}

.line-icon-Skype:before {
    content: "\ec71";
}

.line-icon-Sled-withGifts:before {
    content: "\ec72";
}

.line-icon-Sled:before {
    content: "\ec73";
}

.line-icon-Sleeping:before {
    content: "\ec74";
}

.line-icon-Sleet:before {
    content: "\ec75";
}

.line-icon-Slippers:before {
    content: "\ec76";
}

.line-icon-Smart:before {
    content: "\ec77";
}

.line-icon-Smartphone-2:before {
    content: "\ec78";
}

.line-icon-Smartphone-3:before {
    content: "\ec79";
}

.line-icon-Smartphone-4:before {
    content: "\ec7a";
}

.line-icon-Smartphone-Secure:before {
    content: "\ec7b";
}

.line-icon-Smartphone:before {
    content: "\ec7c";
}

.line-icon-Smile:before {
    content: "\ec7d";
}

.line-icon-Smoking-Area:before {
    content: "\ec7e";
}

.line-icon-Smoking-Pipe:before {
    content: "\ec7f";
}

.line-icon-Snake:before {
    content: "\ec80";
}

.line-icon-Snorkel:before {
    content: "\ec81";
}

.line-icon-Snow-2:before {
    content: "\ec82";
}

.line-icon-Snow-Dome:before {
    content: "\ec83";
}

.line-icon-Snow-Storm:before {
    content: "\ec84";
}

.line-icon-Snow:before {
    content: "\ec85";
}

.line-icon-Snowflake-2:before {
    content: "\ec86";
}

.line-icon-Snowflake-3:before {
    content: "\ec87";
}

.line-icon-Snowflake-4:before {
    content: "\ec88";
}

.line-icon-Snowflake:before {
    content: "\ec89";
}

.line-icon-Snowman:before {
    content: "\ec8a";
}

.line-icon-Soccer-Ball:before {
    content: "\ec8b";
}

.line-icon-Soccer-Shoes:before {
    content: "\ec8c";
}

.line-icon-Socks:before {
    content: "\ec8d";
}

.line-icon-Solar:before {
    content: "\ec8e";
}

.line-icon-Sound-Wave:before {
    content: "\ec8f";
}

.line-icon-Sound:before {
    content: "\ec90";
}

.line-icon-Soundcloud:before {
    content: "\ec91";
}

.line-icon-Soup:before {
    content: "\ec92";
}

.line-icon-South-Africa:before {
    content: "\ec93";
}

.line-icon-Space-Needle:before {
    content: "\ec94";
}

.line-icon-Spain:before {
    content: "\ec95";
}

.line-icon-Spam-Mail:before {
    content: "\ec96";
}

.line-icon-Speach-Bubble:before {
    content: "\ec97";
}

.line-icon-Speach-Bubble2:before {
    content: "\ec98";
}

.line-icon-Speach-Bubble3:before {
    content: "\ec99";
}

.line-icon-Speach-Bubble4:before {
    content: "\ec9a";
}

.line-icon-Speach-Bubble5:before {
    content: "\ec9b";
}

.line-icon-Speach-Bubble6:before {
    content: "\ec9c";
}

.line-icon-Speach-Bubble7:before {
    content: "\ec9d";
}

.line-icon-Speach-Bubble8:before {
    content: "\ec9e";
}

.line-icon-Speach-Bubble9:before {
    content: "\ec9f";
}

.line-icon-Speach-Bubble10:before {
    content: "\eca0";
}

.line-icon-Speach-Bubble11:before {
    content: "\eca1";
}

.line-icon-Speach-Bubble12:before {
    content: "\eca2";
}

.line-icon-Speach-Bubble13:before {
    content: "\eca3";
}

.line-icon-Speach-BubbleAsking:before {
    content: "\eca4";
}

.line-icon-Speach-BubbleComic:before {
    content: "\eca5";
}

.line-icon-Speach-BubbleComic2:before {
    content: "\eca6";
}

.line-icon-Speach-BubbleComic3:before {
    content: "\eca7";
}

.line-icon-Speach-BubbleComic4:before {
    content: "\eca8";
}

.line-icon-Speach-BubbleDialog:before {
    content: "\eca9";
}

.line-icon-Speach-Bubbles:before {
    content: "\ecaa";
}

.line-icon-Speak-2:before {
    content: "\ecab";
}

.line-icon-Speak:before {
    content: "\ecac";
}

.line-icon-Speaker-2:before {
    content: "\ecad";
}

.line-icon-Speaker:before {
    content: "\ecae";
}

.line-icon-Spell-Check:before {
    content: "\ecaf";
}

.line-icon-Spell-CheckABC:before {
    content: "\ecb0";
}

.line-icon-Spermium:before {
    content: "\ecb1";
}

.line-icon-Spider:before {
    content: "\ecb2";
}

.line-icon-Spiderweb:before {
    content: "\ecb3";
}

.line-icon-Split-FourSquareWindow:before {
    content: "\ecb4";
}

.line-icon-Split-Horizontal:before {
    content: "\ecb5";
}

.line-icon-Split-Horizontal2Window:before {
    content: "\ecb6";
}

.line-icon-Split-Vertical:before {
    content: "\ecb7";
}

.line-icon-Split-Vertical2:before {
    content: "\ecb8";
}

.line-icon-Split-Window:before {
    content: "\ecb9";
}

.line-icon-Spoder:before {
    content: "\ecba";
}

.line-icon-Spoon:before {
    content: "\ecbb";
}

.line-icon-Sport-Mode:before {
    content: "\ecbc";
}

.line-icon-Sports-Clothings1:before {
    content: "\ecbd";
}

.line-icon-Sports-Clothings2:before {
    content: "\ecbe";
}

.line-icon-Sports-Shirt:before {
    content: "\ecbf";
}

.line-icon-Spot:before {
    content: "\ecc0";
}

.line-icon-Spray:before {
    content: "\ecc1";
}

.line-icon-Spread:before {
    content: "\ecc2";
}

.line-icon-Spring:before {
    content: "\ecc3";
}

.line-icon-Spurl:before {
    content: "\ecc4";
}

.line-icon-Spy:before {
    content: "\ecc5";
}

.line-icon-Squirrel:before {
    content: "\ecc6";
}

.line-icon-SSL:before {
    content: "\ecc7";
}

.line-icon-St-BasilsCathedral:before {
    content: "\ecc8";
}

.line-icon-St-PaulsCathedral:before {
    content: "\ecc9";
}

.line-icon-Stamp-2:before {
    content: "\ecca";
}

.line-icon-Stamp:before {
    content: "\eccb";
}

.line-icon-Stapler:before {
    content: "\eccc";
}

.line-icon-Star-Track:before {
    content: "\eccd";
}

.line-icon-Star:before {
    content: "\ecce";
}

.line-icon-Starfish:before {
    content: "\eccf";
}

.line-icon-Start2:before {
    content: "\ecd0";
}

.line-icon-Start-3:before {
    content: "\ecd1";
}

.line-icon-Start-ways:before {
    content: "\ecd2";
}

.line-icon-Start:before {
    content: "\ecd3";
}

.line-icon-Statistic:before {
    content: "\ecd4";
}

.line-icon-Stethoscope:before {
    content: "\ecd5";
}

.line-icon-stop--2:before {
    content: "\ecd6";
}

.line-icon-Stop-Music:before {
    content: "\ecd7";
}

.line-icon-Stop:before {
    content: "\ecd8";
}

.line-icon-Stopwatch-2:before {
    content: "\ecd9";
}

.line-icon-Stopwatch:before {
    content: "\ecda";
}

.line-icon-Storm:before {
    content: "\ecdb";
}

.line-icon-Street-View:before {
    content: "\ecdc";
}

.line-icon-Street-View2:before {
    content: "\ecdd";
}

.line-icon-Strikethrough-Text:before {
    content: "\ecde";
}

.line-icon-Stroller:before {
    content: "\ecdf";
}

.line-icon-Structure:before {
    content: "\ece0";
}

.line-icon-Student-Female:before {
    content: "\ece1";
}

.line-icon-Student-Hat:before {
    content: "\ece2";
}

.line-icon-Student-Hat2:before {
    content: "\ece3";
}

.line-icon-Student-Male:before {
    content: "\ece4";
}

.line-icon-Student-MaleFemale:before {
    content: "\ece5";
}

.line-icon-Students:before {
    content: "\ece6";
}

.line-icon-Studio-Flash:before {
    content: "\ece7";
}

.line-icon-Studio-Lightbox:before {
    content: "\ece8";
}

.line-icon-Stumbleupon:before {
    content: "\ece9";
}

.line-icon-Suit:before {
    content: "\ecea";
}

.line-icon-Suitcase:before {
    content: "\eceb";
}

.line-icon-Sum-2:before {
    content: "\ecec";
}

.line-icon-Sum:before {
    content: "\eced";
}

.line-icon-Summer:before {
    content: "\ecee";
}

.line-icon-Sun-CloudyRain:before {
    content: "\ecef";
}

.line-icon-Sun:before {
    content: "\ecf0";
}

.line-icon-Sunglasses-2:before {
    content: "\ecf1";
}

.line-icon-Sunglasses-3:before {
    content: "\ecf2";
}

.line-icon-Sunglasses-Smiley:before {
    content: "\ecf3";
}

.line-icon-Sunglasses-Smiley2:before {
    content: "\ecf4";
}

.line-icon-Sunglasses-W:before {
    content: "\ecf5";
}

.line-icon-Sunglasses-W2:before {
    content: "\ecf6";
}

.line-icon-Sunglasses-W3:before {
    content: "\ecf7";
}

.line-icon-Sunglasses:before {
    content: "\ecf8";
}

.line-icon-Sunrise:before {
    content: "\ecf9";
}

.line-icon-Sunset:before {
    content: "\ecfa";
}

.line-icon-Superman:before {
    content: "\ecfb";
}

.line-icon-Support:before {
    content: "\ecfc";
}

.line-icon-Surprise:before {
    content: "\ecfd";
}

.line-icon-Sushi:before {
    content: "\ecfe";
}

.line-icon-Sweden:before {
    content: "\ecff";
}

.line-icon-Swimming-Short:before {
    content: "\ed00";
}

.line-icon-Swimming:before {
    content: "\ed01";
}

.line-icon-Swimmwear:before {
    content: "\ed02";
}

.line-icon-Switch:before {
    content: "\ed03";
}

.line-icon-Switzerland:before {
    content: "\ed04";
}

.line-icon-Sync-Cloud:before {
    content: "\ed05";
}

.line-icon-Sync:before {
    content: "\ed06";
}

.line-icon-Synchronize-2:before {
    content: "\ed07";
}

.line-icon-Synchronize:before {
    content: "\ed08";
}

.line-icon-T-Shirt:before {
    content: "\ed09";
}

.line-icon-Tablet-2:before {
    content: "\ed0a";
}

.line-icon-Tablet-3:before {
    content: "\ed0b";
}

.line-icon-Tablet-Orientation:before {
    content: "\ed0c";
}

.line-icon-Tablet-Phone:before {
    content: "\ed0d";
}

.line-icon-Tablet-Secure:before {
    content: "\ed0e";
}

.line-icon-Tablet-Vertical:before {
    content: "\ed0f";
}

.line-icon-Tablet:before {
    content: "\ed10";
}

.line-icon-Tactic:before {
    content: "\ed11";
}

.line-icon-Tag-2:before {
    content: "\ed12";
}

.line-icon-Tag-3:before {
    content: "\ed13";
}

.line-icon-Tag-4:before {
    content: "\ed14";
}

.line-icon-Tag-5:before {
    content: "\ed15";
}

.line-icon-Tag:before {
    content: "\ed16";
}

.line-icon-Taj-Mahal:before {
    content: "\ed17";
}

.line-icon-Talk-Man:before {
    content: "\ed18";
}

.line-icon-Tap:before {
    content: "\ed19";
}

.line-icon-Target-Market:before {
    content: "\ed1a";
}

.line-icon-Target:before {
    content: "\ed1b";
}

.line-icon-Taurus-2:before {
    content: "\ed1c";
}

.line-icon-Taurus:before {
    content: "\ed1d";
}

.line-icon-Taxi-2:before {
    content: "\ed1e";
}

.line-icon-Taxi-Sign:before {
    content: "\ed1f";
}

.line-icon-Taxi:before {
    content: "\ed20";
}

.line-icon-Teacher:before {
    content: "\ed21";
}

.line-icon-Teapot:before {
    content: "\ed22";
}

.line-icon-Technorati:before {
    content: "\ed23";
}

.line-icon-Teddy-Bear:before {
    content: "\ed24";
}

.line-icon-Tee-Mug:before {
    content: "\ed25";
}

.line-icon-Telephone-2:before {
    content: "\ed26";
}

.line-icon-Telephone:before {
    content: "\ed27";
}

.line-icon-Telescope:before {
    content: "\ed28";
}

.line-icon-Temperature-2:before {
    content: "\ed29";
}

.line-icon-Temperature-3:before {
    content: "\ed2a";
}

.line-icon-Temperature:before {
    content: "\ed2b";
}

.line-icon-Temple:before {
    content: "\ed2c";
}

.line-icon-Tennis-Ball:before {
    content: "\ed2d";
}

.line-icon-Tennis:before {
    content: "\ed2e";
}

.line-icon-Tent:before {
    content: "\ed2f";
}

.line-icon-Test-Tube:before {
    content: "\ed30";
}

.line-icon-Test-Tube2:before {
    content: "\ed31";
}

.line-icon-Testimonal:before {
    content: "\ed32";
}

.line-icon-Text-Box:before {
    content: "\ed33";
}

.line-icon-Text-Effect:before {
    content: "\ed34";
}

.line-icon-Text-HighlightColor:before {
    content: "\ed35";
}

.line-icon-Text-Paragraph:before {
    content: "\ed36";
}

.line-icon-Thailand:before {
    content: "\ed37";
}

.line-icon-The-WhiteHouse:before {
    content: "\ed38";
}

.line-icon-This-SideUp:before {
    content: "\ed39";
}

.line-icon-Thread:before {
    content: "\ed3a";
}

.line-icon-Three-ArrowFork:before {
    content: "\ed3b";
}

.line-icon-Three-Fingers:before {
    content: "\ed3c";
}

.line-icon-Three-FingersDrag:before {
    content: "\ed3d";
}

.line-icon-Three-FingersDrag2:before {
    content: "\ed3e";
}

.line-icon-Three-FingersTouch:before {
    content: "\ed3f";
}

.line-icon-Thumb:before {
    content: "\ed40";
}

.line-icon-Thumbs-DownSmiley:before {
    content: "\ed41";
}

.line-icon-Thumbs-UpSmiley:before {
    content: "\ed42";
}

.line-icon-Thunder:before {
    content: "\ed43";
}

.line-icon-Thunderstorm:before {
    content: "\ed44";
}

.line-icon-Ticket:before {
    content: "\ed45";
}

.line-icon-Tie-2:before {
    content: "\ed46";
}

.line-icon-Tie-3:before {
    content: "\ed47";
}

.line-icon-Tie-4:before {
    content: "\ed48";
}

.line-icon-Tie:before {
    content: "\ed49";
}

.line-icon-Tiger:before {
    content: "\ed4a";
}

.line-icon-Time-Backup:before {
    content: "\ed4b";
}

.line-icon-Time-Bomb:before {
    content: "\ed4c";
}

.line-icon-Time-Clock:before {
    content: "\ed4d";
}

.line-icon-Time-Fire:before {
    content: "\ed4e";
}

.line-icon-Time-Machine:before {
    content: "\ed4f";
}

.line-icon-Time-Window:before {
    content: "\ed50";
}

.line-icon-Timer-2:before {
    content: "\ed51";
}

.line-icon-Timer:before {
    content: "\ed52";
}

.line-icon-To-Bottom:before {
    content: "\ed53";
}

.line-icon-To-Bottom2:before {
    content: "\ed54";
}

.line-icon-To-Left:before {
    content: "\ed55";
}

.line-icon-To-Right:before {
    content: "\ed56";
}

.line-icon-To-Top:before {
    content: "\ed57";
}

.line-icon-To-Top2:before {
    content: "\ed58";
}

.line-icon-Token-:before {
    content: "\ed59";
}

.line-icon-Tomato:before {
    content: "\ed5a";
}

.line-icon-Tongue:before {
    content: "\ed5b";
}

.line-icon-Tooth-2:before {
    content: "\ed5c";
}

.line-icon-Tooth:before {
    content: "\ed5d";
}

.line-icon-Top-ToBottom:before {
    content: "\ed5e";
}

.line-icon-Touch-Window:before {
    content: "\ed5f";
}

.line-icon-Tourch:before {
    content: "\ed60";
}

.line-icon-Tower-2:before {
    content: "\ed61";
}

.line-icon-Tower-Bridge:before {
    content: "\ed62";
}

.line-icon-Tower:before {
    content: "\ed63";
}

.line-icon-Trace:before {
    content: "\ed64";
}

.line-icon-Tractor:before {
    content: "\ed65";
}

.line-icon-traffic-Light:before {
    content: "\ed66";
}

.line-icon-Traffic-Light2:before {
    content: "\ed67";
}

.line-icon-Train-2:before {
    content: "\ed68";
}

.line-icon-Train:before {
    content: "\ed69";
}

.line-icon-Tram:before {
    content: "\ed6a";
}

.line-icon-Transform-2:before {
    content: "\ed6b";
}

.line-icon-Transform-3:before {
    content: "\ed6c";
}

.line-icon-Transform-4:before {
    content: "\ed6d";
}

.line-icon-Transform:before {
    content: "\ed6e";
}

.line-icon-Trash-withMen:before {
    content: "\ed6f";
}

.line-icon-Tree-2:before {
    content: "\ed70";
}

.line-icon-Tree-3:before {
    content: "\ed71";
}

.line-icon-Tree-4:before {
    content: "\ed72";
}

.line-icon-Tree-5:before {
    content: "\ed73";
}

.line-icon-Tree:before {
    content: "\ed74";
}

.line-icon-Trekking:before {
    content: "\ed75";
}

.line-icon-Triangle-ArrowDown:before {
    content: "\ed76";
}

.line-icon-Triangle-ArrowLeft:before {
    content: "\ed77";
}

.line-icon-Triangle-ArrowRight:before {
    content: "\ed78";
}

.line-icon-Triangle-ArrowUp:before {
    content: "\ed79";
}

.line-icon-Tripod-2:before {
    content: "\ed7a";
}

.line-icon-Tripod-andVideo:before {
    content: "\ed7b";
}

.line-icon-Tripod-withCamera:before {
    content: "\ed7c";
}

.line-icon-Tripod-withGopro:before {
    content: "\ed7d";
}

.line-icon-Trophy-2:before {
    content: "\ed7e";
}

.line-icon-Trophy:before {
    content: "\ed7f";
}

.line-icon-Truck:before {
    content: "\ed80";
}

.line-icon-Trumpet:before {
    content: "\ed81";
}

.line-icon-Tumblr:before {
    content: "\ed82";
}

.line-icon-Turkey:before {
    content: "\ed83";
}

.line-icon-Turn-Down:before {
    content: "\ed84";
}

.line-icon-Turn-Down2:before {
    content: "\ed85";
}

.line-icon-Turn-DownFromLeft:before {
    content: "\ed86";
}

.line-icon-Turn-DownFromRight:before {
    content: "\ed87";
}

.line-icon-Turn-Left:before {
    content: "\ed88";
}

.line-icon-Turn-Left3:before {
    content: "\ed89";
}

.line-icon-Turn-Right:before {
    content: "\ed8a";
}

.line-icon-Turn-Right3:before {
    content: "\ed8b";
}

.line-icon-Turn-Up:before {
    content: "\ed8c";
}

.line-icon-Turn-Up2:before {
    content: "\ed8d";
}

.line-icon-Turtle:before {
    content: "\ed8e";
}

.line-icon-Tuxedo:before {
    content: "\ed8f";
}

.line-icon-TV:before {
    content: "\ed90";
}

.line-icon-Twister:before {
    content: "\ed91";
}

.line-icon-Twitter-2:before {
    content: "\ed92";
}

.line-icon-Twitter:before {
    content: "\ed93";
}

.line-icon-Two-Fingers:before {
    content: "\ed94";
}

.line-icon-Two-FingersDrag:before {
    content: "\ed95";
}

.line-icon-Two-FingersDrag2:before {
    content: "\ed96";
}

.line-icon-Two-FingersScroll:before {
    content: "\ed97";
}

.line-icon-Two-FingersTouch:before {
    content: "\ed98";
}

.line-icon-Two-Windows:before {
    content: "\ed99";
}

.line-icon-Type-Pass:before {
    content: "\ed9a";
}

.line-icon-Ukraine:before {
    content: "\ed9b";
}

.line-icon-Umbrela:before {
    content: "\ed9c";
}

.line-icon-Umbrella-2:before {
    content: "\ed9d";
}

.line-icon-Umbrella-3:before {
    content: "\ed9e";
}

.line-icon-Under-LineText:before {
    content: "\ed9f";
}

.line-icon-Undo:before {
    content: "\eda0";
}

.line-icon-United-Kingdom:before {
    content: "\eda1";
}

.line-icon-United-States:before {
    content: "\eda2";
}

.line-icon-University-2:before {
    content: "\eda3";
}

.line-icon-University:before {
    content: "\eda4";
}

.line-icon-Unlike-2:before {
    content: "\eda5";
}

.line-icon-Unlike:before {
    content: "\eda6";
}

.line-icon-Unlock-2:before {
    content: "\eda7";
}

.line-icon-Unlock-3:before {
    content: "\eda8";
}

.line-icon-Unlock:before {
    content: "\eda9";
}

.line-icon-Up--Down:before {
    content: "\edaa";
}

.line-icon-Up--Down3:before {
    content: "\edab";
}

.line-icon-Up-2:before {
    content: "\edac";
}

.line-icon-Up-3:before {
    content: "\edad";
}

.line-icon-Up-4:before {
    content: "\edae";
}

.line-icon-Up:before {
    content: "\edaf";
}

.line-icon-Upgrade:before {
    content: "\edb0";
}

.line-icon-Upload-2:before {
    content: "\edb1";
}

.line-icon-Upload-toCloud:before {
    content: "\edb2";
}

.line-icon-Upload-Window:before {
    content: "\edb3";
}

.line-icon-Upload:before {
    content: "\edb4";
}

.line-icon-Uppercase-Text:before {
    content: "\edb5";
}

.line-icon-Upward:before {
    content: "\edb6";
}

.line-icon-URL-Window:before {
    content: "\edb7";
}

.line-icon-Usb-2:before {
    content: "\edb8";
}

.line-icon-Usb-Cable:before {
    content: "\edb9";
}

.line-icon-Usb:before {
    content: "\edba";
}

.line-icon-User:before {
    content: "\edbb";
}

.line-icon-Ustream:before {
    content: "\edbc";
}

.line-icon-Vase:before {
    content: "\edbd";
}

.line-icon-Vector-2:before {
    content: "\edbe";
}

.line-icon-Vector-3:before {
    content: "\edbf";
}

.line-icon-Vector-4:before {
    content: "\edc0";
}

.line-icon-Vector-5:before {
    content: "\edc1";
}

.line-icon-Vector:before {
    content: "\edc2";
}

.line-icon-Venn-Diagram:before {
    content: "\edc3";
}

.line-icon-Vest-2:before {
    content: "\edc4";
}

.line-icon-Vest:before {
    content: "\edc5";
}

.line-icon-Viddler:before {
    content: "\edc6";
}

.line-icon-Video-2:before {
    content: "\edc7";
}

.line-icon-Video-3:before {
    content: "\edc8";
}

.line-icon-Video-4:before {
    content: "\edc9";
}

.line-icon-Video-5:before {
    content: "\edca";
}

.line-icon-Video-6:before {
    content: "\edcb";
}

.line-icon-Video-GameController:before {
    content: "\edcc";
}

.line-icon-Video-Len:before {
    content: "\edcd";
}

.line-icon-Video-Len2:before {
    content: "\edce";
}

.line-icon-Video-Photographer:before {
    content: "\edcf";
}

.line-icon-Video-Tripod:before {
    content: "\edd0";
}

.line-icon-Video:before {
    content: "\edd1";
}

.line-icon-Vietnam:before {
    content: "\edd2";
}

.line-icon-View-Height:before {
    content: "\edd3";
}

.line-icon-View-Width:before {
    content: "\edd4";
}

.line-icon-Vimeo:before {
    content: "\edd5";
}

.line-icon-Virgo-2:before {
    content: "\edd6";
}

.line-icon-Virgo:before {
    content: "\edd7";
}

.line-icon-Virus-2:before {
    content: "\edd8";
}

.line-icon-Virus-3:before {
    content: "\edd9";
}

.line-icon-Virus:before {
    content: "\edda";
}

.line-icon-Visa:before {
    content: "\eddb";
}

.line-icon-Voice:before {
    content: "\eddc";
}

.line-icon-Voicemail:before {
    content: "\eddd";
}

.line-icon-Volleyball:before {
    content: "\edde";
}

.line-icon-Volume-Down:before {
    content: "\eddf";
}

.line-icon-Volume-Up:before {
    content: "\ede0";
}

.line-icon-VPN:before {
    content: "\ede1";
}

.line-icon-Wacom-Tablet:before {
    content: "\ede2";
}

.line-icon-Waiter:before {
    content: "\ede3";
}

.line-icon-Walkie-Talkie:before {
    content: "\ede4";
}

.line-icon-Wallet-2:before {
    content: "\ede5";
}

.line-icon-Wallet-3:before {
    content: "\ede6";
}

.line-icon-Wallet:before {
    content: "\ede7";
}

.line-icon-Warehouse:before {
    content: "\ede8";
}

.line-icon-Warning-Window:before {
    content: "\ede9";
}

.line-icon-Watch-2:before {
    content: "\edea";
}

.line-icon-Watch-3:before {
    content: "\edeb";
}

.line-icon-Watch:before {
    content: "\edec";
}

.line-icon-Wave-2:before {
    content: "\eded";
}

.line-icon-Wave:before {
    content: "\edee";
}

.line-icon-Webcam:before {
    content: "\edef";
}

.line-icon-weight-Lift:before {
    content: "\edf0";
}

.line-icon-Wheelbarrow:before {
    content: "\edf1";
}

.line-icon-Wheelchair:before {
    content: "\edf2";
}

.line-icon-Width-Window:before {
    content: "\edf3";
}

.line-icon-Wifi-2:before {
    content: "\edf4";
}

.line-icon-Wifi-Keyboard:before {
    content: "\edf5";
}

.line-icon-Wifi:before {
    content: "\edf6";
}

.line-icon-Wind-Turbine:before {
    content: "\edf7";
}

.line-icon-Windmill:before {
    content: "\edf8";
}

.line-icon-Window-2:before {
    content: "\edf9";
}

.line-icon-Window:before {
    content: "\edfa";
}

.line-icon-Windows-2:before {
    content: "\edfb";
}

.line-icon-Windows-Microsoft:before {
    content: "\edfc";
}

.line-icon-Windows:before {
    content: "\edfd";
}

.line-icon-Windsock:before {
    content: "\edfe";
}

.line-icon-Windy:before {
    content: "\edff";
}

.line-icon-Wine-Bottle:before {
    content: "\ee00";
}

.line-icon-Wine-Glass:before {
    content: "\ee01";
}

.line-icon-Wink:before {
    content: "\ee02";
}

.line-icon-Winter-2:before {
    content: "\ee03";
}

.line-icon-Winter:before {
    content: "\ee04";
}

.line-icon-Wireless:before {
    content: "\ee05";
}

.line-icon-Witch-Hat:before {
    content: "\ee06";
}

.line-icon-Witch:before {
    content: "\ee07";
}

.line-icon-Wizard:before {
    content: "\ee08";
}

.line-icon-Wolf:before {
    content: "\ee09";
}

.line-icon-Woman-Sign:before {
    content: "\ee0a";
}

.line-icon-WomanMan:before {
    content: "\ee0b";
}

.line-icon-Womans-Underwear:before {
    content: "\ee0c";
}

.line-icon-Womans-Underwear2:before {
    content: "\ee0d";
}

.line-icon-Women:before {
    content: "\ee0e";
}

.line-icon-Wonder-Woman:before {
    content: "\ee0f";
}

.line-icon-Wordpress:before {
    content: "\ee10";
}

.line-icon-Worker-Clothes:before {
    content: "\ee11";
}

.line-icon-Worker:before {
    content: "\ee12";
}

.line-icon-Wrap-Text:before {
    content: "\ee13";
}

.line-icon-Wreath:before {
    content: "\ee14";
}

.line-icon-Wrench:before {
    content: "\ee15";
}

.line-icon-X-Box:before {
    content: "\ee16";
}

.line-icon-X-ray:before {
    content: "\ee17";
}

.line-icon-Xanga:before {
    content: "\ee18";
}

.line-icon-Xing:before {
    content: "\ee19";
}

.line-icon-Yacht:before {
    content: "\ee1a";
}

.line-icon-Yahoo-Buzz:before {
    content: "\ee1b";
}

.line-icon-Yahoo:before {
    content: "\ee1c";
}

.line-icon-Yelp:before {
    content: "\ee1d";
}

.line-icon-Yes:before {
    content: "\ee1e";
}

.line-icon-Ying-Yang:before {
    content: "\ee1f";
}

.line-icon-Youtube:before {
    content: "\ee20";
}

.line-icon-Z-A:before {
    content: "\ee21";
}

.line-icon-Zebra:before {
    content: "\ee22";
}

.line-icon-Zombie:before {
    content: "\ee23";
}

.line-icon-Zoom-Gesture:before {
    content: "\ee24";
}

.line-icon-Zootool:before {
    content: "\ee25";
}

@font-face {
    font-family: icomoon-solid;
    src: url(../fonts/icomoon-solid.eot?1sxah1);
    src: url(../fonts/icomoon-solid.eot?#iefix1sxah1) format("embedded-opentype"), url(../fonts/icomoon-solid.woff?1sxah1) format("woff"), url(../fonts/icomoon-solid.ttf?1sxah1) format("truetype"), url(../fonts/icomoon-solid.svg?1sxah1#icomoon) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

[class*=" solid-icon-"],
[class^=solid-icon-] {
    font-family: icomoon-solid;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.solid-icon-A-Z:before {
    content: "\e600";
}

.solid-icon-Aa:before {
    content: "\e601";
}

.solid-icon-Add-Bag:before {
    content: "\e602";
}

.solid-icon-Add-Basket:before {
    content: "\e603";
}

.solid-icon-Add-Cart:before {
    content: "\e604";
}

.solid-icon-Add-File:before {
    content: "\e605";
}

.solid-icon-Add-SpaceAfterParagraph:before {
    content: "\e606";
}

.solid-icon-Add-SpaceBeforeParagraph:before {
    content: "\e607";
}

.solid-icon-Add-User:before {
    content: "\e608";
}

.solid-icon-Add-UserStar:before {
    content: "\e609";
}

.solid-icon-Add-Window:before {
    content: "\e60a";
}

.solid-icon-Add:before {
    content: "\e60b";
}

.solid-icon-Address-Book:before {
    content: "\e60c";
}

.solid-icon-Address-Book2:before {
    content: "\e60d";
}

.solid-icon-Administrator:before {
    content: "\e60e";
}

.solid-icon-Aerobics-2:before {
    content: "\e60f";
}

.solid-icon-Aerobics-3:before {
    content: "\e610";
}

.solid-icon-Aerobics:before {
    content: "\e611";
}

.solid-icon-Affiliate:before {
    content: "\e612";
}

.solid-icon-Aim:before {
    content: "\e613";
}

.solid-icon-Air-Balloon:before {
    content: "\e614";
}

.solid-icon-Airbrush:before {
    content: "\e615";
}

.solid-icon-Airship:before {
    content: "\e616";
}

.solid-icon-Alarm-Clock:before {
    content: "\e617";
}

.solid-icon-Alarm-Clock2:before {
    content: "\e618";
}

.solid-icon-Alarm:before {
    content: "\e619";
}

.solid-icon-Alien-2:before {
    content: "\e61a";
}

.solid-icon-Alien:before {
    content: "\e61b";
}

.solid-icon-Aligator:before {
    content: "\e61c";
}

.solid-icon-Align-Center:before {
    content: "\e61d";
}

.solid-icon-Align-JustifyAll:before {
    content: "\e61e";
}

.solid-icon-Align-JustifyCenter:before {
    content: "\e61f";
}

.solid-icon-Align-JustifyLeft:before {
    content: "\e620";
}

.solid-icon-Align-JustifyRight:before {
    content: "\e621";
}

.solid-icon-Align-Left:before {
    content: "\e622";
}

.solid-icon-Align-Right:before {
    content: "\e623";
}

.solid-icon-Alpha:before {
    content: "\e624";
}

.solid-icon-Ambulance:before {
    content: "\e625";
}

.solid-icon-AMX:before {
    content: "\e626";
}

.solid-icon-Anchor-2:before {
    content: "\e627";
}

.solid-icon-Anchor:before {
    content: "\e628";
}

.solid-icon-Android-Store:before {
    content: "\e629";
}

.solid-icon-Android:before {
    content: "\e62a";
}

.solid-icon-Angel-Smiley:before {
    content: "\e62b";
}

.solid-icon-Angel:before {
    content: "\e62c";
}

.solid-icon-Angry:before {
    content: "\e62d";
}

.solid-icon-Apple-Bite:before {
    content: "\e62e";
}

.solid-icon-Apple-Store:before {
    content: "\e62f";
}

.solid-icon-Apple:before {
    content: "\e630";
}

.solid-icon-Approved-Window:before {
    content: "\e631";
}

.solid-icon-Aquarius-2:before {
    content: "\e632";
}

.solid-icon-Aquarius:before {
    content: "\e633";
}

.solid-icon-Archery-2:before {
    content: "\e634";
}

.solid-icon-Archery:before {
    content: "\e635";
}

.solid-icon-Argentina:before {
    content: "\e636";
}

.solid-icon-Aries-2:before {
    content: "\e637";
}

.solid-icon-Aries:before {
    content: "\e638";
}

.solid-icon-Army-Key:before {
    content: "\e639";
}

.solid-icon-Arrow-Around:before {
    content: "\e63a";
}

.solid-icon-Arrow-Back:before {
    content: "\e63b";
}

.solid-icon-Arrow-Back2:before {
    content: "\e63c";
}

.solid-icon-Arrow-back3:before {
    content: "\e63d";
}

.solid-icon-Arrow-Barrier:before {
    content: "\e63e";
}

.solid-icon-Arrow-Circle:before {
    content: "\e63f";
}

.solid-icon-Arrow-Cross:before {
    content: "\e640";
}

.solid-icon-Arrow-Down:before {
    content: "\e641";
}

.solid-icon-Arrow-Down2:before {
    content: "\e642";
}

.solid-icon-Arrow-Down3:before {
    content: "\e643";
}

.solid-icon-Arrow-DowninCircle:before {
    content: "\e644";
}

.solid-icon-Arrow-Fork:before {
    content: "\e645";
}

.solid-icon-Arrow-Forward:before {
    content: "\e646";
}

.solid-icon-Arrow-Forward2:before {
    content: "\e647";
}

.solid-icon-Arrow-From:before {
    content: "\e648";
}

.solid-icon-Arrow-Inside:before {
    content: "\e649";
}

.solid-icon-Arrow-Inside45:before {
    content: "\e64a";
}

.solid-icon-Arrow-InsideGap:before {
    content: "\e64b";
}

.solid-icon-Arrow-InsideGap45:before {
    content: "\e64c";
}

.solid-icon-Arrow-Into:before {
    content: "\e64d";
}

.solid-icon-Arrow-Join:before {
    content: "\e64e";
}

.solid-icon-Arrow-Junction:before {
    content: "\e64f";
}

.solid-icon-Arrow-Left:before {
    content: "\e650";
}

.solid-icon-Arrow-Left2:before {
    content: "\e651";
}

.solid-icon-Arrow-LeftinCircle:before {
    content: "\e652";
}

.solid-icon-Arrow-Loop:before {
    content: "\e653";
}

.solid-icon-Arrow-Merge:before {
    content: "\e654";
}

.solid-icon-Arrow-Mix:before {
    content: "\e655";
}

.solid-icon-Arrow-Next:before {
    content: "\e656";
}

.solid-icon-Arrow-OutLeft:before {
    content: "\e657";
}

.solid-icon-Arrow-OutRight:before {
    content: "\e658";
}

.solid-icon-Arrow-Outside:before {
    content: "\e659";
}

.solid-icon-Arrow-Outside45:before {
    content: "\e65a";
}

.solid-icon-Arrow-OutsideGap:before {
    content: "\e65b";
}

.solid-icon-Arrow-OutsideGap45:before {
    content: "\e65c";
}

.solid-icon-Arrow-Over:before {
    content: "\e65d";
}

.solid-icon-Arrow-Refresh:before {
    content: "\e65e";
}

.solid-icon-Arrow-Refresh2:before {
    content: "\e65f";
}

.solid-icon-Arrow-Right:before {
    content: "\e660";
}

.solid-icon-Arrow-Right2:before {
    content: "\e661";
}

.solid-icon-Arrow-RightinCircle:before {
    content: "\e662";
}

.solid-icon-Arrow-Shuffle:before {
    content: "\e663";
}

.solid-icon-Arrow-Squiggly:before {
    content: "\e664";
}

.solid-icon-Arrow-Through:before {
    content: "\e665";
}

.solid-icon-Arrow-To:before {
    content: "\e666";
}

.solid-icon-Arrow-TurnLeft:before {
    content: "\e667";
}

.solid-icon-Arrow-TurnRight:before {
    content: "\e668";
}

.solid-icon-Arrow-Up:before {
    content: "\e669";
}

.solid-icon-Arrow-Up2:before {
    content: "\e66a";
}

.solid-icon-Arrow-Up3:before {
    content: "\e66b";
}

.solid-icon-Arrow-UpinCircle:before {
    content: "\e66c";
}

.solid-icon-Arrow-XLeft:before {
    content: "\e66d";
}

.solid-icon-Arrow-XRight:before {
    content: "\e66e";
}

.solid-icon-Ask:before {
    content: "\e66f";
}

.solid-icon-Assistant:before {
    content: "\e670";
}

.solid-icon-Astronaut:before {
    content: "\e671";
}

.solid-icon-At-Sign:before {
    content: "\e672";
}

.solid-icon-ATM:before {
    content: "\e673";
}

.solid-icon-Atom:before {
    content: "\e674";
}

.solid-icon-Audio:before {
    content: "\e675";
}

.solid-icon-Auto-Flash:before {
    content: "\e676";
}

.solid-icon-Autumn:before {
    content: "\e677";
}

.solid-icon-Baby-Clothes:before {
    content: "\e678";
}

.solid-icon-Baby-Clothes2:before {
    content: "\e679";
}

.solid-icon-Baby-Cry:before {
    content: "\e67a";
}

.solid-icon-Baby:before {
    content: "\e67b";
}

.solid-icon-Back-Media:before {
    content: "\e67c";
}

.solid-icon-Back-Media2:before {
    content: "\e67d";
}

.solid-icon-Back-Music:before {
    content: "\e67e";
}

.solid-icon-Back:before {
    content: "\e67f";
}

.solid-icon-Background:before {
    content: "\e680";
}

.solid-icon-Bacteria:before {
    content: "\e681";
}

.solid-icon-Bag-Coins:before {
    content: "\e682";
}

.solid-icon-Bag-Items:before {
    content: "\e683";
}

.solid-icon-Bag-Quantity:before {
    content: "\e684";
}

.solid-icon-Bag:before {
    content: "\e685";
}

.solid-icon-Bakelite:before {
    content: "\e686";
}

.solid-icon-Ballet-Shoes:before {
    content: "\e687";
}

.solid-icon-Balloon:before {
    content: "\e688";
}

.solid-icon-Banana:before {
    content: "\e689";
}

.solid-icon-Band-Aid:before {
    content: "\e68a";
}

.solid-icon-Bank:before {
    content: "\e68b";
}

.solid-icon-Bar-Chart:before {
    content: "\e68c";
}

.solid-icon-Bar-Chart2:before {
    content: "\e68d";
}

.solid-icon-Bar-Chart3:before {
    content: "\e68e";
}

.solid-icon-Bar-Chart4:before {
    content: "\e68f";
}

.solid-icon-Bar-Chart5:before {
    content: "\e690";
}

.solid-icon-Bar-Code:before {
    content: "\e691";
}

.solid-icon-Barricade-2:before {
    content: "\e692";
}

.solid-icon-Barricade:before {
    content: "\e693";
}

.solid-icon-Baseball:before {
    content: "\e694";
}

.solid-icon-Basket-Ball:before {
    content: "\e695";
}

.solid-icon-Basket-Coins:before {
    content: "\e696";
}

.solid-icon-Basket-Items:before {
    content: "\e697";
}

.solid-icon-Basket-Quantity:before {
    content: "\e698";
}

.solid-icon-Bat-2:before {
    content: "\e699";
}

.solid-icon-Bat:before {
    content: "\e69a";
}

.solid-icon-Bathrobe:before {
    content: "\e69b";
}

.solid-icon-Batman-Mask:before {
    content: "\e69c";
}

.solid-icon-Battery-0:before {
    content: "\e69d";
}

.solid-icon-Battery-25:before {
    content: "\e69e";
}

.solid-icon-Battery-50:before {
    content: "\e69f";
}

.solid-icon-Battery-75:before {
    content: "\e6a0";
}

.solid-icon-Battery-100:before {
    content: "\e6a1";
}

.solid-icon-Battery-Charge:before {
    content: "\e6a2";
}

.solid-icon-Bear:before {
    content: "\e6a3";
}

.solid-icon-Beard-2:before {
    content: "\e6a4";
}

.solid-icon-Beard-3:before {
    content: "\e6a5";
}

.solid-icon-Beard:before {
    content: "\e6a6";
}

.solid-icon-Bebo:before {
    content: "\e6a7";
}

.solid-icon-Bee:before {
    content: "\e6a8";
}

.solid-icon-Beer-Glass:before {
    content: "\e6a9";
}

.solid-icon-Beer:before {
    content: "\e6aa";
}

.solid-icon-Behance:before {
    content: "\e6ab";
}

.solid-icon-Bell-2:before {
    content: "\e6ac";
}

.solid-icon-Bell:before {
    content: "\e6ad";
}

.solid-icon-Belt-2:before {
    content: "\e6ae";
}

.solid-icon-Belt-3:before {
    content: "\e6af";
}

.solid-icon-Belt:before {
    content: "\e6b0";
}

.solid-icon-Berlin-Tower:before {
    content: "\e6b1";
}

.solid-icon-Beta:before {
    content: "\e6b2";
}

.solid-icon-Betvibes:before {
    content: "\e6b3";
}

.solid-icon-Bicycle-2:before {
    content: "\e6b4";
}

.solid-icon-Bicycle-3:before {
    content: "\e6b5";
}

.solid-icon-Bicycle:before {
    content: "\e6b6";
}

.solid-icon-Big-Bang:before {
    content: "\e6b7";
}

.solid-icon-Big-Data:before {
    content: "\e6b8";
}

.solid-icon-Bike-Helmet:before {
    content: "\e6b9";
}

.solid-icon-Bikini:before {
    content: "\e6ba";
}

.solid-icon-Bilk-Bottle2:before {
    content: "\e6bb";
}

.solid-icon-Billing:before {
    content: "\e6bc";
}

.solid-icon-Bing:before {
    content: "\e6bd";
}

.solid-icon-Binocular:before {
    content: "\e6be";
}

.solid-icon-Bio-Hazard:before {
    content: "\e6bf";
}

.solid-icon-Biotech:before {
    content: "\e6c0";
}

.solid-icon-Bird-DeliveringLetter:before {
    content: "\e6c1";
}

.solid-icon-Bird:before {
    content: "\e6c2";
}

.solid-icon-Birthday-Cake:before {
    content: "\e6c3";
}

.solid-icon-Bisexual:before {
    content: "\e6c4";
}

.solid-icon-Bishop:before {
    content: "\e6c5";
}

.solid-icon-Bitcoin:before {
    content: "\e6c6";
}

.solid-icon-Black-Cat:before {
    content: "\e6c7";
}

.solid-icon-Blackboard:before {
    content: "\e6c8";
}

.solid-icon-Blinklist:before {
    content: "\e6c9";
}

.solid-icon-Block-Cloud:before {
    content: "\e6ca";
}

.solid-icon-Block-Window:before {
    content: "\e6cb";
}

.solid-icon-Blogger:before {
    content: "\e6cc";
}

.solid-icon-Blood:before {
    content: "\e6cd";
}

.solid-icon-Blouse:before {
    content: "\e6ce";
}

.solid-icon-Blueprint:before {
    content: "\e6cf";
}

.solid-icon-Board:before {
    content: "\e6d0";
}

.solid-icon-Bodybuilding:before {
    content: "\e6d1";
}

.solid-icon-Bold-Text:before {
    content: "\e6d2";
}

.solid-icon-Bone:before {
    content: "\e6d3";
}

.solid-icon-Bones:before {
    content: "\e6d4";
}

.solid-icon-Book:before {
    content: "\e6d5";
}

.solid-icon-Bookmark:before {
    content: "\e6d6";
}

.solid-icon-Books-2:before {
    content: "\e6d7";
}

.solid-icon-Books:before {
    content: "\e6d8";
}

.solid-icon-Boom:before {
    content: "\e6d9";
}

.solid-icon-Boot-2:before {
    content: "\e6da";
}

.solid-icon-Boot:before {
    content: "\e6db";
}

.solid-icon-Bottom-ToTop:before {
    content: "\e6dc";
}

.solid-icon-Bow-2:before {
    content: "\e6dd";
}

.solid-icon-Bow-3:before {
    content: "\e6de";
}

.solid-icon-Bow-4:before {
    content: "\e6df";
}

.solid-icon-Bow-5:before {
    content: "\e6e0";
}

.solid-icon-Bow-6:before {
    content: "\e6e1";
}

.solid-icon-Bow:before {
    content: "\e6e2";
}

.solid-icon-Bowling-2:before {
    content: "\e6e3";
}

.solid-icon-Bowling:before {
    content: "\e6e4";
}

.solid-icon-Box-Close:before {
    content: "\e6e5";
}

.solid-icon-Box-Full:before {
    content: "\e6e6";
}

.solid-icon-Box-Gloves:before {
    content: "\e6e7";
}

.solid-icon-Box-Open:before {
    content: "\e6e8";
}

.solid-icon-Box-withFolders:before {
    content: "\e6e9";
}

.solid-icon-Box:before {
    content: "\e6ea";
}

.solid-icon-Boy:before {
    content: "\e6eb";
}

.solid-icon-Bra:before {
    content: "\e6ec";
}

.solid-icon-Brain-2:before {
    content: "\e6ed";
}

.solid-icon-Brain-3:before {
    content: "\e6ee";
}

.solid-icon-Brain:before {
    content: "\e6ef";
}

.solid-icon-Brazil:before {
    content: "\e6f0";
}

.solid-icon-Bread-2:before {
    content: "\e6f1";
}

.solid-icon-Bread:before {
    content: "\e6f2";
}

.solid-icon-Bridge:before {
    content: "\e6f3";
}

.solid-icon-Brightkite:before {
    content: "\e6f4";
}

.solid-icon-Broke-Link2:before {
    content: "\e6f5";
}

.solid-icon-Broken-Link:before {
    content: "\e6f6";
}

.solid-icon-Broom:before {
    content: "\e6f7";
}

.solid-icon-Brush:before {
    content: "\e6f8";
}

.solid-icon-Bucket:before {
    content: "\e6f9";
}

.solid-icon-Bug:before {
    content: "\e6fa";
}

.solid-icon-Building:before {
    content: "\e6fb";
}

.solid-icon-Bulleted-List:before {
    content: "\e6fc";
}

.solid-icon-Bus-2:before {
    content: "\e6fd";
}

.solid-icon-Bus:before {
    content: "\e6fe";
}

.solid-icon-Business-Man:before {
    content: "\e6ff";
}

.solid-icon-Business-ManWoman:before {
    content: "\e700";
}

.solid-icon-Business-Mens:before {
    content: "\e701";
}

.solid-icon-Business-Woman:before {
    content: "\e702";
}

.solid-icon-Butterfly:before {
    content: "\e703";
}

.solid-icon-Button:before {
    content: "\e704";
}

.solid-icon-Cable-Car:before {
    content: "\e705";
}

.solid-icon-Cake:before {
    content: "\e706";
}

.solid-icon-Calculator-2:before {
    content: "\e707";
}

.solid-icon-Calculator-3:before {
    content: "\e708";
}

.solid-icon-Calculator:before {
    content: "\e709";
}

.solid-icon-Calendar-2:before {
    content: "\e70a";
}

.solid-icon-Calendar-3:before {
    content: "\e70b";
}

.solid-icon-Calendar-4:before {
    content: "\e70c";
}

.solid-icon-Calendar-Clock:before {
    content: "\e70d";
}

.solid-icon-Calendar:before {
    content: "\e70e";
}

.solid-icon-Camel:before {
    content: "\e70f";
}

.solid-icon-Camera-2:before {
    content: "\e710";
}

.solid-icon-Camera-3:before {
    content: "\e711";
}

.solid-icon-Camera-4:before {
    content: "\e712";
}

.solid-icon-Camera-5:before {
    content: "\e713";
}

.solid-icon-Camera-Back:before {
    content: "\e714";
}

.solid-icon-Camera:before {
    content: "\e715";
}

.solid-icon-Can-2:before {
    content: "\e716";
}

.solid-icon-Can:before {
    content: "\e717";
}

.solid-icon-Canada:before {
    content: "\e718";
}

.solid-icon-Cancer-2:before {
    content: "\e719";
}

.solid-icon-Cancer-3:before {
    content: "\e71a";
}

.solid-icon-Cancer:before {
    content: "\e71b";
}

.solid-icon-Candle:before {
    content: "\e71c";
}

.solid-icon-Candy-Cane:before {
    content: "\e71d";
}

.solid-icon-Candy:before {
    content: "\e71e";
}

.solid-icon-Cannon:before {
    content: "\e71f";
}

.solid-icon-Cap-2:before {
    content: "\e720";
}

.solid-icon-Cap-3:before {
    content: "\e721";
}

.solid-icon-Cap-Smiley:before {
    content: "\e722";
}

.solid-icon-Cap:before {
    content: "\e723";
}

.solid-icon-Capricorn-2:before {
    content: "\e724";
}

.solid-icon-Capricorn:before {
    content: "\e725";
}

.solid-icon-Car-2:before {
    content: "\e726";
}

.solid-icon-Car-3:before {
    content: "\e727";
}

.solid-icon-Car-Coins:before {
    content: "\e728";
}

.solid-icon-Car-Items:before {
    content: "\e729";
}

.solid-icon-Car-Wheel:before {
    content: "\e72a";
}

.solid-icon-Car:before {
    content: "\e72b";
}

.solid-icon-Cardigan:before {
    content: "\e72c";
}

.solid-icon-Cardiovascular:before {
    content: "\e72d";
}

.solid-icon-Cart-Quantity:before {
    content: "\e72e";
}

.solid-icon-Casette-Tape:before {
    content: "\e72f";
}

.solid-icon-Cash-Register:before {
    content: "\e730";
}

.solid-icon-Cash-register2:before {
    content: "\e731";
}

.solid-icon-Castle:before {
    content: "\e732";
}

.solid-icon-Cat:before {
    content: "\e733";
}

.solid-icon-Cathedral:before {
    content: "\e734";
}

.solid-icon-Cauldron:before {
    content: "\e735";
}

.solid-icon-CD-2:before {
    content: "\e736";
}

.solid-icon-CD-Cover:before {
    content: "\e737";
}

.solid-icon-CD:before {
    content: "\e738";
}

.solid-icon-Cello:before {
    content: "\e739";
}

.solid-icon-Celsius:before {
    content: "\e73a";
}

.solid-icon-Chacked-Flag:before {
    content: "\e73b";
}

.solid-icon-Chair:before {
    content: "\e73c";
}

.solid-icon-Charger:before {
    content: "\e73d";
}

.solid-icon-Check-2:before {
    content: "\e73e";
}

.solid-icon-Check:before {
    content: "\e73f";
}

.solid-icon-Checked-User:before {
    content: "\e740";
}

.solid-icon-Checkmate:before {
    content: "\e741";
}

.solid-icon-Checkout-Bag:before {
    content: "\e742";
}

.solid-icon-Checkout-Basket:before {
    content: "\e743";
}

.solid-icon-Checkout:before {
    content: "\e744";
}

.solid-icon-Cheese:before {
    content: "\e745";
}

.solid-icon-Cheetah:before {
    content: "\e746";
}

.solid-icon-Chef-Hat:before {
    content: "\e747";
}

.solid-icon-Chef-Hat2:before {
    content: "\e748";
}

.solid-icon-Chef:before {
    content: "\e749";
}

.solid-icon-Chemical-2:before {
    content: "\e74a";
}

.solid-icon-Chemical-3:before {
    content: "\e74b";
}

.solid-icon-Chemical-4:before {
    content: "\e74c";
}

.solid-icon-Chemical-5:before {
    content: "\e74d";
}

.solid-icon-Chemical:before {
    content: "\e74e";
}

.solid-icon-Chess-Board:before {
    content: "\e74f";
}

.solid-icon-Chess:before {
    content: "\e750";
}

.solid-icon-Chicken:before {
    content: "\e751";
}

.solid-icon-Chile:before {
    content: "\e752";
}

.solid-icon-Chimney:before {
    content: "\e753";
}

.solid-icon-China:before {
    content: "\e754";
}

.solid-icon-Chinese-Temple:before {
    content: "\e755";
}

.solid-icon-Chip:before {
    content: "\e756";
}

.solid-icon-Chopsticks-2:before {
    content: "\e757";
}

.solid-icon-Chopsticks:before {
    content: "\e758";
}

.solid-icon-Christmas-Ball:before {
    content: "\e759";
}

.solid-icon-Christmas-Bell:before {
    content: "\e75a";
}

.solid-icon-Christmas-Candle:before {
    content: "\e75b";
}

.solid-icon-Christmas-Hat:before {
    content: "\e75c";
}

.solid-icon-Christmas-Sleigh:before {
    content: "\e75d";
}

.solid-icon-Christmas-Snowman:before {
    content: "\e75e";
}

.solid-icon-Christmas-Sock:before {
    content: "\e75f";
}

.solid-icon-Christmas-Tree:before {
    content: "\e760";
}

.solid-icon-Christmas:before {
    content: "\e761";
}

.solid-icon-Chrome:before {
    content: "\e762";
}

.solid-icon-Chrysler-Building:before {
    content: "\e763";
}

.solid-icon-Cinema:before {
    content: "\e764";
}

.solid-icon-Circular-Point:before {
    content: "\e765";
}

.solid-icon-City-Hall:before {
    content: "\e766";
}

.solid-icon-Clamp:before {
    content: "\e767";
}

.solid-icon-Clapperboard-Close:before {
    content: "\e768";
}

.solid-icon-Clapperboard-Open:before {
    content: "\e769";
}

.solid-icon-Claps:before {
    content: "\e76a";
}

.solid-icon-Clef:before {
    content: "\e76b";
}

.solid-icon-Clinic:before {
    content: "\e76c";
}

.solid-icon-Clock-2:before {
    content: "\e76d";
}

.solid-icon-Clock-3:before {
    content: "\e76e";
}

.solid-icon-Clock-4:before {
    content: "\e76f";
}

.solid-icon-Clock-Back:before {
    content: "\e770";
}

.solid-icon-Clock-Forward:before {
    content: "\e771";
}

.solid-icon-Clock:before {
    content: "\e772";
}

.solid-icon-Close-Window:before {
    content: "\e773";
}

.solid-icon-Close:before {
    content: "\e774";
}

.solid-icon-Clothing-Store:before {
    content: "\e775";
}

.solid-icon-Cloud--:before {
    content: "\e776";
}

.solid-icon-Cloud-:before {
    content: "\e777";
}

.solid-icon-Cloud-Camera:before {
    content: "\e778";
}

.solid-icon-Cloud-Computer:before {
    content: "\e779";
}

.solid-icon-Cloud-Email:before {
    content: "\e77a";
}

.solid-icon-Cloud-Hail:before {
    content: "\e77b";
}

.solid-icon-Cloud-Laptop:before {
    content: "\e77c";
}

.solid-icon-Cloud-Lock:before {
    content: "\e77d";
}

.solid-icon-Cloud-Moon:before {
    content: "\e77e";
}

.solid-icon-Cloud-Music:before {
    content: "\e77f";
}

.solid-icon-Cloud-Picture:before {
    content: "\e780";
}

.solid-icon-Cloud-Rain:before {
    content: "\e781";
}

.solid-icon-Cloud-Remove:before {
    content: "\e782";
}

.solid-icon-Cloud-Secure:before {
    content: "\e783";
}

.solid-icon-Cloud-Settings:before {
    content: "\e784";
}

.solid-icon-Cloud-Smartphone:before {
    content: "\e785";
}

.solid-icon-Cloud-Snow:before {
    content: "\e786";
}

.solid-icon-Cloud-Sun:before {
    content: "\e787";
}

.solid-icon-Cloud-Tablet:before {
    content: "\e788";
}

.solid-icon-Cloud-Video:before {
    content: "\e789";
}

.solid-icon-Cloud-Weather:before {
    content: "\e78a";
}

.solid-icon-Cloud:before {
    content: "\e78b";
}

.solid-icon-Clouds-Weather:before {
    content: "\e78c";
}

.solid-icon-Clouds:before {
    content: "\e78d";
}

.solid-icon-Clown:before {
    content: "\e78e";
}

.solid-icon-CMYK:before {
    content: "\e78f";
}

.solid-icon-Coat:before {
    content: "\e790";
}

.solid-icon-Cocktail:before {
    content: "\e791";
}

.solid-icon-Coconut:before {
    content: "\e792";
}

.solid-icon-Code-Window:before {
    content: "\e793";
}

.solid-icon-Coding:before {
    content: "\e794";
}

.solid-icon-Coffee-2:before {
    content: "\e795";
}

.solid-icon-Coffee-Bean:before {
    content: "\e796";
}

.solid-icon-Coffee-Machine:before {
    content: "\e797";
}

.solid-icon-Coffee-toGo:before {
    content: "\e798";
}

.solid-icon-Coffee:before {
    content: "\e799";
}

.solid-icon-Coffin:before {
    content: "\e79a";
}

.solid-icon-Coin:before {
    content: "\e79b";
}

.solid-icon-Coins-2:before {
    content: "\e79c";
}

.solid-icon-Coins-3:before {
    content: "\e79d";
}

.solid-icon-Coins:before {
    content: "\e79e";
}

.solid-icon-Colombia:before {
    content: "\e79f";
}

.solid-icon-Colosseum:before {
    content: "\e7a0";
}

.solid-icon-Column-2:before {
    content: "\e7a1";
}

.solid-icon-Column-3:before {
    content: "\e7a2";
}

.solid-icon-Column:before {
    content: "\e7a3";
}

.solid-icon-Comb-2:before {
    content: "\e7a4";
}

.solid-icon-Comb:before {
    content: "\e7a5";
}

.solid-icon-Communication-Tower:before {
    content: "\e7a6";
}

.solid-icon-Communication-Tower2:before {
    content: "\e7a7";
}

.solid-icon-Compass-2:before {
    content: "\e7a8";
}

.solid-icon-Compass-3:before {
    content: "\e7a9";
}

.solid-icon-Compass-4:before {
    content: "\e7aa";
}

.solid-icon-Compass-Rose:before {
    content: "\e7ab";
}

.solid-icon-Compass:before {
    content: "\e7ac";
}

.solid-icon-Computer-2:before {
    content: "\e7ad";
}

.solid-icon-Computer-3:before {
    content: "\e7ae";
}

.solid-icon-Computer-Secure:before {
    content: "\e7af";
}

.solid-icon-Computer:before {
    content: "\e7b0";
}

.solid-icon-Conference:before {
    content: "\e7b1";
}

.solid-icon-Confused:before {
    content: "\e7b2";
}

.solid-icon-Conservation:before {
    content: "\e7b3";
}

.solid-icon-Consulting:before {
    content: "\e7b4";
}

.solid-icon-Contrast:before {
    content: "\e7b5";
}

.solid-icon-Control-2:before {
    content: "\e7b6";
}

.solid-icon-Control:before {
    content: "\e7b7";
}

.solid-icon-Cookie-Man:before {
    content: "\e7b8";
}

.solid-icon-Cookies:before {
    content: "\e7b9";
}

.solid-icon-Cool-Guy:before {
    content: "\e7ba";
}

.solid-icon-Cool:before {
    content: "\e7bb";
}

.solid-icon-Copyright:before {
    content: "\e7bc";
}

.solid-icon-Costume:before {
    content: "\e7bd";
}

.solid-icon-Couple-Sign:before {
    content: "\e7be";
}

.solid-icon-Cow:before {
    content: "\e7bf";
}

.solid-icon-CPU:before {
    content: "\e7c0";
}

.solid-icon-Crane:before {
    content: "\e7c1";
}

.solid-icon-Cranium:before {
    content: "\e7c2";
}

.solid-icon-Credit-Card:before {
    content: "\e7c3";
}

.solid-icon-Credit-Card2:before {
    content: "\e7c4";
}

.solid-icon-Credit-Card3:before {
    content: "\e7c5";
}

.solid-icon-Cricket:before {
    content: "\e7c6";
}

.solid-icon-Criminal:before {
    content: "\e7c7";
}

.solid-icon-Croissant:before {
    content: "\e7c8";
}

.solid-icon-Crop-2:before {
    content: "\e7c9";
}

.solid-icon-Crop-3:before {
    content: "\e7ca";
}

.solid-icon-Crown-2:before {
    content: "\e7cb";
}

.solid-icon-Crown:before {
    content: "\e7cc";
}

.solid-icon-Crying:before {
    content: "\e7cd";
}

.solid-icon-Cube-Molecule:before {
    content: "\e7ce";
}

.solid-icon-Cube-Molecule2:before {
    content: "\e7cf";
}

.solid-icon-Cupcake:before {
    content: "\e7d0";
}

.solid-icon-Cursor-Click:before {
    content: "\e7d1";
}

.solid-icon-Cursor-Click2:before {
    content: "\e7d2";
}

.solid-icon-Cursor-Move:before {
    content: "\e7d3";
}

.solid-icon-Cursor-Move2:before {
    content: "\e7d4";
}

.solid-icon-Cursor-Select:before {
    content: "\e7d5";
}

.solid-icon-Cursor:before {
    content: "\e7d6";
}

.solid-icon-D-Eyeglasses:before {
    content: "\e7d7";
}

.solid-icon-D-Eyeglasses2:before {
    content: "\e7d8";
}

.solid-icon-Dam:before {
    content: "\e7d9";
}

.solid-icon-Danemark:before {
    content: "\e7da";
}

.solid-icon-Danger-2:before {
    content: "\e7db";
}

.solid-icon-Danger:before {
    content: "\e7dc";
}

.solid-icon-Dashboard:before {
    content: "\e7dd";
}

.solid-icon-Data-Backup:before {
    content: "\e7de";
}

.solid-icon-Data-Block:before {
    content: "\e7df";
}

.solid-icon-Data-Center:before {
    content: "\e7e0";
}

.solid-icon-Data-Clock:before {
    content: "\e7e1";
}

.solid-icon-Data-Cloud:before {
    content: "\e7e2";
}

.solid-icon-Data-Compress:before {
    content: "\e7e3";
}

.solid-icon-Data-Copy:before {
    content: "\e7e4";
}

.solid-icon-Data-Download:before {
    content: "\e7e5";
}

.solid-icon-Data-Financial:before {
    content: "\e7e6";
}

.solid-icon-Data-Key:before {
    content: "\e7e7";
}

.solid-icon-Data-Lock:before {
    content: "\e7e8";
}

.solid-icon-Data-Network:before {
    content: "\e7e9";
}

.solid-icon-Data-Password:before {
    content: "\e7ea";
}

.solid-icon-Data-Power:before {
    content: "\e7eb";
}

.solid-icon-Data-Refresh:before {
    content: "\e7ec";
}

.solid-icon-Data-Save:before {
    content: "\e7ed";
}

.solid-icon-Data-Search:before {
    content: "\e7ee";
}

.solid-icon-Data-Security:before {
    content: "\e7ef";
}

.solid-icon-Data-Settings:before {
    content: "\e7f0";
}

.solid-icon-Data-Sharing:before {
    content: "\e7f1";
}

.solid-icon-Data-Shield:before {
    content: "\e7f2";
}

.solid-icon-Data-Signal:before {
    content: "\e7f3";
}

.solid-icon-Data-Storage:before {
    content: "\e7f4";
}

.solid-icon-Data-Stream:before {
    content: "\e7f5";
}

.solid-icon-Data-Transfer:before {
    content: "\e7f6";
}

.solid-icon-Data-Unlock:before {
    content: "\e7f7";
}

.solid-icon-Data-Upload:before {
    content: "\e7f8";
}

.solid-icon-Data-Yes:before {
    content: "\e7f9";
}

.solid-icon-Data:before {
    content: "\e7fa";
}

.solid-icon-David-Star:before {
    content: "\e7fb";
}

.solid-icon-Daylight:before {
    content: "\e7fc";
}

.solid-icon-Death:before {
    content: "\e7fd";
}

.solid-icon-Debian:before {
    content: "\e7fe";
}

.solid-icon-Dec:before {
    content: "\e7ff";
}

.solid-icon-Decrase-Inedit:before {
    content: "\e800";
}

.solid-icon-Deer-2:before {
    content: "\e801";
}

.solid-icon-Deer:before {
    content: "\e802";
}

.solid-icon-Delete-File:before {
    content: "\e803";
}

.solid-icon-Delete-Window:before {
    content: "\e804";
}

.solid-icon-Delicious:before {
    content: "\e805";
}

.solid-icon-Depression:before {
    content: "\e806";
}

.solid-icon-Deviantart:before {
    content: "\e807";
}

.solid-icon-Device-SyncwithCloud:before {
    content: "\e808";
}

.solid-icon-Diamond:before {
    content: "\e809";
}

.solid-icon-Dice-2:before {
    content: "\e80a";
}

.solid-icon-Dice:before {
    content: "\e80b";
}

.solid-icon-Digg:before {
    content: "\e80c";
}

.solid-icon-Digital-Drawing:before {
    content: "\e80d";
}

.solid-icon-Diigo:before {
    content: "\e80e";
}

.solid-icon-Dinosaur:before {
    content: "\e80f";
}

.solid-icon-Diploma-2:before {
    content: "\e810";
}

.solid-icon-Diploma:before {
    content: "\e811";
}

.solid-icon-Direction-East:before {
    content: "\e812";
}

.solid-icon-Direction-North:before {
    content: "\e813";
}

.solid-icon-Direction-South:before {
    content: "\e814";
}

.solid-icon-Direction-West:before {
    content: "\e815";
}

.solid-icon-Director:before {
    content: "\e816";
}

.solid-icon-Disk:before {
    content: "\e817";
}

.solid-icon-Dj:before {
    content: "\e818";
}

.solid-icon-DNA-2:before {
    content: "\e819";
}

.solid-icon-DNA-Helix:before {
    content: "\e81a";
}

.solid-icon-DNA:before {
    content: "\e81b";
}

.solid-icon-Doctor:before {
    content: "\e81c";
}

.solid-icon-Dog:before {
    content: "\e81d";
}

.solid-icon-Dollar-Sign:before {
    content: "\e81e";
}

.solid-icon-Dollar-Sign2:before {
    content: "\e81f";
}

.solid-icon-Dollar:before {
    content: "\e820";
}

.solid-icon-Dolphin:before {
    content: "\e821";
}

.solid-icon-Domino:before {
    content: "\e822";
}

.solid-icon-Door-Hanger:before {
    content: "\e823";
}

.solid-icon-Door:before {
    content: "\e824";
}

.solid-icon-Doplr:before {
    content: "\e825";
}

.solid-icon-Double-Circle:before {
    content: "\e826";
}

.solid-icon-Double-Tap:before {
    content: "\e827";
}

.solid-icon-Doughnut:before {
    content: "\e828";
}

.solid-icon-Dove:before {
    content: "\e829";
}

.solid-icon-Down-2:before {
    content: "\e82a";
}

.solid-icon-Down-3:before {
    content: "\e82b";
}

.solid-icon-Down-4:before {
    content: "\e82c";
}

.solid-icon-Down:before {
    content: "\e82d";
}

.solid-icon-Download-2:before {
    content: "\e82e";
}

.solid-icon-Download-fromCloud:before {
    content: "\e82f";
}

.solid-icon-Download-Window:before {
    content: "\e830";
}

.solid-icon-Download:before {
    content: "\e831";
}

.solid-icon-Downward:before {
    content: "\e832";
}

.solid-icon-Drag-Down:before {
    content: "\e833";
}

.solid-icon-Drag-Left:before {
    content: "\e834";
}

.solid-icon-Drag-Right:before {
    content: "\e835";
}

.solid-icon-Drag-Up:before {
    content: "\e836";
}

.solid-icon-Drag:before {
    content: "\e837";
}

.solid-icon-Dress:before {
    content: "\e838";
}

.solid-icon-Dribble:before {
    content: "\e839";
}

.solid-icon-Drill-2:before {
    content: "\e83a";
}

.solid-icon-Drill:before {
    content: "\e83b";
}

.solid-icon-Drop:before {
    content: "\e83c";
}

.solid-icon-Dropbox:before {
    content: "\e83d";
}

.solid-icon-Drum:before {
    content: "\e83e";
}

.solid-icon-Dry:before {
    content: "\e83f";
}

.solid-icon-Duck:before {
    content: "\e840";
}

.solid-icon-Dumbbell:before {
    content: "\e841";
}

.solid-icon-Duplicate-Layer:before {
    content: "\e842";
}

.solid-icon-Duplicate-Window:before {
    content: "\e843";
}

.solid-icon-DVD:before {
    content: "\e844";
}

.solid-icon-Eagle:before {
    content: "\e845";
}

.solid-icon-Ear:before {
    content: "\e846";
}

.solid-icon-Earphones-2:before {
    content: "\e847";
}

.solid-icon-Earphones:before {
    content: "\e848";
}

.solid-icon-Eci-Icon:before {
    content: "\e849";
}

.solid-icon-Edit-Map:before {
    content: "\e84a";
}

.solid-icon-Edit:before {
    content: "\e84b";
}

.solid-icon-Eggs:before {
    content: "\e84c";
}

.solid-icon-Egypt:before {
    content: "\e84d";
}

.solid-icon-Eifel-Tower:before {
    content: "\e84e";
}

.solid-icon-Eject-2:before {
    content: "\e84f";
}

.solid-icon-Eject:before {
    content: "\e850";
}

.solid-icon-El-Castillo:before {
    content: "\e851";
}

.solid-icon-Elbow:before {
    content: "\e852";
}

.solid-icon-Electric-Guitar:before {
    content: "\e853";
}

.solid-icon-Electricity:before {
    content: "\e854";
}

.solid-icon-Elephant:before {
    content: "\e855";
}

.solid-icon-Email:before {
    content: "\e856";
}

.solid-icon-Embassy:before {
    content: "\e857";
}

.solid-icon-Empire-StateBuilding:before {
    content: "\e858";
}

.solid-icon-Empty-Box:before {
    content: "\e859";
}

.solid-icon-End2:before {
    content: "\e85a";
}

.solid-icon-End-2:before {
    content: "\e85b";
}

.solid-icon-End:before {
    content: "\e85c";
}

.solid-icon-Endways:before {
    content: "\e85d";
}

.solid-icon-Engineering:before {
    content: "\e85e";
}

.solid-icon-Envelope-2:before {
    content: "\e85f";
}

.solid-icon-Envelope:before {
    content: "\e860";
}

.solid-icon-Environmental-2:before {
    content: "\e861";
}

.solid-icon-Environmental-3:before {
    content: "\e862";
}

.solid-icon-Environmental:before {
    content: "\e863";
}

.solid-icon-Equalizer:before {
    content: "\e864";
}

.solid-icon-Eraser-2:before {
    content: "\e865";
}

.solid-icon-Eraser-3:before {
    content: "\e866";
}

.solid-icon-Eraser:before {
    content: "\e867";
}

.solid-icon-Error-404Window:before {
    content: "\e868";
}

.solid-icon-Euro-Sign:before {
    content: "\e869";
}

.solid-icon-Euro-Sign2:before {
    content: "\e86a";
}

.solid-icon-Euro:before {
    content: "\e86b";
}

.solid-icon-Evernote:before {
    content: "\e86c";
}

.solid-icon-Evil:before {
    content: "\e86d";
}

.solid-icon-Explode:before {
    content: "\e86e";
}

.solid-icon-Eye-2:before {
    content: "\e86f";
}

.solid-icon-Eye-Blind:before {
    content: "\e870";
}

.solid-icon-Eye-Invisible:before {
    content: "\e871";
}

.solid-icon-Eye-Scan:before {
    content: "\e872";
}

.solid-icon-Eye-Visible:before {
    content: "\e873";
}

.solid-icon-Eye:before {
    content: "\e874";
}

.solid-icon-Eyebrow-2:before {
    content: "\e875";
}

.solid-icon-Eyebrow-3:before {
    content: "\e876";
}

.solid-icon-Eyebrow:before {
    content: "\e877";
}

.solid-icon-Eyeglasses-Smiley:before {
    content: "\e878";
}

.solid-icon-Eyeglasses-Smiley2:before {
    content: "\e879";
}

.solid-icon-Face-Style:before {
    content: "\e87a";
}

.solid-icon-Face-Style2:before {
    content: "\e87b";
}

.solid-icon-Face-Style3:before {
    content: "\e87c";
}

.solid-icon-Face-Style4:before {
    content: "\e87d";
}

.solid-icon-Face-Style5:before {
    content: "\e87e";
}

.solid-icon-Face-Style6:before {
    content: "\e87f";
}

.solid-icon-Facebook-2:before {
    content: "\e880";
}

.solid-icon-Facebook:before {
    content: "\e881";
}

.solid-icon-Factory-2:before {
    content: "\e882";
}

.solid-icon-Factory:before {
    content: "\e883";
}

.solid-icon-Fahrenheit:before {
    content: "\e884";
}

.solid-icon-Family-Sign:before {
    content: "\e885";
}

.solid-icon-Fan:before {
    content: "\e886";
}

.solid-icon-Farmer:before {
    content: "\e887";
}

.solid-icon-Fashion:before {
    content: "\e888";
}

.solid-icon-Favorite-Window:before {
    content: "\e889";
}

.solid-icon-Fax:before {
    content: "\e88a";
}

.solid-icon-Feather:before {
    content: "\e88b";
}

.solid-icon-Feedburner:before {
    content: "\e88c";
}

.solid-icon-Female-2:before {
    content: "\e88d";
}

.solid-icon-Female-Sign:before {
    content: "\e88e";
}

.solid-icon-Female:before {
    content: "\e88f";
}

.solid-icon-File-Block:before {
    content: "\e890";
}

.solid-icon-File-Bookmark:before {
    content: "\e891";
}

.solid-icon-File-Chart:before {
    content: "\e892";
}

.solid-icon-File-Clipboard:before {
    content: "\e893";
}

.solid-icon-File-ClipboardFileText:before {
    content: "\e894";
}

.solid-icon-File-ClipboardTextImage:before {
    content: "\e895";
}

.solid-icon-File-Cloud:before {
    content: "\e896";
}

.solid-icon-File-Copy:before {
    content: "\e897";
}

.solid-icon-File-Copy2:before {
    content: "\e898";
}

.solid-icon-File-CSV:before {
    content: "\e899";
}

.solid-icon-File-Download:before {
    content: "\e89a";
}

.solid-icon-File-Edit:before {
    content: "\e89b";
}

.solid-icon-File-Excel:before {
    content: "\e89c";
}

.solid-icon-File-Favorite:before {
    content: "\e89d";
}

.solid-icon-File-Fire:before {
    content: "\e89e";
}

.solid-icon-File-Graph:before {
    content: "\e89f";
}

.solid-icon-File-Hide:before {
    content: "\e8a0";
}

.solid-icon-File-Horizontal:before {
    content: "\e8a1";
}

.solid-icon-File-HorizontalText:before {
    content: "\e8a2";
}

.solid-icon-File-HTML:before {
    content: "\e8a3";
}

.solid-icon-File-JPG:before {
    content: "\e8a4";
}

.solid-icon-File-Link:before {
    content: "\e8a5";
}

.solid-icon-File-Loading:before {
    content: "\e8a6";
}

.solid-icon-File-Lock:before {
    content: "\e8a7";
}

.solid-icon-File-Love:before {
    content: "\e8a8";
}

.solid-icon-File-Music:before {
    content: "\e8a9";
}

.solid-icon-File-Network:before {
    content: "\e8aa";
}

.solid-icon-File-Pictures:before {
    content: "\e8ab";
}

.solid-icon-File-Pie:before {
    content: "\e8ac";
}

.solid-icon-File-Presentation:before {
    content: "\e8ad";
}

.solid-icon-File-Refresh:before {
    content: "\e8ae";
}

.solid-icon-File-Search:before {
    content: "\e8af";
}

.solid-icon-File-Settings:before {
    content: "\e8b0";
}

.solid-icon-File-Share:before {
    content: "\e8b1";
}

.solid-icon-File-TextImage:before {
    content: "\e8b2";
}

.solid-icon-File-Trash:before {
    content: "\e8b3";
}

.solid-icon-File-TXT:before {
    content: "\e8b4";
}

.solid-icon-File-Upload:before {
    content: "\e8b5";
}

.solid-icon-File-Video:before {
    content: "\e8b6";
}

.solid-icon-File-Word:before {
    content: "\e8b7";
}

.solid-icon-File-Zip:before {
    content: "\e8b8";
}

.solid-icon-File:before {
    content: "\e8b9";
}

.solid-icon-Files:before {
    content: "\e8ba";
}

.solid-icon-Film-Board:before {
    content: "\e8bb";
}

.solid-icon-Film-Cartridge:before {
    content: "\e8bc";
}

.solid-icon-Film-Strip:before {
    content: "\e8bd";
}

.solid-icon-Film-Video:before {
    content: "\e8be";
}

.solid-icon-Film:before {
    content: "\e8bf";
}

.solid-icon-Filter-2:before {
    content: "\e8c0";
}

.solid-icon-Filter:before {
    content: "\e8c1";
}

.solid-icon-Financial:before {
    content: "\e8c2";
}

.solid-icon-Find-User:before {
    content: "\e8c3";
}

.solid-icon-Finger-DragFourSides:before {
    content: "\e8c4";
}

.solid-icon-Finger-DragTwoSides:before {
    content: "\e8c5";
}

.solid-icon-Finger-Print:before {
    content: "\e8c6";
}

.solid-icon-Finger:before {
    content: "\e8c7";
}

.solid-icon-Fingerprint-2:before {
    content: "\e8c8";
}

.solid-icon-Fingerprint:before {
    content: "\e8c9";
}

.solid-icon-Fire-Flame:before {
    content: "\e8ca";
}

.solid-icon-Fire-Flame2:before {
    content: "\e8cb";
}

.solid-icon-Fire-Hydrant:before {
    content: "\e8cc";
}

.solid-icon-Fire-Staion:before {
    content: "\e8cd";
}

.solid-icon-Firefox:before {
    content: "\e8ce";
}

.solid-icon-Firewall:before {
    content: "\e8cf";
}

.solid-icon-First-Aid:before {
    content: "\e8d0";
}

.solid-icon-First:before {
    content: "\e8d1";
}

.solid-icon-Fish-Food:before {
    content: "\e8d2";
}

.solid-icon-Fish:before {
    content: "\e8d3";
}

.solid-icon-Fit-To:before {
    content: "\e8d4";
}

.solid-icon-Fit-To2:before {
    content: "\e8d5";
}

.solid-icon-Five-Fingers:before {
    content: "\e8d6";
}

.solid-icon-Five-FingersDrag:before {
    content: "\e8d7";
}

.solid-icon-Five-FingersDrag2:before {
    content: "\e8d8";
}

.solid-icon-Five-FingersTouch:before {
    content: "\e8d9";
}

.solid-icon-Flag-2:before {
    content: "\e8da";
}

.solid-icon-Flag-3:before {
    content: "\e8db";
}

.solid-icon-Flag-4:before {
    content: "\e8dc";
}

.solid-icon-Flag-5:before {
    content: "\e8dd";
}

.solid-icon-Flag-6:before {
    content: "\e8de";
}

.solid-icon-Flag:before {
    content: "\e8df";
}

.solid-icon-Flamingo:before {
    content: "\e8e0";
}

.solid-icon-Flash-2:before {
    content: "\e8e1";
}

.solid-icon-Flash-Video:before {
    content: "\e8e2";
}

.solid-icon-Flash:before {
    content: "\e8e3";
}

.solid-icon-Flashlight:before {
    content: "\e8e4";
}

.solid-icon-Flask-2:before {
    content: "\e8e5";
}

.solid-icon-Flask:before {
    content: "\e8e6";
}

.solid-icon-Flick:before {
    content: "\e8e7";
}

.solid-icon-Flickr:before {
    content: "\e8e8";
}

.solid-icon-Flowerpot:before {
    content: "\e8e9";
}

.solid-icon-Fluorescent:before {
    content: "\e8ea";
}

.solid-icon-Fog-Day:before {
    content: "\e8eb";
}

.solid-icon-Fog-Night:before {
    content: "\e8ec";
}

.solid-icon-Folder-Add:before {
    content: "\e8ed";
}

.solid-icon-Folder-Archive:before {
    content: "\e8ee";
}

.solid-icon-Folder-Binder:before {
    content: "\e8ef";
}

.solid-icon-Folder-Binder2:before {
    content: "\e8f0";
}

.solid-icon-Folder-Block:before {
    content: "\e8f1";
}

.solid-icon-Folder-Bookmark:before {
    content: "\e8f2";
}

.solid-icon-Folder-Close:before {
    content: "\e8f3";
}

.solid-icon-Folder-Cloud:before {
    content: "\e8f4";
}

.solid-icon-Folder-Delete:before {
    content: "\e8f5";
}

.solid-icon-Folder-Download:before {
    content: "\e8f6";
}

.solid-icon-Folder-Edit:before {
    content: "\e8f7";
}

.solid-icon-Folder-Favorite:before {
    content: "\e8f8";
}

.solid-icon-Folder-Fire:before {
    content: "\e8f9";
}

.solid-icon-Folder-Hide:before {
    content: "\e8fa";
}

.solid-icon-Folder-Link:before {
    content: "\e8fb";
}

.solid-icon-Folder-Loading:before {
    content: "\e8fc";
}

.solid-icon-Folder-Lock:before {
    content: "\e8fd";
}

.solid-icon-Folder-Love:before {
    content: "\e8fe";
}

.solid-icon-Folder-Music:before {
    content: "\e8ff";
}

.solid-icon-Folder-Network:before {
    content: "\e900";
}

.solid-icon-Folder-Open:before {
    content: "\e901";
}

.solid-icon-Folder-Open2:before {
    content: "\e902";
}

.solid-icon-Folder-Organizing:before {
    content: "\e903";
}

.solid-icon-Folder-Pictures:before {
    content: "\e904";
}

.solid-icon-Folder-Refresh:before {
    content: "\e905";
}

.solid-icon-Folder-Remove-:before {
    content: "\e906";
}

.solid-icon-Folder-Search:before {
    content: "\e907";
}

.solid-icon-Folder-Settings:before {
    content: "\e908";
}

.solid-icon-Folder-Share:before {
    content: "\e909";
}

.solid-icon-Folder-Trash:before {
    content: "\e90a";
}

.solid-icon-Folder-Upload:before {
    content: "\e90b";
}

.solid-icon-Folder-Video:before {
    content: "\e90c";
}

.solid-icon-Folder-WithDocument:before {
    content: "\e90d";
}

.solid-icon-Folder-Zip:before {
    content: "\e90e";
}

.solid-icon-Folder:before {
    content: "\e90f";
}

.solid-icon-Folders:before {
    content: "\e910";
}

.solid-icon-Font-Color:before {
    content: "\e911";
}

.solid-icon-Font-Name:before {
    content: "\e912";
}

.solid-icon-Font-Size:before {
    content: "\e913";
}

.solid-icon-Font-Style:before {
    content: "\e914";
}

.solid-icon-Font-StyleSubscript:before {
    content: "\e915";
}

.solid-icon-Font-StyleSuperscript:before {
    content: "\e916";
}

.solid-icon-Font-Window:before {
    content: "\e917";
}

.solid-icon-Foot-2:before {
    content: "\e918";
}

.solid-icon-Foot:before {
    content: "\e919";
}

.solid-icon-Football-2:before {
    content: "\e91a";
}

.solid-icon-Football:before {
    content: "\e91b";
}

.solid-icon-Footprint-2:before {
    content: "\e91c";
}

.solid-icon-Footprint-3:before {
    content: "\e91d";
}

.solid-icon-Footprint:before {
    content: "\e91e";
}

.solid-icon-Forest:before {
    content: "\e91f";
}

.solid-icon-Fork:before {
    content: "\e920";
}

.solid-icon-Formspring:before {
    content: "\e921";
}

.solid-icon-Formula:before {
    content: "\e922";
}

.solid-icon-Forsquare:before {
    content: "\e923";
}

.solid-icon-Forward:before {
    content: "\e924";
}

.solid-icon-Fountain-Pen:before {
    content: "\e925";
}

.solid-icon-Four-Fingers:before {
    content: "\e926";
}

.solid-icon-Four-FingersDrag:before {
    content: "\e927";
}

.solid-icon-Four-FingersDrag2:before {
    content: "\e928";
}

.solid-icon-Four-FingersTouch:before {
    content: "\e929";
}

.solid-icon-Fox:before {
    content: "\e92a";
}

.solid-icon-Frankenstein:before {
    content: "\e92b";
}

.solid-icon-French-Fries:before {
    content: "\e92c";
}

.solid-icon-Friendfeed:before {
    content: "\e92d";
}

.solid-icon-Friendster:before {
    content: "\e92e";
}

.solid-icon-Frog:before {
    content: "\e92f";
}

.solid-icon-Fruits:before {
    content: "\e930";
}

.solid-icon-Fuel:before {
    content: "\e931";
}

.solid-icon-Full-Basket:before {
    content: "\e932";
}

.solid-icon-Full-Cart:before {
    content: "\e933";
}

.solid-icon-Full-Moon:before {
    content: "\e934";
}

.solid-icon-Full-Screen:before {
    content: "\e935";
}

.solid-icon-Full-Screen2:before {
    content: "\e936";
}

.solid-icon-Full-View:before {
    content: "\e937";
}

.solid-icon-Full-View2:before {
    content: "\e938";
}

.solid-icon-Full-ViewWindow:before {
    content: "\e939";
}

.solid-icon-Function:before {
    content: "\e93a";
}

.solid-icon-Funky:before {
    content: "\e93b";
}

.solid-icon-Funny-Bicycle:before {
    content: "\e93c";
}

.solid-icon-Furl:before {
    content: "\e93d";
}

.solid-icon-Fyll-Bag:before {
    content: "\e93e";
}

.solid-icon-Gamepad-2:before {
    content: "\e93f";
}

.solid-icon-Gamepad:before {
    content: "\e940";
}

.solid-icon-Gas-Pump:before {
    content: "\e941";
}

.solid-icon-Gaugage-2:before {
    content: "\e942";
}

.solid-icon-Gaugage:before {
    content: "\e943";
}

.solid-icon-Gay:before {
    content: "\e944";
}

.solid-icon-Gear-2:before {
    content: "\e945";
}

.solid-icon-Gear:before {
    content: "\e946";
}

.solid-icon-Gears-2:before {
    content: "\e947";
}

.solid-icon-Gears:before {
    content: "\e948";
}

.solid-icon-Geek-2:before {
    content: "\e949";
}

.solid-icon-Geek:before {
    content: "\e94a";
}

.solid-icon-Gemini-2:before {
    content: "\e94b";
}

.solid-icon-Gemini:before {
    content: "\e94c";
}

.solid-icon-Genius:before {
    content: "\e94d";
}

.solid-icon-Gentleman:before {
    content: "\e94e";
}

.solid-icon-Geo--:before {
    content: "\e94f";
}

.solid-icon-Geo-:before {
    content: "\e950";
}

.solid-icon-Geo-Close:before {
    content: "\e951";
}

.solid-icon-Geo-Love:before {
    content: "\e952";
}

.solid-icon-Geo-Number:before {
    content: "\e953";
}

.solid-icon-Geo-Star:before {
    content: "\e954";
}

.solid-icon-Geo:before {
    content: "\e955";
}

.solid-icon-Geo2--:before {
    content: "\e956";
}

.solid-icon-Geo2-:before {
    content: "\e957";
}

.solid-icon-Geo2-Close:before {
    content: "\e958";
}

.solid-icon-Geo2-Love:before {
    content: "\e959";
}

.solid-icon-Geo2-Number:before {
    content: "\e95a";
}

.solid-icon-Geo2-Star:before {
    content: "\e95b";
}

.solid-icon-Geo2:before {
    content: "\e95c";
}

.solid-icon-Geo3--:before {
    content: "\e95d";
}

.solid-icon-Geo3-:before {
    content: "\e95e";
}

.solid-icon-Geo3-Close:before {
    content: "\e95f";
}

.solid-icon-Geo3-Love:before {
    content: "\e960";
}

.solid-icon-Geo3-Number:before {
    content: "\e961";
}

.solid-icon-Geo3-Star:before {
    content: "\e962";
}

.solid-icon-Geo3:before {
    content: "\e963";
}

.solid-icon-Gey:before {
    content: "\e964";
}

.solid-icon-Gift-Box:before {
    content: "\e965";
}

.solid-icon-Giraffe:before {
    content: "\e966";
}

.solid-icon-Girl:before {
    content: "\e967";
}

.solid-icon-Glass-Water:before {
    content: "\e968";
}

.solid-icon-Glasses-2:before {
    content: "\e969";
}

.solid-icon-Glasses-3:before {
    content: "\e96a";
}

.solid-icon-Glasses:before {
    content: "\e96b";
}

.solid-icon-Global-Position:before {
    content: "\e96c";
}

.solid-icon-Globe-2:before {
    content: "\e96d";
}

.solid-icon-Globe:before {
    content: "\e96e";
}

.solid-icon-Gloves:before {
    content: "\e96f";
}

.solid-icon-Go-Bottom:before {
    content: "\e970";
}

.solid-icon-Go-Top:before {
    content: "\e971";
}

.solid-icon-Goggles:before {
    content: "\e972";
}

.solid-icon-Golf-2:before {
    content: "\e973";
}

.solid-icon-Golf:before {
    content: "\e974";
}

.solid-icon-Google-Buzz:before {
    content: "\e975";
}

.solid-icon-Google-Drive:before {
    content: "\e976";
}

.solid-icon-Google-Play:before {
    content: "\e977";
}

.solid-icon-Google-Plus:before {
    content: "\e978";
}

.solid-icon-Google:before {
    content: "\e979";
}

.solid-icon-Gopro:before {
    content: "\e97a";
}

.solid-icon-Gorilla:before {
    content: "\e97b";
}

.solid-icon-Gowalla:before {
    content: "\e97c";
}

.solid-icon-Grave:before {
    content: "\e97d";
}

.solid-icon-Graveyard:before {
    content: "\e97e";
}

.solid-icon-Greece:before {
    content: "\e97f";
}

.solid-icon-Green-Energy:before {
    content: "\e980";
}

.solid-icon-Green-House:before {
    content: "\e981";
}

.solid-icon-Guitar:before {
    content: "\e982";
}

.solid-icon-Gun-2:before {
    content: "\e983";
}

.solid-icon-Gun-3:before {
    content: "\e984";
}

.solid-icon-Gun:before {
    content: "\e985";
}

.solid-icon-Gymnastics:before {
    content: "\e986";
}

.solid-icon-Hair-2:before {
    content: "\e987";
}

.solid-icon-Hair-3:before {
    content: "\e988";
}

.solid-icon-Hair-4:before {
    content: "\e989";
}

.solid-icon-Hair:before {
    content: "\e98a";
}

.solid-icon-Half-Moon:before {
    content: "\e98b";
}

.solid-icon-Halloween-HalfMoon:before {
    content: "\e98c";
}

.solid-icon-Halloween-Moon:before {
    content: "\e98d";
}

.solid-icon-Hamburger:before {
    content: "\e98e";
}

.solid-icon-Hammer:before {
    content: "\e98f";
}

.solid-icon-Hand-Touch:before {
    content: "\e990";
}

.solid-icon-Hand-Touch2:before {
    content: "\e991";
}

.solid-icon-Hand-TouchSmartphone:before {
    content: "\e992";
}

.solid-icon-Hand:before {
    content: "\e993";
}

.solid-icon-Hands:before {
    content: "\e994";
}

.solid-icon-Handshake:before {
    content: "\e995";
}

.solid-icon-Hanger:before {
    content: "\e996";
}

.solid-icon-Happy:before {
    content: "\e997";
}

.solid-icon-Hat-2:before {
    content: "\e998";
}

.solid-icon-Hat:before {
    content: "\e999";
}

.solid-icon-Haunted-House:before {
    content: "\e99a";
}

.solid-icon-HD-Video:before {
    content: "\e99b";
}

.solid-icon-HD:before {
    content: "\e99c";
}

.solid-icon-HDD:before {
    content: "\e99d";
}

.solid-icon-Headphone:before {
    content: "\e99e";
}

.solid-icon-Headphones:before {
    content: "\e99f";
}

.solid-icon-Headset:before {
    content: "\e9a0";
}

.solid-icon-Heart-2:before {
    content: "\e9a1";
}

.solid-icon-Heart:before {
    content: "\e9a2";
}

.solid-icon-Heels-2:before {
    content: "\e9a3";
}

.solid-icon-Heels:before {
    content: "\e9a4";
}

.solid-icon-Height-Window:before {
    content: "\e9a5";
}

.solid-icon-Helicopter-2:before {
    content: "\e9a6";
}

.solid-icon-Helicopter:before {
    content: "\e9a7";
}

.solid-icon-Helix-2:before {
    content: "\e9a8";
}

.solid-icon-Hello:before {
    content: "\e9a9";
}

.solid-icon-Helmet-2:before {
    content: "\e9aa";
}

.solid-icon-Helmet-3:before {
    content: "\e9ab";
}

.solid-icon-Helmet:before {
    content: "\e9ac";
}

.solid-icon-Hipo:before {
    content: "\e9ad";
}

.solid-icon-Hipster-Glasses:before {
    content: "\e9ae";
}

.solid-icon-Hipster-Glasses2:before {
    content: "\e9af";
}

.solid-icon-Hipster-Glasses3:before {
    content: "\e9b0";
}

.solid-icon-Hipster-Headphones:before {
    content: "\e9b1";
}

.solid-icon-Hipster-Men:before {
    content: "\e9b2";
}

.solid-icon-Hipster-Men2:before {
    content: "\e9b3";
}

.solid-icon-Hipster-Men3:before {
    content: "\e9b4";
}

.solid-icon-Hipster-Sunglasses:before {
    content: "\e9b5";
}

.solid-icon-Hipster-Sunglasses2:before {
    content: "\e9b6";
}

.solid-icon-Hipster-Sunglasses3:before {
    content: "\e9b7";
}

.solid-icon-Hokey:before {
    content: "\e9b8";
}

.solid-icon-Holly:before {
    content: "\e9b9";
}

.solid-icon-Home-2:before {
    content: "\e9ba";
}

.solid-icon-Home-3:before {
    content: "\e9bb";
}

.solid-icon-Home-4:before {
    content: "\e9bc";
}

.solid-icon-Home-5:before {
    content: "\e9bd";
}

.solid-icon-Home-Window:before {
    content: "\e9be";
}

.solid-icon-Home:before {
    content: "\e9bf";
}

.solid-icon-Homosexual:before {
    content: "\e9c0";
}

.solid-icon-Honey:before {
    content: "\e9c1";
}

.solid-icon-Hong-Kong:before {
    content: "\e9c2";
}

.solid-icon-Hoodie:before {
    content: "\e9c3";
}

.solid-icon-Horror:before {
    content: "\e9c4";
}

.solid-icon-Horse:before {
    content: "\e9c5";
}

.solid-icon-Hospital-2:before {
    content: "\e9c6";
}

.solid-icon-Hospital:before {
    content: "\e9c7";
}

.solid-icon-Host:before {
    content: "\e9c8";
}

.solid-icon-Hot-Dog:before {
    content: "\e9c9";
}

.solid-icon-Hotel:before {
    content: "\e9ca";
}

.solid-icon-Hour:before {
    content: "\e9cb";
}

.solid-icon-Hub:before {
    content: "\e9cc";
}

.solid-icon-Humor:before {
    content: "\e9cd";
}

.solid-icon-Hurt:before {
    content: "\e9ce";
}

.solid-icon-Ice-Cream:before {
    content: "\e9cf";
}

.solid-icon-ICQ:before {
    content: "\e9d0";
}

.solid-icon-ID-2:before {
    content: "\e9d1";
}

.solid-icon-ID-3:before {
    content: "\e9d2";
}

.solid-icon-ID-Card:before {
    content: "\e9d3";
}

.solid-icon-Idea-2:before {
    content: "\e9d4";
}

.solid-icon-Idea-3:before {
    content: "\e9d5";
}

.solid-icon-Idea-4:before {
    content: "\e9d6";
}

.solid-icon-Idea-5:before {
    content: "\e9d7";
}

.solid-icon-Idea:before {
    content: "\e9d8";
}

.solid-icon-Identification-Badge:before {
    content: "\e9d9";
}

.solid-icon-ImDB:before {
    content: "\e9da";
}

.solid-icon-Inbox-Empty:before {
    content: "\e9db";
}

.solid-icon-Inbox-Forward:before {
    content: "\e9dc";
}

.solid-icon-Inbox-Full:before {
    content: "\e9dd";
}

.solid-icon-Inbox-Into:before {
    content: "\e9de";
}

.solid-icon-Inbox-Out:before {
    content: "\e9df";
}

.solid-icon-Inbox-Reply:before {
    content: "\e9e0";
}

.solid-icon-Inbox:before {
    content: "\e9e1";
}

.solid-icon-Increase-Inedit:before {
    content: "\e9e2";
}

.solid-icon-Indent-FirstLine:before {
    content: "\e9e3";
}

.solid-icon-Indent-LeftMargin:before {
    content: "\e9e4";
}

.solid-icon-Indent-RightMargin:before {
    content: "\e9e5";
}

.solid-icon-India:before {
    content: "\e9e6";
}

.solid-icon-Info-Window:before {
    content: "\e9e7";
}

.solid-icon-Information:before {
    content: "\e9e8";
}

.solid-icon-Inifity:before {
    content: "\e9e9";
}

.solid-icon-Instagram:before {
    content: "\e9ea";
}

.solid-icon-Internet-2:before {
    content: "\e9eb";
}

.solid-icon-Internet-Explorer:before {
    content: "\e9ec";
}

.solid-icon-Internet-Smiley:before {
    content: "\e9ed";
}

.solid-icon-Internet:before {
    content: "\e9ee";
}

.solid-icon-iOS-Apple:before {
    content: "\e9ef";
}

.solid-icon-Israel:before {
    content: "\e9f0";
}

.solid-icon-Italic-Text:before {
    content: "\e9f1";
}

.solid-icon-Jacket-2:before {
    content: "\e9f2";
}

.solid-icon-Jacket:before {
    content: "\e9f3";
}

.solid-icon-Jamaica:before {
    content: "\e9f4";
}

.solid-icon-Japan:before {
    content: "\e9f5";
}

.solid-icon-Japanese-Gate:before {
    content: "\e9f6";
}

.solid-icon-Jeans:before {
    content: "\e9f7";
}

.solid-icon-Jeep-2:before {
    content: "\e9f8";
}

.solid-icon-Jeep:before {
    content: "\e9f9";
}

.solid-icon-Jet:before {
    content: "\e9fa";
}

.solid-icon-Joystick:before {
    content: "\e9fb";
}

.solid-icon-Juice:before {
    content: "\e9fc";
}

.solid-icon-Jump-Rope:before {
    content: "\e9fd";
}

.solid-icon-Kangoroo:before {
    content: "\e9fe";
}

.solid-icon-Kenya:before {
    content: "\e9ff";
}

.solid-icon-Key-2:before {
    content: "\ea00";
}

.solid-icon-Key-3:before {
    content: "\ea01";
}

.solid-icon-Key-Lock:before {
    content: "\ea02";
}

.solid-icon-Key:before {
    content: "\ea03";
}

.solid-icon-Keyboard:before {
    content: "\ea04";
}

.solid-icon-Keyboard3:before {
    content: "\ea05";
}

.solid-icon-Keypad:before {
    content: "\ea06";
}

.solid-icon-King-2:before {
    content: "\ea07";
}

.solid-icon-King:before {
    content: "\ea08";
}

.solid-icon-Kiss:before {
    content: "\ea09";
}

.solid-icon-Knee:before {
    content: "\ea0a";
}

.solid-icon-Knife-2:before {
    content: "\ea0b";
}

.solid-icon-Knife:before {
    content: "\ea0c";
}

.solid-icon-Knight:before {
    content: "\ea0d";
}

.solid-icon-Koala:before {
    content: "\ea0e";
}

.solid-icon-Korea:before {
    content: "\ea0f";
}

.solid-icon-Lamp:before {
    content: "\ea10";
}

.solid-icon-Landscape-2:before {
    content: "\ea11";
}

.solid-icon-Landscape:before {
    content: "\ea12";
}

.solid-icon-Lantern:before {
    content: "\ea13";
}

.solid-icon-Laptop-2:before {
    content: "\ea14";
}

.solid-icon-Laptop-3:before {
    content: "\ea15";
}

.solid-icon-Laptop-Phone:before {
    content: "\ea16";
}

.solid-icon-Laptop-Secure:before {
    content: "\ea17";
}

.solid-icon-Laptop-Tablet:before {
    content: "\ea18";
}

.solid-icon-Laptop:before {
    content: "\ea19";
}

.solid-icon-Laser:before {
    content: "\ea1a";
}

.solid-icon-Last-FM:before {
    content: "\ea1b";
}

.solid-icon-Last:before {
    content: "\ea1c";
}

.solid-icon-Laughing:before {
    content: "\ea1d";
}

.solid-icon-Layer-Backward:before {
    content: "\ea1e";
}

.solid-icon-Layer-Forward:before {
    content: "\ea1f";
}

.solid-icon-Leafs-2:before {
    content: "\ea20";
}

.solid-icon-Leafs:before {
    content: "\ea21";
}

.solid-icon-Leaning-Tower:before {
    content: "\ea22";
}

.solid-icon-Left--Right:before {
    content: "\ea23";
}

.solid-icon-Left--Right3:before {
    content: "\ea24";
}

.solid-icon-Left-2:before {
    content: "\ea25";
}

.solid-icon-Left-3:before {
    content: "\ea26";
}

.solid-icon-Left-4:before {
    content: "\ea27";
}

.solid-icon-Left-ToRight:before {
    content: "\ea28";
}

.solid-icon-Left:before {
    content: "\ea29";
}

.solid-icon-Leg-2:before {
    content: "\ea2a";
}

.solid-icon-Leg:before {
    content: "\ea2b";
}

.solid-icon-Lego:before {
    content: "\ea2c";
}

.solid-icon-Lemon:before {
    content: "\ea2d";
}

.solid-icon-Len-2:before {
    content: "\ea2e";
}

.solid-icon-Len-3:before {
    content: "\ea2f";
}

.solid-icon-Len:before {
    content: "\ea30";
}

.solid-icon-Leo-2:before {
    content: "\ea31";
}

.solid-icon-Leo:before {
    content: "\ea32";
}

.solid-icon-Leopard:before {
    content: "\ea33";
}

.solid-icon-Lesbian:before {
    content: "\ea34";
}

.solid-icon-Lesbians:before {
    content: "\ea35";
}

.solid-icon-Letter-Close:before {
    content: "\ea36";
}

.solid-icon-Letter-Open:before {
    content: "\ea37";
}

.solid-icon-Letter-Sent:before {
    content: "\ea38";
}

.solid-icon-Libra-2:before {
    content: "\ea39";
}

.solid-icon-Libra:before {
    content: "\ea3a";
}

.solid-icon-Library-2:before {
    content: "\ea3b";
}

.solid-icon-Library:before {
    content: "\ea3c";
}

.solid-icon-Life-Jacket:before {
    content: "\ea3d";
}

.solid-icon-Life-Safer:before {
    content: "\ea3e";
}

.solid-icon-Light-Bulb:before {
    content: "\ea3f";
}

.solid-icon-Light-Bulb2:before {
    content: "\ea40";
}

.solid-icon-Light-BulbLeaf:before {
    content: "\ea41";
}

.solid-icon-Lighthouse:before {
    content: "\ea42";
}

.solid-icon-Like-2:before {
    content: "\ea43";
}

.solid-icon-Like:before {
    content: "\ea44";
}

.solid-icon-Line-Chart:before {
    content: "\ea45";
}

.solid-icon-Line-Chart2:before {
    content: "\ea46";
}

.solid-icon-Line-Chart3:before {
    content: "\ea47";
}

.solid-icon-Line-Chart4:before {
    content: "\ea48";
}

.solid-icon-Line-Spacing:before {
    content: "\ea49";
}

.solid-icon-Line-SpacingText:before {
    content: "\ea4a";
}

.solid-icon-Link-2:before {
    content: "\ea4b";
}

.solid-icon-Link:before {
    content: "\ea4c";
}

.solid-icon-Linkedin-2:before {
    content: "\ea4d";
}

.solid-icon-Linkedin:before {
    content: "\ea4e";
}

.solid-icon-Linux:before {
    content: "\ea4f";
}

.solid-icon-Lion:before {
    content: "\ea50";
}

.solid-icon-Livejournal:before {
    content: "\ea51";
}

.solid-icon-Loading-2:before {
    content: "\ea52";
}

.solid-icon-Loading-3:before {
    content: "\ea53";
}

.solid-icon-Loading-Window:before {
    content: "\ea54";
}

.solid-icon-Loading:before {
    content: "\ea55";
}

.solid-icon-Location-2:before {
    content: "\ea56";
}

.solid-icon-Location:before {
    content: "\ea57";
}

.solid-icon-Lock-2:before {
    content: "\ea58";
}

.solid-icon-Lock-3:before {
    content: "\ea59";
}

.solid-icon-Lock-User:before {
    content: "\ea5a";
}

.solid-icon-Lock-Window:before {
    content: "\ea5b";
}

.solid-icon-Lock:before {
    content: "\ea5c";
}

.solid-icon-Lollipop-2:before {
    content: "\ea5d";
}

.solid-icon-Lollipop-3:before {
    content: "\ea5e";
}

.solid-icon-Lollipop:before {
    content: "\ea5f";
}

.solid-icon-Loop:before {
    content: "\ea60";
}

.solid-icon-Loud:before {
    content: "\ea61";
}

.solid-icon-Loudspeaker:before {
    content: "\ea62";
}

.solid-icon-Love-2:before {
    content: "\ea63";
}

.solid-icon-Love-User:before {
    content: "\ea64";
}

.solid-icon-Love-Window:before {
    content: "\ea65";
}

.solid-icon-Love:before {
    content: "\ea66";
}

.solid-icon-Lowercase-Text:before {
    content: "\ea67";
}

.solid-icon-Luggafe-Front:before {
    content: "\ea68";
}

.solid-icon-Luggage-2:before {
    content: "\ea69";
}

.solid-icon-Macro:before {
    content: "\ea6a";
}

.solid-icon-Magic-Wand:before {
    content: "\ea6b";
}

.solid-icon-Magnet:before {
    content: "\ea6c";
}

.solid-icon-Magnifi-Glass-:before {
    content: "\ea6d";
}

.solid-icon-Magnifi-Glass:before {
    content: "\ea6e";
}

.solid-icon-Magnifi-Glass2:before {
    content: "\ea6f";
}

.solid-icon-Mail-2:before {
    content: "\ea70";
}

.solid-icon-Mail-3:before {
    content: "\ea71";
}

.solid-icon-Mail-Add:before {
    content: "\ea72";
}

.solid-icon-Mail-Attachement:before {
    content: "\ea73";
}

.solid-icon-Mail-Block:before {
    content: "\ea74";
}

.solid-icon-Mail-Delete:before {
    content: "\ea75";
}

.solid-icon-Mail-Favorite:before {
    content: "\ea76";
}

.solid-icon-Mail-Forward:before {
    content: "\ea77";
}

.solid-icon-Mail-Gallery:before {
    content: "\ea78";
}

.solid-icon-Mail-Inbox:before {
    content: "\ea79";
}

.solid-icon-Mail-Link:before {
    content: "\ea7a";
}

.solid-icon-Mail-Lock:before {
    content: "\ea7b";
}

.solid-icon-Mail-Love:before {
    content: "\ea7c";
}

.solid-icon-Mail-Money:before {
    content: "\ea7d";
}

.solid-icon-Mail-Open:before {
    content: "\ea7e";
}

.solid-icon-Mail-Outbox:before {
    content: "\ea7f";
}

.solid-icon-Mail-Password:before {
    content: "\ea80";
}

.solid-icon-Mail-Photo:before {
    content: "\ea81";
}

.solid-icon-Mail-Read:before {
    content: "\ea82";
}

.solid-icon-Mail-Removex:before {
    content: "\ea83";
}

.solid-icon-Mail-Reply:before {
    content: "\ea84";
}

.solid-icon-Mail-ReplyAll:before {
    content: "\ea85";
}

.solid-icon-Mail-Search:before {
    content: "\ea86";
}

.solid-icon-Mail-Send:before {
    content: "\ea87";
}

.solid-icon-Mail-Settings:before {
    content: "\ea88";
}

.solid-icon-Mail-Unread:before {
    content: "\ea89";
}

.solid-icon-Mail-Video:before {
    content: "\ea8a";
}

.solid-icon-Mail-withAtSign:before {
    content: "\ea8b";
}

.solid-icon-Mail-WithCursors:before {
    content: "\ea8c";
}

.solid-icon-Mail:before {
    content: "\ea8d";
}

.solid-icon-Mailbox-Empty:before {
    content: "\ea8e";
}

.solid-icon-Mailbox-Full:before {
    content: "\ea8f";
}

.solid-icon-Male-2:before {
    content: "\ea90";
}

.solid-icon-Male-Sign:before {
    content: "\ea91";
}

.solid-icon-Male:before {
    content: "\ea92";
}

.solid-icon-MaleFemale:before {
    content: "\ea93";
}

.solid-icon-Man-Sign:before {
    content: "\ea94";
}

.solid-icon-Management:before {
    content: "\ea95";
}

.solid-icon-Mans-Underwear:before {
    content: "\ea96";
}

.solid-icon-Mans-Underwear2:before {
    content: "\ea97";
}

.solid-icon-Map-Marker:before {
    content: "\ea98";
}

.solid-icon-Map-Marker2:before {
    content: "\ea99";
}

.solid-icon-Map-Marker3:before {
    content: "\ea9a";
}

.solid-icon-Map:before {
    content: "\ea9b";
}

.solid-icon-Map2:before {
    content: "\ea9c";
}

.solid-icon-Marker-2:before {
    content: "\ea9d";
}

.solid-icon-Marker-3:before {
    content: "\ea9e";
}

.solid-icon-Marker:before {
    content: "\ea9f";
}

.solid-icon-Martini-Glass:before {
    content: "\eaa0";
}

.solid-icon-Mask:before {
    content: "\eaa1";
}

.solid-icon-Master-Card:before {
    content: "\eaa2";
}

.solid-icon-Maximize-Window:before {
    content: "\eaa3";
}

.solid-icon-Maximize:before {
    content: "\eaa4";
}

.solid-icon-Medal-2:before {
    content: "\eaa5";
}

.solid-icon-Medal-3:before {
    content: "\eaa6";
}

.solid-icon-Medal:before {
    content: "\eaa7";
}

.solid-icon-Medical-Sign:before {
    content: "\eaa8";
}

.solid-icon-Medicine-2:before {
    content: "\eaa9";
}

.solid-icon-Medicine-3:before {
    content: "\eaaa";
}

.solid-icon-Medicine:before {
    content: "\eaab";
}

.solid-icon-Megaphone:before {
    content: "\eaac";
}

.solid-icon-Memory-Card:before {
    content: "\eaad";
}

.solid-icon-Memory-Card2:before {
    content: "\eaae";
}

.solid-icon-Memory-Card3:before {
    content: "\eaaf";
}

.solid-icon-Men:before {
    content: "\eab0";
}

.solid-icon-Menorah:before {
    content: "\eab1";
}

.solid-icon-Mens:before {
    content: "\eab2";
}

.solid-icon-Metacafe:before {
    content: "\eab3";
}

.solid-icon-Mexico:before {
    content: "\eab4";
}

.solid-icon-Mic:before {
    content: "\eab5";
}

.solid-icon-Microphone-2:before {
    content: "\eab6";
}

.solid-icon-Microphone-3:before {
    content: "\eab7";
}

.solid-icon-Microphone-4:before {
    content: "\eab8";
}

.solid-icon-Microphone-5:before {
    content: "\eab9";
}

.solid-icon-Microphone-6:before {
    content: "\eaba";
}

.solid-icon-Microphone-8:before {
    content: "\eabb";
}

.solid-icon-Microphone:before {
    content: "\eabc";
}

.solid-icon-Microscope:before {
    content: "\eabd";
}

.solid-icon-Milk-Bottle:before {
    content: "\eabe";
}

.solid-icon-Mine:before {
    content: "\eabf";
}

.solid-icon-Minimize-Maximize-Close-Window:before {
    content: "\eac0";
}

.solid-icon-Minimize-Window:before {
    content: "\eac1";
}

.solid-icon-Minimize:before {
    content: "\eac2";
}

.solid-icon-Mirror:before {
    content: "\eac3";
}

.solid-icon-Mixer:before {
    content: "\eac4";
}

.solid-icon-Mixx:before {
    content: "\eac5";
}

.solid-icon-Money-2:before {
    content: "\eac6";
}

.solid-icon-Money-Bag:before {
    content: "\eac7";
}

.solid-icon-Money-Smiley:before {
    content: "\eac8";
}

.solid-icon-Money:before {
    content: "\eac9";
}

.solid-icon-Monitor-2:before {
    content: "\eaca";
}

.solid-icon-Monitor-3:before {
    content: "\eacb";
}

.solid-icon-Monitor-4:before {
    content: "\eacc";
}

.solid-icon-Monitor-5:before {
    content: "\eacd";
}

.solid-icon-Monitor-Analytics:before {
    content: "\eace";
}

.solid-icon-Monitor-Laptop:before {
    content: "\eacf";
}

.solid-icon-Monitor-phone:before {
    content: "\ead0";
}

.solid-icon-Monitor-Tablet:before {
    content: "\ead1";
}

.solid-icon-Monitor-Vertical:before {
    content: "\ead2";
}

.solid-icon-Monitor:before {
    content: "\ead3";
}

.solid-icon-Monitoring:before {
    content: "\ead4";
}

.solid-icon-Monkey:before {
    content: "\ead5";
}

.solid-icon-Monster:before {
    content: "\ead6";
}

.solid-icon-Morocco:before {
    content: "\ead7";
}

.solid-icon-Motorcycle:before {
    content: "\ead8";
}

.solid-icon-Mouse-2:before {
    content: "\ead9";
}

.solid-icon-Mouse-3:before {
    content: "\eada";
}

.solid-icon-Mouse-4:before {
    content: "\eadb";
}

.solid-icon-Mouse-Pointer:before {
    content: "\eadc";
}

.solid-icon-Mouse:before {
    content: "\eadd";
}

.solid-icon-Moustache-Smiley:before {
    content: "\eade";
}

.solid-icon-Movie-Ticket:before {
    content: "\eadf";
}

.solid-icon-Movie:before {
    content: "\eae0";
}

.solid-icon-Mp3-File:before {
    content: "\eae1";
}

.solid-icon-Museum:before {
    content: "\eae2";
}

.solid-icon-Mushroom:before {
    content: "\eae3";
}

.solid-icon-Music-Note:before {
    content: "\eae4";
}

.solid-icon-Music-Note2:before {
    content: "\eae5";
}

.solid-icon-Music-Note3:before {
    content: "\eae6";
}

.solid-icon-Music-Note4:before {
    content: "\eae7";
}

.solid-icon-Music-Player:before {
    content: "\eae8";
}

.solid-icon-Mustache-2:before {
    content: "\eae9";
}

.solid-icon-Mustache-3:before {
    content: "\eaea";
}

.solid-icon-Mustache-4:before {
    content: "\eaeb";
}

.solid-icon-Mustache-5:before {
    content: "\eaec";
}

.solid-icon-Mustache-6:before {
    content: "\eaed";
}

.solid-icon-Mustache-7:before {
    content: "\eaee";
}

.solid-icon-Mustache-8:before {
    content: "\eaef";
}

.solid-icon-Mustache:before {
    content: "\eaf0";
}

.solid-icon-Mute:before {
    content: "\eaf1";
}

.solid-icon-Myspace:before {
    content: "\eaf2";
}

.solid-icon-Navigat-Start:before {
    content: "\eaf3";
}

.solid-icon-Navigate-End:before {
    content: "\eaf4";
}

.solid-icon-Navigation-LeftWindow:before {
    content: "\eaf5";
}

.solid-icon-Navigation-RightWindow:before {
    content: "\eaf6";
}

.solid-icon-Nepal:before {
    content: "\eaf7";
}

.solid-icon-Netscape:before {
    content: "\eaf8";
}

.solid-icon-Network-Window:before {
    content: "\eaf9";
}

.solid-icon-Network:before {
    content: "\eafa";
}

.solid-icon-Neutron:before {
    content: "\eafb";
}

.solid-icon-New-Mail:before {
    content: "\eafc";
}

.solid-icon-New-Tab:before {
    content: "\eafd";
}

.solid-icon-Newspaper-2:before {
    content: "\eafe";
}

.solid-icon-Newspaper:before {
    content: "\eaff";
}

.solid-icon-Newsvine:before {
    content: "\eb00";
}

.solid-icon-Next-3:before {
    content: "\eb01";
}

.solid-icon-Next-Media:before {
    content: "\eb02";
}

.solid-icon-Next-Music:before {
    content: "\eb03";
}

.solid-icon-Next:before {
    content: "\eb04";
}

.solid-icon-No-Battery:before {
    content: "\eb05";
}

.solid-icon-No-Drop:before {
    content: "\eb06";
}

.solid-icon-No-Flash:before {
    content: "\eb07";
}

.solid-icon-No-Smoking:before {
    content: "\eb08";
}

.solid-icon-Noose:before {
    content: "\eb09";
}

.solid-icon-Normal-Text:before {
    content: "\eb0a";
}

.solid-icon-Note:before {
    content: "\eb0b";
}

.solid-icon-Notepad-2:before {
    content: "\eb0c";
}

.solid-icon-Notepad:before {
    content: "\eb0d";
}

.solid-icon-Nuclear:before {
    content: "\eb0e";
}

.solid-icon-Numbering-List:before {
    content: "\eb0f";
}

.solid-icon-Nurse:before {
    content: "\eb10";
}

.solid-icon-Office-Lamp:before {
    content: "\eb11";
}

.solid-icon-Office:before {
    content: "\eb12";
}

.solid-icon-Oil:before {
    content: "\eb13";
}

.solid-icon-Old-Camera:before {
    content: "\eb14";
}

.solid-icon-Old-Cassette:before {
    content: "\eb15";
}

.solid-icon-Old-Clock:before {
    content: "\eb16";
}

.solid-icon-Old-Radio:before {
    content: "\eb17";
}

.solid-icon-Old-Sticky:before {
    content: "\eb18";
}

.solid-icon-Old-Sticky2:before {
    content: "\eb19";
}

.solid-icon-Old-Telephone:before {
    content: "\eb1a";
}

.solid-icon-Old-TV:before {
    content: "\eb1b";
}

.solid-icon-On-Air:before {
    content: "\eb1c";
}

.solid-icon-On-Off-2:before {
    content: "\eb1d";
}

.solid-icon-On-Off-3:before {
    content: "\eb1e";
}

.solid-icon-On-off:before {
    content: "\eb1f";
}

.solid-icon-One-Finger:before {
    content: "\eb20";
}

.solid-icon-One-FingerTouch:before {
    content: "\eb21";
}

.solid-icon-One-Window:before {
    content: "\eb22";
}

.solid-icon-Open-Banana:before {
    content: "\eb23";
}

.solid-icon-Open-Book:before {
    content: "\eb24";
}

.solid-icon-Opera-House:before {
    content: "\eb25";
}

.solid-icon-Opera:before {
    content: "\eb26";
}

.solid-icon-Optimization:before {
    content: "\eb27";
}

.solid-icon-Orientation-2:before {
    content: "\eb28";
}

.solid-icon-Orientation-3:before {
    content: "\eb29";
}

.solid-icon-Orientation:before {
    content: "\eb2a";
}

.solid-icon-Orkut:before {
    content: "\eb2b";
}

.solid-icon-Ornament:before {
    content: "\eb2c";
}

.solid-icon-Over-Time:before {
    content: "\eb2d";
}

.solid-icon-Over-Time2:before {
    content: "\eb2e";
}

.solid-icon-Owl:before {
    content: "\eb2f";
}

.solid-icon-Pac-Man:before {
    content: "\eb30";
}

.solid-icon-Paint-Brush:before {
    content: "\eb31";
}

.solid-icon-Paint-Bucket:before {
    content: "\eb32";
}

.solid-icon-Paintbrush:before {
    content: "\eb33";
}

.solid-icon-Palette:before {
    content: "\eb34";
}

.solid-icon-Palm-Tree:before {
    content: "\eb35";
}

.solid-icon-Panda:before {
    content: "\eb36";
}

.solid-icon-Panorama:before {
    content: "\eb37";
}

.solid-icon-Pantheon:before {
    content: "\eb38";
}

.solid-icon-Pantone:before {
    content: "\eb39";
}

.solid-icon-Pants:before {
    content: "\eb3a";
}

.solid-icon-Paper-Plane:before {
    content: "\eb3b";
}

.solid-icon-Paper:before {
    content: "\eb3c";
}

.solid-icon-Parasailing:before {
    content: "\eb3d";
}

.solid-icon-Parrot:before {
    content: "\eb3e";
}

.solid-icon-Password-2shopping:before {
    content: "\eb3f";
}

.solid-icon-Password-Field:before {
    content: "\eb40";
}

.solid-icon-Password-shopping:before {
    content: "\eb41";
}

.solid-icon-Password:before {
    content: "\eb42";
}

.solid-icon-Pause-2:before {
    content: "\eb43";
}

.solid-icon-Pause:before {
    content: "\eb44";
}

.solid-icon-Paw:before {
    content: "\eb45";
}

.solid-icon-Pawn:before {
    content: "\eb46";
}

.solid-icon-Paypal:before {
    content: "\eb47";
}

.solid-icon-Pen-2:before {
    content: "\eb48";
}

.solid-icon-Pen-3:before {
    content: "\eb49";
}

.solid-icon-Pen-4:before {
    content: "\eb4a";
}

.solid-icon-Pen-5:before {
    content: "\eb4b";
}

.solid-icon-Pen-6:before {
    content: "\eb4c";
}

.solid-icon-Pen:before {
    content: "\eb4d";
}

.solid-icon-Pencil-Ruler:before {
    content: "\eb4e";
}

.solid-icon-Pencil:before {
    content: "\eb4f";
}

.solid-icon-Penguin:before {
    content: "\eb50";
}

.solid-icon-Pentagon:before {
    content: "\eb51";
}

.solid-icon-People-onCloud:before {
    content: "\eb52";
}

.solid-icon-Pepper-withFire:before {
    content: "\eb53";
}

.solid-icon-Pepper:before {
    content: "\eb54";
}

.solid-icon-Petrol:before {
    content: "\eb55";
}

.solid-icon-Petronas-Tower:before {
    content: "\eb56";
}

.solid-icon-Philipines:before {
    content: "\eb57";
}

.solid-icon-Phone-2:before {
    content: "\eb58";
}

.solid-icon-Phone-3:before {
    content: "\eb59";
}

.solid-icon-Phone-3G:before {
    content: "\eb5a";
}

.solid-icon-Phone-4G:before {
    content: "\eb5b";
}

.solid-icon-Phone-Simcard:before {
    content: "\eb5c";
}

.solid-icon-Phone-SMS:before {
    content: "\eb5d";
}

.solid-icon-Phone-Wifi:before {
    content: "\eb5e";
}

.solid-icon-Phone:before {
    content: "\eb5f";
}

.solid-icon-Photo-2:before {
    content: "\eb60";
}

.solid-icon-Photo-3:before {
    content: "\eb61";
}

.solid-icon-Photo-Album:before {
    content: "\eb62";
}

.solid-icon-Photo-Album2:before {
    content: "\eb63";
}

.solid-icon-Photo-Album3:before {
    content: "\eb64";
}

.solid-icon-Photo:before {
    content: "\eb65";
}

.solid-icon-Photos:before {
    content: "\eb66";
}

.solid-icon-Physics:before {
    content: "\eb67";
}

.solid-icon-Pi:before {
    content: "\eb68";
}

.solid-icon-Piano:before {
    content: "\eb69";
}

.solid-icon-Picasa:before {
    content: "\eb6a";
}

.solid-icon-Pie-Chart:before {
    content: "\eb6b";
}

.solid-icon-Pie-Chart2:before {
    content: "\eb6c";
}

.solid-icon-Pie-Chart3:before {
    content: "\eb6d";
}

.solid-icon-Pilates-2:before {
    content: "\eb6e";
}

.solid-icon-Pilates-3:before {
    content: "\eb6f";
}

.solid-icon-Pilates:before {
    content: "\eb70";
}

.solid-icon-Pilot:before {
    content: "\eb71";
}

.solid-icon-Pinch:before {
    content: "\eb72";
}

.solid-icon-Ping-Pong:before {
    content: "\eb73";
}

.solid-icon-Pinterest:before {
    content: "\eb74";
}

.solid-icon-Pipe:before {
    content: "\eb75";
}

.solid-icon-Pipette:before {
    content: "\eb76";
}

.solid-icon-Piramids:before {
    content: "\eb77";
}

.solid-icon-Pisces-2:before {
    content: "\eb78";
}

.solid-icon-Pisces:before {
    content: "\eb79";
}

.solid-icon-Pizza-Slice:before {
    content: "\eb7a";
}

.solid-icon-Pizza:before {
    content: "\eb7b";
}

.solid-icon-Plane-2:before {
    content: "\eb7c";
}

.solid-icon-Plane:before {
    content: "\eb7d";
}

.solid-icon-Plant:before {
    content: "\eb7e";
}

.solid-icon-Plasmid:before {
    content: "\eb7f";
}

.solid-icon-Plaster:before {
    content: "\eb80";
}

.solid-icon-Plastic-CupPhone:before {
    content: "\eb81";
}

.solid-icon-Plastic-CupPhone2:before {
    content: "\eb82";
}

.solid-icon-Plate:before {
    content: "\eb83";
}

.solid-icon-Plates:before {
    content: "\eb84";
}

.solid-icon-Plaxo:before {
    content: "\eb85";
}

.solid-icon-Play-Music:before {
    content: "\eb86";
}

.solid-icon-Plug-In:before {
    content: "\eb87";
}

.solid-icon-Plug-In2:before {
    content: "\eb88";
}

.solid-icon-Plurk:before {
    content: "\eb89";
}

.solid-icon-Pointer:before {
    content: "\eb8a";
}

.solid-icon-Poland:before {
    content: "\eb8b";
}

.solid-icon-Police-Man:before {
    content: "\eb8c";
}

.solid-icon-Police-Station:before {
    content: "\eb8d";
}

.solid-icon-Police-Woman:before {
    content: "\eb8e";
}

.solid-icon-Police:before {
    content: "\eb8f";
}

.solid-icon-Polo-Shirt:before {
    content: "\eb90";
}

.solid-icon-Portrait:before {
    content: "\eb91";
}

.solid-icon-Portugal:before {
    content: "\eb92";
}

.solid-icon-Post-Mail:before {
    content: "\eb93";
}

.solid-icon-Post-Mail2:before {
    content: "\eb94";
}

.solid-icon-Post-Office:before {
    content: "\eb95";
}

.solid-icon-Post-Sign:before {
    content: "\eb96";
}

.solid-icon-Post-Sign2ways:before {
    content: "\eb97";
}

.solid-icon-Posterous:before {
    content: "\eb98";
}

.solid-icon-Pound-Sign:before {
    content: "\eb99";
}

.solid-icon-Pound-Sign2:before {
    content: "\eb9a";
}

.solid-icon-Pound:before {
    content: "\eb9b";
}

.solid-icon-Power-2:before {
    content: "\eb9c";
}

.solid-icon-Power-3:before {
    content: "\eb9d";
}

.solid-icon-Power-Cable:before {
    content: "\eb9e";
}

.solid-icon-Power-Station:before {
    content: "\eb9f";
}

.solid-icon-Power:before {
    content: "\eba0";
}

.solid-icon-Prater:before {
    content: "\eba1";
}

.solid-icon-Present:before {
    content: "\eba2";
}

.solid-icon-Presents:before {
    content: "\eba3";
}

.solid-icon-Press:before {
    content: "\eba4";
}

.solid-icon-Preview:before {
    content: "\eba5";
}

.solid-icon-Previous:before {
    content: "\eba6";
}

.solid-icon-Pricing:before {
    content: "\eba7";
}

.solid-icon-Printer:before {
    content: "\eba8";
}

.solid-icon-Professor:before {
    content: "\eba9";
}

.solid-icon-Profile:before {
    content: "\ebaa";
}

.solid-icon-Project:before {
    content: "\ebab";
}

.solid-icon-Projector-2:before {
    content: "\ebac";
}

.solid-icon-Projector:before {
    content: "\ebad";
}

.solid-icon-Pulse:before {
    content: "\ebae";
}

.solid-icon-Pumpkin:before {
    content: "\ebaf";
}

.solid-icon-Punk:before {
    content: "\ebb0";
}

.solid-icon-Punker:before {
    content: "\ebb1";
}

.solid-icon-Puzzle:before {
    content: "\ebb2";
}

.solid-icon-QIK:before {
    content: "\ebb3";
}

.solid-icon-QR-Code:before {
    content: "\ebb4";
}

.solid-icon-Queen-2:before {
    content: "\ebb5";
}

.solid-icon-Queen:before {
    content: "\ebb6";
}

.solid-icon-Quill-2:before {
    content: "\ebb7";
}

.solid-icon-Quill-3:before {
    content: "\ebb8";
}

.solid-icon-Quill:before {
    content: "\ebb9";
}

.solid-icon-Quotes-2:before {
    content: "\ebba";
}

.solid-icon-Quotes:before {
    content: "\ebbb";
}

.solid-icon-Radio:before {
    content: "\ebbc";
}

.solid-icon-Radioactive:before {
    content: "\ebbd";
}

.solid-icon-Rafting:before {
    content: "\ebbe";
}

.solid-icon-Rain-Drop:before {
    content: "\ebbf";
}

.solid-icon-Rainbow-2:before {
    content: "\ebc0";
}

.solid-icon-Rainbow:before {
    content: "\ebc1";
}

.solid-icon-Ram:before {
    content: "\ebc2";
}

.solid-icon-Razzor-Blade:before {
    content: "\ebc3";
}

.solid-icon-Receipt-2:before {
    content: "\ebc4";
}

.solid-icon-Receipt-3:before {
    content: "\ebc5";
}

.solid-icon-Receipt-4:before {
    content: "\ebc6";
}

.solid-icon-Receipt:before {
    content: "\ebc7";
}

.solid-icon-Record-3:before {
    content: "\ebc8";
}

.solid-icon-Record-Media:before {
    content: "\ebc9";
}

.solid-icon-Record-Music:before {
    content: "\ebca";
}

.solid-icon-Record:before {
    content: "\ebcb";
}

.solid-icon-Recycling-2:before {
    content: "\ebcc";
}

.solid-icon-Recycling:before {
    content: "\ebcd";
}

.solid-icon-Reddit:before {
    content: "\ebce";
}

.solid-icon-Redhat:before {
    content: "\ebcf";
}

.solid-icon-Redirect:before {
    content: "\ebd0";
}

.solid-icon-Redo:before {
    content: "\ebd1";
}

.solid-icon-Reel:before {
    content: "\ebd2";
}

.solid-icon-Refinery:before {
    content: "\ebd3";
}

.solid-icon-Refresh-Window:before {
    content: "\ebd4";
}

.solid-icon-Refresh:before {
    content: "\ebd5";
}

.solid-icon-Reload-2:before {
    content: "\ebd6";
}

.solid-icon-Reload-3:before {
    content: "\ebd7";
}

.solid-icon-Reload:before {
    content: "\ebd8";
}

.solid-icon-Remote-Controll:before {
    content: "\ebd9";
}

.solid-icon-Remote-Controll2:before {
    content: "\ebda";
}

.solid-icon-Remove-Bag:before {
    content: "\ebdb";
}

.solid-icon-Remove-Basket:before {
    content: "\ebdc";
}

.solid-icon-Remove-Cart:before {
    content: "\ebdd";
}

.solid-icon-Remove-File:before {
    content: "\ebde";
}

.solid-icon-Remove-User:before {
    content: "\ebdf";
}

.solid-icon-Remove-Window:before {
    content: "\ebe0";
}

.solid-icon-Remove:before {
    content: "\ebe1";
}

.solid-icon-Rename:before {
    content: "\ebe2";
}

.solid-icon-Repair:before {
    content: "\ebe3";
}

.solid-icon-Repeat-2:before {
    content: "\ebe4";
}

.solid-icon-Repeat-3:before {
    content: "\ebe5";
}

.solid-icon-Repeat-4:before {
    content: "\ebe6";
}

.solid-icon-Repeat-5:before {
    content: "\ebe7";
}

.solid-icon-Repeat-6:before {
    content: "\ebe8";
}

.solid-icon-Repeat-7:before {
    content: "\ebe9";
}

.solid-icon-Repeat:before {
    content: "\ebea";
}

.solid-icon-Reset:before {
    content: "\ebeb";
}

.solid-icon-Resize:before {
    content: "\ebec";
}

.solid-icon-Restore-Window:before {
    content: "\ebed";
}

.solid-icon-Retouching:before {
    content: "\ebee";
}

.solid-icon-Retro-Camera:before {
    content: "\ebef";
}

.solid-icon-Retro:before {
    content: "\ebf0";
}

.solid-icon-Retweet:before {
    content: "\ebf1";
}

.solid-icon-Reverbnation:before {
    content: "\ebf2";
}

.solid-icon-Rewind:before {
    content: "\ebf3";
}

.solid-icon-RGB:before {
    content: "\ebf4";
}

.solid-icon-Ribbon-2:before {
    content: "\ebf5";
}

.solid-icon-Ribbon-3:before {
    content: "\ebf6";
}

.solid-icon-Ribbon:before {
    content: "\ebf7";
}

.solid-icon-Right-2:before {
    content: "\ebf8";
}

.solid-icon-Right-3:before {
    content: "\ebf9";
}

.solid-icon-Right-4:before {
    content: "\ebfa";
}

.solid-icon-Right-ToLeft:before {
    content: "\ebfb";
}

.solid-icon-Right:before {
    content: "\ebfc";
}

.solid-icon-Road-2:before {
    content: "\ebfd";
}

.solid-icon-Road-3:before {
    content: "\ebfe";
}

.solid-icon-Road:before {
    content: "\ebff";
}

.solid-icon-Robot-2:before {
    content: "\ec00";
}

.solid-icon-Robot:before {
    content: "\ec01";
}

.solid-icon-Rock-andRoll:before {
    content: "\ec02";
}

.solid-icon-Rocket:before {
    content: "\ec03";
}

.solid-icon-Roller:before {
    content: "\ec04";
}

.solid-icon-Roof:before {
    content: "\ec05";
}

.solid-icon-Rook:before {
    content: "\ec06";
}

.solid-icon-Rotate-Gesture:before {
    content: "\ec07";
}

.solid-icon-Rotate-Gesture2:before {
    content: "\ec08";
}

.solid-icon-Rotate-Gesture3:before {
    content: "\ec09";
}

.solid-icon-Rotation-390:before {
    content: "\ec0a";
}

.solid-icon-Rotation:before {
    content: "\ec0b";
}

.solid-icon-Router-2:before {
    content: "\ec0c";
}

.solid-icon-Router:before {
    content: "\ec0d";
}

.solid-icon-RSS:before {
    content: "\ec0e";
}

.solid-icon-Ruler-2:before {
    content: "\ec0f";
}

.solid-icon-Ruler:before {
    content: "\ec10";
}

.solid-icon-Running-Shoes:before {
    content: "\ec11";
}

.solid-icon-Running:before {
    content: "\ec12";
}

.solid-icon-Safari:before {
    content: "\ec13";
}

.solid-icon-Safe-Box:before {
    content: "\ec14";
}

.solid-icon-Safe-Box2:before {
    content: "\ec15";
}

.solid-icon-Safety-PinClose:before {
    content: "\ec16";
}

.solid-icon-Safety-PinOpen:before {
    content: "\ec17";
}

.solid-icon-Sagittarus-2:before {
    content: "\ec18";
}

.solid-icon-Sagittarus:before {
    content: "\ec19";
}

.solid-icon-Sailing-Ship:before {
    content: "\ec1a";
}

.solid-icon-Sand-watch:before {
    content: "\ec1b";
}

.solid-icon-Sand-watch2:before {
    content: "\ec1c";
}

.solid-icon-Santa-Claus:before {
    content: "\ec1d";
}

.solid-icon-Santa-Claus2:before {
    content: "\ec1e";
}

.solid-icon-Santa-onSled:before {
    content: "\ec1f";
}

.solid-icon-Satelite-2:before {
    content: "\ec20";
}

.solid-icon-Satelite:before {
    content: "\ec21";
}

.solid-icon-Save-Window:before {
    content: "\ec22";
}

.solid-icon-Save:before {
    content: "\ec23";
}

.solid-icon-Saw:before {
    content: "\ec24";
}

.solid-icon-Saxophone:before {
    content: "\ec25";
}

.solid-icon-Scale:before {
    content: "\ec26";
}

.solid-icon-Scarf:before {
    content: "\ec27";
}

.solid-icon-Scissor:before {
    content: "\ec28";
}

.solid-icon-Scooter-Front:before {
    content: "\ec29";
}

.solid-icon-Scooter:before {
    content: "\ec2a";
}

.solid-icon-Scorpio-2:before {
    content: "\ec2b";
}

.solid-icon-Scorpio:before {
    content: "\ec2c";
}

.solid-icon-Scotland:before {
    content: "\ec2d";
}

.solid-icon-Screwdriver:before {
    content: "\ec2e";
}

.solid-icon-Scroll-Fast:before {
    content: "\ec2f";
}

.solid-icon-Scroll:before {
    content: "\ec30";
}

.solid-icon-Scroller-2:before {
    content: "\ec31";
}

.solid-icon-Scroller:before {
    content: "\ec32";
}

.solid-icon-Sea-Dog:before {
    content: "\ec33";
}

.solid-icon-Search-onCloud:before {
    content: "\ec34";
}

.solid-icon-Search-People:before {
    content: "\ec35";
}

.solid-icon-secound:before {
    content: "\ec36";
}

.solid-icon-secound2:before {
    content: "\ec37";
}

.solid-icon-Security-Block:before {
    content: "\ec38";
}

.solid-icon-Security-Bug:before {
    content: "\ec39";
}

.solid-icon-Security-Camera:before {
    content: "\ec3a";
}

.solid-icon-Security-Check:before {
    content: "\ec3b";
}

.solid-icon-Security-Settings:before {
    content: "\ec3c";
}

.solid-icon-Security-Smiley:before {
    content: "\ec3d";
}

.solid-icon-Securiy-Remove:before {
    content: "\ec3e";
}

.solid-icon-Seed:before {
    content: "\ec3f";
}

.solid-icon-Selfie:before {
    content: "\ec40";
}

.solid-icon-Serbia:before {
    content: "\ec41";
}

.solid-icon-Server-2:before {
    content: "\ec42";
}

.solid-icon-Server:before {
    content: "\ec43";
}

.solid-icon-Servers:before {
    content: "\ec44";
}

.solid-icon-Settings-Window:before {
    content: "\ec45";
}

.solid-icon-Sewing-Machine:before {
    content: "\ec46";
}

.solid-icon-Sexual:before {
    content: "\ec47";
}

.solid-icon-Share-onCloud:before {
    content: "\ec48";
}

.solid-icon-Share-Window:before {
    content: "\ec49";
}

.solid-icon-Share:before {
    content: "\ec4a";
}

.solid-icon-Sharethis:before {
    content: "\ec4b";
}

.solid-icon-Shark:before {
    content: "\ec4c";
}

.solid-icon-Sheep:before {
    content: "\ec4d";
}

.solid-icon-Sheriff-Badge:before {
    content: "\ec4e";
}

.solid-icon-Shield:before {
    content: "\ec4f";
}

.solid-icon-Ship-2:before {
    content: "\ec50";
}

.solid-icon-Ship:before {
    content: "\ec51";
}

.solid-icon-Shirt:before {
    content: "\ec52";
}

.solid-icon-Shoes-2:before {
    content: "\ec53";
}

.solid-icon-Shoes-3:before {
    content: "\ec54";
}

.solid-icon-Shoes:before {
    content: "\ec55";
}

.solid-icon-Shop-2:before {
    content: "\ec56";
}

.solid-icon-Shop-3:before {
    content: "\ec57";
}

.solid-icon-Shop-4:before {
    content: "\ec58";
}

.solid-icon-Shop:before {
    content: "\ec59";
}

.solid-icon-Shopping-Bag:before {
    content: "\ec5a";
}

.solid-icon-Shopping-Basket:before {
    content: "\ec5b";
}

.solid-icon-Shopping-Cart:before {
    content: "\ec5c";
}

.solid-icon-Short-Pants:before {
    content: "\ec5d";
}

.solid-icon-Shoutwire:before {
    content: "\ec5e";
}

.solid-icon-Shovel:before {
    content: "\ec5f";
}

.solid-icon-Shuffle-2:before {
    content: "\ec60";
}

.solid-icon-Shuffle-3:before {
    content: "\ec61";
}

.solid-icon-Shuffle-4:before {
    content: "\ec62";
}

.solid-icon-Shuffle:before {
    content: "\ec63";
}

.solid-icon-Shutter:before {
    content: "\ec64";
}

.solid-icon-Sidebar-Window:before {
    content: "\ec65";
}

.solid-icon-Signal:before {
    content: "\ec66";
}

.solid-icon-Singapore:before {
    content: "\ec67";
}

.solid-icon-Skate-Shoes:before {
    content: "\ec68";
}

.solid-icon-Skateboard-2:before {
    content: "\ec69";
}

.solid-icon-Skateboard:before {
    content: "\ec6a";
}

.solid-icon-Skeleton:before {
    content: "\ec6b";
}

.solid-icon-Ski:before {
    content: "\ec6c";
}

.solid-icon-Skirt:before {
    content: "\ec6d";
}

.solid-icon-Skrill:before {
    content: "\ec6e";
}

.solid-icon-Skull:before {
    content: "\ec6f";
}

.solid-icon-Skydiving:before {
    content: "\ec70";
}

.solid-icon-Skype:before {
    content: "\ec71";
}

.solid-icon-Sled-withGifts:before {
    content: "\ec72";
}

.solid-icon-Sled:before {
    content: "\ec73";
}

.solid-icon-Sleeping:before {
    content: "\ec74";
}

.solid-icon-Sleet:before {
    content: "\ec75";
}

.solid-icon-Slippers:before {
    content: "\ec76";
}

.solid-icon-Smart:before {
    content: "\ec77";
}

.solid-icon-Smartphone-2:before {
    content: "\ec78";
}

.solid-icon-Smartphone-3:before {
    content: "\ec79";
}

.solid-icon-Smartphone-4:before {
    content: "\ec7a";
}

.solid-icon-Smartphone-Secure:before {
    content: "\ec7b";
}

.solid-icon-Smartphone:before {
    content: "\ec7c";
}

.solid-icon-Smile:before {
    content: "\ec7d";
}

.solid-icon-Smoking-Area:before {
    content: "\ec7e";
}

.solid-icon-Smoking-Pipe:before {
    content: "\ec7f";
}

.solid-icon-Snake:before {
    content: "\ec80";
}

.solid-icon-Snorkel:before {
    content: "\ec81";
}

.solid-icon-Snow-2:before {
    content: "\ec82";
}

.solid-icon-Snow-Dome:before {
    content: "\ec83";
}

.solid-icon-Snow-Storm:before {
    content: "\ec84";
}

.solid-icon-Snow:before {
    content: "\ec85";
}

.solid-icon-Snowflake-2:before {
    content: "\ec86";
}

.solid-icon-Snowflake-3:before {
    content: "\ec87";
}

.solid-icon-Snowflake-4:before {
    content: "\ec88";
}

.solid-icon-Snowflake:before {
    content: "\ec89";
}

.solid-icon-Snowman:before {
    content: "\ec8a";
}

.solid-icon-Soccer-Ball:before {
    content: "\ec8b";
}

.solid-icon-Soccer-Shoes:before {
    content: "\ec8c";
}

.solid-icon-Socks:before {
    content: "\ec8d";
}

.solid-icon-Solar:before {
    content: "\ec8e";
}

.solid-icon-Sound-Wave:before {
    content: "\ec8f";
}

.solid-icon-Sound:before {
    content: "\ec90";
}

.solid-icon-Soundcloud:before {
    content: "\ec91";
}

.solid-icon-Soup:before {
    content: "\ec92";
}

.solid-icon-South-Africa:before {
    content: "\ec93";
}

.solid-icon-Space-Needle:before {
    content: "\ec94";
}

.solid-icon-Spain:before {
    content: "\ec95";
}

.solid-icon-Spam-Mail:before {
    content: "\ec96";
}

.solid-icon-Speach-Bubble:before {
    content: "\ec97";
}

.solid-icon-Speach-Bubble2:before {
    content: "\ec98";
}

.solid-icon-Speach-Bubble3:before {
    content: "\ec99";
}

.solid-icon-Speach-Bubble4:before {
    content: "\ec9a";
}

.solid-icon-Speach-Bubble5:before {
    content: "\ec9b";
}

.solid-icon-Speach-Bubble6:before {
    content: "\ec9c";
}

.solid-icon-Speach-Bubble7:before {
    content: "\ec9d";
}

.solid-icon-Speach-Bubble8:before {
    content: "\ec9e";
}

.solid-icon-Speach-Bubble9:before {
    content: "\ec9f";
}

.solid-icon-Speach-Bubble10:before {
    content: "\eca0";
}

.solid-icon-Speach-Bubble11:before {
    content: "\eca1";
}

.solid-icon-Speach-Bubble12:before {
    content: "\eca2";
}

.solid-icon-Speach-Bubble13:before {
    content: "\eca3";
}

.solid-icon-Speach-BubbleAsking:before {
    content: "\eca4";
}

.solid-icon-Speach-BubbleComic:before {
    content: "\eca5";
}

.solid-icon-Speach-BubbleComic2:before {
    content: "\eca6";
}

.solid-icon-Speach-BubbleComic3:before {
    content: "\eca7";
}

.solid-icon-Speach-BubbleComic4:before {
    content: "\eca8";
}

.solid-icon-Speach-BubbleDialog:before {
    content: "\eca9";
}

.solid-icon-Speach-Bubbles:before {
    content: "\ecaa";
}

.solid-icon-Speak-2:before {
    content: "\ecab";
}

.solid-icon-Speak:before {
    content: "\ecac";
}

.solid-icon-Speaker-2:before {
    content: "\ecad";
}

.solid-icon-Speaker:before {
    content: "\ecae";
}

.solid-icon-Spell-Check:before {
    content: "\ecaf";
}

.solid-icon-Spell-CheckABC:before {
    content: "\ecb0";
}

.solid-icon-Spermium:before {
    content: "\ecb1";
}

.solid-icon-Spider:before {
    content: "\ecb2";
}

.solid-icon-Spiderweb:before {
    content: "\ecb3";
}

.solid-icon-Split-FourSquareWindow:before {
    content: "\ecb4";
}

.solid-icon-Split-Horizontal:before {
    content: "\ecb5";
}

.solid-icon-Split-Horizontal2Window:before {
    content: "\ecb6";
}

.solid-icon-Split-Vertical:before {
    content: "\ecb7";
}

.solid-icon-Split-Vertical2:before {
    content: "\ecb8";
}

.solid-icon-Split-Window:before {
    content: "\ecb9";
}

.solid-icon-Spoder:before {
    content: "\ecba";
}

.solid-icon-Spoon:before {
    content: "\ecbb";
}

.solid-icon-Sport-Mode:before {
    content: "\ecbc";
}

.solid-icon-Sports-Clothings1:before {
    content: "\ecbd";
}

.solid-icon-Sports-Clothings2:before {
    content: "\ecbe";
}

.solid-icon-Sports-Shirt:before {
    content: "\ecbf";
}

.solid-icon-Spot:before {
    content: "\ecc0";
}

.solid-icon-Spray:before {
    content: "\ecc1";
}

.solid-icon-Spread:before {
    content: "\ecc2";
}

.solid-icon-Spring:before {
    content: "\ecc3";
}

.solid-icon-Spurl:before {
    content: "\ecc4";
}

.solid-icon-Spy:before {
    content: "\ecc5";
}

.solid-icon-Squirrel:before {
    content: "\ecc6";
}

.solid-icon-SSL:before {
    content: "\ecc7";
}

.solid-icon-St-BasilsCathedral:before {
    content: "\ecc8";
}

.solid-icon-St-PaulsCathedral:before {
    content: "\ecc9";
}

.solid-icon-Stamp-2:before {
    content: "\ecca";
}

.solid-icon-Stamp:before {
    content: "\eccb";
}

.solid-icon-Stapler:before {
    content: "\eccc";
}

.solid-icon-Star-Track:before {
    content: "\eccd";
}

.solid-icon-Star:before {
    content: "\ecce";
}

.solid-icon-Starfish:before {
    content: "\eccf";
}

.solid-icon-Start2:before {
    content: "\ecd0";
}

.solid-icon-Start-3:before {
    content: "\ecd1";
}

.solid-icon-Start-ways:before {
    content: "\ecd2";
}

.solid-icon-Start:before {
    content: "\ecd3";
}

.solid-icon-Statistic:before {
    content: "\ecd4";
}

.solid-icon-Stethoscope:before {
    content: "\ecd5";
}

.solid-icon-Stop-2:before {
    content: "\ecd6";
}

.solid-icon-Stop-Music:before {
    content: "\ecd7";
}

.solid-icon-Stop:before {
    content: "\ecd8";
}

.solid-icon-Stopwatch-2:before {
    content: "\ecd9";
}

.solid-icon-Stopwatch:before {
    content: "\ecda";
}

.solid-icon-Storm:before {
    content: "\ecdb";
}

.solid-icon-Street-View:before {
    content: "\ecdc";
}

.solid-icon-Street-View2:before {
    content: "\ecdd";
}

.solid-icon-Strikethrough-Text:before {
    content: "\ecde";
}

.solid-icon-Stroller:before {
    content: "\ecdf";
}

.solid-icon-Structure:before {
    content: "\ece0";
}

.solid-icon-Student-Female:before {
    content: "\ece1";
}

.solid-icon-Student-Hat:before {
    content: "\ece2";
}

.solid-icon-Student-Hat2:before {
    content: "\ece3";
}

.solid-icon-Student-Male:before {
    content: "\ece4";
}

.solid-icon-Student-MaleFemale:before {
    content: "\ece5";
}

.solid-icon-Students:before {
    content: "\ece6";
}

.solid-icon-Studio-Flash:before {
    content: "\ece7";
}

.solid-icon-Studio-Lightbox:before {
    content: "\ece8";
}

.solid-icon-Stumbleupon:before {
    content: "\ece9";
}

.solid-icon-Suit:before {
    content: "\ecea";
}

.solid-icon-Suitcase:before {
    content: "\eceb";
}

.solid-icon-Sum-2:before {
    content: "\ecec";
}

.solid-icon-Sum:before {
    content: "\eced";
}

.solid-icon-Summer:before {
    content: "\ecee";
}

.solid-icon-Sun-CloudyRain:before {
    content: "\ecef";
}

.solid-icon-Sun:before {
    content: "\ecf0";
}

.solid-icon-Sunglasses-2:before {
    content: "\ecf1";
}

.solid-icon-Sunglasses-3:before {
    content: "\ecf2";
}

.solid-icon-Sunglasses-Smiley:before {
    content: "\ecf3";
}

.solid-icon-Sunglasses-Smiley2:before {
    content: "\ecf4";
}

.solid-icon-Sunglasses-W:before {
    content: "\ecf5";
}

.solid-icon-Sunglasses-W2:before {
    content: "\ecf6";
}

.solid-icon-Sunglasses-W3:before {
    content: "\ecf7";
}

.solid-icon-Sunglasses:before {
    content: "\ecf8";
}

.solid-icon-Sunrise:before {
    content: "\ecf9";
}

.solid-icon-Sunset:before {
    content: "\ecfa";
}

.solid-icon-Superman:before {
    content: "\ecfb";
}

.solid-icon-Support:before {
    content: "\ecfc";
}

.solid-icon-Surprise:before {
    content: "\ecfd";
}

.solid-icon-Sushi:before {
    content: "\ecfe";
}

.solid-icon-Sweden:before {
    content: "\ecff";
}

.solid-icon-Swimming-Short:before {
    content: "\ed00";
}

.solid-icon-Swimming:before {
    content: "\ed01";
}

.solid-icon-Swimmwear:before {
    content: "\ed02";
}

.solid-icon-Switch:before {
    content: "\ed03";
}

.solid-icon-Switzerland:before {
    content: "\ed04";
}

.solid-icon-Sync-Cloud:before {
    content: "\ed05";
}

.solid-icon-Sync:before {
    content: "\ed06";
}

.solid-icon-Synchronize-2:before {
    content: "\ed07";
}

.solid-icon-Synchronize:before {
    content: "\ed08";
}

.solid-icon-T-Shirt:before {
    content: "\ed09";
}

.solid-icon-Tablet-2:before {
    content: "\ed0a";
}

.solid-icon-Tablet-3:before {
    content: "\ed0b";
}

.solid-icon-Tablet-Orientation:before {
    content: "\ed0c";
}

.solid-icon-Tablet-Phone:before {
    content: "\ed0d";
}

.solid-icon-Tablet-Secure:before {
    content: "\ed0e";
}

.solid-icon-Tablet-Vertical:before {
    content: "\ed0f";
}

.solid-icon-Tablet:before {
    content: "\ed10";
}

.solid-icon-Tactic:before {
    content: "\ed11";
}

.solid-icon-Tag-2:before {
    content: "\ed12";
}

.solid-icon-Tag-3:before {
    content: "\ed13";
}

.solid-icon-Tag-4:before {
    content: "\ed14";
}

.solid-icon-Tag-5:before {
    content: "\ed15";
}

.solid-icon-Tag:before {
    content: "\ed16";
}

.solid-icon-Taj-Mahal:before {
    content: "\ed17";
}

.solid-icon-Talk-Man:before {
    content: "\ed18";
}

.solid-icon-Tap:before {
    content: "\ed19";
}

.solid-icon-Target-Market:before {
    content: "\ed1a";
}

.solid-icon-Target:before {
    content: "\ed1b";
}

.solid-icon-Taurus-2:before {
    content: "\ed1c";
}

.solid-icon-Taurus:before {
    content: "\ed1d";
}

.solid-icon-Taxi-2:before {
    content: "\ed1e";
}

.solid-icon-Taxi-Sign:before {
    content: "\ed1f";
}

.solid-icon-Taxi:before {
    content: "\ed20";
}

.solid-icon-Teacher:before {
    content: "\ed21";
}

.solid-icon-Teapot:before {
    content: "\ed22";
}

.solid-icon-Technorati:before {
    content: "\ed23";
}

.solid-icon-Teddy-Bear:before {
    content: "\ed24";
}

.solid-icon-Tee-Mug:before {
    content: "\ed25";
}

.solid-icon-Telephone-2:before {
    content: "\ed26";
}

.solid-icon-Telephone:before {
    content: "\ed27";
}

.solid-icon-Telescope:before {
    content: "\ed28";
}

.solid-icon-Temperature-2:before {
    content: "\ed29";
}

.solid-icon-Temperature-3:before {
    content: "\ed2a";
}

.solid-icon-Temperature:before {
    content: "\ed2b";
}

@font-face {
    font-family: "themify";
    src: url("../fonts/themify.eot");
    src: url("../fonts/themify.eot") format("embedded-opentype"), url("../fonts/themify.woff") format("woff"), url("../fonts/themify.ttf") format("truetype"), url("../fonts/themify.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^=ti-],
[class*=" ti-"] {
    font-family: "themify";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
    content: "\e600";
}

.ti-volume:before {
    content: "\e601";
}

.ti-user:before {
    content: "\e602";
}

.ti-unlock:before {
    content: "\e603";
}

.ti-unlink:before {
    content: "\e604";
}

.ti-trash:before {
    content: "\e605";
}

.ti-thought:before {
    content: "\e606";
}

.ti-target:before {
    content: "\e607";
}

.ti-tag:before {
    content: "\e608";
}

.ti-tablet:before {
    content: "\e609";
}

.ti-star:before {
    content: "\e60a";
}

.ti-spray:before {
    content: "\e60b";
}

.ti-signal:before {
    content: "\e60c";
}

.ti-shopping-cart:before {
    content: "\e60d";
}

.ti-shopping-cart-full:before {
    content: "\e60e";
}

.ti-settings:before {
    content: "\e60f";
}

.ti-search:before {
    content: "\e610";
}

.ti-zoom-in:before {
    content: "\e611";
}

.ti-zoom-out:before {
    content: "\e612";
}

.ti-cut:before {
    content: "\e613";
}

.ti-ruler:before {
    content: "\e614";
}

.ti-ruler-pencil:before {
    content: "\e615";
}

.ti-ruler-alt:before {
    content: "\e616";
}

.ti-bookmark:before {
    content: "\e617";
}

.ti-bookmark-alt:before {
    content: "\e618";
}

.ti-reload:before {
    content: "\e619";
}

.ti-plus:before {
    content: "\e61a";
}

.ti-pin:before {
    content: "\e61b";
}

.ti-pencil:before {
    content: "\e61c";
}

.ti-pencil-alt:before {
    content: "\e61d";
}

.ti-paint-roller:before {
    content: "\e61e";
}

.ti-paint-bucket:before {
    content: "\e61f";
}

.ti-na:before {
    content: "\e620";
}

.ti-mobile:before {
    content: "\e621";
}

.ti-minus:before {
    content: "\e622";
}

.ti-medall:before {
    content: "\e623";
}

.ti-medall-alt:before {
    content: "\e624";
}

.ti-marker:before {
    content: "\e625";
}

.ti-marker-alt:before {
    content: "\e626";
}

.ti-arrow-up:before {
    content: "\e627";
}

.ti-arrow-right:before {
    content: "\e628";
}

.ti-arrow-left:before {
    content: "\e629";
}

.ti-arrow-down:before {
    content: "\e62a";
}

.ti-lock:before {
    content: "\e62b";
}

.ti-location-arrow:before {
    content: "\e62c";
}

.ti-link:before {
    content: "\e62d";
}

.ti-layout:before {
    content: "\e62e";
}

.ti-layers:before {
    content: "\e62f";
}

.ti-layers-alt:before {
    content: "\e630";
}

.ti-key:before {
    content: "\e631";
}

.ti-import:before {
    content: "\e632";
}

.ti-image:before {
    content: "\e633";
}

.ti-heart:before {
    content: "\e634";
}

.ti-heart-broken:before {
    content: "\e635";
}

.ti-hand-stop:before {
    content: "\e636";
}

.ti-hand-open:before {
    content: "\e637";
}

.ti-hand-drag:before {
    content: "\e638";
}

.ti-folder:before {
    content: "\e639";
}

.ti-flag:before {
    content: "\e63a";
}

.ti-flag-alt:before {
    content: "\e63b";
}

.ti-flag-alt-2:before {
    content: "\e63c";
}

.ti-eye:before {
    content: "\e63d";
}

.ti-export:before {
    content: "\e63e";
}

.ti-exchange-vertical:before {
    content: "\e63f";
}

.ti-desktop:before {
    content: "\e640";
}

.ti-cup:before {
    content: "\e641";
}

.ti-crown:before {
    content: "\e642";
}

.ti-comments:before {
    content: "\e643";
}

.ti-comment:before {
    content: "\e644";
}

.ti-comment-alt:before {
    content: "\e645";
}

.ti-close:before {
    content: "\e646";
}

.ti-clip:before {
    content: "\e647";
}

.ti-angle-up:before {
    content: "\e648";
}

.ti-angle-right:before {
    content: "\e649";
}

.ti-angle-left:before {
    content: "\e64a";
}

.ti-angle-down:before {
    content: "\e64b";
}

.ti-check:before {
    content: "\e64c";
}

.ti-check-box:before {
    content: "\e64d";
}

.ti-camera:before {
    content: "\e64e";
}

.ti-announcement:before {
    content: "\e64f";
}

.ti-brush:before {
    content: "\e650";
}

.ti-briefcase:before {
    content: "\e651";
}

.ti-bolt:before {
    content: "\e652";
}

.ti-bolt-alt:before {
    content: "\e653";
}

.ti-blackboard:before {
    content: "\e654";
}

.ti-bag:before {
    content: "\e655";
}

.ti-move:before {
    content: "\e656";
}

.ti-arrows-vertical:before {
    content: "\e657";
}

.ti-arrows-horizontal:before {
    content: "\e658";
}

.ti-fullscreen:before {
    content: "\e659";
}

.ti-arrow-top-right:before {
    content: "\e65a";
}

.ti-arrow-top-left:before {
    content: "\e65b";
}

.ti-arrow-circle-up:before {
    content: "\e65c";
}

.ti-arrow-circle-right:before {
    content: "\e65d";
}

.ti-arrow-circle-left:before {
    content: "\e65e";
}

.ti-arrow-circle-down:before {
    content: "\e65f";
}

.ti-angle-double-up:before {
    content: "\e660";
}

.ti-angle-double-right:before {
    content: "\e661";
}

.ti-angle-double-left:before {
    content: "\e662";
}

.ti-angle-double-down:before {
    content: "\e663";
}

.ti-zip:before {
    content: "\e664";
}

.ti-world:before {
    content: "\e665";
}

.ti-wheelchair:before {
    content: "\e666";
}

.ti-view-list:before {
    content: "\e667";
}

.ti-view-list-alt:before {
    content: "\e668";
}

.ti-view-grid:before {
    content: "\e669";
}

.ti-uppercase:before {
    content: "\e66a";
}

.ti-upload:before {
    content: "\e66b";
}

.ti-underline:before {
    content: "\e66c";
}

.ti-truck:before {
    content: "\e66d";
}

.ti-timer:before {
    content: "\e66e";
}

.ti-ticket:before {
    content: "\e66f";
}

.ti-thumb-up:before {
    content: "\e670";
}

.ti-thumb-down:before {
    content: "\e671";
}

.ti-text:before {
    content: "\e672";
}

.ti-stats-up:before {
    content: "\e673";
}

.ti-stats-down:before {
    content: "\e674";
}

.ti-split-v:before {
    content: "\e675";
}

.ti-split-h:before {
    content: "\e676";
}

.ti-smallcap:before {
    content: "\e677";
}

.ti-shine:before {
    content: "\e678";
}

.ti-shift-right:before {
    content: "\e679";
}

.ti-shift-left:before {
    content: "\e67a";
}

.ti-shield:before {
    content: "\e67b";
}

.ti-notepad:before {
    content: "\e67c";
}

.ti-server:before {
    content: "\e67d";
}

.ti-quote-right:before {
    content: "\e67e";
}

.ti-quote-left:before {
    content: "\e67f";
}

.ti-pulse:before {
    content: "\e680";
}

.ti-printer:before {
    content: "\e681";
}

.ti-power-off:before {
    content: "\e682";
}

.ti-plug:before {
    content: "\e683";
}

.ti-pie-chart:before {
    content: "\e684";
}

.ti-paragraph:before {
    content: "\e685";
}

.ti-panel:before {
    content: "\e686";
}

.ti-package:before {
    content: "\e687";
}

.ti-music:before {
    content: "\e688";
}

.ti-music-alt:before {
    content: "\e689";
}

.ti-mouse:before {
    content: "\e68a";
}

.ti-mouse-alt:before {
    content: "\e68b";
}

.ti-money:before {
    content: "\e68c";
}

.ti-microphone:before {
    content: "\e68d";
}

.ti-menu:before {
    content: "\e68e";
}

.ti-menu-alt:before {
    content: "\e68f";
}

.ti-map:before {
    content: "\e690";
}

.ti-map-alt:before {
    content: "\e691";
}

.ti-loop:before {
    content: "\e692";
}

.ti-location-pin:before {
    content: "\e693";
}

.ti-list:before {
    content: "\e694";
}

.ti-light-bulb:before {
    content: "\e695";
}

.ti-Italic:before {
    content: "\e696";
}

.ti-info:before {
    content: "\e697";
}

.ti-infinite:before {
    content: "\e698";
}

.ti-id-badge:before {
    content: "\e699";
}

.ti-hummer:before {
    content: "\e69a";
}

.ti-home:before {
    content: "\e69b";
}

.ti-help:before {
    content: "\e69c";
}

.ti-headphone:before {
    content: "\e69d";
}

.ti-harddrives:before {
    content: "\e69e";
}

.ti-harddrive:before {
    content: "\e69f";
}

.ti-gift:before {
    content: "\e6a0";
}

.ti-game:before {
    content: "\e6a1";
}

.ti-filter:before {
    content: "\e6a2";
}

.ti-files:before {
    content: "\e6a3";
}

.ti-file:before {
    content: "\e6a4";
}

.ti-eraser:before {
    content: "\e6a5";
}

.ti-envelope:before {
    content: "\e6a6";
}

.ti-download:before {
    content: "\e6a7";
}

.ti-direction:before {
    content: "\e6a8";
}

.ti-direction-alt:before {
    content: "\e6a9";
}

.ti-dashboard:before {
    content: "\e6aa";
}

.ti-control-stop:before {
    content: "\e6ab";
}

.ti-control-shuffle:before {
    content: "\e6ac";
}

.ti-control-play:before {
    content: "\e6ad";
}

.ti-control-pause:before {
    content: "\e6ae";
}

.ti-control-forward:before {
    content: "\e6af";
}

.ti-control-backward:before {
    content: "\e6b0";
}

.ti-cloud:before {
    content: "\e6b1";
}

.ti-cloud-up:before {
    content: "\e6b2";
}

.ti-cloud-down:before {
    content: "\e6b3";
}

.ti-clipboard:before {
    content: "\e6b4";
}

.ti-car:before {
    content: "\e6b5";
}

.ti-calendar:before {
    content: "\e6b6";
}

.ti-book:before {
    content: "\e6b7";
}

.ti-bell:before {
    content: "\e6b8";
}

.ti-basketball:before {
    content: "\e6b9";
}

.ti-bar-chart:before {
    content: "\e6ba";
}

.ti-bar-chart-alt:before {
    content: "\e6bb";
}

.ti-back-right:before {
    content: "\e6bc";
}

.ti-back-left:before {
    content: "\e6bd";
}

.ti-arrows-corner:before {
    content: "\e6be";
}

.ti-archive:before {
    content: "\e6bf";
}

.ti-anchor:before {
    content: "\e6c0";
}

.ti-align-right:before {
    content: "\e6c1";
}

.ti-align-left:before {
    content: "\e6c2";
}

.ti-align-justify:before {
    content: "\e6c3";
}

.ti-align-center:before {
    content: "\e6c4";
}

.ti-alert:before {
    content: "\e6c5";
}

.ti-alarm-clock:before {
    content: "\e6c6";
}

.ti-agenda:before {
    content: "\e6c7";
}

.ti-write:before {
    content: "\e6c8";
}

.ti-window:before {
    content: "\e6c9";
}

.ti-widgetized:before {
    content: "\e6ca";
}

.ti-widget:before {
    content: "\e6cb";
}

.ti-widget-alt:before {
    content: "\e6cc";
}

.ti-wallet:before {
    content: "\e6cd";
}

.ti-video-clapper:before {
    content: "\e6ce";
}

.ti-video-camera:before {
    content: "\e6cf";
}

.ti-vector:before {
    content: "\e6d0";
}

.ti-themify-logo:before {
    content: "\e6d1";
}

.ti-themify-favicon:before {
    content: "\e6d2";
}

.ti-themify-favicon-alt:before {
    content: "\e6d3";
}

.ti-support:before {
    content: "\e6d4";
}

.ti-stamp:before {
    content: "\e6d5";
}

.ti-split-v-alt:before {
    content: "\e6d6";
}

.ti-slice:before {
    content: "\e6d7";
}

.ti-shortcode:before {
    content: "\e6d8";
}

.ti-shift-right-alt:before {
    content: "\e6d9";
}

.ti-shift-left-alt:before {
    content: "\e6da";
}

.ti-ruler-alt-2:before {
    content: "\e6db";
}

.ti-receipt:before {
    content: "\e6dc";
}

.ti-pin2:before {
    content: "\e6dd";
}

.ti-pin-alt:before {
    content: "\e6de";
}

.ti-pencil-alt2:before {
    content: "\e6df";
}

.ti-palette:before {
    content: "\e6e0";
}

.ti-more:before {
    content: "\e6e1";
}

.ti-more-alt:before {
    content: "\e6e2";
}

.ti-microphone-alt:before {
    content: "\e6e3";
}

.ti-magnet:before {
    content: "\e6e4";
}

.ti-line-double:before {
    content: "\e6e5";
}

.ti-line-dotted:before {
    content: "\e6e6";
}

.ti-line-dashed:before {
    content: "\e6e7";
}

.ti-layout-width-full:before {
    content: "\e6e8";
}

.ti-layout-width-default:before {
    content: "\e6e9";
}

.ti-layout-width-default-alt:before {
    content: "\e6ea";
}

.ti-layout-tab:before {
    content: "\e6eb";
}

.ti-layout-tab-window:before {
    content: "\e6ec";
}

.ti-layout-tab-v:before {
    content: "\e6ed";
}

.ti-layout-tab-min:before {
    content: "\e6ee";
}

.ti-layout-slider:before {
    content: "\e6ef";
}

.ti-layout-slider-alt:before {
    content: "\e6f0";
}

.ti-layout-sidebar-right:before {
    content: "\e6f1";
}

.ti-layout-sidebar-none:before {
    content: "\e6f2";
}

.ti-layout-sidebar-left:before {
    content: "\e6f3";
}

.ti-layout-placeholder:before {
    content: "\e6f4";
}

.ti-layout-menu:before {
    content: "\e6f5";
}

.ti-layout-menu-v:before {
    content: "\e6f6";
}

.ti-layout-menu-separated:before {
    content: "\e6f7";
}

.ti-layout-menu-full:before {
    content: "\e6f8";
}

.ti-layout-media-right-alt:before {
    content: "\e6f9";
}

.ti-layout-media-right:before {
    content: "\e6fa";
}

.ti-layout-media-overlay:before {
    content: "\e6fb";
}

.ti-layout-media-overlay-alt:before {
    content: "\e6fc";
}

.ti-layout-media-overlay-alt-2:before {
    content: "\e6fd";
}

.ti-layout-media-left-alt:before {
    content: "\e6fe";
}

.ti-layout-media-left:before {
    content: "\e6ff";
}

.ti-layout-media-center-alt:before {
    content: "\e700";
}

.ti-layout-media-center:before {
    content: "\e701";
}

.ti-layout-list-thumb:before {
    content: "\e702";
}

.ti-layout-list-thumb-alt:before {
    content: "\e703";
}

.ti-layout-list-post:before {
    content: "\e704";
}

.ti-layout-list-large-image:before {
    content: "\e705";
}

.ti-layout-line-solid:before {
    content: "\e706";
}

.ti-layout-grid4:before {
    content: "\e707";
}

.ti-layout-grid3:before {
    content: "\e708";
}

.ti-layout-grid2:before {
    content: "\e709";
}

.ti-layout-grid2-thumb:before {
    content: "\e70a";
}

.ti-layout-cta-right:before {
    content: "\e70b";
}

.ti-layout-cta-left:before {
    content: "\e70c";
}

.ti-layout-cta-center:before {
    content: "\e70d";
}

.ti-layout-cta-btn-right:before {
    content: "\e70e";
}

.ti-layout-cta-btn-left:before {
    content: "\e70f";
}

.ti-layout-column4:before {
    content: "\e710";
}

.ti-layout-column3:before {
    content: "\e711";
}

.ti-layout-column2:before {
    content: "\e712";
}

.ti-layout-accordion-separated:before {
    content: "\e713";
}

.ti-layout-accordion-merged:before {
    content: "\e714";
}

.ti-layout-accordion-list:before {
    content: "\e715";
}

.ti-ink-pen:before {
    content: "\e716";
}

.ti-info-alt:before {
    content: "\e717";
}

.ti-help-alt:before {
    content: "\e718";
}

.ti-headphone-alt:before {
    content: "\e719";
}

.ti-hand-point-up:before {
    content: "\e71a";
}

.ti-hand-point-right:before {
    content: "\e71b";
}

.ti-hand-point-left:before {
    content: "\e71c";
}

.ti-hand-point-down:before {
    content: "\e71d";
}

.ti-gallery:before {
    content: "\e71e";
}

.ti-face-smile:before {
    content: "\e71f";
}

.ti-face-sad:before {
    content: "\e720";
}

.ti-credit-card:before {
    content: "\e721";
}

.ti-control-skip-forward:before {
    content: "\e722";
}

.ti-control-skip-backward:before {
    content: "\e723";
}

.ti-control-record:before {
    content: "\e724";
}

.ti-control-eject:before {
    content: "\e725";
}

.ti-comments-smiley:before {
    content: "\e726";
}

.ti-brush-alt:before {
    content: "\e727";
}

.ti-youtube:before {
    content: "\e728";
}

.ti-vimeo:before {
    content: "\e729";
}

.ti-twitter:before {
    content: "\e72a";
}

.ti-time:before {
    content: "\e72b";
}

.ti-tumblr:before {
    content: "\e72c";
}

.ti-skype:before {
    content: "\e72d";
}

.ti-share:before {
    content: "\e72e";
}

.ti-share-alt:before {
    content: "\e72f";
}

.ti-rocket:before {
    content: "\e730";
}

.ti-pinterest:before {
    content: "\e731";
}

.ti-new-window:before {
    content: "\e732";
}

.ti-microsoft:before {
    content: "\e733";
}

.ti-list-ol:before {
    content: "\e734";
}

.ti-linkedin:before {
    content: "\e735";
}

.ti-layout-sidebar-2:before {
    content: "\e736";
}

.ti-layout-grid4-alt:before {
    content: "\e737";
}

.ti-layout-grid3-alt:before {
    content: "\e738";
}

.ti-layout-grid2-alt:before {
    content: "\e739";
}

.ti-layout-column4-alt:before {
    content: "\e73a";
}

.ti-layout-column3-alt:before {
    content: "\e73b";
}

.ti-layout-column2-alt:before {
    content: "\e73c";
}

.ti-instagram:before {
    content: "\e73d";
}

.ti-google:before {
    content: "\e73e";
}

.ti-github:before {
    content: "\e73f";
}

.ti-flickr:before {
    content: "\e740";
}

.ti-facebook:before {
    content: "\e741";
}

.ti-dropbox:before {
    content: "\e742";
}

.ti-dribbble:before {
    content: "\e743";
}

.ti-apple:before {
    content: "\e744";
}

.ti-android:before {
    content: "\e745";
}

.ti-save:before {
    content: "\e746";
}

.ti-save-alt:before {
    content: "\e747";
}

.ti-yahoo:before {
    content: "\e748";
}

.ti-wordpress:before {
    content: "\e749";
}

.ti-vimeo-alt:before {
    content: "\e74a";
}

.ti-twitter-alt:before {
    content: "\e74b";
}

.ti-tumblr-alt:before {
    content: "\e74c";
}

.ti-trello:before {
    content: "\e74d";
}

.ti-stack-overflow:before {
    content: "\e74e";
}

.ti-soundcloud:before {
    content: "\e74f";
}

.ti-sharethis:before {
    content: "\e750";
}

.ti-sharethis-alt:before {
    content: "\e751";
}

.ti-reddit:before {
    content: "\e752";
}

.ti-pinterest-alt:before {
    content: "\e753";
}

.ti-microsoft-alt:before {
    content: "\e754";
}

.ti-linux:before {
    content: "\e755";
}

.ti-jsfiddle:before {
    content: "\e756";
}

.ti-joomla:before {
    content: "\e757";
}

.ti-html5:before {
    content: "\e758";
}

.ti-flickr-alt:before {
    content: "\e759";
}

.ti-email:before {
    content: "\e75a";
}

.ti-drupal:before {
    content: "\e75b";
}

.ti-dropbox-alt:before {
    content: "\e75c";
}

.ti-css3:before {
    content: "\e75d";
}

.ti-rss:before {
    content: "\e75e";
}

.ti-rss-alt:before {
    content: "\e75f";
}

@font-face {
    font-family: "simple-line-icons";
    src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0");
    src: url("../fonts/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"), url("../fonts/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"), url("../fonts/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"), url("../fonts/Simple-Line-Icons.woff?v=2.4.0") format("woff"), url("../fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*
 Use the following CSS code if you want to have a class per icon.
 Instead of a list of all class selectors, you can use the generic [class*="icon-"] selector, but it's slower:
*/
.icon-user,
.icon-people,
.icon-user-female,
.icon-user-follow,
.icon-user-following,
.icon-user-unfollow,
.icon-login,
.icon-logout,
.icon-emotsmile,
.icon-phone,
.icon-call-end,
.icon-call-in,
.icon-call-out,
.icon-map,
.icon-location-pin,
.icon-direction,
.icon-directions,
.icon-compass,
.icon-layers,
.icon-menu,
.icon-list,
.icon-options-vertical,
.icon-options,
.icon-arrow-down,
.icon-arrow-left,
.icon-arrow-right,
.icon-arrow-up,
.icon-arrow-up-circle,
.icon-arrow-left-circle,
.icon-arrow-right-circle,
.icon-arrow-down-circle,
.icon-check,
.icon-clock,
.icon-plus,
.icon-minus,
.icon-close,
.icon-event,
.icon-exclamation,
.icon-organization,
.icon-trophy,
.icon-screen-smartphone,
.icon-screen-desktop,
.icon-plane,
.icon-notebook,
.icon-mustache,
.icon-mouse,
.icon-magnet,
.icon-energy,
.icon-disc,
.icon-cursor,
.icon-cursor-move,
.icon-crop,
.icon-chemistry,
.icon-speedometer,
.icon-shield,
.icon-screen-tablet,
.icon-magic-wand,
.icon-hourglass,
.icon-graduation,
.icon-ghost,
.icon-game-controller,
.icon-fire,
.icon-eyeglass,
.icon-envelope-open,
.icon-envelope-letter,
.icon-bell,
.icon-badge,
.icon-anchor,
.icon-wallet,
.icon-vector,
.icon-speech,
.icon-puzzle,
.icon-printer,
.icon-present,
.icon-playlist,
.icon-pin,
.icon-picture,
.icon-handbag,
.icon-globe-alt,
.icon-globe,
.icon-folder-alt,
.icon-folder,
.icon-film,
.icon-feed,
.icon-drop,
.icon-drawer,
.icon-docs,
.icon-doc,
.icon-diamond,
.icon-cup,
.icon-calculator,
.icon-bubbles,
.icon-briefcase,
.icon-book-open,
.icon-basket-loaded,
.icon-basket,
.icon-bag,
.icon-action-undo,
.icon-action-redo,
.icon-wrench,
.icon-umbrella,
.icon-trash,
.icon-tag,
.icon-support,
.icon-frame,
.icon-size-fullscreen,
.icon-size-actual,
.icon-shuffle,
.icon-share-alt,
.icon-share,
.icon-rocket,
.icon-question,
.icon-pie-chart,
.icon-pencil,
.icon-note,
.icon-loop,
.icon-home,
.icon-grid,
.icon-graph,
.icon-microphone,
.icon-music-tone-alt,
.icon-music-tone,
.icon-earphones-alt,
.icon-earphones,
.icon-equalizer,
.icon-like,
.icon-dislike,
.icon-control-start,
.icon-control-rewind,
.icon-control-play,
.icon-control-pause,
.icon-control-forward,
.icon-control-end,
.icon-volume-1,
.icon-volume-2,
.icon-volume-off,
.icon-calendar,
.icon-bulb,
.icon-chart,
.icon-ban,
.icon-bubble,
.icon-camrecorder,
.icon-camera,
.icon-cloud-download,
.icon-cloud-upload,
.icon-envelope,
.icon-eye,
.icon-flag,
.icon-heart,
.icon-info,
.icon-key,
.icon-link,
.icon-lock,
.icon-lock-open,
.icon-magnifier,
.icon-magnifier-add,
.icon-magnifier-remove,
.icon-paper-clip,
.icon-paper-plane,
.icon-power,
.icon-refresh,
.icon-reload,
.icon-settings,
.icon-star,
.icon-symbol-female,
.icon-symbol-male,
.icon-target,
.icon-credit-card,
.icon-paypal,
.icon-social-tumblr,
.icon-social-twitter,
.icon-social-facebook,
.icon-social-instagram,
.icon-social-linkedin,
.icon-social-pinterest,
.icon-social-github,
.icon-social-google,
.icon-social-reddit,
.icon-social-skype,
.icon-social-dribbble,
.icon-social-behance,
.icon-social-foursqare,
.icon-social-soundcloud,
.icon-social-spotify,
.icon-social-stumbleupon,
.icon-social-youtube,
.icon-social-dropbox,
.icon-social-vkontakte,
.icon-social-steam {
    font-family: "simple-line-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
    content: "\e005";
}

.icon-people:before {
    content: "\e001";
}

.icon-user-female:before {
    content: "\e000";
}

.icon-user-follow:before {
    content: "\e002";
}

.icon-user-following:before {
    content: "\e003";
}

.icon-user-unfollow:before {
    content: "\e004";
}

.icon-login:before {
    content: "\e066";
}

.icon-logout:before {
    content: "\e065";
}

.icon-emotsmile:before {
    content: "\e021";
}

.icon-phone:before {
    content: "\e600";
}

.icon-call-end:before {
    content: "\e048";
}

.icon-call-in:before {
    content: "\e047";
}

.icon-call-out:before {
    content: "\e046";
}

.icon-map:before {
    content: "\e033";
}

.icon-location-pin:before {
    content: "\e096";
}

.icon-direction:before {
    content: "\e042";
}

.icon-directions:before {
    content: "\e041";
}

.icon-compass:before {
    content: "\e045";
}

.icon-layers:before {
    content: "\e034";
}

.icon-menu:before {
    content: "\e601";
}

.icon-list:before {
    content: "\e067";
}

.icon-options-vertical:before {
    content: "\e602";
}

.icon-options:before {
    content: "\e603";
}

.icon-arrow-down:before {
    content: "\e604";
}

.icon-arrow-left:before {
    content: "\e605";
}

.icon-arrow-right:before {
    content: "\e606";
}

.icon-arrow-up:before {
    content: "\e607";
}

.icon-arrow-up-circle:before {
    content: "\e078";
}

.icon-arrow-left-circle:before {
    content: "\e07a";
}

.icon-arrow-right-circle:before {
    content: "\e079";
}

.icon-arrow-down-circle:before {
    content: "\e07b";
}

.icon-check:before {
    content: "\e080";
}

.icon-clock:before {
    content: "\e081";
}

.icon-plus:before {
    content: "\e095";
}

.icon-minus:before {
    content: "\e615";
}

.icon-close:before {
    content: "\e082";
}

.icon-event:before {
    content: "\e619";
}

.icon-exclamation:before {
    content: "\e617";
}

.icon-organization:before {
    content: "\e616";
}

.icon-trophy:before {
    content: "\e006";
}

.icon-screen-smartphone:before {
    content: "\e010";
}

.icon-screen-desktop:before {
    content: "\e011";
}

.icon-plane:before {
    content: "\e012";
}

.icon-notebook:before {
    content: "\e013";
}

.icon-mustache:before {
    content: "\e014";
}

.icon-mouse:before {
    content: "\e015";
}

.icon-magnet:before {
    content: "\e016";
}

.icon-energy:before {
    content: "\e020";
}

.icon-disc:before {
    content: "\e022";
}

.icon-cursor:before {
    content: "\e06e";
}

.icon-cursor-move:before {
    content: "\e023";
}

.icon-crop:before {
    content: "\e024";
}

.icon-chemistry:before {
    content: "\e026";
}

.icon-speedometer:before {
    content: "\e007";
}

.icon-shield:before {
    content: "\e00e";
}

.icon-screen-tablet:before {
    content: "\e00f";
}

.icon-magic-wand:before {
    content: "\e017";
}

.icon-hourglass:before {
    content: "\e018";
}

.icon-graduation:before {
    content: "\e019";
}

.icon-ghost:before {
    content: "\e01a";
}

.icon-game-controller:before {
    content: "\e01b";
}

.icon-fire:before {
    content: "\e01c";
}

.icon-eyeglass:before {
    content: "\e01d";
}

.icon-envelope-open:before {
    content: "\e01e";
}

.icon-envelope-letter:before {
    content: "\e01f";
}

.icon-bell:before {
    content: "\e027";
}

.icon-badge:before {
    content: "\e028";
}

.icon-anchor:before {
    content: "\e029";
}

.icon-wallet:before {
    content: "\e02a";
}

.icon-vector:before {
    content: "\e02b";
}

.icon-speech:before {
    content: "\e02c";
}

.icon-puzzle:before {
    content: "\e02d";
}

.icon-printer:before {
    content: "\e02e";
}

.icon-present:before {
    content: "\e02f";
}

.icon-playlist:before {
    content: "\e030";
}

.icon-pin:before {
    content: "\e031";
}

.icon-picture:before {
    content: "\e032";
}

.icon-handbag:before {
    content: "\e035";
}

.icon-globe-alt:before {
    content: "\e036";
}

.icon-globe:before {
    content: "\e037";
}

.icon-folder-alt:before {
    content: "\e039";
}

.icon-folder:before {
    content: "\e089";
}

.icon-film:before {
    content: "\e03a";
}

.icon-feed:before {
    content: "\e03b";
}

.icon-drop:before {
    content: "\e03e";
}

.icon-drawer:before {
    content: "\e03f";
}

.icon-docs:before {
    content: "\e040";
}

.icon-doc:before {
    content: "\e085";
}

.icon-diamond:before {
    content: "\e043";
}

.icon-cup:before {
    content: "\e044";
}

.icon-calculator:before {
    content: "\e049";
}

.icon-bubbles:before {
    content: "\e04a";
}

.icon-briefcase:before {
    content: "\e04b";
}

.icon-book-open:before {
    content: "\e04c";
}

.icon-basket-loaded:before {
    content: "\e04d";
}

.icon-basket:before {
    content: "\e04e";
}

.icon-bag:before {
    content: "\e04f";
}

.icon-action-undo:before {
    content: "\e050";
}

.icon-action-redo:before {
    content: "\e051";
}

.icon-wrench:before {
    content: "\e052";
}

.icon-umbrella:before {
    content: "\e053";
}

.icon-trash:before {
    content: "\e054";
}

.icon-tag:before {
    content: "\e055";
}

.icon-support:before {
    content: "\e056";
}

.icon-frame:before {
    content: "\e038";
}

.icon-size-fullscreen:before {
    content: "\e057";
}

.icon-size-actual:before {
    content: "\e058";
}

.icon-shuffle:before {
    content: "\e059";
}

.icon-share-alt:before {
    content: "\e05a";
}

.icon-share:before {
    content: "\e05b";
}

.icon-rocket:before {
    content: "\e05c";
}

.icon-question:before {
    content: "\e05d";
}

.icon-pie-chart:before {
    content: "\e05e";
}

.icon-pencil:before {
    content: "\e05f";
}

.icon-note:before {
    content: "\e060";
}

.icon-loop:before {
    content: "\e064";
}

.icon-home:before {
    content: "\e069";
}

.icon-grid:before {
    content: "\e06a";
}

.icon-graph:before {
    content: "\e06b";
}

.icon-microphone:before {
    content: "\e063";
}

.icon-music-tone-alt:before {
    content: "\e061";
}

.icon-music-tone:before {
    content: "\e062";
}

.icon-earphones-alt:before {
    content: "\e03c";
}

.icon-earphones:before {
    content: "\e03d";
}

.icon-equalizer:before {
    content: "\e06c";
}

.icon-like:before {
    content: "\e068";
}

.icon-dislike:before {
    content: "\e06d";
}

.icon-control-start:before {
    content: "\e06f";
}

.icon-control-rewind:before {
    content: "\e070";
}

.icon-control-play:before {
    content: "\e071";
}

.icon-control-pause:before {
    content: "\e072";
}

.icon-control-forward:before {
    content: "\e073";
}

.icon-control-end:before {
    content: "\e074";
}

.icon-volume-1:before {
    content: "\e09f";
}

.icon-volume-2:before {
    content: "\e0a0";
}

.icon-volume-off:before {
    content: "\e0a1";
}

.icon-calendar:before {
    content: "\e075";
}

.icon-bulb:before {
    content: "\e076";
}

.icon-chart:before {
    content: "\e077";
}

.icon-ban:before {
    content: "\e07c";
}

.icon-bubble:before {
    content: "\e07d";
}

.icon-camrecorder:before {
    content: "\e07e";
}

.icon-camera:before {
    content: "\e07f";
}

.icon-cloud-download:before {
    content: "\e083";
}

.icon-cloud-upload:before {
    content: "\e084";
}

.icon-envelope:before {
    content: "\e086";
}

.icon-eye:before {
    content: "\e087";
}

.icon-flag:before {
    content: "\e088";
}

.icon-heart:before {
    content: "\e08a";
}

.icon-info:before {
    content: "\e08b";
}

.icon-key:before {
    content: "\e08c";
}

.icon-link:before {
    content: "\e08d";
}

.icon-lock:before {
    content: "\e08e";
}

.icon-lock-open:before {
    content: "\e08f";
}

.icon-magnifier:before {
    content: "\e090";
}

.icon-magnifier-add:before {
    content: "\e091";
}

.icon-magnifier-remove:before {
    content: "\e092";
}

.icon-paper-clip:before {
    content: "\e093";
}

.icon-paper-plane:before {
    content: "\e094";
}

.icon-power:before {
    content: "\e097";
}

.icon-refresh:before {
    content: "\e098";
}

.icon-reload:before {
    content: "\e099";
}

.icon-settings:before {
    content: "\e09a";
}

.icon-star:before {
    content: "\e09b";
}

.icon-symbol-female:before {
    content: "\e09c";
}

.icon-symbol-male:before {
    content: "\e09d";
}

.icon-target:before {
    content: "\e09e";
}

.icon-credit-card:before {
    content: "\e025";
}

.icon-paypal:before {
    content: "\e608";
}

.icon-social-tumblr:before {
    content: "\e00a";
}

.icon-social-twitter:before {
    content: "\e009";
}

.icon-social-facebook:before {
    content: "\e00b";
}

.icon-social-instagram:before {
    content: "\e609";
}

.icon-social-linkedin:before {
    content: "\e60a";
}

.icon-social-pinterest:before {
    content: "\e60b";
}

.icon-social-github:before {
    content: "\e60c";
}

.icon-social-google:before {
    content: "\e60d";
}

.icon-social-reddit:before {
    content: "\e60e";
}

.icon-social-skype:before {
    content: "\e60f";
}

.icon-social-dribbble:before {
    content: "\e00d";
}

.icon-social-behance:before {
    content: "\e610";
}

.icon-social-foursqare:before {
    content: "\e611";
}

.icon-social-soundcloud:before {
    content: "\e612";
}

.icon-social-spotify:before {
    content: "\e613";
}

.icon-social-stumbleupon:before {
    content: "\e614";
}

.icon-social-youtube:before {
    content: "\e008";
}

.icon-social-dropbox:before {
    content: "\e00c";
}

.icon-social-vkontakte:before {
    content: "\e618";
}

.icon-social-steam:before {
    content: "\e620";
}

/*
ET Line
Plugin URI: https://museomix.be/doc/Icons-ET-LineIcons.html
*/
@font-face {
    font-family: et-line;
    src: url(../fonts/et-line.eot);
    src: url(../fonts/et-line.eot?#iefix) format("embedded-opentype"), url(../fonts/et-line.woff) format("woff"), url(../fonts/et-line.ttf) format("truetype"), url(../fonts/et-line.svg#et-line) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

[data-icon]:before {
    font-family: et-line;
    content: attr(data-icon);
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}

.icon-adjustments,
.icon-alarmclock,
.icon-anchor,
.icon-aperture,
.icon-attachment,
.icon-bargraph,
.icon-basket,
.icon-beaker,
.icon-bike,
.icon-book-open,
.icon-briefcase,
.icon-browser,
.icon-calendar,
.icon-camera,
.icon-caution,
.icon-chat,
.icon-circle-compass,
.icon-clipboard,
.icon-clock,
.icon-cloud,
.icon-compass,
.icon-desktop,
.icon-dial,
.icon-document,
.icon-documents,
.icon-download,
.icon-dribbble,
.icon-edit,
.icon-envelope,
.icon-expand,
.icon-facebook,
.icon-flag,
.icon-focus,
.icon-gears,
.icon-genius,
.icon-gift,
.icon-global,
.icon-globe,
.icon-googleplus,
.icon-grid,
.icon-happy,
.icon-hazardous,
.icon-heart,
.icon-hotairballoon,
.icon-hourglass,
.icon-key,
.icon-laptop,
.icon-layers,
.icon-lifesaver,
.icon-lightbulb,
.icon-linegraph,
.icon-linkedin,
.icon-lock,
.icon-magnifying-glass,
.icon-map,
.icon-map-pin,
.icon-megaphone,
.icon-mic,
.icon-mobile,
.icon-newspaper,
.icon-notebook,
.icon-paintbrush,
.icon-paperclip,
.icon-pencil,
.icon-phone,
.icon-picture,
.icon-pictures,
.icon-piechart,
.icon-presentation,
.icon-pricetags,
.icon-printer,
.icon-profile-female,
.icon-profile-male,
.icon-puzzle,
.icon-quote,
.icon-recycle,
.icon-refresh,
.icon-ribbon,
.icon-rss,
.icon-sad,
.icon-scissors,
.icon-scope,
.icon-search,
.icon-shield,
.icon-speedometer,
.icon-strategy,
.icon-streetsign,
.icon-tablet,
.icon-target,
.icon-telescope,
.icon-toolbox,
.icon-tools,
.icon-tools-2,
.icon-trophy,
.icon-tumblr,
.icon-twitter,
.icon-upload,
.icon-video,
.icon-wallet,
.icon-wine {
    font-family: et-line;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
}

.icon-mobile:before {
    content: "\e000";
}

.icon-laptop:before {
    content: "\e001";
}

.icon-desktop:before {
    content: "\e002";
}

.icon-tablet:before {
    content: "\e003";
}

.icon-phone:before {
    content: "\e004";
}

.icon-document:before {
    content: "\e005";
}

.icon-documents:before {
    content: "\e006";
}

.icon-search:before {
    content: "\e007";
}

.icon-clipboard:before {
    content: "\e008";
}

.icon-newspaper:before {
    content: "\e009";
}

.icon-notebook:before {
    content: "\e00a";
}

.icon-book-open:before {
    content: "\e00b";
}

.icon-browser:before {
    content: "\e00c";
}

.icon-calendar:before {
    content: "\e00d";
}

.icon-presentation:before {
    content: "\e00e";
}

.icon-picture:before {
    content: "\e00f";
}

.icon-pictures:before {
    content: "\e010";
}

.icon-video:before {
    content: "\e011";
}

.icon-camera:before {
    content: "\e012";
}

.icon-printer:before {
    content: "\e013";
}

.icon-toolbox:before {
    content: "\e014";
}

.icon-briefcase:before {
    content: "\e015";
}

.icon-wallet:before {
    content: "\e016";
}

.icon-gift:before {
    content: "\e017";
}

.icon-bargraph:before {
    content: "\e018";
}

.icon-grid:before {
    content: "\e019";
}

.icon-expand:before {
    content: "\e01a";
}

.icon-focus:before {
    content: "\e01b";
}

.icon-edit:before {
    content: "\e01c";
}

.icon-adjustments:before {
    content: "\e01d";
}

.icon-ribbon:before {
    content: "\e01e";
}

.icon-hourglass:before {
    content: "\e01f";
}

.icon-lock:before {
    content: "\e020";
}

.icon-megaphone:before {
    content: "\e021";
}

.icon-shield:before {
    content: "\e022";
}

.icon-trophy:before {
    content: "\e023";
}

.icon-flag:before {
    content: "\e024";
}

.icon-map:before {
    content: "\e025";
}

.icon-puzzle:before {
    content: "\e026";
}

.icon-basket:before {
    content: "\e027";
}

.icon-envelope:before {
    content: "\e028";
}

.icon-streetsign:before {
    content: "\e029";
}

.icon-telescope:before {
    content: "\e02a";
}

.icon-gears:before {
    content: "\e02b";
}

.icon-key:before {
    content: "\e02c";
}

.icon-paperclip:before {
    content: "\e02d";
}

.icon-attachment:before {
    content: "\e02e";
}

.icon-pricetags:before {
    content: "\e02f";
}

.icon-lightbulb:before {
    content: "\e030";
}

.icon-layers:before {
    content: "\e031";
}

.icon-pencil:before {
    content: "\e032";
}

.icon-tools:before {
    content: "\e033";
}

.icon-tools-2:before {
    content: "\e034";
}

.icon-scissors:before {
    content: "\e035";
}

.icon-paintbrush:before {
    content: "\e036";
}

.icon-magnifying-glass:before {
    content: "\e037";
}

.icon-circle-compass:before {
    content: "\e038";
}

.icon-linegraph:before {
    content: "\e039";
}

.icon-mic:before {
    content: "\e03a";
}

.icon-strategy:before {
    content: "\e03b";
}

.icon-beaker:before {
    content: "\e03c";
}

.icon-caution:before {
    content: "\e03d";
}

.icon-recycle:before {
    content: "\e03e";
}

.icon-anchor:before {
    content: "\e03f";
}

.icon-profile-male:before {
    content: "\e040";
}

.icon-profile-female:before {
    content: "\e041";
}

.icon-bike:before {
    content: "\e042";
}

.icon-wine:before {
    content: "\e043";
}

.icon-hotairballoon:before {
    content: "\e044";
}

.icon-globe:before {
    content: "\e045";
}

.icon-genius:before {
    content: "\e046";
}

.icon-map-pin:before {
    content: "\e047";
}

.icon-dial:before {
    content: "\e048";
}

.icon-chat:before {
    content: "\e049";
}

.icon-heart:before {
    content: "\e04a";
}

.icon-cloud:before {
    content: "\e04b";
}

.icon-upload:before {
    content: "\e04c";
}

.icon-download:before {
    content: "\e04d";
}

.icon-target:before {
    content: "\e04e";
}

.icon-hazardous:before {
    content: "\e04f";
}

.icon-piechart:before {
    content: "\e050";
}

.icon-speedometer:before {
    content: "\e051";
}

.icon-global:before {
    content: "\e052";
}

.icon-compass:before {
    content: "\e053";
}

.icon-lifesaver:before {
    content: "\e054";
}

.icon-clock:before {
    content: "\e055";
}

.icon-aperture:before {
    content: "\e056";
}

.icon-quote:before {
    content: "\e057";
}

.icon-scope:before {
    content: "\e058";
}

.icon-alarmclock:before {
    content: "\e059";
}

.icon-refresh:before {
    content: "\e05a";
}

.icon-happy:before {
    content: "\e05b";
}

.icon-sad:before {
    content: "\e05c";
}

.icon-facebook:before {
    content: "\e05d";
}

.icon-twitter:before {
    content: "\e05e";
}

.icon-googleplus:before {
    content: "\e05f";
}

.icon-rss:before {
    content: "\e060";
}

.icon-tumblr:before {
    content: "\e061";
}

.icon-linkedin:before {
    content: "\e062";
}

.icon-dribbble:before {
    content: "\e063";
}