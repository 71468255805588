.divider-full {
  .divider-border {
    &:before {
      flex: 1 1 auto;
      content: "";
      border-top: 1px solid #e4e4e4;
    }

    &:after {
      flex: 1 1 auto;
      content: "";
      border-top: 1px solid #e4e4e4;
    }

    &.divider-light {
      &:before {
        border-color: rgba(255, 255, 255, 0.1);
      }

      &:after {
        border-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.architecture-hamburgermenu {
  .close-btn {
    i {
      font-family: themify;
      font-style: normal;

      &:before {
        content: "\e646";
      }
    }
  }
}

.architecture-slider {
  .swiper-button-prev,
  .swiper-button-next {
    width: 66px;
    height: 67px;
    right: 0;
    left: auto;
    background-color: #fff;
    color: #000;
    transition: var(--default-transition);

    &:after {
      font-size: 20px;
    }

    &:hover {
      background-color: #23262d;
      color: #fff;
    }
  }

  .swiper-button-prev {
    top: auto;
    bottom: 264px;
    transform: translateY(0);

    @screen lg {
      bottom: 245px;
    }

    @screen sm {
      bottom: 67px;
    }
  }

  .swiper-button-next {
    bottom: 198px;
    top: auto;
    transform: translateY(0);
    left: auto;

    @screen lg {
      bottom: 178px;
    }

    @screen sm {
      bottom: 0;
    }
  }
}

.work-architecture-slider {
  .portfolio-box {
    transition: var(--default-transition);
    .portfolio-hover {
      transition: var(--default-transition);
      transition-delay: 0.2s;
      opacity: 0;
      margin-top: 15px;
    }
    .portfolio-image {
      @apply bg-[#cee002];
      img {
        transition: var(--default-transition);
      }
    }

    &:hover {
      transition: var(--default-transition);
      .portfolio-hover {
        opacity: 1;
        margin-top: 0;
      }
      .portfolio-image {
        img {
          opacity: 0.15;
        }
      }
    }
  }
}
