.custom-title-class {
    color: #FFF;
}

a.custom-title-class2 {
    color: #232323;
    text-decoration: none;
    position: relative;
}

.custom-news-class {
    color: #FFF;
}

.text-14 {
    font-size: 14px;
}

.text-12 {
    font-size: 12px;
}

.text-13 {
    font-size: 13px;
}
.btncolor{
    --bs-link-color: 'white' 
}

.custom-bg {
    background-image: linear-gradient(to right top, #b783ff, #e37be0, #fa7cc1, #ff85a6, #ff9393);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.arrow-list {
    list-style-type: none;
    padding-left: 0;
}

.arrow-list li {
    position: relative;
    padding-left: 20px; 
    margin-bottom: 10px;
}

.arrow-list li::before {
    content: '\2192'; 
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
    line-height: inherit;
    color: #85260E; 
}

.responsive-image {
    width: 100%;
    max-height: 399px;
    max-width: 500px;
    border-radius: 6px;
}


@media (max-width: 768px) {
    .responsive-image {
        max-width: 100%;
        max-height: 275px;
    }
}

.custom-nav-item {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    
}

.custom-nav-item .nav-link,
.custom-nav-item .dropdown-toggle {
    color: #000 !important;
    padding: 0 !important;
}

.custom-nav-item .dropdown-toggle::after {
    display: none;
}

.nav-separator {
    height: 1px;
    background-color: #ccc;
    margin: 0 20px;
}

.card-image {
    transition: box-shadow 0.3s ease-in-out;
}

.card-image:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}
.border-right-gray {
    border-right: 1px solid #ccc;
    padding-right: 55px; 
    margin-right: 55px;  


    @media (max-width: 768px) {
        padding-right: 0;
        margin-right: 0;
    }
}

.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px; 
}

.card-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120%;
    height: 120%;
    font-size: 16px; 
    text-align: center;
}

.main-title-container {
    max-width: 90%; 
    margin: 0 auto;  
}

.input-field {
    color: #000; 
    margin-bottom: 6px;
}

.input-field:focus {
    border: 1px solid #000; 
    outline: none; 
}
